import { faPlus, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import ContactInput from "src/components/Input/ContactInput";
import { KeyStringVal } from "src/types/general";

const MultiContactFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  required?: boolean;
}) => {
  return (
    <section className="flex flex-col flex-grow gap-2">
      <ul className="flex flex-col flex-grow gap-1">
        {inputs[keyName]?.map((_: KeyStringVal, index: number) => {
          return (
            <li key={index} className="flex items-center gap-4">
              <article className="flex items-start gap-4">
                <ContactInput
                  label="Contact Name"
                  keyName="contact_name"
                  index={index}
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <ContactInput
                  label="Contact Email"
                  keyName="contact_email"
                  index={index}
                  inputs={inputs}
                  setInputs={setInputs}
                  singleEmail
                />
                <ContactInput
                  label="Contact Phone"
                  keyName="contact_phone"
                  index={index}
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <ContactInput
                  label="Location"
                  keyName="location"
                  index={index}
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <ContactInput
                  label="Data Host Location"
                  keyName="data_host_location"
                  index={index}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </article>
              <button
                className="red-trash-button"
                onClick={() =>
                  setInputs({
                    ...inputs,
                    contacts: inputs.contacts.filter(
                      (_: KeyStringVal, contactIndex: number) =>
                        index !== contactIndex
                    ),
                  })
                }
              >
                <FontAwesomeIcon icon={faTrashCan} />
              </button>
            </li>
          );
        })}
      </ul>
      <button
        className="place-self-end blue-button"
        onClick={() =>
          setInputs({
            ...inputs,
            [keyName]: [
              ...(inputs[keyName] || []),
              {
                contact_name: "",
                contact_email: "",
                contact_phone: "",
                location: "",
                data_host_location: "",
              },
            ],
          })
        }
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Contact
      </button>
    </section>
  );
};

export default MultiContactFilter;
