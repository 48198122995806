import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import SelectFrameworkFilter from "src/components/Filter/RegulationPolicy/SelectFrameworkFilter";
import { GetFrameworksWithControls } from "src/services/regulation-policy/framework";
import { AddAudit } from "src/services/audit-management";
import RegularInput from "src/components/Input/RegularInput";
import ToastLayout from "../../layouts/ToastLayout";
import { checkIsReadOnly } from "src/utils/general";
import CheckboxInput from "src/components/Input/CheckboxInput";

const NewAudit = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [selectedFramework, setSelectedFramework] = useState<any>({});

  const { data: frameworksWithControls } = GetFrameworksWithControls();
  const addAudit = AddAudit();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAudit.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({});
          setSelectedFramework({});
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Audit / Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">
            New Audit / Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <CheckboxInput
            label="Use Organization Controls"
            keyName="use_organization_controls"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="grid gap-2">
            <h4>Framework</h4>
            <SelectFrameworkFilter
              selectedFramework={selectedFramework}
              setSelectedFramework={setSelectedFramework}
              list={frameworksWithControls?.data}
              disabled={inputs.use_organization_controls}
            />
          </article>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                !inputs.name ||
                (!inputs.use_organization_controls && !selectedFramework.id)
              }
              className="blue-button"
              onClick={() => {
                addAudit.mutate({
                  inputs: {
                    ...inputs,
                    ...(!inputs.use_organization_controls && {
                      framework_id: selectedFramework.id,
                    }),
                  },
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAudit.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New audit has been created"
      />
    </>
  );
};

export default NewAudit;
