import React from "react";
import { Customer } from "src/types/settings";
import CustomerModules from "./CustomerModules";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLightbulb, faUser } from "@fortawesome/free-solid-svg-icons";

const CustomerDetail = ({ customer }: { customer: Customer }) => {
  return (
    <li className="flex flex-col flex-grow gap-4 p-4 dark:bg-gray-800 black-shadow rounded-xl">
      <article className="grid gap-1">
        <h4 className="text-b1-semi">{customer.customer_name}</h4>
        <p className="text-b2-reg">{customer.customer_alias}</p>
        <p className="text-b2-reg">{customer.address}</p>
        <article className="flex items-center gap-2 text-b2-reg">
          {customer.has_site_admin === true && (
            <span>
              <FontAwesomeIcon icon={faUser} className="dark:text-blue-500" />{" "}
              Site Admin
            </span>
          )}
          {customer.current_user_customer === true && (
            <span>
              <FontAwesomeIcon icon={faLightbulb} /> Current Customer
            </span>
          )}
        </article>
      </article>

      <CustomerModules customerID={customer.customer_id} />
    </li>
  );
};

export default CustomerDetail;
