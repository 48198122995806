import React, { useState } from "react";
import SortColumn from "src/components/Button/SortColumn";
import RowValue from "src/components/General/RowValue";
import TablePagination from "src/components/General/TablePagination";
import { initialSort, pageSize } from "src/constants/general";
import TableLayout from "src/layouts/TableLayout";
import { GetSLAViolations } from "src/services/summaries/sla-remediation";
import { ListHeader } from "src/types/general";
import { sortRows } from "src/utils/general";

const Violations = ({
  selectedIntegrationType,
  selectedSourceAccountID,
  selectedService,
  selectedSeverity,
  earliestTime,
  latestTime,
}: {
  selectedIntegrationType: string;
  selectedSourceAccountID: string;
  selectedService: string;
  selectedSeverity: string;
  earliestTime: number;
  latestTime: number;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState(initialSort);

  const { data: violations } = GetSLAViolations(
    selectedIntegrationType,
    selectedSourceAccountID,
    selectedService,
    selectedSeverity,
    earliestTime,
    latestTime
  );

  const totalCount = violations?.pager.total_results || 0;
  const totalPages = violations?.pager.num_pages;
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;
  const sortedViolations = sortRows(violations?.data, sort);

  return (
    <section className="grid p-4 dark:bg-gray-900">
      {violations && (
        <section className="flex flex-col flex-grow content-start gap-4">
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          {sortedViolations?.length > 0 ? (
            <TableLayout height="max-h-[20rem]">
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {violations.metadata.headers.map((col: ListHeader) => {
                    return (
                      <th
                        scope="col"
                        key={col.display_name}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <h4>{col.display_name}</h4>
                          <SortColumn
                            propertyName={col.property_name}
                            setSort={setSort}
                          />
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                {sortedViolations?.map((row: any, rowIndex: number) => {
                  return (
                    <tr
                      key={rowIndex}
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    >
                      {violations.metadata.headers.map(
                        (col: ListHeader, index: number) => {
                          return (
                            <td
                              key={index}
                              className="relative px-6 py-3 last:pr-16 text-left break-words"
                            >
                              {col.property_name === "cve_id" ? (
                                <a
                                  href={`/cves/details?cve_id=${row.cve_id}`}
                                  className="hover:underline"
                                >
                                  {row.cve_id}
                                </a>
                              ) : (
                                <RowValue row={row} col={col.property_name} />
                              )}
                            </td>
                          );
                        }
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </TableLayout>
          ) : (
            <p>No data available</p>
          )}
        </section>
      )}
    </section>
  );
};

export default Violations;
