import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { LineWave } from "react-loader-spinner";
import {
  GenerateControls,
  GeneratePolicyMappings,
  GetControlsStatus,
  GetPolicyMappingStatus,
} from "src/services/regulation-policy/regulation-policy";

const GenerateControl = ({
  documentType,
  documentID,
}: {
  documentType: string;
  documentID: string;
}) => {
  const generateControls = GenerateControls(documentType, documentID);
  const { data: controlsStatus } = GetControlsStatus(documentType, documentID);
  const generatePolicyMapping = GeneratePolicyMappings(
    documentType,
    documentID
  );
  const { data: policyMappingstatus } = GetPolicyMappingStatus(
    documentType,
    documentID
  );

  return (
    <>
      {controlsStatus?.status === "processing" ? (
        <span className="flex items-center gap-1">
          Generating
          <LineWave
            visible={true}
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </span>
      ) : controlsStatus?.status === "failed" ? (
        <span>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-500"
          />{" "}
          Generation failed
        </span>
      ) : (
        controlsStatus?.status === "none" && (
          <button
            className="blue-button"
            onClick={() => generateControls.mutate({})}
          >
            Generate Controls
          </button>
        )
      )}
      {controlsStatus?.status === "ready" && (
        <>
          {policyMappingstatus?.status === "processing" ? (
            <span className="flex items-center gap-1">
              Finding
              <LineWave
                visible={true}
                height="30"
                width="30"
                color="#4fa94d"
                ariaLabel="line-wave-loading"
                wrapperStyle={{}}
                wrapperClass=""
                firstLineColor=""
                middleLineColor=""
                lastLineColor=""
              />
            </span>
          ) : policyMappingstatus?.status === "failed" ? (
            <span>
              <FontAwesomeIcon
                icon={faTriangleExclamation}
                className="text-red-500"
              />{" "}
              Failed
            </span>
          ) : (
            policyMappingstatus?.status === "none" && (
              <button
                className="blue-button"
                onClick={() => generatePolicyMapping.mutate({})}
              >
                Find Matching Policy Sections
              </button>
            )
          )}
        </>
      )}
    </>
  );
};

export default GenerateControl;
