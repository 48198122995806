import React, { useState } from "react";

const PasswordInput = ({
  label,
  keyName,
  inputs,
  setInputs,
  disabled,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  disabled?: boolean;
  required?: boolean;
}) => {
  const [clicked, setClicked] = useState<boolean>(false);

  return (
    <article className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="-mt-2 text-h5 text-red-500">*</span>}
      </header>
      <article>
        <input
          spellCheck="false"
          autoComplete="off"
          disabled={disabled}
          value={inputs[keyName]}
          placeholder="Input key"
          onClick={() => setClicked(false)}
          onBlur={() => setClicked(true)}
          onChange={(e) =>
            setInputs({
              ...inputs,
              [keyName]: e.target.value,
            })
          }
          type="input"
          className={`px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent ${
            required && clicked && inputs[keyName] === ""
              ? "border dark:border-reset"
              : "dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500"
          } rounded`}
        />
      </article>
    </article>
  );
};

export default PasswordInput;
