/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { GetAdminRoles, GetAllRoles } from "../../../services/settings/roles";
import { sortRows } from "../../../utils/general";
import { roleTypeColors } from "../../../constants/settings";
import { Customer, Role } from "../../../types/settings";
import AddRole from "./AddRole";
import EditRole from "./EditRole";
import { checkIsSuperOrSiteAdmin, getCustomerID } from "../../../utils/general";
import { ListHeader } from "../../../types/general";
import TableLayout from "../../../layouts/TableLayout";
import SortColumn from "src/components/Button/SortColumn";
import { GetCustomers } from "src/services/settings/organization";
import { initialSort, pageSize } from "src/constants/general";
import RoleActionFilter from "src/components/Filter/Settings/RoleActionFilter";
import TablePagination from "src/components/General/TablePagination";

export const roleHeaders = [
  { display_name: "Customer Name", property_name: "customer_id" },
  { display_name: "Role Name", property_name: "role_name" },
  { display_name: "Role Type", property_name: "role_type" },
];

const Roles = () => {
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const [editRole, setEditRole] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [multiSelect, setMultiSelect] = useState<string[]>([]);
  const [sort, setSort] = useState(initialSort);

  const [customerID, setCustomerID] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: allCustomers } = GetCustomers(isSuperOrSiteAdmin);
  const { data: allRoles } = GetAllRoles(getCustomerID());
  const { data: adminRoles } = GetAdminRoles(isSuperOrSiteAdmin);

  const roles = isSuperOrSiteAdmin
    ? adminRoles
    : allRoles?.filter((role: Role) => role.role_type !== "Admin");

  const filteredRoles = roles
    ? roles.filter((row: Role) =>
        row.role_name
          .toLowerCase()
          .replaceAll(/\s+/g, "")
          .includes(
            query.replaceAll('"', "").toLowerCase().replaceAll(/\s+/g, "")
          )
      )
    : [];
  const sortedRoles = sortRows(filteredRoles, sort);
  const totalCount = sortedRoles.length;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="grid content-start gap-6 pb-24 w-full h-full">
      <header className="flex items-center justify-between gap-10">
        <h4 className="text-subt1-semi">Roles ({sortedRoles?.length || 0})</h4>
        <AddRole customerID={customerID} setCustomerID={setCustomerID} />
      </header>
      <header className="flex justify-between gap-10">
        <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="filter"
            autoComplete="off"
            spellCheck="false"
            placeholder="Filter roles by name"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
        </article>
        {multiSelect.length > 0 && (
          <RoleActionFilter
            list={["Delete"]}
            multiSelect={multiSelect}
            setMultiSelect={setMultiSelect}
          />
        )}
      </header>
      <TableLayout fullHeight>
        <thead className="sticky -top-1.5 dark:bg-gray-900">
          <tr>
            <th className="px-5 pr-6 w-5">
              <input
                id="row"
                type="checkbox"
                checked={
                  sortedRoles?.length > 0 && multiSelect.length === totalCount
                }
                className="white-checkbox"
                onChange={(e) => {
                  e.stopPropagation();
                  if (multiSelect.length < totalCount) {
                    setMultiSelect(
                      sortedRoles?.map((role: Role) => role.role_id)
                    );
                  } else setMultiSelect([]);
                }}
              />
            </th>
            {roleHeaders.map((col: ListHeader) => {
              if (!isSuperOrSiteAdmin && col.property_name === "customer_id")
                return null;
              return (
                <th
                  scope="col"
                  key={col.display_name}
                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                >
                  <article className="capitalize flex gap-10 justify-between">
                    <h4>{col.display_name}</h4>
                    <SortColumn
                      propertyName={col.property_name}
                      setSort={setSort}
                    />
                  </article>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y dark:divide-gray-700">
          {sortedRoles?.map((role: Role) => {
            return (
              <tr
                key={role.role_id}
                className="relative p-5 gap-3 break-words dark:bg-gray-800"
              >
                <td className="px-5 w-5">
                  <input
                    type="checkbox"
                    checked={multiSelect.includes(role.role_id)}
                    className="white-checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={() => {
                      if (multiSelect.includes(role.role_id))
                        setMultiSelect(
                          multiSelect.filter(
                            (userID) => userID !== role.role_id
                          )
                        );
                      else setMultiSelect([...multiSelect, role.role_id]);
                    }}
                  />
                </td>
                {roleHeaders.map((col: ListHeader, colIndex: number) => {
                  if (
                    !isSuperOrSiteAdmin &&
                    col.property_name === "customer_id"
                  )
                    return null;

                  return (
                    <td
                      key={`${role.role_id}-${colIndex}`}
                      className="relative px-6 py-3 last:pr-16 text-left break-words"
                    >
                      <p
                        className={`${
                          col.property_name === "role_type"
                            ? "flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded"
                            : ""
                        }`}
                      >
                        {isSuperOrSiteAdmin &&
                        col.property_name === "customer_id"
                          ? allCustomers?.find(
                              (customer: Customer) =>
                                customer.customer_id === role[col.property_name]
                            )?.customer_name
                          : role[col.property_name]}
                      </p>
                      {colIndex === roleHeaders.length - 1 && (
                        <EditRole
                          editRole={editRole}
                          setEditRole={setEditRole}
                          role={role}
                          setCustomerID={setCustomerID}
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableLayout>
      <TablePagination
        totalPages={totalPages}
        beginning={beginning}
        end={end}
        totalCount={totalCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </section>
  );
};

export default Roles;
