/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetAuditTrailTypes } from "src/services/regulation-policy/overview";
import { checkIsAdminOrSuperAdmin } from "src/utils/general";

const AuditTrailTypesFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  refetch,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: any;
  refetch: any;
}) => {
  const isAdmin = checkIsAdminOrSuperAdmin();

  const { data: auditTrailTypes } = GetAuditTrailTypes();

  const handleSelect = (value: string) => {
    if (value === "All" && auditTrailTypes?.length > 0) {
      if (inputs[keyName].length < auditTrailTypes.length) {
        setInputs({ ...inputs, [keyName]: auditTrailTypes });
      } else setInputs({ ...inputs, [keyName]: [] });
    } else if (inputs[keyName].includes(value))
      setInputs({
        ...inputs,
        [keyName]: inputs[keyName].filter((curVal: string) => curVal !== value),
      });
    else setInputs({ ...inputs, [keyName]: [...inputs[keyName], value] });
  };

  return (
    <FilterLayout label="Activity Types" showOnLeft>
      <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
        <li className="flex items-start gap-2">
          <input
            type="checkbox"
            className="transparent-checkbox"
            checked={inputs[keyName].length === auditTrailTypes?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="">All</label>
        </li>
        {auditTrailTypes?.map((option: string) => {
          if (
            ["USER_SIGNED_IN", "USER_SIGNED_OUT"].includes(option) &&
            !isAdmin
          )
            return null;
          return (
            <li key={option} className="flex items-start gap-2 px-4">
              <input
                type="checkbox"
                className="transparent-checkbox"
                checked={inputs[keyName].includes(option)}
                onClick={() => handleSelect(option)}
              />
              <label htmlFor="" className="w-max">
                {option.replaceAll("_", " ")}
              </label>
            </li>
          );
        })}
      </ul>
      <article className="w-full border-t dark:border-gray-600">
        <article className="sticky bottom-0 flex items-center gap-2 pt-2 place-self-end">
          <button
            className="red-text-button"
            onClick={() =>
              setInputs({
                ...inputs,
                [keyName]: [],
              })
            }
          >
            Clear
          </button>
          <button className="small-blue-button" onClick={refetch}>
            Apply
          </button>
        </article>
      </article>
    </FilterLayout>
  );
};

export default AuditTrailTypesFilter;
