import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

export const biaPrefix = `bcm/business-impact`;

export const GetBIAList = (
  pageNumber: number,
  columns?: string[],
  queryList?: any
) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-bia-list", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const AddBIA = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-bia-list"]),
    }
  );

export const EditBIA = (biaID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${biaPrefix}/${biaID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-bia-list"]);
        queryClient.invalidateQueries(["get-bia-metadata"]);
      },
    }
  );

export const RemoveBIA = (biaID: string) => {
  return useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/delete?bia_id=${biaID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-bia-list"]),
    }
  );
};

export const GetBIAMetadata = (biaID: string) =>
  useQuery<any, unknown, any, string[]>(
    ["get-bia-metadata", biaID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${biaPrefix}/${biaID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: biaID !== "",
    }
  );

export const GetBIAStatus = (biaID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-bia-status", biaID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${biaPrefix}/${biaID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: biaID !== "",
      keepPreviousData: false,
    }
  );

export const ExportBIA = (biaID: string, sopVersionIDs: string[]) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/export-bia-mappings`,
          {
            bia_id: biaID,
            sop_version_ids: sopVersionIDs,
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-export-bia-status", biaID]);
      },
    }
  );

export const GetExportBIAStatus = (biaID: string) =>
  useQuery<any, unknown, any, (string | boolean)[]>(
    ["get-export-bia-status", biaID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/bia-mappings-export-status?bia_id=${biaID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: biaID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const DownloadBIA = (biaID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/download-bia-mappings-export?bia_id=${biaID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-export-bia-status", biaID]);
      },
    }
  );

export const ViewBIA = (sopVersionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/documents/get-bia-for-sop?sop_version_id=${sopVersionID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetMappedSOPs = (biaID: string, pageNumber: number) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-mapped-sops", biaID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/mapped-sops?bia_id=${biaID}`,
          {
            pager: { page_number: pageNumber, page_size: pageSize },
            user_query: "",
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: biaID !== "",
      keepPreviousData: false,
    }
  );

export const GetBIAMappings = (biaID: string, sopVersionIDs: string[]) =>
  useQuery<any, unknown, any, (string | string[])[]>(
    ["get-bia-mappings", biaID, sopVersionIDs],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${biaPrefix}/get-bia-mappings`,
          {
            bia_id: biaID,
            sop_version_ids: sopVersionIDs,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: sopVersionIDs.length > 0,
      keepPreviousData: false,
    }
  );
