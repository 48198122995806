import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { AddAssessment } from "src/services/questionnaire-rfp";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import Tags from "./Tags";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly, decodeJWT } from "src/utils/general";
import ToastLayout from "../../../../layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";

const NewAssessment = ({
  selectedSourceType,
}: {
  selectedSourceType: string;
}) => {
  const jwt = decodeJWT();
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    name: "",
    requesting_party: "",
    owner: "",
    reviewer: "",
    due_date: Date.now() * 1000,
    tags: [],
    source_type: selectedSourceType,
    enable_sources: true,
    enable_questionbank: true,
  });

  const addAssessment = AddAssessment();

  const handleOnClose = () => {
    setShow(false);
    addAssessment.reset();
  };
  const handleOnCloseConfirmation = () => addAssessment.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            requesting_party: "",
            owner: "",
            reviewer: "",
            due_date: Date.now() * 1000,
            tags: [],
            source_type: selectedSourceType,
            enable_sources: true,
            enable_questionbank: true,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Start Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Start Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Requesting Party"
            keyName="requesting_party"
            inputs={inputs}
            setInputs={setInputs}
          />
          <GeneralSnapshotDatepicker
            label="Due Date"
            keyName="due_date"
            inputs={inputs}
            setInputs={setInputs}
            onlyFutureDate
          />

          <article className="flex flex-col flex-grow gap-1 text-b2-reg">
            <h4>Must select at least one </h4>
            <CheckboxInput
              label="Search Documents (e.g. Policies, Procedures, Manuals, and SOPs)"
              keyName="enable_sources"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Search Question Banks"
              keyName="enable_questionbank"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <Tags inputs={inputs} setInputs={setInputs} />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                (!inputs.enable_questionbank && !inputs.enable_sources)
              }
              className="blue-button"
              onClick={() => {
                addAssessment.mutate({
                  inputs: {
                    name: inputs.name,
                    requesting_party: inputs.requesting_party,
                    due_date: inputs.due_date,
                    owner: jwt?.sub,
                    reviewer: null,
                    tags: inputs.tags.reduce(
                      (pV: string[], cV: KeyStringVal) => [...pV, cV.tag_id],
                      []
                    ),
                    source_type: inputs.source_type,
                    enable_sources: inputs.enable_sources,
                    enable_questionbank: inputs.enable_questionbank,
                  },
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAssessment.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New assessment has been created"
      />
    </>
  );
};

export default NewAssessment;
