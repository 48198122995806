/* eslint-disable react-hooks/exhaustive-deps */
import {
  faArrowLeftLong,
  faCopy,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { KeyStringVal } from "src/types/general";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";
import {
  GetCustomQuestions,
  GetQuestionSetMetadata,
} from "src/services/third-party-risk/questions/custom-question-sets";
import TableLayout from "src/layouts/TableLayout";
import { checkIsReadOnly, handleSort, sortRows } from "src/utils/general";
import { Sort } from "src/types/dashboard";
import CopyQuestionSet from "./CopyQuestionSet/CopyQuestionSet";
import Response from "./Response";
import AskQuestion from "./AddQuestion";
import State from "./State";
import {
  useSensor,
  PointerSensor,
  useSensors,
  DragEndEvent,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";

const CustomQuestionSetDetail = ({
  selectedQuestionSet,
  setSelectedQuestionSet,
}: {
  selectedQuestionSet: KeyStringVal;
  setSelectedQuestionSet: (selectedQuestionSet: KeyStringVal) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>({
    order: "asc",
    orderBy: "question",
  });
  const [selectedQuestions, setSelectedQuestions] = useState<any>([]);
  const [list, setList] = useState([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<KeyStringVal>({
    name: "",
    description: "",
  });

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const questionSetID = sessionStorage.custom_question_set_id;

  const { data: metadata } = GetQuestionSetMetadata(questionSetID);
  const { data: questionSet, status: questionSetStatus } = GetCustomQuestions(
    questionSetID,
    pageNumber
  );

  const headers = questionSet && JSON.parse(questionSet.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = questionSet && sortRows(questionSet?.data, sort);
  const totalCount = questionSet?.pager?.total_results || 0;
  const totalPages = questionSet?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const allQuestionIDs = questionSet?.data.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.generated_id],
    []
  );
  const selectedQuestionIDs = selectedQuestions.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.generated_id],
    []
  );
  const selectedAll =
    selectedQuestions.length > 0 &&
    questionSet?.data.every((qa: KeyStringVal) =>
      selectedQuestionIDs.includes(qa.generated_id)
    );

  const handleSelectAll = () => {
    if (selectedAll)
      setSelectedQuestions(
        selectedQuestions.filter(
          (qa: KeyStringVal) => !allQuestionIDs.includes(qa.generated_id)
        )
      );
    else {
      const filteredQuestions = questionSet?.data
        .map((qa: KeyStringVal) => {
          if (selectedQuestionIDs.includes(qa.generated_id)) return null;
          else return qa;
        })
        .filter(Boolean);
      setSelectedQuestions([...selectedQuestions, ...filteredQuestions]);
    }
  };

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setList(arrayMove(list, oldIdx, newIdx));
    }
  };

  const handleReturn = () => {
    sessionStorage.removeItem("custom_question_set_id");
    sessionStorage.removeItem("custom_question_set_name");
    setSelectedQuestionSet({});
  };

  useEffect(() => {
    if (sortedRows) setList(sortedRows);
  }, [questionSet]);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center gap-2">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-b3-reg dark:hover:text-gray-700/70 duration-100"
          onClick={handleReturn}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Question Sets</span>{" "}
          <span>/</span>
          <span>Questions</span>
        </h4>
      </header>
      <article className="flex items-center justify-between gap-10">
        <article className="flex items-center gap-4">
          <h3 className="text-t1-semi">{metadata?.name}</h3>
          <State questionSetID={questionSetID} />
          {metadata?.global_questionset && (
            <span className="text-b3-semi">Global</span>
          )}
        </article>
        <article className="flex items-center place-content-end gap-4">
          <button
            disabled={isReadOnly || selectedQuestions.length === 0}
            className="black-button"
            onClick={() => {
              setShow(!show);
              setInputs({
                name: "",
                description: "",
              });
            }}
          >
            <FontAwesomeIcon icon={faCopy} />
            Copy Question Set
          </button>
          <AskQuestion questionSetID={questionSetID} />
        </article>
      </article>
      {show && (
        <CopyQuestionSet
          questionSetID={questionSetID}
          setShow={setShow}
          inputs={inputs}
          setInputs={setInputs}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          headers={headers}
          filteredHeaders={filteredHeaders}
        />
      )}
      {questionSetStatus === "loading" ? (
        <Loader />
      ) : questionSet?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4 pb-24">
          <DndContext
            sensors={sensors}
            onDragStart={(e) =>
              setActiveItem(
                list.find(
                  (item: KeyStringVal) => item.generated_id === e.active.id
                )
              )
            }
            onDragEnd={reorderQuestionsList}
          >
            <TableLayout fullHeight>
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {filteredHeaders?.map((col: string, colIndex: number) => {
                    const column = headers.properties[col];
                    if (!column) return null;

                    return (
                      <th
                        scope="col"
                        key={colIndex}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <article className="flex items-center gap-4">
                            {colIndex === 0 && (
                              <input
                                type="checkbox"
                                disabled={isReadOnly}
                                checked={selectedAll}
                                onChange={handleSelectAll}
                                className="ml-4 white-checkbox"
                              />
                            )}
                            <h4 className="break-words">{column.title}</h4>
                          </article>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                <SortableContext items={list}>
                  {list?.map((row: any) => {
                    return (
                      <Response
                        key={row.generated_id}
                        questionSetID={questionSetID}
                        qa={row}
                        selectedQuestions={selectedQuestions}
                        setSelectedQuestions={setSelectedQuestions}
                        filteredHeaders={filteredHeaders}
                      />
                    );
                  })}
                </SortableContext>
              </tbody>
            </TableLayout>
            <DragOverlay
              dropAnimation={{
                duration: 150,
                easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
              }}
            >
              {activeItem ? (
                <Response
                  questionSetID={questionSetID}
                  qa={activeItem}
                  selectedQuestions={selectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                  filteredHeaders={filteredHeaders}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="questionSet placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Questions</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default CustomQuestionSetDetail;
