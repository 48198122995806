/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { periods } from "src/constants/summaries";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { useSummaryStore } from "src/stores/summaries";

const PeriodFilter = ({ excludePeriods }: { excludePeriods?: number[] }) => {
  const { period, setPeriod } = useSummaryStore();

  return (
    <DropdownLayout value={periods[period]}>
      {Object.keys(periods).map((curPeriod: string) => {
        if (excludePeriods?.includes(Number(curPeriod))) return null;
        const selected = period === Number(curPeriod);

        return (
          <button
            key={curPeriod}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => setPeriod(Number(curPeriod))}
          >
            <p>{periods[curPeriod]}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default PeriodFilter;
