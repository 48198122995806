import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import { Fragment, useState } from "react";
import {
  GetNotifications,
  UpdateNotificationStatus,
  ClearAllNotifications,
  HasNotification,
} from "../../services/general/general";
import { lastUpdatedAt } from "../../utils/general";
import { notificationTypes } from "src/constants/general";
import { queryClient } from "src/App";
import { useNavigate } from "react-router-dom";
import { useInvestigationStore } from "src/stores/investigation";
import { useGRCStore } from "src/stores/grc";

const Notif = () => {
  const navigate = useNavigate();

  const {
    setShowEvidencePanel,
    setSelectedEvidencePanelTab,
    setSelectedEvidenceID,
  } = useInvestigationStore();
  const {
    setGRCQuestionIDNotif,
    setSelectedGRCAssessment,
    setShowGRCPanel,
    setSelectedAnchorID,
    setSelectedGRCPanelTab,
  } = useGRCStore();

  const [showMore, setShowMore] = useState<boolean>(false);

  const { data: hasNotification } = HasNotification();
  const notifications = GetNotifications();
  const updateNotification = UpdateNotificationStatus();
  const clearAllNotifications = ClearAllNotifications();

  return (
    <Popover className="relative">
      {({ open }) => (
        <>
          <Popover.Button
            className={`group relative
          ${open ? "" : "text-opacity-90"}
          focus:outline-none group relative inline-flex items-start text-base font-medium`}
          >
            <FontAwesomeIcon
              icon={faBell}
              className="w-5 h-5 mt-1 dark:text-gray-400"
              onClick={() => notifications.mutate({})}
            />
            {hasNotification && (
              <span className="grid content-center -ml-1 w-2 h-2 text-[0.55rem] dark:bg-blue-600 rounded-full"></span>
            )}
            <span className="hidden group-hover:block absolute top-10 right-0 p-2 w-max text-b3-reg dark:bg-gray-600 black-shadow rounded z-20">
              Notifications
            </span>
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 mt-2 w-max text-sm z-30">
              {({ close }) => (
                <article
                  className={`grid content-start ${
                    showMore ? "h-[30rem]" : "h-max"
                  } w-[26rem] dark:bg-gray-900 overflow-auto scrollbar`}
                >
                  <header className="sticky top-0 flex justify-between gap-4 px-5 py-4  dark:bg-gray-900 z-10">
                    <h4>Your notifications</h4>
                    <button
                      disabled={notifications?.data?.length === 0}
                      className="px-4 text-b2-reg dark:text-gray-400 dark:hover:text-white dark:hover:bg-filter dark:disabled:text-gray-500 dark:disabled:hover:bg-transparent rounded-full duration-100"
                      onClick={() => {
                        clearAllNotifications.mutate({ state: "hidden" });
                        close();
                      }}
                    >
                      Clear All
                    </button>
                  </header>
                  <nav className="relative grid gap-2 px-5 pb-5 w-full text-b2-reg">
                    {notifications?.data
                      ?.slice(0, showMore ? notifications.data.length - 4 : 4)
                      .map((notification: any) => {
                        return (
                          <button
                            id={notification.notification_id}
                            key={notification.notification_id}
                            className="flex items-start gap-4 py-2 px-4 cursor-pointer duration-100 dark:bg-tooltip/60 dark:hover:bg-tooltip focus:outline-none"
                            onClick={(e) => {
                              updateNotification.mutate(
                                {
                                  notificationID: notification.notification_id,
                                },
                                {
                                  onSuccess: (data) => {
                                    switch (notification.type) {
                                      case "UNORDERLY_DIARY_SUMMARY":
                                        sessionStorage.page = "Investigation";
                                        setShowEvidencePanel(true);
                                        setSelectedEvidenceID(
                                          notification.meta.evidence_id
                                        );
                                        setSelectedEvidencePanelTab("Notes");
                                        navigate(
                                          `/investigation/diary/details?diary_id=${notification.meta.diary_id}`
                                        );
                                        break;
                                      case "CONTRACTUAL_OBLIGATION":
                                        sessionStorage.page =
                                          "Agreement & Proposal Review";
                                        setShowGRCPanel(true);
                                        setSelectedAnchorID(
                                          notification.meta.anchor_id
                                        );
                                        setSelectedGRCPanelTab("Discussion");
                                        navigate(
                                          `/agreement-proposal-review/agreement/details?agreement_id=${notification.meta.document_id}`
                                        );
                                        break;
                                      case "COMPLIANCE":
                                        if (
                                          notification.meta.status !== "error"
                                        ) {
                                          if (notification.meta.assessment_id) {
                                            if (
                                              notification.meta.third_party_id
                                            ) {
                                              sessionStorage.page =
                                                "Third Party Risk";
                                              navigate(
                                                `/third-party-risk/vendors/details?vendor_id=${notification.meta.third_party_id}&assessment_id=${notification.meta.assessment_id}`
                                              );
                                            } else {
                                              sessionStorage.page =
                                                "Questionnaire & RFP";
                                              if (notification.meta.answer_id)
                                                setGRCQuestionIDNotif(
                                                  notification.meta.answer_id
                                                );
                                              setSelectedGRCAssessment({
                                                name: notification.meta
                                                  .assessment_name,
                                                assessment_id:
                                                  notification.meta
                                                    .assessment_id,
                                              });
                                              sessionStorage.assessment_id =
                                                notification.meta.assessment_id;
                                              navigate(
                                                "/questionnaire-rfp/summary"
                                              );
                                            }
                                          } else {
                                            sessionStorage.page =
                                              "Regulation & Policy";
                                            sessionStorage.GRCCategory =
                                              notification.meta.document_type;
                                            navigate(
                                              `/regulation-policy/document/details?document_type=${
                                                notification.meta.document_type
                                              }&document_id=${
                                                notification.meta.document_id
                                              }${
                                                notification.meta.version_id
                                                  ? `&policy_version_id=${notification.meta.version_id}`
                                                  : ""
                                              }`
                                            );
                                          }
                                        }
                                        break;
                                      default:
                                        queryClient.invalidateQueries([
                                          "get-notifications",
                                        ]);
                                    }
                                  },
                                }
                              );
                              close();
                            }}
                          >
                            <FontAwesomeIcon
                              icon={notificationTypes[notification.type]?.icon}
                              className={`mt-1 ${
                                notificationTypes[notification.type]?.iconColor
                              }`}
                            />
                            <section className="grid gap-2 text-left">
                              <p>{notification.description}</p>
                              {/* how long ago since the notification is posted */}
                              {notification.time && (
                                <p className="dark:text-gray-400 text-[0.68rem]">
                                  {lastUpdatedAt(notification.time)}
                                </p>
                              )}
                            </section>
                          </button>
                        );
                      })}
                    {notifications?.data?.length > 4 && (
                      <button
                        className="px-4 py-2 dark:bg-filter/30 dark:hover:bg-filter/60 duration-100 border dark:border-filter rounded-sm"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore
                          ? "Show less "
                          : `${notifications.data.length - 4} more `}
                        notifications
                      </button>
                    )}
                  </nav>
                </article>
              )}
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Notif;
