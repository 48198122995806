import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AttachmentMetadata from "./DocumentRequestMetadata";
import ItemList from "./Items/ItemList";

const DocumentRequest = () => {
  const parsed = parseURL();

  const documentRequestID = String(parsed.drl_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "attachments";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 min-h-full w-full overflow-auto scrollbar">
        <AttachmentMetadata documentRequestID={documentRequestID} />
        <ItemList documentRequestID={documentRequestID} />
      </main>
    </PageLayout>
  );
};

export default DocumentRequest;
