import React, { useState } from "react";
import { faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { convertToUTCString } from "src/utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";

const AttachmentList = ({ attachments }: { attachments: any }) => {
  const [selectedFile, setSelectedFile] = useState<string>("");

  const handleOnClose = () => setSelectedFile("");

  return (
    <section className="flex flex-wrap gap-2">
      {attachments?.map((row) => {
        const attachmentID = row.attachment_id;
        const uri = row.attachment_url;

        const fileType = uri?.slice(
          uri?.lastIndexOf(".") + 1,
          uri?.indexOf("?")
        );

        return (
          <article key={attachmentID}>
            <button
              className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 dark:hover:bg-gray-700/70 duration-100 rounded"
              onClick={() => setSelectedFile(attachmentID)}
            >
              <FontAwesomeIcon icon={faLink} className="dark:text-blue-500" />
              <h4>{row.attachment_name}</h4>
            </button>
            <ModalLayout
              showModal={selectedFile === attachmentID}
              onClose={handleOnClose}
            >
              <section className="flex flex-col flex-grow gap-4">
                <article className="flex flex-col flex-gfile gap-3 text-b1-reg">
                  <article className="flex flex-col flex-gfile">
                    <h4 className="text-subt1-semi">{row.attachment_name}</h4>
                    <article className="flex items-center gap-1 text-b2-reg dark:text-gray-400">
                      <h4>Uploaded at</h4>
                      <p>{convertToUTCString(row.uploaded_at)}</p>
                    </article>
                  </article>
                </article>
                <article className="flex items-center place-content-end gap-4">
                  <button
                    className="blue-button"
                    onClick={() => {
                      let a = document.createElement("a");
                      a.href = uri;
                      a.target = "_blank";
                      a.click();
                    }}
                  >
                    <FontAwesomeIcon icon={faDownload} /> Download as File
                  </button>
                </article>
                <DocViewer
                  documents={[
                    {
                      uri,
                      fileType,
                    },
                  ]}
                  pluginRenderers={DocViewerRenderers}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                  }}
                />
              </section>
            </ModalLayout>
          </article>
        );
      })}
    </section>
  );
};

export default AttachmentList;
