/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { convertToUTCString } from "src/utils/general";

const TimestampFilter = ({
  label,
  list,
  value,
  setValue,
}: {
  label?: string;
  list: number[];
  value: number;
  setValue: (value: number) => void;
}) => {
  return (
    <DropdownLayout label={label} value={value} timestamp>
      {list?.map((item: number) => {
        const selected = value === item;

        return (
          <button
            key={item}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => setValue(item)}
          >
            <p>{convertToUTCString(item)}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default TimestampFilter;
