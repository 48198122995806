import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import { EditControlBlueprint } from "src/services/erc/controls/control-blueprints";
import SourceNameFilter from "src/components/Filter/ERC/SourceNameFilter";
import SourceControlFilter from "src/components/Filter/ERC/SourceControlFilter";
import SourceSubcontrolFilter from "src/components/Filter/ERC/SourceSubcontrolFilter";
import UserInput from "src/components/Input/UserInput";
import { checkIsReadOnly } from "src/utils/general";

const UpdateControlBlueprint = ({
  blueprintID,
  blueprint,
}: {
  blueprintID: string;
  blueprint: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const editRiskBlueprint = EditControlBlueprint(blueprintID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end edit-button"
        onClick={() => {
          setShow(true);
          setInputs(blueprint);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Control Blueprint
          </h3>
          <section className="grid gap-4 w-full">
            <section className="grid content-start md:grid-cols-2 gap-4">
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                valid={valid}
                setValid={setValid}
                required
              />
              <SourceNameFilter
                label="Source Name"
                keyName="source_name"
                inputs={inputs}
                setInputs={setInputs}
              />
              {inputs.source_name && (
                <SourceControlFilter
                  label="Source Control Id"
                  keyName="source_control_id"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
              {inputs.source_control_id && (
                <SourceSubcontrolFilter
                  label="Source Sub-Control Id"
                  keyName="source_sub_control_id"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
              <RegularInput
                label="Function"
                keyName="function"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Category"
                keyName="category"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Subcategory"
                keyName="sub_category"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Classification"
                keyName="classification"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Type"
                keyName="type"
                inputs={inputs}
                setInputs={setInputs}
              />
              <CheckboxInput
                label="Create Controls Automatically"
                keyName="create_controls_automatically_enabled"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Compliance Score"
                keyName="compliance_score"
                inputs={inputs}
                setInputs={setInputs}
              />
              <UserInput
                label="Owner"
                keyName="owner"
                inputs={inputs}
                setInputs={setInputs}
              />
            </section>
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editRiskBlueprint.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateControlBlueprint;
