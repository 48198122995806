import React from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AssessmentMetadata from "./AssessmentMetadata";
import VendorList from "./VendorList";

const Assessment = () => {
  const parsed = parseURL();

  const assessmentID = String(parsed.assessment_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 pb-24 h-full w-full overflow-auto scrollbar">
        <AssessmentMetadata assessmentID={assessmentID} />
        <VendorList assessmentID={assessmentID} />
      </main>
    </PageLayout>
  );
};

export default Assessment;
