import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

export const riskPrefix = "grc/risk-register/risks";

export const SearchCategory = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/search_category`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchSubCategory = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/search_sub_category`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetRisks = (
  pageNumber?: number,
  tags?: string[] | undefined,
  names?: string[],
  serialNumbers?: string[],
  columns?: string[],
  queryList?: any
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/getall`,
          {
            ...(pageNumber && {
              pager: {
                page_number: pageNumber,
                page_size: pageSize,
              },
            }),
            tags,
            risk_name_list: names,
            risk_serial_number_list: serialNumbers,
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const BulkRiskAction = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/grc/risk-register/risks-bulk-action`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risks"]),
    }
  );

export const AddRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risks"]),
    }
  );

export const EditRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${riskPrefix}/${riskID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risk-metadata"]);
      },
    }
  );

export const GetRiskMetadata = (riskID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-risk-metadata", riskID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${riskPrefix}/${riskID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const AddRiskAttachments = () =>
  useMutation<any, unknown, any, string>(
    async ({
      riskID,
      formData,
      signal,
    }: {
      riskID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks"]);
        queryClient.invalidateQueries(["get-risk-attachments"]);
      },
    }
  );

export const RemoveRiskAttachment = (riskID: string, attachmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/removedocument?attachment_id=${attachmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-attachments"]),
    }
  );

export const DownloadRiskAttachment = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      attachmentID,
      signal,
    }: {
      attachmentID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/download?attachment_id=${attachmentID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-attachments"]),
    }
  );

export const GetRiskAttachments = (riskID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-risk-attachments", riskID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/listdocuments`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-risk"]);
      },
    }
  );

export const RemoveTagFromRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risks"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-risk"]);
      },
    }
  );

export const GenerateTreatmentPlan = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/treatment-plan`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-treatment-plan-status"]);
      },
    }
  );

export const GetTreatmentPlanStatus = (riskID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-treatment-plan-status", riskID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/treatment-plan/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const SearchThreat = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      threatName,
      signal,
    }: {
      threatName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-threat`,
          { threat_name: threatName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchVulnerability = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      vulnerabilityName,
      signal,
    }: {
      vulnerabilityName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-vulnerability`,
          { vulnerability_name: vulnerabilityName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorsForRisk = (riskID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendors-for-risk", riskID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/vendors`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const AddVendorToRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({
      riskID,
      vendor,
      signal,
    }: {
      riskID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/vendors/add`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-risk"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const RemoveVendorFromRisk = () =>
  useMutation<any, unknown, any, string>(
    async ({
      riskID,
      vendor,
      signal,
    }: {
      riskID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/vendors/remove`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-risk"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const SearchRiskName = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ riskName, signal }: { riskName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-risk`,
          { risk_name: riskName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchRiskID = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      serialNumber,
      signal,
    }: {
      serialNumber: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/risk-register/search-risk-by-serial-number`,
          { serial_number: serialNumber },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const RestoreRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-metadata"]),
    }
  );

export const ArchiveRisk = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-risk-metadata"]),
    }
  );

export const ExportAllRisks = () =>
  useMutation<any, unknown, any, (string | (string | null)[] | null)[]>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        await client.post(`/api/${apiVersion}/${riskPrefix}/exportall`, {
          signal,
        });
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-risks-export-status"]),
    }
  );

export const GetRisksExportStatus = () =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-risks-export-status"],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/exportall/status`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      refetchInterval: 30000,
      keepPreviousData: false,
    }
  );

export const DownloadAllRisks = () =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${riskPrefix}/exportall/download`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-risks-export-status"]),
    }
  );

export const GetRiskLifecycleState = (riskID: string) =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-risk-lifecycle-state", riskID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/lifecycle`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );

export const UpdateRiskState = (riskID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/transition-state`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-risk-lifecycle-state"]);
        queryClient.invalidateQueries(["get-risk-lifecycle-events"]);
        queryClient.invalidateQueries(["get-risk-metadata"]);
      },
    }
  );

export const GetRiskLifecycleEvents = (riskID: string) =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-risk-lifecycle-events", riskID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${riskPrefix}/${riskID}/events`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: riskID !== "",
      keepPreviousData: false,
    }
  );
