/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import Loader from "src/components/Loader/Loader";
import Response from "./Response";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import {
  GetAssessmentMetadata,
  GetResponsesForAssessment,
} from "src/services/erc/assessments/assessments";
import AllQuestionSetTags from "../../../../../../../components/GRC/AllQuestionTags";

const VendorResponseList = ({
  entityID,
  assessmentID,
  documentID,
}: {
  entityID: string;
  assessmentID: string;
  documentID: string;
}) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data: metadata } = GetAssessmentMetadata(
    assessmentID,
    entityID,
    "ERC",
    "ENTITY"
  );
  const {
    data: vendorResponses,
    status: vendorResponseStatus,
    refetch,
    isFetching,
  } = GetResponsesForAssessment(
    entityID,
    assessmentID,
    documentID,
    pageNumber,
    "ERC",
    "ENTITY",
    selectedTags
  );

  const totalCount = vendorResponses?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4">
      <AllQuestionSetTags
        questionSetID={metadata?.question_set_id}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      {vendorResponseStatus === "loading" ? (
        <Loader />
      ) : !isFetching ? (
        vendorResponses?.data.length > 0 ? (
          <section className="flex flex-col flex-grow gap-4 pb-24">
            <ul className="flex flex-col flex-grow gap-4">
              {vendorResponses?.data.map(
                (vendorResponse: any, index: number) => {
                  return (
                    <Response
                      key={index}
                      entityID={entityID}
                      assessmentID={assessmentID}
                      questionIndex={index}
                      qa={vendorResponse}
                    />
                  );
                }
              )}
            </ul>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        ) : (
          <section className="flex items-center gap-10 w-full h-full">
            <img
              src="/grc/frameworks-placeholder.svg"
              alt="frameworks placeholder"
              className="w-40 h-40"
            />
            <article className="grid gap-3">
              <header className="flex items-center gap-3">
                <h4 className="text-t1-bold">Vendor Responses</h4>
                <button className="black-button" onClick={() => refetch()}>
                  <FontAwesomeIcon icon={faRefresh} />
                  Refresh
                </button>
              </header>
              <h4>No vendor responses available</h4>
            </article>
          </section>
        )
      ) : null}
    </section>
  );
};

export default VendorResponseList;
