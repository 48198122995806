import React, { useState } from "react";
import NewRiskQuestionnaire from "./Questionnaires/NewQuestionnaire";
import NewQuestion from "./Questions/NewQuestion";
import Questions from "./Questions/Questions";
import Questionnaires from "./Questionnaires/Questionnaires";

const Assessments = () => {
  const [selectedTab, setSelectedTab] = useState<string>("questions");

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["questions", "questionnaires"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "questions" ? (
          <NewQuestion />
        ) : (
          <NewRiskQuestionnaire />
        )}
      </header>
      {selectedTab === "questions" ? <Questions /> : <Questionnaires />}
    </section>
  );
};

export default Assessments;
