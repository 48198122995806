import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import MultipleVendorFileInput from "../../../../../../components/Input/MultipleVendorFileInput";
import { ThreeDots } from "react-loader-spinner";
import { queryClient } from "src/App";
import { UploadDocs } from "src/services/erc/assessments/assessments";
import { checkIsReadOnly } from "src/utils/general";

const NewDocuments = ({
  entityID,
  assessmentID,
}: {
  entityID: string;
  assessmentID: string;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    files: [],
    document_types: [],
    frameworks: [],
    to_extracts: [],
    assessments: [],
    question_sets: [],
  });

  const uploadDocs = UploadDocs(assessmentID, entityID, "ERC", "ENTITY");

  const handleOnClose = () => {
    setShow(false);
    uploadDocs.reset();
  };

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            files: [],
            document_types: [],
            frameworks: [],
            to_extracts: [],
            assessments: [],
            question_sets: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Documents</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        {uploadDocs.status === "success" ? (
          <section className="grid content-start gap-10 h-full">
            <article className="flex items-center gap-2">
              <img
                src="/general/checkmark.svg"
                alt="checkmark"
                className="w-12 h-12 mx-auto"
              />
              <h3 className="text-subt1-semi">
                New Documents have been uploaded!
              </h3>
            </article>
            <h3 className="text-b1-reg">
              GRC AI Agents are on it! Will notify you as soon as the document
              is read, parsed, understood, and analyzed by the autonomous agent.
            </h3>
          </section>
        ) : (
          <section className="grid content-start gap-4 h-full">
            <h3 className="flex items-center gap-2 text-t1-semi">
              Upload Documents
            </h3>
            <>
              {uploadDocs.status === "loading" ? (
                <article className="flex flex-wrap items-center place-content-center gap-2">
                  Files are being uploaded{" "}
                  <ThreeDots
                    visible={true}
                    height="20"
                    width="50"
                    ariaLabel="dna-loading"
                    wrapperStyle={{}}
                    wrapperClass="dna-wrapper"
                  />
                </article>
              ) : (
                <>
                  <MultipleVendorFileInput
                    label="Documents (.pdf, .docx, .xlsx)"
                    types={["pdf", "docx", "xlsx"]}
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <article className="flex items-center place-content-end gap-4">
                    <button className="black-button" onClick={handleOnClose}>
                      Cancel
                    </button>
                    <button
                      disabled={
                        inputs.files.length === 0 ||
                        inputs.document_types.includes("") ||
                        inputs.document_types.some(
                          (documentType, index) =>
                            (["Audit Reports", "Privacy Documents"].includes(
                              documentType
                            ) &&
                              inputs.frameworks[index] === "") ||
                            (documentType === "Questionnaire Responses" &&
                              inputs.files[index]?.name?.includes("xlsx") &&
                              inputs.to_extracts[index] === "")
                        )
                      }
                      className="blue-button"
                      onClick={() => {
                        const formData = new FormData();

                        if (inputs.files.length > 0)
                          inputs.files.forEach((file: any) =>
                            formData.append("files", file)
                          );
                        if (inputs.document_types.length > 0)
                          inputs.document_types.forEach((documentType: any) =>
                            formData.append("document_types", documentType)
                          );
                        if (inputs.frameworks.length > 0)
                          inputs.frameworks.forEach((framework: any) =>
                            formData.append("frameworks", framework)
                          );
                        if (inputs.to_extracts.length > 0)
                          inputs.to_extracts.forEach((toExtract: any) =>
                            formData.append("to_extracts", toExtract)
                          );
                        if (inputs.assessments.length > 0)
                          inputs.assessments.forEach((assessment: any) =>
                            formData.append("assessments", assessment)
                          );
                        if (inputs.question_sets.length > 0)
                          inputs.question_sets.forEach((questionSet: any) =>
                            formData.append("question_sets", questionSet)
                          );

                        uploadDocs.mutate(
                          {
                            formData,
                          },
                          {
                            onSuccess: () => {
                              queryClient.invalidateQueries([
                                "get-vendor-documents",
                              ]);
                              queryClient.invalidateQueries([
                                "get-vendor-metadata",
                              ]);
                              handleOnClose();
                            },
                          }
                        );
                      }}
                    >
                      Process
                    </button>
                  </article>
                </>
              )}
            </>
          </section>
        )}
      </ModalLayout>
    </>
  );
};

export default NewDocuments;
