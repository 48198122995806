/* eslint-disable react-hooks/exhaustive-deps */
import {
  faRefresh,
  faXmark,
  faMagnifyingGlass,
  faPlus,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  Fragment,
  MutableRefObject,
  useEffect,
  useRef,
  useState,
} from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { KeyStringVal } from "src/types/general";
import { GetQnAList } from "src/services/questionnaire-rfp";
import { pageSize } from "src/constants/general";
import { useGRCStore } from "src/stores/grc";
import Response from "./Response";
import ExportFile from "./ExportFile";
import {
  checkIsReadOnly,
  checkIsSuperAdmin,
  handleSort,
  sortRows,
} from "src/utils/general";
import CreateTemplate from "./CreateTemplate";
import {
  AddQAPair,
  GetQuestionBanks,
  ResubmitQuestion,
} from "../../../../../services/questionnaire-rfp";
import { Popover, Transition } from "@headlessui/react";
import TableLayout from "src/layouts/TableLayout";
import { Sort } from "src/types/dashboard";

const ResponseList = ({ assessmentID }: { assessmentID: string }) => {
  const isSuperAdmin = checkIsSuperAdmin();
  const isReadOnly = checkIsReadOnly();

  const sourceType = sessionStorage.source_type || "";

  const { GRCQuestionIDNotif } = useGRCStore();

  const [historyQuery, setHistoryQuery] = useState<string>("");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedQuestions, setSelectedQuestions] = useState<any>([]);
  const [sort, setSort] = useState<Sort>({
    order: "asc",
    orderBy: "question",
  });

  const {
    data: qnaList,
    refetch,
    isFetching,
  } = GetQnAList(assessmentID, pageNumber, historyQuery);
  const { data: questionBanks } = GetQuestionBanks(1);
  const addQAPair = AddQAPair();
  const resubmitQuestion = ResubmitQuestion(assessmentID, sourceType);

  const sortedRows = qnaList && sortRows(qnaList?.data, sort);
  const totalCount = qnaList?.pager.total_results || 0;
  const totalPages = qnaList?.pager.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const questionRef = useRef(
    Array(qnaList?.data.length).fill(null)
  ) as MutableRefObject<any[]>;

  const allQuestionIDs = qnaList?.data.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.question_id],
    []
  );
  const selectedQuestionIDs = selectedQuestions.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.question_id],
    []
  );
  const selectedAll =
    selectedQuestions.length > 0 &&
    qnaList?.data.every((qa: KeyStringVal) =>
      selectedQuestionIDs.includes(qa.question_id)
    );

  const handleSelectAll = () => {
    if (selectedAll)
      setSelectedQuestions(
        selectedQuestions.filter(
          (qa: KeyStringVal) => !allQuestionIDs.includes(qa.question_id)
        )
      );
    else {
      const filteredQuestions = qnaList?.data
        .map((qa: KeyStringVal) => {
          if (selectedQuestionIDs.includes(qa.question_id)) return null;
          else return qa;
        })
        .filter(Boolean);
      setSelectedQuestions([...selectedQuestions, ...filteredQuestions]);
    }
  };

  useEffect(() => {
    if (qnaList?.data.length > 0) {
      const questionIndex = qnaList?.data.findIndex(
        (question: KeyStringVal) => question.question_id === GRCQuestionIDNotif
      );
      if (questionRef?.current && questionRef?.current[questionIndex])
        questionRef.current[questionIndex].scrollIntoView();
    }
  }, [qnaList, GRCQuestionIDNotif]);

  useEffect(() => {
    if (qnaList?.data) handleSelectAll();
  }, [qnaList?.data]);

  useEffect(() => {
    if (historyQuery.length >= 3 || historyQuery.length === 0) refetch();
  }, [historyQuery]);

  return (
    <section className="grid content-start gap-4 pb-24 w-full h-full">
      <header className="flex flex-wrap items-center justify-between gap-10">
        <article className="flex flex-wrap items-center gap-6">
          <h4 className="text-t1-semi">Responses</h4>
          <article className="flex items-center gap-4 text-b2-reg">
            <article className="flex items-center gap-1 px-2 py-1 dark:bg-gray-700 rounded">
              <span className="text-b1-reg">Search Documents</span>{" "}
              <span className="text-b1-semi capitalize dark:text-blue-500">
                {sessionStorage.enable_sources}
              </span>
            </article>
            <article className="flex items-center gap-1 px-2 py-1 dark:bg-gray-700 rounded">
              <span className="text-b1-reg">Search Question Banks</span>{" "}
              <span className="text-b1-semi capitalize dark:text-blue-500">
                {sessionStorage.enable_questionbank}
              </span>
            </article>
          </article>
        </article>
        <article className="flex items-center px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Search any question"
            value={historyQuery}
            onChange={(e) => setHistoryQuery(e.target.value)}
            className="p-4 pr-12 w-[30rem] h-8 dark:bg-gray-800 focus:outline-none"
          />
          <article className="flex items-center gap-2 divide-x dark:divide-checkbox">
            {historyQuery !== "" && (
              <button
                data-test="clear-query"
                className="trash-button"
                onClick={() => setHistoryQuery("")}
              >
                <FontAwesomeIcon icon={faXmark} />
              </button>
            )}
            <button
              disabled={historyQuery === ""}
              className="px-2 dark:disabled:text-blue-500/30 dark:text-blue-500 dark:hover:text-blue-500/60 duration-100"
              onClick={() => refetch()}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          </article>
        </article>
      </header>
      <section className="flex items-center place-content-end gap-4">
        <button className="black-button" onClick={() => refetch()}>
          <FontAwesomeIcon icon={faRefresh} />
          Refresh
        </button>
        {isSuperAdmin && (
          <CreateTemplate qnaList={qnaList} assessmentID={assessmentID} />
        )}

        <button
          disabled={isReadOnly || selectedQuestions.length === 0}
          className="black-button"
          onClick={() => {
            selectedQuestions.forEach((question: KeyStringVal) =>
              resubmitQuestion.mutate({
                questionID: question.question_id,
              })
            );
          }}
        >
          <img src="/general/resubmit.svg" alt="" />
          Resubmit
        </button>
        <Popover className="relative">
          <Popover.Button
            disabled={isReadOnly || selectedQuestions.length === 0}
            className="black-button"
          >
            <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
            Add To
          </Popover.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute right-0 top-12 z-10">
              {({ close }) => (
                <section className="grid gap-1 p-4 w-[30rem] dark:bg-gray-700 black-shadow rounded-md">
                  <h4>Question Banks</h4>
                  <ul className="grid gap-3">
                    {questionBanks?.data.map((questionBank: KeyStringVal) => {
                      return (
                        <li
                          key={questionBank.document_id}
                          className="px-3 py-1 cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-md"
                          onClick={() => {
                            selectedQuestions.forEach(
                              (question: KeyStringVal) =>
                                addQAPair.mutate({
                                  questionBankID: questionBank.document_id,
                                  question: question.question,
                                  answer: question.answer,
                                })
                            );
                            close();
                          }}
                        >
                          {questionBank.name}
                        </li>
                      );
                    })}
                  </ul>
                </section>
              )}
            </Popover.Panel>
          </Transition>
        </Popover>
        {sessionStorage.source_type === "policies" && (
          <ExportFile
            assessmentID={assessmentID}
            selectedQuestions={selectedQuestions}
          />
        )}
      </section>
      {isFetching ? (
        <Loader />
      ) : qnaList?.data.length > 0 ? (
        <section className="grid gap-3">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {["question", "answer"]?.map(
                  (col: string, colIndex: number) => {
                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <article className="flex items-center gap-4">
                            {colIndex === 0 && (
                              <input
                                type="checkbox"
                                checked={selectedAll}
                                onChange={handleSelectAll}
                                className="ml-4 white-checkbox"
                              />
                            )}
                            <h4 className="break-words">{col}</h4>
                          </article>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((question: any, questionIndex: number) => {
                return (
                  <Response
                    key={questionIndex}
                    assessmentID={assessmentID}
                    questionIndex={questionIndex}
                    questionRef={questionRef}
                    question={question}
                    selectedQuestions={selectedQuestions}
                    setSelectedQuestions={setSelectedQuestions}
                  />
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <p>No responses found</p>
      )}
    </section>
  );
};

export default ResponseList;
