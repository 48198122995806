/* eslint-disable no-restricted-globals */
import ReturnPage from "../../../components/Button/ReturnPage";
import {
  GetCustomerEnvAccounts,
  GetCustomerEnvs,
} from "../../../services/settings/environments";
import { getCustomerID, parseURL } from "../../../utils/general";
import { Account, CustomerEnv } from "../../../types/settings";
import TableLayout from "src/layouts/TableLayout";
import { useState } from "react";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";

const EnvironmentDetails = () => {
  const parsed = parseURL();
  const customerID = getCustomerID();

  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const { data: customerEnvAccounts } = GetCustomerEnvAccounts(
    customerID,
    customerEnvs?.find(
      (customerEnv: CustomerEnv) => customerEnv.env_type === parsed.selected_env
    )?.env_id
  );

  const integrationTypes = [
    ...new Set(
      customerEnvAccounts?.reduce(
        (pV: string[], cV: Account) => [...pV, cV.integration_type],
        []
      )
    ),
  ] as string[];

  const filteredHeaders = ["integration", "accounts"];
  const totalCount = integrationTypes?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-grow flex-col content-start gap-6 pb-24">
      <header className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Environments</span>{" "}
          <span>/</span>
          <span>{parsed.selected_env}</span>
        </h4>
      </header>
      <section className="grid gap-6">
        <h4 className="text-subt1-semi">{parsed.selected_env} Environment</h4>
        {customerEnvAccounts ? (
          integrationTypes?.length > 0 ? (
            <>
              <TableLayout fullHeight>
                <thead className="sticky -top-1.5 dark:bg-gray-900">
                  <tr>
                    {filteredHeaders?.map((col: string) => {
                      return (
                        <th
                          scope="col"
                          key={col}
                          className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                        >
                          <article className="capitalize flex gap-10 justify-between">
                            <h4 className="break-words">{col}</h4>
                          </article>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {integrationTypes?.map(
                    (integrationType: string, index: number) => {
                      const filteredAccounts = customerEnvAccounts?.filter(
                        (account: Account) =>
                          account.integration_type === integrationType
                      );

                      return (
                        <tr
                          key={index}
                          data-test="table-row"
                          className="relative p-5 gap-3 break-words dark:bg-gray-800"
                        >
                          {filteredHeaders?.map(
                            (col: string, colIndex: number) => {
                              return (
                                <td
                                  key={`${index}-${colIndex}`}
                                  className="relative px-6 py-3 last:pr-16 text-left break-words"
                                >
                                  {col === "integration" ? (
                                    <article className="flex items-center gap-2 text-b1-reg">
                                      <img
                                        src={`/general/integrations/${integrationType?.toLowerCase()}.svg`}
                                        alt={integrationType}
                                        className="w-6 h-6"
                                      />
                                      {integrationType}
                                    </article>
                                  ) : (
                                    <ul
                                      key={integrationType}
                                      className="flex flex-wrap items-center gap-2"
                                    >
                                      {filteredAccounts?.map(
                                        (account: Account) => {
                                          return (
                                            <li
                                              key={account.integration_id}
                                              className="px-2 py-1 text-b2-reg dark:bg-gray-700 rounded"
                                            >
                                              {account.customer_cloud_id}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  )}
                                </td>
                              );
                            }
                          )}
                        </tr>
                      );
                    }
                  )}
                </tbody>
              </TableLayout>
              <TablePagination
                totalPages={totalPages}
                beginning={beginning}
                end={end}
                totalCount={totalCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </>
          ) : (
            <p className="text-b1-reg">No integrations in this environment</p>
          )
        ) : null}
      </section>
    </section>
  );
};

export default EnvironmentDetails;
