import React from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { CreateRedlining } from "../../../../../services/grc";
import { KeyStringVal } from "../../../../../types/general";

const InsertRedlining = ({
  documentID,
  showModal,
  setShowModal,
  selectedText,
  newText,
  setNewText,
}: {
  documentID: string;
  showModal: boolean;
  setShowModal: (showModal: boolean) => void;
  selectedText: string;
  newText: KeyStringVal;
  setNewText: (newText: KeyStringVal) => void;
}) => {
  const createRedlining = CreateRedlining(documentID);

  const handleOnClose = () => {
    setShowModal(false);
  };

  return (
    <ModalLayout showModal={showModal} onClose={handleOnClose}>
      <section className="grid content-start gap-4 h-full">
        <h3 className="flex items-center gap-2 text-t1-semi">
          Instruction for Redlining
        </h3>
        <RegularInput
          label="How would you like to redline this?"
          keyName="new_text"
          inputs={newText}
          setInputs={setNewText}
          textarea
        />
        <article className="flex items-center place-content-end gap-4">
          <button className="black-button" onClick={handleOnClose}>
            Cancel
          </button>
          <button
            disabled={newText.new_text === ""}
            className="blue-button"
            onClick={() => {
              createRedlining.mutate({
                redlining: {
                  old_content: selectedText,
                  new_edits: newText.new_text,
                  start: 0,
                  end: 100,
                },
              });
              handleOnClose();
            }}
          >
            <h4>Done</h4>
          </button>
        </article>
      </section>
    </ModalLayout>
  );
};

export default InsertRedlining;
