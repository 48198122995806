/* eslint-disable react-hooks/exhaustive-deps */
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { handleClickMapping } from "src/utils/grc";
import SubsectionContent from "./SubsectionContent";
import ViewInFile from "src/pages/RegulationPolicy/Document/ViewInFile/ViewInFile";
import { GetAIGeneratedAnswer } from "src/services/third-party-risk/assessments/vendor-assessment";

const Subsection = ({
  documentType,
  documentID,
  documentName,
  reviewID,
  auditID,
  subsection,
  selectedTab,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  documentName: string;
  reviewID: string;
  auditID: string;
  subsection: any;
  selectedTab: string;
  editSections: any;
  setEditSections: any;
}) => {
  const [opened, setOpened] = useState<boolean>(false);
  const sectionRef = useRef() as MutableRefObject<HTMLElement>;

  const isControls = selectedTab === "Controls Coverage";
  const generatedID = subsection.generated_id;

  const aiGeneratedAnswer = GetAIGeneratedAnswer(reviewID, generatedID);

  useEffect(() => {
    aiGeneratedAnswer.mutate({});
  }, [subsection]);

  return (
    <article
      ref={sectionRef}
      className="grid content-start gap-3 p-4 dark:bg-gray-900 rounded-lg"
    >
      <h4 className="flex items-start gap-2 text-b1-semi break-all">
        {subsection.sub_section_id}{" "}
        {subsection.sub_section_title !== "-" && subsection.sub_section_title}
      </h4>
      {aiGeneratedAnswer.data ? (
        typeof aiGeneratedAnswer.data !== "string" ? (
          <article className="flex items-center gap-4 text-b2-reg">
            {["Covered", "Covered with Warning", "Not Covered"].map((stat) => {
              const num =
                aiGeneratedAnswer.data[stat.toLowerCase().replaceAll(" ", "_")];
              if (num === 0) return null;
              return (
                <article
                  key={stat}
                  className={`flex items-center gap-1 px-2 py-1 ${
                    stat === "Covered"
                      ? "bg-green-700"
                      : stat === "Covered with Warning"
                      ? "bg-yellow-600"
                      : "bg-red-700"
                  } rounded`}
                >
                  <span className="text-b1-reg">{stat}</span>
                </article>
              );
            })}
          </article>
        ) : (
          <article className="flex items-center gap-1 px-2 py-1 w-max dark:bg-gray-700 rounded">
            <span className="text-b1-reg">{aiGeneratedAnswer.data}</span>{" "}
          </article>
        )
      ) : null}
      <article className="flex flex-col flex-grow gap-4 w-full">
        {subsection.metadata_ &&
          Object.entries(subsection.metadata_).length > 0 && (
            <ul className="flex flex-wrap items-center gap-2">
              {Object.entries(subsection.metadata_).map((keyVal, index) => {
                if (
                  !keyVal[1] ||
                  (Array.isArray(keyVal[1]) && keyVal[1]?.length === 0)
                )
                  return null;
                return (
                  <li
                    key={keyVal[0]}
                    className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded"
                  >
                    <h4 className="capitalize">
                      {keyVal[0].replaceAll("_", " ")}
                    </h4>
                    {Array.isArray(keyVal[1]) ? (
                      keyVal[1].map((value) => {
                        return (
                          <span key={value} className="dark:text-blue-500">
                            {value}
                          </span>
                        );
                      })
                    ) : (
                      <span className="dark:text-blue-500">{keyVal[1]}</span>
                    )}
                  </li>
                );
              })}
            </ul>
          )}
        <article className="flex items-center gap-10">
          {isControls && (
            <>
              <a
                href="/grc/mapping"
                className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                onClick={() =>
                  handleClickMapping(
                    documentType,
                    documentName,
                    documentID,
                    selectedTab,
                    subsection,
                    "Relevant Sections",
                    subsection.mapped_self_control_sections,
                    auditID
                  )
                }
              >
                <p>
                  {subsection.mapped_self_control_sections === 0
                    ? "Relevant Sections"
                    : `${
                        subsection.mapped_self_control_sections
                      } Relevant Section${
                        subsection.mapped_self_control_sections === 1 ? "" : "s"
                      }`}
                </p>{" "}
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
            </>
          )}
          <a
            href="/grc/mapping"
            className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
            onClick={() =>
              handleClickMapping(
                documentType,
                documentName,
                documentID,
                selectedTab,
                subsection,
                "RFS",
                subsection.mapped_framework_sections,
                auditID
              )
            }
          >
            <p>
              {subsection.mapped_framework_sections === 0
                ? "Framework"
                : `${subsection.mapped_framework_sections} Overlap${
                    subsection.mapped_framework_sections === 1 ? "s" : ""
                  } with Framework`}
            </p>{" "}
            <FontAwesomeIcon icon={faArrowRightLong} />
          </a>
        </article>
      </article>
      <article
        className={`grid content-start ${
          subsection.page_metadata?.length > 0 ? "md:grid-cols-2" : ""
        } gap-10`}
      >
        {subsection.content && (
          <SubsectionContent
            documentID={documentID}
            reviewID={reviewID}
            auditID={auditID}
            subsection={subsection}
            selectedTab={selectedTab}
            editSections={editSections}
            setEditSections={setEditSections}
            opened={opened}
            setOpened={setOpened}
          />
        )}
        {subsection.page_metadata?.length > 0 && (
          <ViewInFile
            generatedID={generatedID}
            section={subsection}
            bbox={subsection.page_metadata}
            documentType={documentType}
            opened={opened}
            setOpened={setOpened}
            isNotModal
          />
        )}
      </article>
    </article>
  );
};

export default Subsection;
