/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import {
  GetAccountInfo,
  UpdateAccount,
} from "../../../services/settings/integrations";
import { getCustomerID } from "../../../utils/general";

const AccountTags = ({
  keyName,
  inputs,
  setInputs,
  selectedAccountID,
}: {
  keyName: string;
  inputs: any;
  setInputs: any;
  selectedAccountID: string;
}) => {
  const customerID = getCustomerID();

  const [addTag, setAddTag] = useState<boolean>(false);
  const [newTag, setNewTag] = useState<string>("");

  const { data: accountInfo } = GetAccountInfo(customerID, selectedAccountID);
  const updateAccount = UpdateAccount(customerID);

  useEffect(() => {
    if (accountInfo) {
      setInputs({ ...inputs, [keyName]: accountInfo.integration_tags });
    }
  }, [accountInfo]);

  return (
    <ul className="flex flex-wrap items-center gap-4 p-2 text-b2-reg dark:bg-gray-900 rounded">
      {inputs[keyName]?.map((tag: string) => {
        return (
          <li
            key={tag}
            className="flex items-center gap-2 px-2 py-1 dark:text-white dark:bg-gray-700 rounded"
          >
            <p>{tag}</p>
            <button className="hover-icon">
              <FontAwesomeIcon
                icon={faXmark}
                onClick={() => {
                  const newTags = inputs[keyName].filter(
                    (curTag: string) => curTag !== tag
                  );
                  if (selectedAccountID !== "")
                    updateAccount.mutate({
                      accountID: selectedAccountID,
                      account: {
                        integration_tags: newTags,
                      },
                    });
                  else setInputs({ ...inputs, [keyName]: newTags });
                }}
              />
            </button>
          </li>
        );
      })}
      {!addTag ? (
        <li
          className="flex items-center gap-2 px-2 py-1 cursor-pointer text-b2-reg dark:bg-blue-500 dark:hover:bg-blue-400/70 duration-100 rounded"
          onClick={() => setAddTag(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          <p className="w-max">Add tag</p>
        </li>
      ) : (
        <article className="flex items-stretch w-max divide-x dark:divide-account border-1 dark:border-org rounded-sm">
          <article className="relative flex items-center gap-2 py-2 px-4 ring-0 dark:ring-search focus:ring-2 dark:focus:ring-blue-600 dark:bg-transparent rounded-sm">
            <input
              spellCheck="false"
              autoComplete="off"
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              type="input"
              className="w-20 h-5 focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-transparent dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent"
            />
          </article>
          <button
            disabled={newTag === ""}
            className="px-2 hover-icon"
            onClick={() => {
              if (!inputs[keyName].includes(newTag)) {
                setAddTag(false);
                setNewTag("");

                const newTags = [...inputs[keyName], newTag];
                if (selectedAccountID !== "")
                  updateAccount.mutate({
                    accountID: selectedAccountID,
                    account: {
                      integration_tags: newTags,
                    },
                  });
                else setInputs({ ...inputs, [keyName]: newTags });
              }
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
          <button
            className="px-2 hover-icon"
            onClick={() => {
              setAddTag(false);
              setNewTag("");
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </button>
        </article>
      )}
    </ul>
  );
};

export default AccountTags;
