/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PageLayout from "src/layouts/PageLayout";
import ProcedureMetadata from "./ProcedureMetadata";
import { parseURL } from "src/utils/general";
import ProcedureDetail from "./ProcedureDetail/ProcedureDetail";

const Procedure = () => {
  const parsed = parseURL();

  const [selectedSOPVersion, setSelectedSOPVersion] = useState<string>("");

  const sopID = String(parsed.sop_id) || "";

  useEffect(() => {
    sessionStorage.GRCCategory = "standard operating procedures";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <ProcedureMetadata
          sopID={sopID}
          selectedSOPVersion={selectedSOPVersion}
          setSelectedSOPVersion={setSelectedSOPVersion}
        />
        <ProcedureDetail sopID={sopID} />
      </main>
    </PageLayout>
  );
};

export default Procedure;
