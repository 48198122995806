import { checkIsSuperOrSiteAdmin } from "src/utils/general";
import { GetCustomers } from "../../../services/settings/organization";
import { Customer } from "../../../types/settings";
import CustomerDetail from "./CustomerDetail";
import NewCustomer from "./NewCustomer";

const SuperAdmin = () => {
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const { data: allCustomers } = GetCustomers(isSuperOrSiteAdmin);

  return (
    <section className="grid content-start gap-6 w-full">
      <header className="flex items-center justify-between gap-10 w-full">
        <h4 className="text-subt1-semi">Customers ({allCustomers?.length})</h4>
        <NewCustomer />
      </header>

      <ul className="grid grid-cols-1 md:grid-cols-4 content-start items-stretch gap-4 w-full h-full">
        {allCustomers?.map((customer: Customer, index: number) => {
          return <CustomerDetail key={index} customer={customer} />;
        })}
      </ul>
    </section>
  );
};

export default SuperAdmin;
