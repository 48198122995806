import {
  faCheckCircle,
  faInfoCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toastBorderColors, toastIconColors } from "src/constants/general";

const MessageLayout: React.FC<{
  type: string;
}> = ({ type, children }) => {
  return (
    <section className={`w-max ${toastBorderColors[type]}`}>
      <FontAwesomeIcon
        icon={
          type === "success"
            ? faCheckCircle
            : type === "info"
            ? faInfoCircle
            : faTriangleExclamation
        }
        className={toastIconColors[type]}
      />
      {children}
    </section>
  );
};

export default MessageLayout;
