import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import RiskQuestionnaireMetadata from "./QuestionnaireMetadata";

const Questionnaire = () => {
  const parsed = parseURL();

  const riskQuestionnaireID = String(parsed.risk_questionnaire_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "risks";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <RiskQuestionnaireMetadata riskQuestionnaireID={riskQuestionnaireID} />
      </main>
    </PageLayout>
  );
};

export default Questionnaire;
