import React, { useState } from "react";
import { attributeColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import {
  checkIsUserObject,
  convertToUTCShortString,
  getCustomerID,
  getEmailFromUserID,
} from "src/utils/general";

const MetadataField = ({
  metadata,
  label,
  keyName,
  singleUser,
  multipleUsers,
  isDate,
  isList,
}: {
  metadata: any;
  label: string;
  keyName: string;
  singleUser?: boolean;
  multipleUsers?: boolean;
  isDate?: boolean;
  isList?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showMore, setShowMore] = useState<boolean>(false);

  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <article className="grid content-start">
      <h4 className="dark:text-gray-300 text-b2-semi">{label}</h4>
      {metadata && (metadata[`${keyName}_text`] || metadata[keyName]) ? (
        (((isList || multipleUsers) &&
          (metadata[`${keyName}_text`] || metadata[keyName])?.length > 0) ||
          (!(isList || multipleUsers) &&
            (metadata[keyName] || metadata[`${keyName}_text`]))) && (
          <>
            {singleUser ? (
              checkIsUserObject(metadata[keyName]) ? (
                <span className="text-b1-semi">
                  {metadata[keyName]?.user_name}
                </span>
              ) : (
                <span className="text-b1-semi">
                  {metadata[`${keyName}_text`]
                    ? metadata[`${keyName}_text`]
                    : getEmailFromUserID(allUsers, metadata[keyName]) ||
                      metadata[keyName]}
                </span>
              )
            ) : multipleUsers ? (
              <article className="flex flex-col flex-grow gap-1">
                {metadata[`${keyName}_text`]?.map((user: string) => {
                  return (
                    <span key={user} className="text-b1-semi">
                      {user}
                    </span>
                  );
                })}
                {metadata[keyName].map((user: any, idx: number) => {
                  let value = "";
                  if (typeof user === "string")
                    value = getEmailFromUserID(allUsers, user);
                  else value = getEmailFromUserID(allUsers, user.user_id);
                  return (
                    <span key={idx} className="text-b1-semi">
                      {value}
                    </span>
                  );
                })}
              </article>
            ) : isDate ? (
              <span className="text-b1-semi">
                {convertToUTCShortString(metadata[keyName])}
              </span>
            ) : isList ? (
              <ul className="flex flex-col flex-grow gap-1">
                {metadata[keyName].map((item: string) => (
                  <span key={item} className="text-b1-semi">
                    {item}
                  </span>
                ))}
              </ul>
            ) : (
              <article className="flex flex-wrap items-center gap-2 break-all text-b1-semi">
                <p className="flex flex-col flex-grow gap-1">
                  {String(metadata[keyName])
                    .slice(0, showMore ? metadata[keyName].length : 200)
                    .split("\n")
                    .map((phrase, idx) => {
                      return (
                        <span
                          key={idx}
                          className={`${
                            attributeColors[phrase?.toLowerCase()]
                          }`}
                        >
                          {phrase}
                        </span>
                      );
                    })}
                </p>
                {metadata[keyName].length > 200 && !showMore && "..."}
                {metadata[keyName].length > 200 && (
                  <button
                    onClick={() => setShowMore(!showMore)}
                    className="dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                  >
                    show {showMore ? "less" : "more"}
                  </button>
                )}
              </article>
            )}
          </>
        )
      ) : (
        <span className="text-b1-semi">-</span>
      )}
    </article>
  );
};

export default MetadataField;
