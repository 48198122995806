import React, { useState } from "react";
import Loader from "src/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import TableLayout from "src/layouts/TableLayout";
import { handleSort, sortRows } from "src/utils/general";
import { faChevronRight, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialSort, pageSize } from "src/constants/general";
import { Sort } from "src/types/dashboard";
import TablePagination from "src/components/General/TablePagination";
import {
  controlPrefix,
  GetControls,
} from "../../../../services/erc/controls/controls";
import AllERCTags from "src/components/GRC/AllERCTags";
import { handleClickMapping } from "src/utils/grc";
import RowValue from "src/components/General/RowValue";
import ColumnFilters from "src/components/GRC/ColumnFilters";
import { useERCStore } from "src/stores/erc";
import { GetEntities } from "src/services/erc/entities/entities";
import QueryBuilder from "src/components/GRC/QueryBuilder/QueryBuilder";
import { KeyStringVal } from "src/types/general";

const ControlList = () => {
  const navigate = useNavigate();

  const { selectedERCFilters, setSelectedERCFilters } = useERCStore();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [queryList, setQueryList] = useState<any>([]);

  const {
    data: controls,
    status: controlsStatus,
    refetch,
  } = GetControls(
    pageNumber,
    selectedTags,
    selectedERCFilters.columns,
    queryList
  );
  const { data: entities } = GetEntities();

  const headers = controls && JSON.parse(controls.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = controls && sortRows(controls?.data, sort);

  const totalCount = controls?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4">
      <AllERCTags
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <article className="flex items-center gap-4">
        <ColumnFilters
          filters={selectedERCFilters}
          setFilters={setSelectedERCFilters}
          prefix={controlPrefix}
          refetch={refetch}
        />
        <QueryBuilder
          prefix={controlPrefix}
          refetch={refetch}
          queryList={queryList}
          setQueryList={setQueryList}
        />
      </article>
      {controlsStatus === "loading" ? (
        <Loader />
      ) : controls?.data?.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4 pb-24">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                const entity =
                  entities?.data?.find(
                    (entity: KeyStringVal) =>
                      entity.grc_entity_id === row.grc_entity_id
                  )?.name || "-";
                const control =
                  controls?.data.find(
                    (control: KeyStringVal) =>
                      control.control_id === row.parent_control_id
                  )?.name || "-";

                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    onClick={() => {
                      sessionStorage.ERCCategory = "controls";
                      navigate(
                        `/erc/controls/details?control_id=${row.control_id}`
                      );
                    }}
                  >
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left break-words"
                        >
                          {col === "control_blueprint" ? (
                            <article
                              className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                              onClick={() => {
                                if (row.control_blueprint)
                                  navigate(
                                    `/erc/control-blueprints/details?control_blueprint_id=${row.control_blueprint.control_blueprint_id}`
                                  );
                              }}
                            >
                              {row.control_blueprint?.name || "-"}
                            </article>
                          ) : col === "grc_entity_id" ? (
                            <article
                              className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                              onClick={() =>
                                navigate(
                                  `/erc/entities/details?entity_id=${row.grc_entity_id}`
                                )
                              }
                            >
                              {entity}
                            </article>
                          ) : col === "parent_control_id" ? (
                            <article
                              className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                              onClick={() =>
                                navigate(
                                  `/erc/controls/details?control_id=${row.parent_control_id}`
                                )
                              }
                            >
                              {control}
                            </article>
                          ) : col === "mapped_policy_sections" ? (
                            <a
                              href="/grc/mapping"
                              className="flex items-center gap-2 dark:text-blue-600 dark:hover:text-blue-600/80 duration-100"
                              onClick={(e) => {
                                e.stopPropagation();

                                handleClickMapping(
                                  "controls",
                                  row.name,
                                  row.control_id,
                                  "",
                                  row,
                                  col === "mapped_policy_sections"
                                    ? "Policy"
                                    : "Framework",
                                  row.mapped_self_control_sections,
                                  "",
                                  row.control_id
                                );
                              }}
                            >
                              <FontAwesomeIcon icon={faChevronRight} />

                              <p>{row[col]} Mapped</p>
                            </a>
                          ) : (
                            <RowValue row={row} col={col} />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="controls placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Controls</h4>
            <h4>No controls available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default ControlList;
