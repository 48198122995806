import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import RiskMetadata from "./RiskMetadata";
import TreatmentPlan from "./TreatmentPlan";
import RiskScore from "./RiskScore";
import AssociateVendorWithRiskFilter from "src/components/Filter/ERC/AssociateVendorWithRiskFilter";
import LifecycleFlow from "./LifecycleFlow";
import AttachmentList from "src/pages/ERC/Risks/RiskList/Risk/Attachments/AttachmentList";

const Risk = () => {
  const parsed = parseURL();

  const riskID = String(parsed.risk_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "risks";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <RiskMetadata riskID={riskID} />
        <AssociateVendorWithRiskFilter riskID={riskID} />
        <AttachmentList riskID={riskID} />
        <LifecycleFlow riskID={riskID} />
        <RiskScore riskID={riskID} />
        <TreatmentPlan riskID={riskID} />
      </main>
    </PageLayout>
  );
};

export default Risk;
