import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const prefix = "grc/tprm/assessment-cycles";

export const GetAssessmentCycleList = (pageNumber: number, category?: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-assessment-cycle-list", pageNumber, category],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/all`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            ...(category && { category: category }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddAssessmentCycle = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-cycle-list"]);
      },
    }
  );

export const RemoveAssessmentCycle = () => {
  const navigate = useNavigate();

  return useMutation<any, unknown, any, string>(
    async ({
      assessmentID,
      signal,
    }: {
      assessmentID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${assessmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-cycle-list"]);
        navigate("/third-party-risk/summary");
      },
    }
  );
};

export const GetAssessmentCycleMetadata = (assessmentID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-assessment-cycle-metadata", assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${assessmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetAssessmentCycleVendorList = (
  assessmentID: string,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-assessment-cycle-vendor-list", assessmentID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/vendors`,
          {
            page_number: pageNumber,
            page_size: pageSize,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const AssessVendors = (assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ formData, signal }: { formData: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${assessmentID}/run`,
          formData,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const RemoveVendorFromAssessment = (
  vendorID: string,
  assessmentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${assessmentID}/vendors/${vendorID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );
