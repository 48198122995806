/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */

import { useRef, MutableRefObject, useState, useEffect } from "react";
import RegularInput from "src/components/Input/RegularInput";
import IntegrationLayout from "src/layouts/IntegrationLayout";
import {
  CreateAccount,
  TestCredentials,
  UploadCredentials,
} from "src/services/settings/integrations";
import { Credentials } from "src/types/settings";
import { parseURL, getCustomerID, checkIsReadOnly } from "src/utils/general";
import ConnectionDetails from "../ConnectionDetails";
import TestButton from "../TestButton";
import PasswordInput from "src/components/Input/PasswordInput";

const SLACK = ({
  setShowIntegrationDetails,
  selectedAccountID,
}: {
  setShowIntegrationDetails: (integrationDetails: boolean) => void;
  selectedAccountID: string;
}) => {
  const parsed = parseURL();
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const testRef = useRef() as MutableRefObject<HTMLElement>;
  const [credentials, setCredentials] = useState<Credentials>({
    slack_bot_token: "",
    slack_signing_secret: "",
  });

  const createAccount = CreateAccount(customerID);
  const testCredentials = TestCredentials(customerID, parsed.integration);
  const uploadCredentials = UploadCredentials(
    customerID,
    setShowIntegrationDetails
  );

  const disabled =
    isReadOnly ||
    testCredentials.status === "loading" ||
    createAccount.status === "loading" ||
    uploadCredentials.status === "loading";

  useEffect(() => {
    if (testCredentials.status === "success")
      testRef?.current?.scrollIntoView();
  }, [testCredentials.status]);

  return (
    <IntegrationLayout
      selectedAccountID={selectedAccountID}
      testCredentials={testCredentials}
      uploadCredentials={uploadCredentials}
      testRef={testRef}
      disabled={disabled}
      credentials={credentials}
      createAccount={createAccount}
    >
      <RegularInput
        label="Slack Bot Token"
        keyName="slack_bot_token"
        inputs={credentials}
        setInputs={setCredentials}
        disabled={disabled}
        required
      />
      <PasswordInput
        label="Slack Signing Secret"
        keyName="slack_signing_secret"
        inputs={credentials}
        setInputs={setCredentials}
        disabled={disabled}
        required
      />
      <TestButton
        disabled={
          credentials.slack_bot_token === "" ||
          credentials.slack_signing_secret === "" ||
          testCredentials.status === "loading" ||
          (testCredentials.data &&
            Object.keys(testCredentials.data.errors).length === 0 &&
            Object.keys(testCredentials.data.result.failed).length === 0)
        }
        handleOnClick={() =>
          testCredentials.mutate({
            credentials,
          })
        }
        handleOnClose={() => setShowIntegrationDetails(false)}
      />

      {/* show which connections passed/failed */}
      {testCredentials.data &&
        Object.keys(testCredentials.data.errors).length === 0 && (
          <ConnectionDetails
            testRef={testRef}
            failed={testCredentials.data.result.failed}
            passed={testCredentials.data.result.passed}
          />
        )}
    </IntegrationLayout>
  );
};

export default SLACK;
