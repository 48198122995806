import { LineWave } from "react-loader-spinner";

const Loader: React.FC = () => {
  return (
    <article className="grid w-full h-full place-content-center">
      {/* <img src="/general/coffee-loading-animated.svg" alt="loader" /> */}
      <LineWave
        visible={true}
        color="#4fa94d"
        ariaLabel="line-wave-loading"
        wrapperStyle={{}}
        wrapperClass=""
        firstLineColor=""
        middleLineColor=""
        lastLineColor=""
      />
    </article>
  );
};

export default Loader;
