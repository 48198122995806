import React, { useEffect, useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { CopyCustomQuestionSet } from "src/services/third-party-risk/questions/custom-question-sets";
import TableLayout from "../../../../../../layouts/TableLayout";
import NewQuestions from "./NewQuestions";
import ToastLayout from "src/layouts/ToastLayout";
import Response from "../Response";
import {
  useSensor,
  PointerSensor,
  useSensors,
  DragEndEvent,
  DndContext,
  DragOverlay,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import { KeyStringVal } from "src/types/general";

const CopyQuestionSet = ({
  questionSetID,
  setShow,
  inputs,
  setInputs,
  selectedQuestions,
  setSelectedQuestions,
  headers,
  filteredHeaders,
}: {
  questionSetID: string;
  setShow: (show: boolean) => void;
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
  headers: any;
  filteredHeaders: any;
}) => {
  const [newQuestions, setNewQuestions] = useState<any>([]);
  const [list, setList] = useState<any>([]);
  const [activeItem, setActiveItem] = useState<any>({});

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const copyQuestionSet = CopyCustomQuestionSet(questionSetID);

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setList(arrayMove(list, oldIdx, newIdx));
    }
  };

  const handleOnClose = () => {
    setSelectedQuestions([]);
    setShow(false);
  };
  const handleOnCloseConfirmation = () => copyQuestionSet.reset();

  useEffect(() => {
    setList(selectedQuestions);
  }, [selectedQuestions]);

  return (
    <>
      <section className="grid content-start gap-4 p-4 h-full dark:bg-gray-700 rounded-xl">
        <h3 className="flex items-center gap-2 text-t1-semi">
          Copy Question Set
        </h3>
        <RegularInput
          label="Name"
          keyName="name"
          inputs={inputs}
          setInputs={setInputs}
          required
        />
        <RegularInput
          label="Description"
          keyName="description"
          inputs={inputs}
          setInputs={setInputs}
          textarea
        />
        <NewQuestions
          newQuestions={newQuestions}
          setNewQuestions={setNewQuestions}
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
        />
        <DndContext
          sensors={sensors}
          onDragStart={(e) =>
            setActiveItem(
              list.find(
                (item: KeyStringVal) => item.generated_id === e.active.id
              )
            )
          }
          onDragEnd={reorderQuestionsList}
        >
          <TableLayout>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string, colIndex: number) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={colIndex}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <article className="flex items-center gap-4">
                          <h4 className="break-words">{column.title}</h4>
                        </article>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              <SortableContext items={list}>
                {list?.map((row: any) => {
                  return (
                    <Response
                      key={row.generated_id}
                      questionSetID={questionSetID}
                      qa={row}
                      selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions}
                      filteredHeaders={filteredHeaders}
                    />
                  );
                })}
              </SortableContext>
            </tbody>
          </TableLayout>
          <DragOverlay
            dropAnimation={{
              duration: 150,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
          >
            {activeItem ? (
              <Response
                questionSetID={questionSetID}
                qa={activeItem}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                filteredHeaders={filteredHeaders}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        <article className="flex items-center place-content-end gap-4">
          <button className="black-button" onClick={handleOnClose}>
            Cancel
          </button>
          <button
            disabled={inputs.name === ""}
            className="blue-button"
            onClick={() => {
              let questionIDs = {};
              selectedQuestions.forEach((curQ, idx) => {
                if (curQ.generated_id)
                  questionIDs = {
                    ...questionIDs,
                    [curQ.generated_id]: {
                      order_key: idx,
                      ...curQ,
                    },
                  };
              });
              copyQuestionSet.mutate({
                inputs: {
                  request: {
                    name: inputs.name,
                    description: inputs.description,
                    question_ids: questionIDs,
                    new_questions: newQuestions,
                  },
                },
              });
              handleOnClose();
            }}
          >
            <h4>Done</h4>
          </button>
        </article>
      </section>
      <ToastLayout
        showModal={copyQuestionSet.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New question set has been copied"
      />
    </>
  );
};

export default CopyQuestionSet;
