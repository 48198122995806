import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import {
  AddVendor,
  GetVendorFieldValues,
  GetVendors,
} from "src/services/third-party-risk/vendors/vendors";
import { KeyStringVal } from "../../../../types/general";
import CategoryInput from "../../../../components/Input/CategoryInput";
import {
  checkIsReadOnly,
  decodeJWT,
  validateEmail,
} from "../../../../utils/general";
import ToastLayout from "../../../../layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import MultiCategoryInput from "src/components/Input/MultiCategoryInput";
import { Disclosure } from "@headlessui/react";
import MultiUserInput from "src/components/Input/MultiUserInput";
import AssessmentQuestionSetFilter from "src/components/Filter/ThirdPartyRisk/AssessmentQuestionSetFilter";
import MultiContactFilter from "src/components/Filter/ThirdPartyRisk/MultiContactFilter";

const NewVendor = () => {
  const jwt = decodeJWT();
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const { data: vendors } = GetVendors();
  const addVendor = AddVendor();

  const { data: status } = GetVendorFieldValues("status");
  const { data: ssoEnabled } = GetVendorFieldValues("sso_enabled");
  const { data: dataClassification } = GetVendorFieldValues(
    "data_classification"
  );
  const { data: reassessmentFrequency } = GetVendorFieldValues(
    "reassessment_frequency"
  );
  const { data: authenticationProtocol } = GetVendorFieldValues(
    "authentication_protocol"
  );
  const { data: reviewChecklist } = GetVendorFieldValues("review_checklist");
  const { data: baaExecuted } = GetVendorFieldValues("baa_executed");

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendor.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            inherent_risk_impact_level: "MODERATE",
            inherent_risk_likelihood_level: "POSSIBLE",
            address: "",
            contacts: [],
            number_of_employees: "0",
            internal_email_list: "",
            business_user_name: "",
            department_head: "",
            department: "",
            vendor_type: "",
            vendor_manager: [],
            vendor_tier: "",
            rank_tier: "",
            website: "",
            duns_number: "",
            status: "DRAFT",
            logo: "",
            is_publicly_traded: false,
            notes: "",
            workflow: "",
            stage: "",
            created_by: jwt?.sub,
            dj_nth_party_risks: "",
            help_ticket: "",
            sso_enabled: "",
            is_PHI: false,
            is_PII: false,
            authentication_protocol: "",
            primary_app_service: "",
            impacted_systems: "",
            review_checklist: [],
            description: "",
            additional_notes: "",
            sponsor: "",
            data_classification: "",
            baa_executed: "",
            creation_date: Date.now() * 1000,
            assessed_for_ai_functionality: "",
            last_updated_by: jwt?.sub,
            ref_id: "",
            last_review_date: Date.now() * 1000,
            next_review_date: Date.now() * 1000,
            reassessment_frequency: "",
            target_primary_application_service: "",
            additional_information: "",
            analyst_comments: "",
            criticality: "",
            historical_data: "",
            external_document_url: "",
            send_risk_assessment: false,
            question_set: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Vendor</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Vendor</h3>
          <section className="grid gap-4 w-full h-full">
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Primary Vendor Information</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 content-start gap-4 w-full">
                      <RegularInput
                        label="Vendor"
                        keyName="name"
                        inputs={inputs}
                        setInputs={setInputs}
                        valid={valid}
                        setValid={setValid}
                        required
                      />
                      <RegularInput
                        label="Vendor Type"
                        keyName="vendor_type"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Assessed for AI Functionality"
                        keyName="assessed_for_ai_functionality"
                        list={["N/A", "No", "Yes"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Website"
                        keyName="website"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Address"
                        keyName="address"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Number of Employees"
                        keyName="number_of_employees"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Ref-ID"
                        keyName="ref_id"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="DUNS Number"
                        keyName="duns_number"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Description"
                        keyName="description"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <CategoryInput
                        label="Status"
                        keyName="status"
                        list={status?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                        allowNewValue
                      />
                      <CategoryInput
                        label="Inherent Risk Impact Level"
                        keyName="inherent_risk_impact_level"
                        list={[
                          "NEGLIGIBLE",
                          "MINOR",
                          "MODERATE",
                          "SIGNIFICANT",
                          "SEVERE",
                        ]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Inherent Risk Likelihood Level"
                        keyName="inherent_risk_likelihood_level"
                        list={[
                          "VERY_UNLIKELY",
                          "UNLIKELY",
                          "POSSIBLE",
                          "LIKELY",
                          "VERY_LIKELY",
                        ]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Rank Tier"
                        keyName="rank_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="Publicly traded"
                        keyName="is_publicly_traded"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <article className="flex flex-col flex-grow">
                        <CheckboxInput
                          label="Assess Inherent Risk"
                          keyName="send_risk_assessment"
                          inputs={inputs}
                          setInputs={setInputs}
                        />
                        <span className="text-b2-reg">
                          Assessment will be sent to Vendor Manager
                        </span>
                      </article>
                      {inputs.send_risk_assessment && (
                        <AssessmentQuestionSetFilter
                          label="Inherent Risk Question set"
                          keyName="question_set"
                          inputs={inputs}
                          setInputs={setInputs}
                          required={inputs.send_risk_assessment}
                        />
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-4">
                      <RegularInput
                        label="Application Owner Email"
                        keyName="primary_app_service"
                        inputs={inputs}
                        setInputs={setInputs}
                        multipleEmail
                      />
                      <MultiUserInput
                        label="Vendor Manager Email"
                        keyName="vendor_manager"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                        required={inputs.send_risk_assessment}
                      />
                      <RegularInput
                        label="Help Ticket"
                        keyName="help_ticket"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Vendor Sponsor"
                        keyName="sponsor"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Criticality"
                        keyName="criticality"
                        list={["Partner", "Critical", "High", "Medium", "Low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Data Classification"
                        keyName="data_classification"
                        list={dataClassification?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <GeneralSnapshotDatepicker
                        label="Last Review Date"
                        keyName="last_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <GeneralSnapshotDatepicker
                        label="Next Review Date"
                        keyName="next_review_date"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Reassessment Frequency"
                        keyName="reassessment_frequency"
                        list={reassessmentFrequency?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                        allowNewValue
                      />
                      <RegularInput
                        label="Notes"
                        keyName="notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Additional Notes"
                        keyName="additional_notes"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Analyst Comments"
                        keyName="analyst_comments"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <MultiCategoryInput
                        label="Vendor Review Checklist"
                        keyName="review_checklist"
                        list={reviewChecklist?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <RegularInput
                        label="Additional Information"
                        keyName="additional_information"
                        inputs={inputs}
                        setInputs={setInputs}
                        textarea
                      />
                      <RegularInput
                        label="Impacted Systems"
                        keyName="impacted_systems"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="SSO Enabled"
                        keyName="sso_enabled"
                        list={ssoEnabled?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Vendor Tier"
                        keyName="vendor_tier"
                        list={["critical", "high", "medium", "low"]}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CategoryInput
                        label="Business Associate Agreement Executed"
                        keyName="baa_executed"
                        list={baaExecuted?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Vendor Contact Information</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <MultiContactFilter
                      label="Contacts"
                      keyName="contacts"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-4">
                      <RegularInput
                        label="Target Primary Application Service"
                        keyName="target_primary_application_service"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CategoryInput
                        label="Authentication Protocol"
                        keyName="authentication_protocol"
                        list={authenticationProtocol?.fields}
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                      />
                      <CheckboxInput
                        label="PHI"
                        keyName="is_PHI"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <CheckboxInput
                        label="PII"
                        keyName="is_PII"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-4">
                      <RegularInput
                        label="Workflow"
                        keyName="workflow"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Dow Jones Nth Party Risks"
                        keyName="dj_nth_party_risks"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Internal Email CC List"
                        keyName="internal_email_list"
                        inputs={inputs}
                        setInputs={setInputs}
                        multipleEmail
                      />
                      <RegularInput
                        label="Name of Business User"
                        keyName="business_user_name"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Head of Department"
                        keyName="department_head"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Department"
                        keyName="department"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Stage"
                        keyName="stage"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="Historical Data"
                        keyName="historical_data"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      <RegularInput
                        label="External Document URL"
                        keyName="external_document_url"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                (inputs.internal_email_list !== "" &&
                  (inputs.internal_email_list
                    ?.replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.internal_email_list?.match(/@/g) || []).length >
                      1 &&
                      !inputs.internal_email_list?.includes(",") &&
                      !inputs.internal_email_list?.includes(";")))) ||
                (inputs.primary_app_service !== "" &&
                  (inputs.primary_app_service
                    ?.replaceAll(" ", "")
                    .split(/[;,]/)
                    .some((email: string) => !validateEmail(email)) ||
                    ((inputs.primary_app_service?.match(/@/g) || []).length >
                      1 &&
                      !inputs.primary_app_service.includes(",") &&
                      !inputs.primary_app_service.includes(";")))) ||
                (inputs.send_risk_assessment &&
                  (inputs.vendor_manager?.length === 0 || !inputs.question_set))
              }
              className="blue-button"
              onClick={() => {
                if (
                  vendors?.data.some(
                    (vendor: KeyStringVal) =>
                      vendor.name.toLowerCase().trim() ===
                      inputs.name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  addVendor.mutate({
                    inputs,
                  });

                  handleOnClose();
                }
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addVendor.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New vendor has been created"
      />
    </>
  );
};

export default NewVendor;
