import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { KeyStringVal } from "../../../../../../types/general";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { checkIsReadOnly } from "src/utils/general";

const AskAQuestion = ({
  inputs,
  setInputs,
}: {
  inputs: any;
  setInputs: (inputs: any) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [query, setQuery] = useState<KeyStringVal>({
    question: "",
  });

  const handleOnClose = () => setShow(false);

  const handleManuallyEnterQuestion = () => {
    if (
      !inputs.drafts.some(
        (draft: KeyStringVal) => draft.draft_question_list_id === "1"
      )
    )
      setInputs({
        drafts: [
          ...inputs.drafts,
          {
            draft_question_list_id: "1",
            draft_document_name: "Manually Entered",
            questions: [query.question],
          },
        ],
      });
    else
      setInputs({
        drafts: inputs.drafts.map((draft: KeyStringVal) => {
          if (draft.draft_question_list_id === "1")
            return {
              ...draft,
              questions: [query.question, ...draft.questions],
            };
          else return draft;
        }),
      });
    setQuery({ question: "" });
  };

  return (
    <>
      <button
        disabled={isReadOnly}
        className="black-button"
        onClick={() => {
          setShow(true);
          setQuery({ question: "" });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Ask a Question</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Ask a Question
          </h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={query}
            setInputs={setQuery}
            required
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={query.question === ""}
              className="blue-button"
              onClick={() => {
                handleManuallyEnterQuestion();
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AskAQuestion;
