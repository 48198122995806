/* eslint-disable react-hooks/exhaustive-deps */
import DatePicker from "react-datepicker";
import { forwardRef, LegacyRef, RefObject, useEffect, useState } from "react";
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@heroicons/react/solid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { utcFormat } from "d3-time-format";
import {
  convertUTCToLocalDate,
  convertLocalToUTCDate,
  convertToUTCString,
  convertToMicrosec,
  convertToDate,
  getYear,
  getAllYearsSinceEpoch,
} from "../../utils/general";
import { useGraphStore } from "../../stores/graph";
import React from "react";

const GeneralTemporalDatepicker = ({
  label,
  startKeyName,
  endKeyName,
  inputs,
  setInputs,
  earliest,
  latest,
  required,
}: {
  label?: string;
  startKeyName: string;
  endKeyName: string;
  inputs: any;
  setInputs: any;
  earliest?: number;
  latest?: number;
  required?: boolean;
}) => {
  const { setGraphSearching } = useGraphStore();

  const [startDateSelected, setStartDateSelected] = useState<boolean>(false);
  const [endDateSelected, setEndDateSelected] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);
  const [apply, setApply] = useState<boolean>(false);

  const today = convertLocalToUTCDate(new Date());
  const thisYear = today?.getFullYear();

  const startTime = inputs[startKeyName] || Date.now() * 1000;
  const endTime = inputs[endKeyName] || Date.now() * 1000;
  const years = getAllYearsSinceEpoch();

  useEffect(() => {
    if (startTime > endTime && endTime !== Date.now() * 1000)
      setInputs({
        ...inputs,
        [startKeyName]: endTime,
        [endKeyName]: startTime,
      });
  }, [inputs]);

  useEffect(() => {
    if (earliest && latest) {
      setInputs({
        ...inputs,
        [startKeyName]: earliest,
        [endKeyName]: latest,
      });
    } else
      setInputs({
        ...inputs,
        [startKeyName]: Date.now() * 1000,
        [endKeyName]: Date.now() * 1000,
      });
  }, []);

  return (
    <section className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1">
        <h4>{label || "Time Range"}</h4>
        {required && <span className="text-h5 text-red-500">*</span>}
      </header>
      <article className="relative flex items-center gap-3 px-4 py-2 w-full select-none dark:bg-gray-900 rounded">
        {/* from date */}
        <article className="relative flex items-center w-full">
          <DatePicker
            selected={convertUTCToLocalDate(convertToDate(startTime))}
            onChange={(date: Date) => {
              setGraphSearching(true);
              setSelected(false);
              setApply(false);
              setStartDateSelected(true);
              setInputs({
                ...inputs,
                [startKeyName]: convertToMicrosec(convertLocalToUTCDate(date)),
              });
            }}
            selectsStart
            // startDate={convertUTCToLocalDate(temporalStartDate)}
            // endDate={convertUTCToLocalDate(temporalEndDate)}
            showMonthYearDropdown
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={60}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="From"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            wrapperClassName="datepicker"
            popperClassName="react-datepicker-left"
            // includeDateIntervals={[
            //   {
            //     start: convertUTCToLocalDate(earliestDate) as Date,
            //     end: convertUTCToLocalDate(latestDate) as Date,
            //   },
            // ]}
            customInput={
              <StartInput
                selected={selected}
                apply={apply}
                temporalStartDate={startTime}
                startDateSelected={startDateSelected}
              />
            }
            renderCustomHeader={({
              date,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <article className="flex items-center justify-between px-2 py-2 dark:text-white">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-b2-reg font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <article className="relative group cursor-pointer">
                  <p className="flex items-center gap-1 text-sm">
                    {utcFormat("%b")(date)}{" "}
                    <span className="dark:text-gray-400">{getYear(date)}</span>
                    <ChevronDownIcon className="w-4 h-4" />
                  </p>
                  <article className="absolute top-5 right-0 hidden group-hover:flex flex-col flex-grow gap-2 max-h-[10rem] dark:bg-gray-900 overflow-auto scrollbar rounded-xl">
                    {years.map((year) => (
                      <button
                        key={year}
                        value={year}
                        className="px-2 py-1 dark:hover:bg-gray-700 duration-100"
                        onClick={() => changeYear(year)}
                      >
                        {year}
                      </button>
                    ))}
                  </article>
                </article>

                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-b2-reg font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </article>
            )}
          />
        </article>

        <FontAwesomeIcon
          icon={faArrowRightLong}
          className="w-3 h-3 dark:text-gray-500"
        />

        {/* to date */}
        <article className="relative flex items-center w-full">
          <DatePicker
            selected={convertUTCToLocalDate(convertToDate(endTime))}
            onChange={(date: Date) => {
              setGraphSearching(true);
              setSelected(false);
              setApply(false);
              setEndDateSelected(true);
              setInputs({
                ...inputs,
                [endKeyName]: convertToMicrosec(convertLocalToUTCDate(date)),
              });
            }}
            selectsEnd
            // startDate={convertUTCToLocalDate(temporalStartDate)}
            // endDate={convertUTCToLocalDate(temporalEndDate)}
            showTimeSelect
            timeFormat="h:mm aa"
            timeIntervals={60}
            dateFormat="MMMM d, yyyy h:mm aa"
            placeholderText="To"
            nextMonthButtonLabel=">"
            previousMonthButtonLabel="<"
            wrapperClassName="datepicker"
            popperClassName="react-datepicker-right"
            minDate={today}
            // includeDateIntervals={[
            //   {
            //     start: convertUTCToLocalDate(earliestDate) as Date,
            //     end: convertUTCToLocalDate(latestDate) as Date,
            //   },
            // ]}
            customInput={
              <EndInput
                selected={selected}
                apply={apply}
                temporalEndDate={endTime}
                endDateSelected={endDateSelected}
              />
            }
            renderCustomHeader={({
              date,
              changeYear,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <article className="flex items-center justify-between px-2 py-2 dark:text-white">
                <button
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                  type="button"
                  className={`${
                    prevMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-b2-reg font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronLeftIcon className="w-5 h-5" />
                </button>
                <article className="relative group cursor-pointer">
                  <p className="flex items-center gap-1 text-sm">
                    {utcFormat("%b")(date)}{" "}
                    <span className="dark:text-gray-400">{getYear(date)}</span>
                    <ChevronDownIcon className="w-4 h-4" />
                  </p>
                  <article className="absolute top-5 right-0 hidden group-hover:flex flex-col flex-grow gap-2 max-h-[10rem] dark:bg-gray-900 overflow-auto scrollbar rounded-xl z-10">
                    {years.map((year) => {
                      if (year < thisYear) return null;
                      return (
                        <button
                          key={year}
                          value={year}
                          className="px-2 py-1 dark:hover:bg-gray-700 duration-100"
                          onClick={() => changeYear(year)}
                        >
                          {year}
                        </button>
                      );
                    })}
                  </article>
                </article>
                <button
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                  type="button"
                  className={`${
                    nextMonthButtonDisabled
                      ? "cursor-not-allowed opacity-50"
                      : ""
                  } inline-flex text-b2-reg font-medium shadow-sm dark:hover:bg-checkbox focus:outline-none rounded`}
                >
                  <ChevronRightIcon className="w-5 h-5" />
                </button>
              </article>
            )}
          />
        </article>
      </article>
    </section>
  );
};

export default GeneralTemporalDatepicker;

const StartInput = forwardRef(
  ({ onClick, temporalStartDate, startDateSelected }: any, ref) => (
    <button
      onClick={onClick}
      ref={ref as RefObject<HTMLButtonElement>}
      className={`w-full text-left text-b2-reg ${
        startDateSelected ? "dark:text-white" : "dark:text-gray-500"
      }`}
    >
      {!startDateSelected
        ? "Start Date"
        : convertToUTCString(temporalStartDate)}
    </button>
  )
);

const EndInput = forwardRef(
  (
    { onClick, temporalEndDate, endDateSelected }: any,
    ref: LegacyRef<HTMLButtonElement> | undefined
  ) => (
    <button
      onClick={onClick}
      ref={ref}
      className={`w-full text-left text-b2-reg ${
        endDateSelected ? "dark:text-white" : "dark:text-gray-500"
      }`}
    >
      {!endDateSelected ? "End Date" : convertToUTCString(temporalEndDate)}
    </button>
  )
);
