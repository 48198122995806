import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React from "react";
import { KeyStringVal } from "src/types/general";

const MappedPolicySections = ({ subsection }: { subsection: any }) => {
  return (
    <>
      {subsection.mapped_policy_section_objects?.length > 0 && (
        <Disclosure defaultOpen>
          {({ open }) => (
            <section className="grid gap-4 p-4 dark:bg-gray-800 rounded-lg">
              <Disclosure.Button className="flex items-center gap-2 text-b1-semi">
                <p>
                  {subsection.mapped_policy_section_objects.length} Mapped
                  Policy Section
                  {subsection.mapped_policy_section_objects.length !== 1 && "s"}
                </p>
                <FontAwesomeIcon
                  icon={open ? faChevronDown : faChevronRight}
                  className="w-4 h-4"
                />
              </Disclosure.Button>
              <Disclosure.Panel>
                <section className="grid gap-2">
                  {subsection.mapped_policy_section_objects?.map(
                    (source: KeyStringVal, sourceIndex: number) => {
                      return (
                        <Disclosure key={sourceIndex}>
                          {({ open }) => (
                            <section className="grid gap-2">
                              <header className="flex items-center gap-4">
                                <p className="px-2 py-1 dark:bg-gray-700 rounded">
                                  {source.sub_section_id}{" "}
                                  {source.sub_section_title}
                                </p>
                                <Disclosure.Button className="flex items-center gap-2 text-b2-reg dark:text-blue-500">
                                  <FontAwesomeIcon
                                    icon={open ? faChevronDown : faChevronRight}
                                  />
                                  {open ? "Hide" : "Show"} content
                                </Disclosure.Button>
                              </header>
                              <Disclosure.Panel>
                                <p className="text-b1-reg dark:text-gray-300">
                                  {source.content}
                                </p>
                              </Disclosure.Panel>
                            </section>
                          )}
                        </Disclosure>
                      );
                    }
                  )}
                </section>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
      )}
    </>
  );
};

export default MappedPolicySections;
