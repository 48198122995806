/* eslint-disable no-restricted-globals */
import ReturnPage from "../../../components/Button/ReturnPage";
import { GetAvailableIntegrations } from "../../../services/settings/integrations";
import { AvailableIntegration } from "../../../types/settings";
import { getCustomerID, parseURL } from "../../../utils/general";
import Configured from "./Configured";
import NewConfiguration from "./NewConfiguration";

const IntegrationDetails = () => {
  const parsed = parseURL();
  const customerID = getCustomerID();

  const { data: availableIntegrations } = GetAvailableIntegrations(customerID);

  const integrationName = availableIntegrations?.find(
    (integration: AvailableIntegration) =>
      integration.integration_type === parsed.integration
  )?.description;

  return (
    <section className="grid content-start gap-6 pb-24">
      <header className="flex items-center justify-between gap-4">
        <article className="flex items-center gap-2">
          <ReturnPage />
          <h4 className="flex items-center gap-2 text-b3-reg">
            <span className="dark:text-gray-500">Integrations</span>
            <span>/</span>
            <span>{integrationName}</span>
          </h4>
        </article>
        <NewConfiguration />
      </header>
      <header className="flex items-center gap-2">
        <img
          src={`/general/integrations/${String(
            parsed.integration
          ).toLowerCase()}.svg`}
          alt={String(parsed.integration)}
          className="w-6 h-6"
        />
        <h3 className="text-subt1-semi">{integrationName}</h3>
      </header>
      <Configured />
    </section>
  );
};

export default IntegrationDetails;
