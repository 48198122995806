import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";
import { KeyStringArrayVal } from "../../../types/general";

export const frameworkControlPrefix = "grc/grc-controls/framework-controls";

export const GetFrameworkControls = (
  pageNumber: number,
  filters: KeyStringArrayVal,
  columns?: string[],
  queryList?: string[]
) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringArrayVal | string[] | undefined)[]
  >(
    ["get-framework-controls", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/getall`,
          {
            filter_criteria: filters,
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddToControlBlueprints = () =>
  useMutation<any, unknown, any, string>(
    async ({
      selectedFrameworks,
      signal,
    }: {
      selectedFrameworks: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/generate_control_blueprints`,
          selectedFrameworks,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-framework-controls"]),
    }
  );

export const SearchFrameworkControls = () =>
  useMutation<any, unknown, any, string>(
    async ({
      type,
      query,
      signal,
    }: {
      type: string;
      query: any;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/search_${type}`,
          { [type]: query },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-framework-controls"]),
    }
  );

export const GetFrameworkControlMetadata = (frameworkControlID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-framework-control-metadata", frameworkControlID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/getbyid`,
          { framework_control_id: frameworkControlID },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: frameworkControlID !== "",
      keepPreviousData: false,
    }
  );
