import React from "react";
import ReturnPage from "src/components/Button/ReturnPage";
import TableLayout from "src/layouts/TableLayout";
import { GetRiskQuestionnaireMetadata } from "src/services/erc/risks/assessments/questionnaire";
import { GetAllUsers } from "src/services/settings/users";
import { getCustomerID, getEmailFromUserID } from "src/utils/general";

const RiskQuestionnaireMetadata = ({
  riskQuestionnaireID,
}: {
  riskQuestionnaireID: string;
}) => {
  const customerID = getCustomerID();

  const { data: metadata } = GetRiskQuestionnaireMetadata(riskQuestionnaireID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Risk Questionnaire</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid content-start gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{metadata.name}</h2>
            </header>
            <section className="grid grid-cols-5 gap-x-20 gap-y-10">
              {metadata.owner && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Owner</h4>
                  <span className="text-b1-semi">
                    {getEmailFromUserID(allUsers, metadata.owner)}
                  </span>
                </article>
              )}
            </section>
          </section>
          {metadata.category.length > 0 && (
            <ul className="grid content-start gap-4">
              {metadata.category.map((category: any) => {
                return (
                  <li
                    key={category.category_id}
                    className="flex flex-col flex-grow gap-3 p-6 dark:bg-gray-800 rounded-xl"
                  >
                    <header className="flex items-center gap-4">
                      <h4 className="text-subt1-semi">{category.name}</h4>
                      <span className="text-b1-reg">
                        Weight {category.weight}
                      </span>
                    </header>
                    {category.description && (
                      <p className="text-b1-reg">{category.description}</p>
                    )}
                    <TableLayout fullHeight>
                      <thead className="sticky -top-1.5 dark:bg-gray-900">
                        <tr>
                          {Object.keys(category.question[0].question).map(
                            (col: string) => {
                              if (col.includes("id")) return null;
                              return (
                                <th
                                  scope="col"
                                  key={col}
                                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                                >
                                  <article className="capitalize flex gap-10 justify-between">
                                    <h4 className="break-words">
                                      {col.replaceAll("_", " ")}
                                    </h4>
                                  </article>
                                </th>
                              );
                            }
                          )}
                        </tr>
                      </thead>
                      <tbody className="divide-y dark:divide-gray-700">
                        {category.question.map((row: any, index: number) => {
                          return (
                            <tr
                              key={index}
                              data-test="table-row"
                              className="relative p-5 gap-3 break-words dark:bg-gray-800"
                            >
                              {Object.keys(category.question[0].question)?.map(
                                (col: string, colIndex: number) => {
                                  if (col.includes("id")) return null;
                                  return (
                                    <td
                                      key={`${index}-${colIndex}`}
                                      className="relative px-6 py-3 last:pr-16 text-left break-words"
                                    >
                                      <p>
                                        {String(row.question[col]).replaceAll(
                                          "_",
                                          " "
                                        )}
                                      </p>
                                    </td>
                                  );
                                }
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </TableLayout>
                  </li>
                );
              })}
            </ul>
          )}
        </header>
      )}
    </>
  );
};

export default RiskQuestionnaireMetadata;
