import React, { useState } from "react";
import HorizontalStackedBarChart from "src/components/Chart/HorizontalStackedBarChart";
import { GetVendorByType } from "src/services/third-party-risk/vendors/vendor-dashboard";

const VendorByType = () => {
  const [sectionProps, setSectionProps] = useState({});

  const { data } = GetVendorByType();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <HorizontalStackedBarChart
        title="Vendor by Type"
        data={data}
        xKey="type"
        hideLegend
        sectionProps={sectionProps}
        setSectionProps={setSectionProps}
      />
    </section>
  );
};

export default VendorByType;
