import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React, { useState } from "react";
import ViewInFile from "src/pages/RegulationPolicy/Document/ViewInFile/ViewInFile";

const Source = ({
  sourceIndex,
  source,
  hideFile,
}: {
  sourceIndex: number;
  source: any;
  hideFile?: boolean;
}) => {
  const [opened, setOpened] = useState<boolean>(false);

  const content = source.content || source.response;

  return (
    <article
      key={sourceIndex}
      className="grid gap-4 p-3 w-full cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/60 duration-100 rounded"
    >
      {source.document_title && <h4>{source.document_title}</h4>}
      {source.name && <h4>{source.name}</h4>}
      <header className="flex items-start justify-between gap-10">
        {source.sub_section_title && (
          <span className="flex items-start gap-2 w-3/5">
            {source.sub_section_id} {source.sub_section_title}
          </span>
        )}
        {source.ip_score && (
          <span className="w-max">{source.ip_score}% similarity</span>
        )}
      </header>
      <section
        className={`grid ${
          !hideFile && source.page_metadata?.length > 0 ? "md:grid-cols-2" : ""
        } gap-10`}
      >
        <Disclosure>
          {({ open }) => {
            return (
              <section className="text-sm">
                <article
                  className="flex items-center gap-2 w-max"
                  onClick={() => setOpened(!opened)}
                >
                  <Disclosure.Button className="flex items-center gap-2">
                    {source.question && (
                      <h4 className="text-left">Q: {source.question}</h4>
                    )}
                    {opened ? "Hide" : "Show"} content
                    <FontAwesomeIcon
                      icon={opened ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                </article>
                <Transition
                  show={opened}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="flex gap-2 p-3 break-words dark:bg-gray-900 rounded-lg">
                    <p className="grid gap-2">
                      {content
                        ?.split("\n")
                        .map((phrase: string, index: number) => (
                          <span key={index}>{phrase}</span>
                        ))}
                      {source.page_metadata && (
                        <span className="pt-2 w-max text-b2-reg border-t dark:border-yellow-500">
                          Page {source.page_metadata[0]?.position?.pageNumber}
                        </span>
                      )}
                    </p>
                  </Disclosure.Panel>
                </Transition>
              </section>
            );
          }}
        </Disclosure>
        {!hideFile && source.page_metadata?.length > 0 && (
          <ViewInFile
            generatedID={source.generated_id}
            section={source}
            bbox={source.page_metadata}
            opened={opened}
            setOpened={setOpened}
            isNotModal
          />
        )}
      </section>
    </article>
  );
};

export default Source;
