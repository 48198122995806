import { useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion } from "src/constants/general";

const prefix = "grc/tprm/third-parties/analytics";

export const GetVendorSummary = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendor-summary"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/status-summary`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetActiveCount = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-active-count"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/active-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetNewCount = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-new-count"],
    async ({ signal }) => {
      try {
        const res = await client.get(`/api/${apiVersion}/${prefix}/new-count`, {
          signal,
        });
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetInAssessmentCount = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-in-assessment-count"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/in-assessment-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetAtRiskCount = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-at-risk-count"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/at-risk-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetMoreThan3YearsCount = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-more-than-3-years-count"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/more-than-3-years-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetInherentRiskDistribution = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-inherent-risk-distribution"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/inherent-risk-distribution`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorRisksByState = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendor-risks-by-state"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/count-by-risk-state`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetAssessmentResultByCategory = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-assessment-result-by-category"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/assessment-results-summary`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorByType = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendor-by-type"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/third-party-by-type`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorByTier = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendor-by-tier"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/third-party-by-tier`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetActiveVendorsOvertime = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-active-vendors-overtime"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/active-by-month`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetOverallRiskScore = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-overall-risk-score"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/aggregate-risk-score`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );
