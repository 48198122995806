/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  CreateCustomerEnv,
  GetCustomerEnvs,
} from "../../../services/settings/environments";
import { useState } from "react";
import { CustomerEnv } from "../../../types/settings";
import { getCustomerID } from "../../../utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";

const CreateEnvironment = () => {
  const customerID = getCustomerID();

  const [inputs, setInputs] = useState<any>({ name: "" });
  const [valid, setValid] = useState<boolean>(true);
  const [show, setShow] = useState<boolean>(false);

  const handleOnClose = () => setShow(false);

  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const createCustomerEnv = CreateCustomerEnv(customerID);

  const allEnvTypes = [
    ...new Set(
      customerEnvs?.reduce(
        (pV: string[], cV: CustomerEnv) => [...pV, cV.env_type],
        []
      )
    ),
  ] as string[];

  return (
    <>
      <button
        className="blue-button"
        onClick={() => {
          setShow(true);
          setValid(true);
          setInputs({
            name: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Environment
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Environment
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            valid={valid}
            setValid={setValid}
            required
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                if (
                  allEnvTypes?.some(
                    (type: string) =>
                      type.toLowerCase().trim() ===
                      inputs.name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  setValid(true);
                  createCustomerEnv.mutate({
                    envType: {
                      env_type: inputs.name,
                    },
                  });
                }
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default CreateEnvironment;
