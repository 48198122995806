/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import {
  AddRisk,
  SearchCategory,
  SearchSubCategory,
} from "../../../../services/erc/risks/risks";
import ToastLayout from "../../../../layouts/ToastLayout";
import MultiUserInput from "src/components/Input/MultiUserInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import RiskBlueprintFilter from "src/components/Filter/ERC/RiskBlueprintFilter";
import { Disclosure } from "@headlessui/react";
import ThreatsFilter from "src/components/Filter/ERC/ThreatsFilter";
import VulnerabilitiesFilter from "src/components/Filter/ERC/VulnerabilitiesFilter";
import RefIDFilter from "src/components/Filter/ERC/RefIDFilter";
import EntityFilter from "src/components/Filter/ERC/EntityFilter";
import MultiControlFilter from "src/components/Filter/ERC/MultiControlFilter";
import { checkIsReadOnly } from "src/utils/general";

const NewRisk = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const addRisk = AddRisk();
  const categories = SearchCategory();
  const subCategories = SearchSubCategory();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRisk.reset();

  useEffect(() => {
    categories.mutate({
      inputs: {
        category: "",
      },
    });
  }, []);

  useEffect(() => {
    if (inputs.category)
      subCategories.mutate({
        inputs: {
          category: inputs.category,
          sub_category: "",
        },
      });
  }, [inputs.category]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            threats: [],
            vulnerabilities: [],
            category: "",
            sub_category: "",
            type: "",
            classification: "",
            owner_same_as_entity_owner: true,
            owners: [],
            owners_text: [],
            reviewers: [],
            reviewers_text: [],
            approvers: [],
            approvers_text: [],
            treatment: "",
            treatment_plan: "",
            treatment_notes: "",
            deadline: Date.now() * 1000,
            inherent_risk_score: 0,
            inherent_risk_impact_level: "MODERATE",
            inherent_risk_likelihood_level: "POSSIBLE",
            assessment: "",
            additional_information: {},
            treatment_type: "MITIGATE",
            treatment_status: "",
            status: "DRAFT",
            risk_identification_source: "",
            control_type: "",
            risk_acceptance_via_email: "",
            cost_benefit_analysis: "",
            url: "",
            compensating_controls: "",
            compliance_contact: "",
            ref_ids: [],
            grc_entity_id: null,
            controls: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Risk</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Risk</h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="grc_entity_id"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiControlFilter
              label="Controls"
              keyName="controls"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RiskBlueprintFilter
              label="Risk Blueprint"
              keyName="risk_blueprint_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Category"
              keyName="category"
              list={categories.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Subcategory"
              keyName="sub_category"
              list={subCategories.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <ThreatsFilter
              label="Threats"
              inputs={inputs}
              setInputs={setInputs}
            />
            <VulnerabilitiesFilter
              label="Vulnerabilities"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Risk Identification Source"
              keyName="risk_identification_source"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Compliance Contact"
              keyName="compliance_contact"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RefIDFilter inputs={inputs} setInputs={setInputs} />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">Risk Score</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CategoryInput
                      label="Inherent Risk Impact Level"
                      keyName="inherent_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Inherent Risk Likelihood Level"
                      keyName="inherent_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Residual Risk Impact Level"
                      keyName="residual_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Residual Risk Likelihood Level"
                      keyName="residual_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">Risk Treatment</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <RegularInput
                      label="Treatment"
                      keyName="treatment"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <CategoryInput
                      label="Treatment Type"
                      keyName="treatment_type"
                      list={["ACCEPT", "AVOID", "MITIGATE", "TRANSFER"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Treatment Status"
                      keyName="treatment_status"
                      list={["Open", "Closed", "Resolved", "Pending"]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <GeneralSnapshotDatepicker
                      label="Deadline"
                      keyName="deadline"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <CategoryInput
                      label="Control Type"
                      keyName="control_type"
                      list={[
                        "CORRECTIVE",
                        "DETECTIVE",
                        "PREVENTIVE",
                        "DETERRENT",
                        "RECOVERY",
                        "COMPENSATING",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <RegularInput
                      label="Risk Acceptance via Email"
                      keyName="risk_acceptance_via_email"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="Cost / Benefit Analysis"
                      keyName="cost_benefit_analysis"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="Treatment Plan"
                      keyName="treatment_plan"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <RegularInput
                      label="Treatment Notes"
                      keyName="treatment_notes"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="URL"
                      keyName="url"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    <RegularInput
                      label="Compensating Controls"
                      keyName="compensating_controls"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <MultiUserInput
                      label="Owners"
                      keyName="owners"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addRisk.mutate({
                  inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRisk.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New risk has been created"
      />
    </>
  );
};

export default NewRisk;
