import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetRiskClassifications } from "src/services/erc/assessments/assessments";

const RiskClassificationFilter = ({
  assessmentID,
  entityID,
  filter,
  setFilter,
}: {
  assessmentID: string;
  entityID: string;
  filter: any;
  setFilter: any;
}) => {
  const { data: classifications } = GetRiskClassifications(
    assessmentID,
    entityID
  );

  return (
    <FilterLayout label="Risk Classification">
      <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
        {classifications?.map((value: string) => {
          return (
            <li key={filter} className="flex items-start gap-2">
              <input
                type="checkbox"
                className="transparent-checkbox"
                checked={filter.classification === value}
                onChange={() => {
                  if (filter.classification)
                    setFilter({ ...filter, classification: "" });
                  else setFilter({ ...filter, classification: value });
                }}
              />
              <label htmlFor="">{value}</label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default RiskClassificationFilter;
