/* eslint-disable react-hooks/exhaustive-deps */
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";

const ColumnTypeFilter = ({
  sheet,
  markedAsQuestionCol,
  setMarkedAsQuestionCol,
  markedAsAnswerCol,
  setMarkedAsAnswerCol,
  colIndex,
  ignoreResponse,
  showGuidelines,
}: {
  sheet: any;
  markedAsQuestionCol: any;
  setMarkedAsQuestionCol: any;
  markedAsAnswerCol: any;
  setMarkedAsAnswerCol: any;
  colIndex: number;
  ignoreResponse?: boolean;
  showGuidelines?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const list = ignoreResponse
    ? ["Questions"]
    : showGuidelines
    ? ["Questions", "Guidelines"]
    : ["Questions", "Answers"];

  const markedAsQuestion = markedAsQuestionCol[sheet] === colIndex;
  const markedAsAnswer = markedAsAnswerCol[sheet] === colIndex;

  return (
    <section
      className="grid gap-3 text-sm text-left"
      onMouseLeave={() => setShowDropdown(false)}
    >
      <article
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/60 duration-100 focus:outline-none rounded-t-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <span>
            {markedAsQuestion
              ? list[0]
              : markedAsAnswer
              ? list[1]
              : "Please Select"}
          </span>
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-7 right-0 grid content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none overflow-auto scrollbar rounded-b-sm z-10">
            {list.map((item: string) => {
              return (
                <button
                  key={item}
                  className="relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 rounded-lg"
                  onClick={() => {
                    if (item === "Questions") {
                      setMarkedAsQuestionCol({
                        ...markedAsQuestionCol,
                        [sheet]: colIndex,
                      });
                      if (markedAsAnswerCol[sheet] === colIndex)
                        setMarkedAsAnswerCol({
                          ...markedAsAnswerCol,
                          [sheet]: -1,
                        });
                    } else {
                      setMarkedAsAnswerCol({
                        ...markedAsAnswerCol,
                        [sheet]: colIndex,
                      });
                      if (markedAsQuestionCol[sheet] === colIndex)
                        setMarkedAsQuestionCol({
                          ...markedAsQuestionCol,
                          [sheet]: -1,
                        });
                    }
                  }}
                >
                  {item}
                </button>
              );
            })}
          </article>
        )}
      </article>
    </section>
  );
};

export default ColumnTypeFilter;
