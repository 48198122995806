/* eslint-disable react-hooks/exhaustive-deps */
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  StrikeThroughSupSubToggles,
  BlockTypeSelect,
  CodeToggle,
  CreateLink,
  InsertCodeBlock,
  InsertFrontmatter,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  tablePlugin,
  imagePlugin,
  frontmatterPlugin,
  diffSourcePlugin,
  linkDialogPlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
} from "@mdxeditor/editor";
import React, { useState } from "react";
import { LineWave } from "react-loader-spinner";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import {
  EditRisk,
  GenerateTreatmentPlan,
  GetRiskMetadata,
  GetTreatmentPlanStatus,
} from "src/services/erc/risks/risks";
import { checkIsReadOnly } from "src/utils/general";

const TreatmentPlan = ({ riskID }: { riskID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [editedContent, setEditedContent] = useState<string>("");

  const { data: metadata } = GetRiskMetadata(riskID);
  const generateTreatmentPlan = GenerateTreatmentPlan(riskID);
  const { data: treatmentPlanStatus } = GetTreatmentPlanStatus(riskID);
  const editRisk = EditRisk(riskID);

  const content = metadata?.generated_treatment_plan;

  return (
    <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      <header className="flex items-center gap-4">
        <h4 className="text-t1-semi">Treatment Plan</h4>
        {treatmentPlanStatus?.status === "PROCESSING" ? (
          <span className="flex items-center gap-1">
            Generating
            <LineWave
              visible={true}
              height="30"
              width="30"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          </span>
        ) : treatmentPlanStatus?.status === "FAILED" ? (
          <span>
            <FontAwesomeIcon
              icon={faTriangleExclamation}
              className="text-red-500"
            />{" "}
            Generation failed
          </span>
        ) : (
          !metadata?.is_treatment_plan_generated && (
            <button
              disabled={
                isReadOnly || generateTreatmentPlan.status === "loading"
              }
              className="blue-button"
              onClick={() => generateTreatmentPlan.mutate({})}
            >
              Generate Treatment Plan
            </button>
          )
        )}
      </header>
      {editedContent ? (
        <>
          <article className="flex items-center place-content-end gap-4">
            <button
              disabled={isReadOnly}
              className="red-button"
              onClick={() => setEditedContent("")}
            >
              Discard
            </button>
            {content !== editedContent && (
              <button
                disabled={isReadOnly}
                className="blue-button"
                onClick={() => {
                  editRisk.mutate({
                    inputs: {
                      treatment_plan: editedContent,
                    },
                  });
                  setEditedContent("");
                }}
              >
                Save
              </button>
            )}
          </article>

          <MDXEditor
            className="min-w-full prose dark:bg-white rounded-xl"
            markdown={content}
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <StrikeThroughSupSubToggles />
                    <BlockTypeSelect />
                    <CodeToggle />
                    <CreateLink />
                    <InsertCodeBlock />
                    <InsertFrontmatter />
                    <InsertImage />
                    <InsertTable />
                    <InsertThematicBreak />
                    <ListsToggle />
                  </>
                ),
              }),
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              markdownShortcutPlugin(),
              tablePlugin(),
              imagePlugin(),
              frontmatterPlugin(),
              diffSourcePlugin({
                diffMarkdown: content,
                viewMode: "rich-text",
                readOnlyDiff: true,
              }),
              linkDialogPlugin(),
              codeBlockPlugin(),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  py: "Python",
                  "": "Text",
                },
              }),
            ]}
            onChange={(content) => setEditedContent(content)}
          />
        </>
      ) : (
        content && (
          <>
            {!editedContent && (
              <button
                disabled={isReadOnly}
                className="sticky top-0 place-self-end blue-button"
                onClick={() => setEditedContent(content)}
              >
                Edit
              </button>
            )}

            <ReactMarkdown className="markdown" remarkPlugins={[remarkGfm]}>
              {content}
            </ReactMarkdown>
          </>
        )
      )}
    </section>
  );
};

export default TreatmentPlan;
