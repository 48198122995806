/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import PageLayout from "src/layouts/PageLayout";
import DocumentMetadata from "./DocumentMetadata/DocumentMetadata";
import DocumentDetail from "./DocumentDetail/DocumentDetail";
import { parseURL } from "src/utils/general";

const Document = () => {
  const parsed = parseURL();

  const [selectedPolicyVersion, setSelectedPolicyVersion] =
    useState<string>("");
  const [editSections, setEditSections] = useState<any>({});
  const [search, setSearch] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const documentType = String(parsed.document_type) || "";
  const documentID = String(parsed.document_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <DocumentMetadata
          documentType={documentType}
          documentID={documentID}
          selectedPolicyVersion={selectedPolicyVersion}
          setSelectedPolicyVersion={setSelectedPolicyVersion}
          search={search}
          setSearch={setSearch}
          query={query}
          setQuery={setQuery}
        />
        <DocumentDetail
          documentType={documentType}
          documentID={documentID}
          editSections={editSections}
          setEditSections={setEditSections}
          search={search}
          setSearch={setSearch}
          query={query}
        />
      </main>
    </PageLayout>
  );
};

export default Document;
