import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { AddCustomQuestionSet } from "src/services/third-party-risk/questions/custom-question-sets";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import TableLayout from "../../../../../layouts/TableLayout";
import { KeyStringVal } from "src/types/general";
import TempResponse from "./TempResponse";
import ToastLayout from "src/layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";
import { checkIsSuperAdmin } from "src/utils/general";

const CreateManualSet = ({
  setShow,
  inputs,
  setInputs,
  selectedQuestions,
  setSelectedQuestions,
  headers,
  filteredHeaders,
}: {
  setShow: (show: boolean) => void;
  inputs: any;
  setInputs: (inputs: any) => void;
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
  headers: any;
  filteredHeaders: any;
}) => {
  const isSuperAdmin = checkIsSuperAdmin();

  const [activeItem, setActiveItem] = useState<any>({});

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const createCustomQuestionSet = AddCustomQuestionSet();

  const handleOnClose = () => {
    setSelectedQuestions([]);
    setShow(false);
  };

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = selectedQuestions.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = selectedQuestions.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setSelectedQuestions(arrayMove(selectedQuestions, oldIdx, newIdx));
    }
  };
  const handleOnCloseConfirmation = () => createCustomQuestionSet.reset();

  return (
    <>
      <section className="grid content-start gap-4 p-4 h-full dark:bg-gray-700 rounded-xl">
        <header className="flex items-start justify-between gap-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Create Question Set
          </h3>
          {isSuperAdmin && (
            <CheckboxInput
              label="Save as Global"
              keyName="save_global"
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
        </header>
        <RegularInput
          label="Name"
          keyName="name"
          inputs={inputs}
          setInputs={setInputs}
          required
        />
        <RegularInput
          label="Description"
          keyName="description"
          inputs={inputs}
          setInputs={setInputs}
          textarea
        />
        <DndContext
          sensors={sensors}
          onDragStart={(e) =>
            setActiveItem(
              selectedQuestions.find(
                (item: KeyStringVal) => item.generated_id === e.active.id
              )
            )
          }
          onDragEnd={reorderQuestionsList}
        >
          <TableLayout>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column || col === "tags") return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              <SortableContext items={selectedQuestions}>
                {selectedQuestions?.map((qa: any) => {
                  return (
                    <TempResponse
                      key={qa.generated_id}
                      qa={qa}
                      selectedQuestions={selectedQuestions}
                      setSelectedQuestions={setSelectedQuestions}
                      filteredHeaders={filteredHeaders}
                    />
                  );
                })}
              </SortableContext>
            </tbody>
          </TableLayout>

          <DragOverlay
            dropAnimation={{
              duration: 150,
              easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
            }}
          >
            {activeItem ? (
              <TempResponse
                qa={activeItem}
                selectedQuestions={selectedQuestions}
                setSelectedQuestions={setSelectedQuestions}
                filteredHeaders={filteredHeaders}
              />
            ) : null}
          </DragOverlay>
        </DndContext>
        <article className="flex items-center place-content-end gap-4">
          <button className="black-button" onClick={handleOnClose}>
            Cancel
          </button>
          <button
            disabled={inputs.name === ""}
            className="blue-button"
            onClick={() => {
              let questionIDs = {};
              selectedQuestions.forEach((curQ, idx) => {
                questionIDs = {
                  ...questionIDs,
                  [curQ.generated_id]: {
                    order_key: idx,
                    ...curQ,
                  },
                };
              });
              createCustomQuestionSet.mutate({
                inputs: {
                  ...inputs,
                  question_ids: questionIDs,
                },
              });
              handleOnClose();
            }}
          >
            <h4>Done</h4>
          </button>
        </article>
      </section>
      <ToastLayout
        showModal={createCustomQuestionSet.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New question set has been created"
      />
    </>
  );
};

export default CreateManualSet;
