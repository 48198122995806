/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const CoverageFilter = ({
  filter,
  setFilter,
}: {
  filter: string;
  setFilter: (filter: string) => void;
}) => {
  return (
    <FilterLayout label="Quick Filter">
      <ul className="flex flex-col flex-grow gap-1">
        {["Flagged Controls", "Overlaps with Audit"].map((option) => {
          return (
            <li key={option} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={filter === option}
                onClick={() => setFilter(option)}
              />
              <label htmlFor="" className="w-max">
                {option === "Flagged Controls"
                  ? "Flagged/Key Controls"
                  : option}
              </label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default CoverageFilter;
