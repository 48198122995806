import { ChevronDownIcon } from "@heroicons/react/solid";
import { useState } from "react";
import { convertToUTCString } from "src/utils/general";

const DropdownLayout: React.FC<{
  label?: string;
  value?: string | number;
  header?: boolean;
  color?: string;
  width?: string;
  placeholder?: string;
  timestamp?: boolean;
  showAbove?: boolean;
  selectedTextSize?: string;
  labelAbove?: boolean;
  required?: boolean;
  isReadOnly?: boolean;
  disabled?: boolean;
}> = ({
  label = "",
  value = "",
  header,
  color,
  width,
  placeholder,
  timestamp,
  showAbove,
  selectedTextSize,
  labelAbove,
  required,
  isReadOnly,
  disabled,
  children,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  return (
    <section
      className={`${
        labelAbove ? "grid content-start" : "flex items-center gap-2"
      } ${header ? "-mt-3" : ""} text-b2-reg`}
    >
      <header className="flex items-center gap-1 capitalize">
        <h4>{label}</h4>
        {required && <span className="text-b1-reg text-red-500">*</span>}
      </header>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className={`relative grid py-2 px-4 ${
          width || "w-full"
        } text-left cursor-pointer ${
          isReadOnly || disabled
            ? "dark:text-gray-600 dark:bg-gray-800"
            : color
            ? color
            : "dark:bg-gray-900 dark:hover:bg-gray-900/60 duration-100"
        } focus:outline-none rounded`}
      >
        <article className="flex items-center justify-between gap-2 truncate">
          <span className={`${selectedTextSize || ""}`}>
            {value === ""
              ? placeholder
                ? placeholder
                : "Select"
              : timestamp
              ? convertToUTCString(Number(value))
              : value}{" "}
          </span>
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {!isReadOnly && !disabled && showDropdown && (
          <article
            className={`absolute ${
              showAbove
                ? "bottom-8"
                : selectedTextSize === "text-lg"
                ? "top-11"
                : "top-8"
            } left-0 grid content-start gap-1 w-full max-h-36 ${
              color ? color : "dark:bg-gray-900"
            } focus:outline-none black-shadow overflow-auto scrollbar rounded-b z-10`}
          >
            {children}
          </article>
        )}
      </article>
    </section>
  );
};

export default DropdownLayout;
