/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { KeyStringVal } from "src/types/general";
import { SearchThreat } from "src/services/erc/risks/risks";

const ThreatsFilter = ({
  label,
  inputs,
  setInputs,
}: {
  label?: string;
  inputs: any;
  setInputs: (inputs: any) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const search = SearchThreat();

  const handleSelect = (threatID: string) => {
    if (inputs.threats.includes(threatID))
      setInputs({
        ...inputs,
        threats: inputs.threats.filter(
          (curThreatID: string) => curThreatID !== threatID
        ),
      });
    else setInputs({ ...inputs, threats: [...inputs.threats, threatID] });
  };

  useEffect(() => {
    search.mutate({
      threatName: "",
    });
  }, []);

  return (
    <section className="grid content-start text-b2-reg text-left">
      {label && <h4>{label}</h4>}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-1 w-full cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-900/60 duration-100 focus:outline-none rounded-sm"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder={`${
              inputs.threats.length > 0
                ? `${inputs.threats.length} selected`
                : "Select"
            }`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              search.mutate({
                threatName: e.target.value,
              });
            }}
            className="w-full border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-8 right-0 grid content-start gap-1 w-full max-h-36 dark:bg-gray-900 focus:outline-none overflow-auto scrollbar rounded-b-sm z-10">
            {search.data ? (
              search.data.length > 0 ? (
                <article className="flex flex-col flex-grow gap-1">
                  <article className="flex items-center gap-4 px-4 py-1 divide-x dark:divide-checkbox">
                    <button
                      className="dark:disabled:text-gray-400 dark:hover:text-checkbox/60 duration-100"
                      onClick={() =>
                        search.mutate(
                          {
                            threatName: "",
                          },
                          {
                            onSuccess: (data) => {
                              const threatIDs = data.reduce(
                                (pV: string[], cV: KeyStringVal) => [
                                  ...pV,
                                  cV.threat_id,
                                ],
                                []
                              );
                              setInputs({
                                ...inputs,
                                threats: threatIDs,
                              });
                            },
                          }
                        )
                      }
                    >
                      Select All
                    </button>
                    <button
                      disabled={inputs.threats.length === 0}
                      className="pl-5 dark:disabled:text-gray-400 dark:hover:text-checkbox/60 duration-100"
                      onClick={() => setInputs({ ...inputs, threats: [] })}
                    >
                      Deselect All
                    </button>
                  </article>
                  {search.data?.map((item: KeyStringVal) => {
                    const selected = inputs.threats.includes(item.threat_id);

                    return (
                      <button
                        key={item.threat_id}
                        className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                          selected ? "dark:bg-gray-800" : ""
                        } rounded-lg`}
                        onClick={() => handleSelect(item.threat_id)}
                      >
                        {selected && <FontAwesomeIcon icon={faCheck} />}
                        {item.name}
                      </button>
                    );
                  })}
                </article>
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
          </article>
        )}
      </article>
    </section>
  );
};

export default ThreatsFilter;
