/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetAuditTrailUsers } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";
import { getCustomerID } from "src/utils/general";

const AuditTrailUsersFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  refetch,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: any;
  refetch: any;
}) => {
  const customerID = getCustomerID();

  const { data: auditTrailUsers } = GetAuditTrailUsers(customerID);

  const handleSelect = (user: any) => {
    if (user === "All" && auditTrailUsers?.length > 0) {
      if (inputs[keyName].length < auditTrailUsers.length) {
        const userIDs = auditTrailUsers?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
          []
        );
        setInputs({ ...inputs, [keyName]: userIDs });
      } else setInputs({ ...inputs, [keyName]: [] });
    } else if (inputs[keyName].includes(user.user_id))
      setInputs({
        ...inputs,
        [keyName]: inputs[keyName].filter(
          (userID: string) => userID !== user.user_id
        ),
      });
    else
      setInputs({ ...inputs, [keyName]: [...inputs[keyName], user.user_id] });
  };

  return (
    <FilterLayout label={label} showOnLeft>
      <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
        <li className="flex items-start gap-2">
          <input
            type="checkbox"
            className="transparent-checkbox"
            checked={inputs[keyName].length === auditTrailUsers?.length}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="">All</label>
        </li>
        {auditTrailUsers?.map((option: KeyStringVal) => {
          return (
            <li key={option.user_id} className="flex items-start gap-2 px-4">
              <input
                type="checkbox"
                className="transparent-checkbox"
                checked={inputs[keyName].includes(option.user_id)}
                onClick={() => handleSelect(option)}
              />
              <label htmlFor="" className="w-max">
                {option.user_email}
              </label>
            </li>
          );
        })}
      </ul>
      <article className="w-full border-t dark:border-gray-600">
        <article className="sticky bottom-0 flex items-center gap-2 pt-2 place-self-end">
          <button
            className="red-text-button"
            onClick={() =>
              setInputs({
                ...inputs,
                [keyName]: [],
              })
            }
          >
            Clear
          </button>
          <button className="small-blue-button" onClick={refetch}>
            Apply
          </button>
        </article>
      </article>
    </FilterLayout>
  );
};

export default AuditTrailUsersFilter;
