import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import EntityTypeMetadata from "./EntityTypeMetadata";
import EntityTypeDetail from "./EntityTypeDetail";
import AssociateBlueprintsFilter from "../../../../../components/Filter/ERC/AssociateBlueprintsFilter";

const EntityType = () => {
  const parsed = parseURL();

  const entityTypeID = String(parsed.entity_type_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "entities";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <EntityTypeMetadata entityTypeID={entityTypeID} />
        <AssociateBlueprintsFilter entityTypeID={entityTypeID} />
        <EntityTypeDetail />
      </main>
    </PageLayout>
  );
};

export default EntityType;
