import React, { useState } from "react";
import FileInput from "src/components/Input/FileInput";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import ToastLayout from "src/layouts/ToastLayout";
import { AddControlEvidence } from "src/services/audit-management";
import { checkIsReadOnly } from "src/utils/general";

const AttachEvidence = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const attachEvidence = AddControlEvidence(auditID, controlID);

  const handleOnClose = () => setShow(false);

  const handleOnCloseConfirmation = () => attachEvidence.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="small-blue-button"
        onClick={() => {
          setShow(true);
          setInputs({});
        }}
      >
        <h4>Add Evidence</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Evidence</h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <FileInput
            label="Evidence"
            keyName="file"
            types={[
              "png",
              "svg",
              "pdf",
              "docx",
              "csv",
              "txt",
              "xlsx",
              "jpg",
              "jpeg",
            ]}
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={!inputs.name || !inputs.file}
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                formData.append("name", inputs.name);
                formData.append("file", inputs.file);

                attachEvidence.mutate({
                  formData,
                });
                handleOnClose();
              }}
            >
              Upload
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={attachEvidence.status === "success"}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New evidence has been added"
      />
    </>
  );
};

export default AttachEvidence;
