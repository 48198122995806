/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { GetBIAMetadata } from "src/services/business-continuity/bia";
import ReturnPage from "src/components/Button/ReturnPage";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { convertToUTCString } from "src/utils/general";
import UpdateBIA from "./UpdateBIA";
import MetadataField from "src/components/GRC/MetadataField";

const BIAMetadata = ({ biaID }: { biaID: string }) => {
  const { data: metadata } = GetBIAMetadata(biaID);

  return (
    <>
      <header className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Business Continuity</span>
          <span>/</span>
          <span>Business Impact Analysis</span>
        </h4>
      </header>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-10">
              <article className="flex flex-col flex-grow gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by {metadata.last_updated_by?.user_name} (
                    {metadata.last_updated_by?.user_email})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by {metadata.created_by?.user_name} (
                    {metadata.created_by?.user_email})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateBIA biaID={biaID} bia={metadata} />
                {/* <ArchiveRestoreBlueprint
                  biaID={biaID}
                  bia={metadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.state?.toLowerCase()]
                }`}
              >
                State
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.state?.toLowerCase()]
                  }`}
                >
                  {metadata.state}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField
                metadata={metadata}
                label="Department"
                keyName="department"
              />
              <MetadataField
                metadata={metadata}
                label="Impact of Downtime"
                keyName="impact_of_downtime"
              />
              <MetadataField
                metadata={metadata}
                label="Last Review Date"
                keyName="last_review_date"
                isDate
              />
              <MetadataField
                metadata={metadata}
                label="Next Review"
                keyName="next_review"
              />
              <MetadataField
                metadata={metadata}
                label="Author"
                keyName="author"
                singleUser
              />
              <MetadataField
                metadata={metadata}
                label="Owners"
                keyName="owners"
                multipleUsers
              />
              <MetadataField
                metadata={metadata}
                label="Reviewers"
                keyName="reviewers"
                multipleUsers
              />
              <MetadataField
                metadata={metadata}
                label="Approvers"
                keyName="approvers"
                multipleUsers
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="BCM Lead"
                keyName="bcm_lead"
                singleUser
              />
              <MetadataField
                metadata={metadata}
                label="Criticality"
                keyName="criticality"
              />
              <MetadataField
                metadata={metadata}
                label="Peak Operating Periods"
                keyName="peak_operating_periods"
                isList
              />
              <MetadataField
                metadata={metadata}
                label="Location"
                keyName="location"
              />
              <MetadataField
                metadata={metadata}
                label="RTO"
                keyName="rto_in_seconds"
              />
              <MetadataField
                metadata={metadata}
                label="RPO"
                keyName="rpo_in_seconds"
              />
              <MetadataField
                metadata={metadata}
                label="MTPD"
                keyName="mtpd_in_seconds"
              />
              <MetadataField
                metadata={metadata}
                label="Downtime Cost"
                keyName="downtime_cost"
              />
              <MetadataField
                metadata={metadata}
                label="Department Head"
                keyName="department_head"
                singleUser
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default BIAMetadata;
