/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const SelectFrameworkFilter = ({
  label,
  selectedFramework,
  setSelectedFramework,
  list,
  selectedTextSize,
  width,
  disabled,
}: {
  label?: string;
  selectedFramework: KeyStringVal;
  setSelectedFramework: (selectedFramework: KeyStringVal) => void;
  list: KeyStringVal[];
  selectedTextSize?: string;
  width?: string;
  disabled?: boolean;
}) => {
  return (
    <DropdownLayout
      label={label}
      value={selectedFramework.name}
      width={`${width ? width : "w-full"}`}
      placeholder="Select framework"
      selectedTextSize={selectedTextSize}
    >
      {!disabled &&
        list?.map((document: KeyStringVal, index: number) => {
          const selected = document.name === selectedFramework.name;

          return (
            <button
              key={index}
              className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                selected ? "dark:bg-gray-800" : ""
              } rounded-lg`}
              onClick={() => setSelectedFramework(document)}
            >
              {selected && <FontAwesomeIcon icon={faCheck} />}
              <p>{document.name}</p>
            </button>
          );
        })}
    </DropdownLayout>
  );
};

export default SelectFrameworkFilter;
