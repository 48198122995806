/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { checkIsReadOnly } from "src/utils/general";
import {
  AddObjectToAttachment,
  GetObjectsForAttachment,
  RemoveObjectFromAttachment,
} from "src/services/erc/attachments/attachments";
import { GetRisks } from "src/services/erc/risks/risks";
import { GetEntities } from "src/services/erc/entities/entities";
import { GetControls } from "src/services/erc/controls/controls";

const AssociateObjectsWithAttachmentFilter = ({
  attachmentID,
}: {
  attachmentID: string;
}) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const [showDropdown, setShowDropdown] = useState<string>("");
  const [query, setQuery] = useState<KeyStringVal>({
    ENTITY: "",
    RISK: "",
    CONTROL: "",
  });

  const { data: objectsForAttachments } = GetObjectsForAttachment(attachmentID);
  const { data: entities } = GetEntities();
  const { data: risks } = GetRisks();
  const { data: controls } = GetControls();
  const add = AddObjectToAttachment();
  const remove = RemoveObjectFromAttachment();

  const list = {
    ENTITY: entities,
    RISK: risks,
    CONTROL: controls,
  };

  return (
    <section className="flex flex-col flex-grow gap-4">
      {["ENTITY", "RISK", "CONTROL"].map((objectType) => {
        const selectedObjects = objectsForAttachments?.data.filter(
          (object: KeyStringVal) => object.attached_erc_type === objectType
        );
        const filteredItems = list[objectType]?.data?.filter(
          (item: KeyStringVal) =>
            item.name
              .toLowerCase()
              .replace(/\s+/g, "")
              .includes(query[objectType].toLowerCase().replace(/\s+/g, ""))
        );

        return (
          <section className="flex flex-col flex-grow gap-3">
            <article className="flex items-center gap-3 text-b2-reg">
              <header className="flex items-center gap-1 capitalize justify-self-start text-subt1-semi">
                <h4>
                  Associated{" "}
                  {objectType === "ENTITY"
                    ? "Entities"
                    : `${objectType.toLowerCase()}s`}
                </h4>
              </header>
              <article
                onMouseLeave={() => setShowDropdown("")}
                onClick={() => setShowDropdown(objectType)}
                className="relative px-4 py-2 w-[15rem] h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
              >
                <article className="flex items-center justify-between gap-2">
                  <input
                    type="input"
                    disabled={isReadOnly}
                    autoComplete="off"
                    spellCheck="false"
                    placeholder={`${selectedObjects?.length || 0} selected`}
                    value={query[objectType]}
                    onChange={(e) => {
                      if (!showDropdown) setShowDropdown(objectType);
                      setQuery({ ...query, [objectType]: e.target.value });
                    }}
                    className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
                  />
                  <span className="flex items-center pointer-events-none">
                    <ChevronDownIcon
                      className="w-5 h-5 dark:text-gray-500"
                      aria-hidden="true"
                    />
                  </span>
                </article>
                {!isReadOnly && showDropdown === objectType && (
                  <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
                    {filteredItems?.map(
                      (object: KeyStringVal, index: number) => {
                        let objectID = "";
                        switch (objectType) {
                          case "ENTITY":
                            objectID = object.grc_entity_id;
                            break;
                          case "RISK":
                            objectID = object.risk_id;
                            break;
                          case "CONTROL":
                            objectID = object.control_id;
                            break;
                        }
                        const selected = selectedObjects?.some(
                          (member: KeyStringVal) =>
                            objectID === member.attached_erc_id
                        );

                        return (
                          <button
                            key={index}
                            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                              selected ? "dark:bg-gray-800" : ""
                            } rounded-lg`}
                            onClick={() => {
                              if (selected)
                                remove.mutate({
                                  attachmentID,
                                  objectID,
                                  objectType,
                                });
                              else
                                add.mutate({
                                  attachmentID,
                                  objectID,
                                  objectType,
                                });
                            }}
                          >
                            {selected && (
                              <FontAwesomeIcon
                                icon={faCheck}
                                className="text-no"
                              />
                            )}
                            <p>{object.name}</p>
                          </button>
                        );
                      }
                    )}
                  </article>
                )}
              </article>
            </article>
            {selectedObjects?.length > 0 && (
              <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
                {selectedObjects?.map((object: KeyStringVal) => {
                  const objectID = object.attached_erc_id;
                  return (
                    <li
                      key={objectID}
                      className="flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white dark:bg-blue-500 dark:hover:bg-blue-400/70 duration-100 rounded"
                      onClick={() => {
                        switch (objectType) {
                          case "ENTITY":
                            navigate(
                              `/erc/entities/details?entity_id=${objectID}`
                            );
                            break;
                          case "RISK":
                            navigate(`/erc/risks/details?risk_id=${objectID}`);
                            break;
                          case "CONTROL":
                            navigate(
                              `/erc/controls/details?control_id=${objectID}`
                            );
                            break;
                        }
                      }}
                    >
                      <p>{object.attached_erc_name}</p>
                      <button
                        disabled={isReadOnly}
                        className="hover-icon"
                        onClick={(e) => {
                          e.stopPropagation();
                          remove.mutate({
                            attachmentID,
                            objectID,
                            objectType,
                          });
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </button>
                    </li>
                  );
                })}
              </ul>
            )}
          </section>
        );
      })}
    </section>
  );
};

export default AssociateObjectsWithAttachmentFilter;
