import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import AttachmentMetadata from "./AttachmentMetadata";
import AssociateObjectsWithAttachmentFilter from "src/components/Filter/ERC/AssociateObjectsWithAttachmentFilter";

const Attachment = () => {
  const parsed = parseURL();

  const attachmentID = String(parsed.attachment_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "attachments";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 min-h-full w-full overflow-auto scrollbar">
        <AttachmentMetadata attachmentID={attachmentID} />
        <AssociateObjectsWithAttachmentFilter attachmentID={attachmentID} />
      </main>
    </PageLayout>
  );
};

export default Attachment;
