import React from "react";
import { metricColors } from "src/constants/general";
import { convertToUTCString, isEpoch } from "src/utils/general";

const CustomMetricTooltip = ({
  active,
  payload,
  keys,
  xKey,
  hasSeverity,
}: any) => {
  if (active && payload && payload.length) {
    const isDate = isEpoch(payload[0].payload[xKey]);

    const filteredKeys = keys ? keys : payload;

    return (
      <article className="grid px-4 py-2 -mt-10 -ml-60 gap-3 text-b2-reg dark:bg-gray-900 black-shadow">
        <h4>
          {isDate
            ? convertToUTCString(payload[0].payload[xKey])
            : payload[0].payload[xKey]}
        </h4>
        <ul className="flex items-center gap-4">
          {filteredKeys?.map((key: any, index: number) => {
            const value = keys ? key : key.dataKey;
            return (
              <li
                key={index}
                className="flex flex-wrap items-center gap-2 capitalize"
              >
                <span
                  className={`w-2 h-2 ${metricColors[value?.toLowerCase()]}`}
                ></span>
                <p>{`${value.replace("_", " ")}: ${
                  payload[0].payload[value]
                }`}</p>
              </li>
            );
          })}
        </ul>
      </article>
    );
  }

  return null;
};

export default CustomMetricTooltip;
