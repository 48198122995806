import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

const prefix = "grc/grc-attachments";

export const GetAttachments = (pageNumber?: number, tags?: string[]) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-attachments", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddAttachments = () =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-attachments"]),
    }
  );

export const RemoveAttachment = (attachmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${attachmentID}/delete `,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-attachments"]),
    }
  );

export const GetAttachmentMetadata = (attachmentID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-attachment-metadata", attachmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${attachmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: attachmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetObjectsForAttachment = (attachmentID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-objects-for-attachment", attachmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${attachmentID}/getobjects`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: attachmentID !== "",
      keepPreviousData: false,
    }
  );

export const AddObjectToAttachment = () =>
  useMutation<any, unknown, any, string>(
    async ({
      attachmentID,
      objectID,
      objectType,
      signal,
    }: {
      attachmentID: string;
      objectID: string;
      objectType: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${attachmentID}/attach?object_id=${objectID}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-objects-for-attachment"]);
        queryClient.invalidateQueries(["get-attachment-metadata"]);
        queryClient.invalidateQueries(["get-attachments"]);
      },
    }
  );

export const RemoveObjectFromAttachment = () =>
  useMutation<any, unknown, any, string>(
    async ({
      attachmentID,
      objectID,
      objectType,
      signal,
    }: {
      attachmentID: string;
      objectID: string;
      objectType: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${attachmentID}/detach?object_id=${objectID}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-objects-for-attachment"]);
        queryClient.invalidateQueries(["get-attachment-metadata"]);
        queryClient.invalidateQueries(["get-attachments"]);
      },
    }
  );
