/* eslint-disable no-restricted-globals */
import { GetDocumentTypes } from "src/services/vendor-webform";
import DropdownLayout from "../../../layouts/DropdownLayout";

const DocumentTypeFilter = ({
  label,
  fileIndex,
  inputs,
  setInputs,
}: {
  label?: string;
  fileIndex: number;
  inputs: any;
  setInputs: any;
}) => {
  const { data: documentTypes } = GetDocumentTypes();

  const value = inputs["document_types"][fileIndex] || "";

  return (
    <DropdownLayout
      label={label}
      value={value}
      placeholder="Select"
      width="w-[18rem]"
      showAbove
    >
      {documentTypes?.map((type: string, index: number) => {
        const selected = value === type;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              setInputs({
                ...inputs,
                document_types: inputs.document_types.map(
                  (documentType: string, curFileIndex: number) => {
                    if (fileIndex === curFileIndex) {
                      return type;
                    }
                    return documentType;
                  }
                ),
                frameworks: inputs.frameworks.map((framework, curFileIndex) => {
                  if (
                    fileIndex === curFileIndex &&
                    !["Audit Reports", "Privacy Documents"].includes(type)
                  ) {
                    return "";
                  }
                  return framework;
                }),
              });
            }}
          >
            <p>{type}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DocumentTypeFilter;
