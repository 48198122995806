/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentNameFilter = ({
  inputs,
  setInputs,
  list,
}: {
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  return (
    <DropdownLayout
      label="Document Name"
      value={inputs.source_document_name}
      width="w-[30rem]"
      placeholder="Select document"
    >
      {list?.map((document: KeyStringVal, index: number) => {
        const sourceDocID = document.id || document.policy_id;
        const selected = inputs.source_document_id === sourceDocID;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() =>
              setInputs({
                ...inputs,
                source_document_name: document.name || document.policy_name,
                source_document_id: sourceDocID,
                source_version_id: document.latest_version_id || "",
                section_id: "",
                source_section_id: "",
                source_section_title: "",
                source_section_generated_id: "",
                content: "",
              })
            }
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{document.name || document.policy_name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DocumentNameFilter;
