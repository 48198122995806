/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const VersionFilter = ({
  selectedChange,
  setSelectedChange,
  list,
  type,
}: {
  selectedChange: KeyStringVal;
  setSelectedChange: (selectedDocument: KeyStringVal) => void;
  list: KeyStringVal[];
  type: string;
}) => {
  const key = `${type}_version`;
  return (
    <DropdownLayout value={selectedChange[key]} placeholder="Select version">
      {list?.map((version: KeyStringVal, index: number) => {
        const selected = version[key] === selectedChange[key];

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() =>
              setSelectedChange({
                ...selectedChange,
                [key]: version[key],
                [`${type}_version_id`]: version[`${type}_version_id`],
              })
            }
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{version[key]}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default VersionFilter;
