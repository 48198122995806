/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TextFilter = ({
  label,
  list,
  value,
  setValue,
  showAbove,
  width,
  searchable,
  isReadOnly,
}: {
  label?: string;
  list: string[];
  value: string;
  setValue: (value: string) => void;
  showAbove?: boolean;
  width?: string;
  searchable?: boolean;
  isReadOnly?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const filteredList = searchable
    ? list?.filter((value: string) =>
        value
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(String(query).toLowerCase().replace(/\s+/g, ""))
      )
    : list;

  return (
    <>
      <section
        className="flex items-center gap-3 text-b1-reg"
        onMouseLeave={() => setShowDropdown(false)}
      >
        <article
          onMouseEnter={() => setShowDropdown(true)}
          className={`relative flex items-center gap-2 px-4 py-1 ${
            width || "w-full"
          } text-left cursor-pointer dark:bg-gray-700 focus:outline-none rounded`}
        >
          {label && <h4>{label}</h4>}
          <article className="flex items-center justify-between gap-2">
            <article className="text-b1-semi dark:text-blue-500">
              {searchable ? (
                <input
                  type="input"
                  disabled={isReadOnly}
                  autoComplete="off"
                  spellCheck="false"
                  placeholder="Select"
                  value={query || value}
                  onChange={(e) => {
                    if (!showDropdown) setShowDropdown(true);
                    if (searchable) setQuery(e.target.value);
                  }}
                  className={`${
                    width || ""
                  } border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none`}
                />
              ) : (
                <span className="pr-2 break-words">{value || "Select"}</span>
              )}
            </article>
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
            </span>
          </article>
          {!isReadOnly && showDropdown && (
            <article
              className={`absolute ${
                showAbove ? "bottom-7" : "top-7"
              } left-0 grid content-start gap-1 p-1 w-full max-h-36 dark:bg-gray-700 focus:outline-none overflow-auto scrollbar rounded-b`}
            >
              {filteredList?.map((item: string) => {
                const selected = value === item;

                return (
                  <button
                    key={item}
                    className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                      selected ? "dark:bg-gray-800" : ""
                    } rounded-lg`}
                    onClick={() => {
                      setValue(item);
                      setQuery("");
                    }}
                  >
                    {selected && <FontAwesomeIcon icon={faCheck} />}
                    <p>{item}</p>
                  </button>
                );
              })}
            </article>
          )}
        </article>
      </section>
    </>
  );
};

export default TextFilter;
