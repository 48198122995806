/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import {
  GetAllGroups,
  GetGroupInfo,
  UpdateGroup,
} from "../../../services/settings/groups";
import { Group } from "../../../types/settings";
import { checkIsSuperOrSiteAdmin, getCustomerID } from "../../../utils/general";
import RegularInput from "src/components/Input/RegularInput";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EditGroup = ({ group }: { group: Group }) => {
  const groupID = group.group_id;

  const customerID = getCustomerID();
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const [show, setShow] = useState<boolean>(false);

  const [editGroupDetails, setEditGroupDetails] = useState({
    group_name: "",
    group_description: "",
  });
  const [valid, setValid] = useState(true);

  const { data: allGroups } = GetAllGroups(customerID, isSuperOrSiteAdmin);
  const { data: groupInfo } = GetGroupInfo(
    customerID,
    groupID,
    isSuperOrSiteAdmin
  );
  const updateGroup = UpdateGroup(customerID);

  useEffect(() => {
    if (groupInfo) {
      setEditGroupDetails({
        group_name: groupInfo.group_name,
        group_description: groupInfo.group_description,
      });
    }
  }, [groupInfo]);

  const handleOnClose = () => {
    setValid(true);
    setShow(false);
  };

  return (
    <>
      <button
        className="white-text-button"
        onClick={() => {
          setShow(true);
          setEditGroupDetails({
            group_name: "",
            group_description: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>

      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Group</h3>
          <RegularInput
            label="Group Name"
            keyName="group_name"
            inputs={editGroupDetails}
            setInputs={setEditGroupDetails}
            valid={valid}
            setValid={setValid}
          />
          <RegularInput
            label="Description"
            keyName="group_description"
            inputs={editGroupDetails}
            setInputs={setEditGroupDetails}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={editGroupDetails.group_name === ""}
              className="blue-button"
              onClick={() => {
                if (
                  allGroups?.some(
                    (curGroup: Group) =>
                      group.group_id !== curGroup.group_id &&
                      curGroup.group_name.toLowerCase().trim() ===
                        editGroupDetails.group_name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  handleOnClose();
                  updateGroup.mutate({
                    groupID,
                    group: editGroupDetails,
                  });
                }
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default EditGroup;
