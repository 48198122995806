/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { KeyStringVal } from "src/types/general";
import { SearchVendorGroup } from "../../../services/third-party-risk/vendors/vendors";
import FilterLayout from "src/layouts/FilterLayout";

const VendorGroupFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  refetch,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  refetch: any;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const search = SearchVendorGroup();

  const handleSelect = (name: any) => {
    if (name === "All" && search?.data?.length > 0) {
      if (inputs[keyName].length < search.data?.length) {
        search.mutate(
          {
            groupName: "",
          },
          {
            onSuccess: (data) => {
              const groups = data.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.name],
                []
              );
              setInputs({
                ...inputs,
                groups,
              });
            },
          }
        );
      } else setInputs({ ...inputs, [keyName]: [] });
    } else {
      if (inputs[keyName].includes(name))
        setInputs({
          ...inputs,
          [keyName]: inputs[keyName].filter(
            (curName: string) => curName !== name
          ),
        });
      else setInputs({ ...inputs, [keyName]: [...inputs[keyName], name] });
    }
  };

  useEffect(() => {
    search.mutate({
      groupName: "",
    });
  }, []);

  return (
    <FilterLayout label={label}>
      <article className="flex flex-col flex-grow gap-2">
        <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              search.mutate({
                groupName: e.target.value,
              });
            }}
            className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
        </article>
        <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
          <li className="flex items-start gap-2 w-full">
            <input
              type="checkbox"
              className="transparent-checkbox"
              checked={inputs[keyName].length === search?.data?.length}
              onClick={() => handleSelect("All")}
            />
            <label htmlFor="" className="w-max">
              All
            </label>
          </li>
          {search.data?.map((item: KeyStringVal, nameIdx: number) => {
            const selected = inputs[keyName].includes(item.name);
            return (
              <li key={nameIdx} className="flex items-start gap-2 px-4">
                <input
                  type="checkbox"
                  className="transparent-checkbox"
                  checked={selected}
                  onClick={() => handleSelect(item.name)}
                />
                <label htmlFor="">{item.name}</label>
              </li>
            );
          })}
        </ul>
        <article className="w-full border-t dark:border-gray-600">
          <article className="sticky bottom-0 flex items-center gap-2 pt-2 place-self-end">
            <button
              className="red-text-button"
              onClick={() =>
                setInputs({
                  ...inputs,
                  [keyName]: [],
                })
              }
            >
              Clear
            </button>
            <button className="small-blue-button" onClick={refetch}>
              Apply
            </button>
          </article>
        </article>
      </article>
    </FilterLayout>
  );
};

export default VendorGroupFilter;
