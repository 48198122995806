import React from "react";
import {
  convertToUTCString,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import {
  GetControlMetadata,
  GetControls,
} from "../../../../../services/erc/controls/controls";
import UpdateControl from "./UpdateControl";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import {
  attributeColors,
  attributeTextColors,
} from "../../../../../constants/general";
import { GetEntities } from "../../../../../services/erc/entities/entities";
import ControlTags from "./ControlTags";
import ControlBlueprintMetadata from "./ControlBlueprintMetadata";
import EntityMetadata from "./EntityMetadata";
import MetadataField from "src/components/GRC/MetadataField";
import ReturnPage from "src/components/Button/ReturnPage";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBookmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import NewException from "./NewException";

const ControlMetadata = ({ controlID }: { controlID: string }) => {
  const customerID = getCustomerID();
  const navigate = useNavigate();

  const { data: metadata } = GetControlMetadata(controlID);
  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: controls } = GetControls();
  const { data: entities } = GetEntities();

  const entity =
    entities?.data?.find(
      (entity: KeyStringVal) => entity.grc_entity_id === metadata?.grc_entity_id
    )?.name || "";
  const control =
    controls?.data?.find(
      (control: KeyStringVal) =>
        control.control_id === metadata?.parent_control_id
    )?.name || "";

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Control</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-4">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(allUsers, metadata.last_updated_by)} (
                    {getEmailFromUserID(allUsers, metadata.last_updated_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, metadata.created_by)} (
                    {getEmailFromUserID(allUsers, metadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <NewException control={metadata} />
                <UpdateControl controlID={controlID} control={metadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.state.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.state.toLowerCase()]
                  }`}
                >
                  {metadata.state?.replaceAll("_", " ")}
                </span>
              </article>
              {metadata.same_as_entity_owner && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Same as Entity
                </article>
              )}
              {metadata.is_key_control && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  <FontAwesomeIcon icon={faBookmark} />
                  Is Key Control
                </article>
              )}
            </section>
            <MetadataField
              metadata={metadata}
              label="Criteria"
              keyName="control_criteria"
            />
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <MetadataField
              metadata={metadata}
              label="Sub Control Description"
              keyName="sub_control_description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              {entity && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Entity</h4>
                  <span
                    className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                    onClick={() =>
                      navigate(
                        `/erc/entities/details?entity_id=${metadata.grc_entity_id}`
                      )
                    }
                  >
                    {control}
                  </span>
                </article>
              )}
              {control && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Parent Control
                  </h4>
                  <span
                    className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                    onClick={() =>
                      navigate(
                        `/erc/controls/details?control_id=${metadata.parent_control_id}`
                      )
                    }
                  >
                    {control}
                  </span>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Control Reference Id"
                keyName="control_reference_id"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField metadata={metadata} label="Type" keyName="type" />
              <MetadataField
                metadata={metadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={metadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
              <MetadataField
                metadata={metadata}
                label="Frequency"
                keyName="frequency"
              />
              <MetadataField
                metadata={metadata}
                label="Reviewer"
                keyName="reviewer"
                singleUser
              />
            </section>
          </section>
          <ControlBlueprintMetadata blueprint={metadata.control_blueprint} />
          <EntityMetadata entity={metadata.grc_entity} />
          <ControlTags controlID={controlID} tags={metadata.tags} />
        </header>
      )}
    </>
  );
};

export default ControlMetadata;
