/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "../../../../components/Input/CategoryInput";
import {
  AddControlBlueprint,
  GetSourceControls,
  GetSourceNames,
} from "../../../../services/erc/controls/control-blueprints";
import SourceNameFilter from "../../../../components/Filter/ERC/SourceNameFilter";
import SourceControlFilter from "../../../../components/Filter/ERC/SourceControlFilter";
import SourceSubcontrolFilter from "../../../../components/Filter/ERC/SourceSubcontrolFilter";
import ToastLayout from "../../../../layouts/ToastLayout";
import CheckboxInput from "src/components/Input/CheckboxInput";
import MultiEntityTypeFilter from "src/components/Filter/ERC/MultiEntityTypeFilter";
import { checkIsReadOnly } from "src/utils/general";

const NewControlBlueprint = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const addControlBlueprint = AddControlBlueprint();
  const { data: sourceNames } = GetSourceNames();
  const frameworkID =
    sourceNames?.find(
      (framework) => framework.framework_short_name === inputs.source_name
    )?.generated_framework_id || "";

  const { data: sourceControls } = GetSourceControls(frameworkID);

  useEffect(() => {
    if (inputs.source_control_id !== "") {
      const control = sourceControls?.data.find(
        (control) =>
          control.control_id.replaceAll(" ", "") === inputs.source_control_id
      );

      if (control)
        setInputs({
          ...inputs,
          function: control.context_or_function || "",
          category: control.category || "",
          sub_category: control.sub_category || "",
        });
    }
  }, [inputs.source_control_id]);

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addControlBlueprint.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            status: "DRAFT",
            function: "",
            category: "",
            sub_category: "",
            classification: "",
            type: "",
            attestation: "",
            source_name: "",
            source_control_id: "",
            source_sub_control_id: "",
            create_controls_automatically_enabled: false,
            entity_type_ids: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Control Blueprint</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Control Blueprint
          </h3>
          <section className="grid gap-4 w-full">
            <section className="grid content-start md:grid-cols-2 gap-4">
              <RegularInput
                label="Name"
                keyName="name"
                inputs={inputs}
                setInputs={setInputs}
                valid={valid}
                setValid={setValid}
                required
              />
              <CategoryInput
                label="Status"
                keyName="status"
                list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
                inputs={inputs}
                setInputs={setInputs}
                allowUnselect
              />
              <SourceNameFilter
                label="Source Name"
                keyName="source_name"
                inputs={inputs}
                setInputs={setInputs}
              />
              {inputs.source_name && (
                <SourceControlFilter
                  label="Source Control Id"
                  keyName="source_control_id"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
              {inputs.source_control_id && (
                <SourceSubcontrolFilter
                  label="Source Subcontrol Id"
                  keyName="source_sub_control_id"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
              <RegularInput
                label="Function"
                keyName="function"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Category"
                keyName="category"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Subcategory"
                keyName="sub_category"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Classification"
                keyName="classification"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Type"
                keyName="type"
                inputs={inputs}
                setInputs={setInputs}
              />
              <RegularInput
                label="Attestation"
                keyName="attestation"
                inputs={inputs}
                setInputs={setInputs}
              />
              <MultiEntityTypeFilter
                label="Entity Types"
                keyName="entity_type_ids"
                inputs={inputs}
                setInputs={setInputs}
              />
              {inputs.entity_type_ids?.length > 0 && (
                <CheckboxInput
                  label="Create Controls Automatically"
                  keyName="create_controls_automatically_enabled"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
            </section>
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addControlBlueprint.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addControlBlueprint.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New control blueprint has been created"
      />
    </>
  );
};

export default NewControlBlueprint;
