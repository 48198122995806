import React from "react";
import { convertToUTCString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import UpdateEntity from "./UpdateEntity";
import EntityTags from "./EntityTags";
import { GetEntityMetadata } from "../../../../../services/erc/entities/entities";
import ArchiveRestoreEntity from "./ArchiveRestoreEntity";
import MetadataField from "src/components/GRC/MetadataField";
import NewAssessment from "./NewAssessment";
import ReturnPage from "src/components/Button/ReturnPage";

const EntityMetadata = ({ entityID }: { entityID: string }) => {
  const { data: metadata } = GetEntityMetadata(entityID);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Entity</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>Last Updated by {metadata.last_updated_by}</h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>Created by {metadata.created_by}</h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <NewAssessment entityID={entityID} />
                <UpdateEntity entityID={entityID} entity={metadata} />
                <ArchiveRestoreEntity entityID={entityID} entity={metadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-4 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status?.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status?.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              {metadata?.entity_type?.name && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Entity Type
                  </h4>
                  <span className="text-b1-semi">
                    {metadata?.entity_type?.name}
                  </span>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="External Reference Id"
                keyName="external_reference_id"
              />
              <MetadataField
                metadata={metadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
              <MetadataField
                metadata={metadata}
                label="Managed By"
                keyName="managed_by"
                singleUser
              />
              {["APPLICATION", "DATABASE"].includes(
                metadata?.entity_type?.name
              ) && (
                <>
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Internal or 3rd Party"
                    keyName="internal_or_3rd_party"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Hosting Locations"
                    keyName="hosting_locations"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Storage Format"
                    keyName="storage_format"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Hosting Type"
                    keyName="hosting_type"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Hosting Provider"
                    keyName="hosting_provider"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Technical Security Measures"
                    keyName="technical_security_measures"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Organizational Security Measures"
                    keyName="organizational_security_measures"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Other Security Measures"
                    keyName="other_security_measures"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Data Retention"
                    keyName="data_retention"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Criticality"
                    keyName="criticality"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Security Classification"
                    keyName="security_classification"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Asset Type"
                    keyName="asset_type"
                  />
                  <MetadataField
                    metadata={metadata?.additional_properties}
                    label="Next Review Date"
                    keyName="next_review_date"
                    isDate
                  />
                </>
              )}
            </section>
          </section>
          <EntityTags entityID={entityID} tags={metadata.tags} />
        </header>
      )}
    </>
  );
};

export default EntityMetadata;
