/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faChevronRight,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import DeleteVendorResponse from "./DeleteVendorResponse";
import Notes from "./Notes";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { KeyStringVal } from "src/types/general";

const Response = ({
  vendorID,
  assessmentID,
  questionIndex,
  qa,
}: {
  vendorID: string;
  assessmentID: string;
  questionIndex: number;
  qa: any;
}) => {
  const questionID = qa.generated_id;

  return (
    <li
      key={questionIndex}
      className="grid gap-2 p-5 w-full dark:bg-gray-700 rounded"
    >
      <header className="grid gap-4 w-full">
        <article className="flex items-center justify-between gap-10 w-full">
          <Notes
            vendorID={vendorID}
            assessmentID={assessmentID}
            questionID={questionID}
            question={qa}
          />
          <DeleteVendorResponse
            vendorID={vendorID}
            assessmentID={assessmentID}
            questionID={questionID}
          />
        </article>

        {qa.tags?.length > 0 && (
          <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-800 rounded">
            <span>Tags</span>
            {qa.tags.map((tag: string, index: number) => {
              return (
                <span key={index} className="dark:text-blue-500">
                  {tag}
                </span>
              );
            })}
          </article>
        )}
        <p className="p-4 text-b2-reg dark:bg-gray-900 rounded">
          Q: {qa.question}
        </p>
      </header>
      {["Yes_No", "Multiple Choice", "Multiple Select"].includes(
        qa.question_type
      ) ? (
        <section className="grid gap-1 w-full max-h-[30rem] overflow-auto scrollbar">
          {qa.multi_choices?.map((choice: KeyStringVal) => {
            const selected = qa.multi_answers.some(
              (selectedChoice: KeyStringVal) =>
                selectedChoice.answer === choice.choice
            );
            return (
              <article key={choice.choice} className="flex items-start gap-1">
                {qa.question_type === "Multiple Select" ? (
                  <input
                    type="checkbox"
                    disabled
                    className="white-checkbox"
                    checked={selected}
                  />
                ) : (
                  <input
                    type="radio"
                    disabled
                    className="blue-radio"
                    checked={selected}
                  />
                )}
                <label htmlFor="">{choice.choice}</label>
              </article>
            );
          })}
        </section>
      ) : null}
      {qa.response && (
        <p className="p-4 text-b2-reg dark:bg-gray-900 rounded">
          A: {qa.response}
        </p>
      )}
      {qa.llm_response && (
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex items-center gap-1 w-max text-b2-reg">
                {open ? "Hide" : "Show"} Response Analysis
                <FontAwesomeIcon
                  icon={open ? faChevronDown : faChevronRight}
                  className="dark:text-white"
                />
              </Disclosure.Button>
              <Transition
                show={open}
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <Disclosure.Panel>
                  <ReactMarkdown
                    className="px-6 py-4 min-w-full text-b2-reg prose dark:prose-invert dark:bg-gray-800 rounded-xl"
                    remarkPlugins={[remarkGfm]}
                  >
                    {qa.llm_response}
                  </ReactMarkdown>
                </Disclosure.Panel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
      {qa.evidences.length > 0 && (
        <article className="grid gap-2 p-4 text-b2-reg dark:bg-gray-800 rounded-lg">
          <h4 className="text-b1-semi">Evidences</h4>
          <ul className="flex flex-wrap gap-2">
            {qa.evidences.map((keyVal: any, idx: number) => {
              const fileName = Object.keys(keyVal)[0] as string;
              const url = Object.values(keyVal)[0] as string;
              const isImage =
                url?.includes("jpeg") ||
                url?.includes("jpg") ||
                url?.includes("png") ||
                url?.includes("svg");
              return (
                <button
                  key={idx}
                  className="flex flex-col flex-grow gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 rounded"
                >
                  <article
                    className="flex items-center gap-2 dark:hover:bg-gray-700/70 duration-100 rounded"
                    onClick={() => {
                      let a = document.createElement("a");
                      a.href = url;
                      a.download = `${fileName}.pdf`;
                      a.target = "_blank";
                      a.click();
                    }}
                  >
                    <FontAwesomeIcon
                      icon={faLink}
                      className="dark:text-blue-500"
                    />
                    <h4>{fileName}</h4>
                  </article>
                  {isImage && (
                    <Disclosure defaultOpen>
                      {({ open }) => (
                        <section className="grid content-start gap-3">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                            <p>{open ? "Hide" : "Show"}</p>
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <img
                              src={url}
                              alt="evidence"
                              className="w-40 h-40"
                            />
                          </Disclosure.Panel>
                        </section>
                      )}
                    </Disclosure>
                  )}
                </button>
              );
            })}
          </ul>
        </article>
      )}
    </li>
  );
};

export default Response;
