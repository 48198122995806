/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { CustomerEnv, UserDetails } from "../../../types/settings";

const DefaultEnvFilter = ({
  envTypes,
  details,
  label,
  value,
  setValue,
  list,
  showAbove,
}: {
  envTypes: CustomerEnv[];
  details: UserDetails;
  label: string;
  value: string;
  setValue: (value: UserDetails) => void;
  list: string[];
  showAbove?: boolean;
}) => {
  return (
    <DropdownLayout
      label={label}
      value={value}
      showAbove={showAbove}
      labelAbove
    >
      {list?.map((item: string, index: number) => {
        const selected = value === item;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              setValue({
                ...details,
                default_env:
                  envTypes?.find(
                    (customerEnv: CustomerEnv) => customerEnv.env_type === item
                  )?.env_id || "",
              });
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DefaultEnvFilter;
