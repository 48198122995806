import { faBan, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { User } from "@sentry/browser";
import React, { useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import {
  DeleteCustomerEnv,
  GetCustomerEnvs,
} from "../../../services/settings/environments";
import { CustomerEnv } from "../../../types/settings";
import { getCustomerID } from "../../../utils/general";
import { GetAllUsers } from "src/services/settings/users";

const DeleteEnvironment = ({ envType }: { envType: string }) => {
  const customerID = getCustomerID();

  const [show, setShow] = useState<boolean>(false);

  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const deleteEnv = DeleteCustomerEnv(customerID);

  const deleteEnvTypeID = customerEnvs?.find(
    (curEnvType: CustomerEnv) => curEnvType.env_type === envType
  )?.env_id;

  const canDelete = allUsers?.every(
    (user: User) => user.default_env !== deleteEnvTypeID
  );

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="red-trash-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        <FontAwesomeIcon icon={faTrashCan} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        {canDelete ? (
          <section className="grid gap-4">
            <h2 className="text-subt1-semi">Delete Environment</h2>
            <h4 className="text-b1-reg">
              Are you sure you want to delete this environment? All associated
              accounts will also be deleted.
            </h4>
            <article className="flex items-center place-content-end gap-4">
              <button className="black-button" onClick={handleOnClose}>
                Cancel
              </button>
              <button
                className="red-button"
                onClick={() => {
                  deleteEnv.mutate({
                    customerEnvID: deleteEnvTypeID,
                  });
                  handleOnClose();
                }}
              >
                Delete
              </button>
            </article>
          </section>
        ) : (
          <section className="grid content-start gap-4 p-5 text-center">
            <FontAwesomeIcon
              icon={faBan}
              className="w-10 h-10 mx-auto dark:text-red-500"
            />
            <p>
              Please make sure no user's default environment is pointing to this
              environment before you try to delete.
            </p>
          </section>
        )}
      </ModalLayout>
    </>
  );
};

export default DeleteEnvironment;
