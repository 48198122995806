import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { SendAssessmentEmail } from "src/services/erc/assessments/assessments";
import { checkIsReadOnly } from "src/utils/general";

const SendEmail = ({
  entityID,
  assessmentID,
  assessment,
  entity,
}: {
  entityID: string;
  assessmentID: string;
  assessment: any;
  entity: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [valid, setValid] = useState<boolean>(true);

  const sendEmail = SendAssessmentEmail(
    entityID,
    assessmentID,
    "ERC",
    "ENTITY"
  );

  const disabled = isReadOnly || sendEmail.status === "loading";

  const handleOnClose = () => {
    setValid(true);
    sendEmail.reset();
  };

  return (
    <>
      <button
        disabled={disabled}
        className="blue-button"
        onClick={() => {
          if (assessment?.respondent_emails?.length > 0 || entity?.owner) {
            setValid(true);
            sendEmail.mutate({});
          } else setValid(false);
        }}
      >
        Send Email
      </button>
      <ModalLayout showModal={!valid} onClose={handleOnClose}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Send Assessment Email</h2>
          <p className="flex items-center gap-2 text-b1-reg">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-red-700"
            />{" "}
            A valid email must be present to send email.
          </p>
          <button
            className="place-self-end black-button"
            onClick={handleOnClose}
          >
            Close
          </button>
        </section>
      </ModalLayout>
      <ModalLayout
        showModal={sendEmail.status === "success"}
        onClose={handleOnClose}
      >
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Send Assessment Email</h2>
          <p className="text-b1-reg">
            An email with the one-time access code has been sent out. The access
            code will expire in{" "}
            {sendEmail.data?.expiry_time <= 24
              ? `${sendEmail.data?.expiry_time} hours`
              : `${Math.round(sendEmail.data?.expiry_time / 24)} days`}
            , so please make sure to click through in time.
          </p>
          <button
            className="place-self-end blue-button"
            onClick={handleOnClose}
          >
            Got it!
          </button>
        </section>
      </ModalLayout>
    </>
  );
};

export default SendEmail;
