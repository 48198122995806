/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { EditBIA } from "src/services/business-continuity/bia";
import CategoryInput from "src/components/Input/CategoryInput";
import UserInput from "src/components/Input/UserInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import MultiUserInput from "src/components/Input/MultiUserInput";
import { checkIsReadOnly } from "src/utils/general";
import { KeyStringVal } from "src/types/general";

const UpdateBIA = ({ biaID, bia }: { biaID: string; bia: any }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const editBIA = EditBIA(biaID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end edit-button"
        onClick={() => {
          setShow(true);
          setInputs({
            ...bia,
            owners:
              bia.owners?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
            reviewers:
              bia.reviewers?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
            approvers:
              bia.approvers?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        <h4>Edit</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full overflow-auto scrollbar">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit BIA</h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
            <RegularInput
              label="Department"
              keyName="department"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Impact of Downtime"
              keyName="impact_of_downtime"
              inputs={inputs}
              setInputs={setInputs}
            />
            <GeneralSnapshotDatepicker
              label="Last Review Date"
              keyName="last_review_date"
              inputs={inputs}
              setInputs={setInputs}
            />
            <GeneralSnapshotDatepicker
              label="Next Review"
              keyName="next_review"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Author"
              keyName="author"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Owners"
              keyName="owners"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Reviewers"
              keyName="reviewers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Approvers"
              keyName="approvers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="State"
              keyName="state"
              list={["Draft", "In Review", "Approved"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="BCM Lead"
              keyName="bcm_lead"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Criticality"
              keyName="criticality"
              list={["Partner", "Critical", "High", "Medium", "Low"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Peak Operating Periods"
              keyName="peak_operating_periods"
              list={[
                "0",
                "1",
                "2",
                "3",
                "4",
                "5",
                "6",
                "7",
                "8",
                "9",
                "10",
                "11",
                "12",
                "13",
                "14",
                "15",
                "16",
                "17",
                "18",
                "19",
                "20",
                "21",
                "22",
                "23",
                "24",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Location"
              keyName="location"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Recovery Time Objective (RTO)"
              keyName="rto_in_seconds"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Recovery Point Objective (RPO)"
              keyName="rpo_in_seconds"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Maximum Tolerable Period of Disruption (MTPD)"
              keyName="mtpd_in_seconds"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Downtime Cost"
              keyName="downtime_cost"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Department Head"
              keyName="department_head"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editBIA.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateBIA;
