/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import ToastLayout from "src/layouts/ToastLayout";
import { GetTableColumns, SaveTableColumns } from "src/services/grc";
import { KeyStringVal } from "src/types/general";

const ColumnFilters = ({
  filters,
  setFilters,
  prefix,
  refetch,
}: {
  filters: any;
  setFilters: any;
  prefix: string;
  refetch: any;
}) => {
  const { data: columns } = GetTableColumns(prefix);
  const saveColumns = SaveTableColumns(prefix);

  const handleOnCloseConfirmation = () => saveColumns.reset();

  return (
    <>
      <FilterLayout label="Columns">
        <article className="flex flex-col flex-grow gap-1">
          <p className="text-b2-reg">Select columns</p>
          <p className="w-full text-b3-reg dark:text-gray-400">
            *Provide a minimum of 5, no more than 8*
          </p>
          <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
            {columns?.map((column: KeyStringVal) => {
              const selected = filters.columns.includes(column.property_name);
              return (
                <li
                  key={column.property_name}
                  className="flex items-center gap-2 px-2"
                >
                  <input
                    type="checkbox"
                    className="transparent-checkbox"
                    checked={selected}
                    onChange={() => {
                      if (selected)
                        setFilters({
                          ...filters,
                          columns: filters.columns.filter(
                            (col: string) => col !== column.property_name
                          ),
                        });
                      else if (filters.columns.length < 8)
                        setFilters({
                          ...filters,
                          columns: [...filters.columns, column.property_name],
                        });
                    }}
                  />
                  <label htmlFor="">{column.display_name}</label>
                </li>
              );
            })}
          </ul>
          <article className="w-full border-t dark:border-gray-600">
            <article className="sticky bottom-0 flex items-center gap-2 pt-2 place-self-end">
              <button
                className="red-text-button"
                onClick={() => {
                  setFilters({
                    ...filters,
                    columns: [],
                  });
                  refetch();
                }}
              >
                Clear
              </button>
              <button
                disabled={
                  filters.columns.length < 5 || saveColumns.status === "loading"
                }
                className="small-blue-button"
                onClick={() =>
                  saveColumns.mutate({
                    columns: filters.columns,
                  })
                }
              >
                Apply
              </button>
            </article>
          </article>
        </article>
      </FilterLayout>
      <ToastLayout
        showModal={saveColumns.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Column selection saved to your preferences"
      />
    </>
  );
};

export default ColumnFilters;
