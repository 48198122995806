import { useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion } from "src/constants/general";

const prefix = "grc/risk-register/risks/analytics";

export const GetRisksByMonth = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-by-month"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/count-by-month`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetRisksByState = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-by-state"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/count-by-state`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetInherentRiskDistribution = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-inherent-risk-distribution"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/inherent-risk-distribution`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetCategoryCounts = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-category-counts"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/count-by-category`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetControlsbyType = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-controls-by-type"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/controls-by-type`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetOverallRiskScore = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-overall-risk-score"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/aggregate-risk-score`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );
