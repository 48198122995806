import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MDXEditor,
  toolbarPlugin,
  UndoRedo,
  BoldItalicUnderlineToggles,
  StrikeThroughSupSubToggles,
  BlockTypeSelect,
  CodeToggle,
  CreateLink,
  InsertCodeBlock,
  InsertFrontmatter,
  InsertImage,
  InsertTable,
  InsertThematicBreak,
  ListsToggle,
  DiffSourceToggleWrapper,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  tablePlugin,
  imagePlugin,
  frontmatterPlugin,
  diffSourcePlugin,
  linkDialogPlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
} from "@mdxeditor/editor";
import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { UpdatePolicySections } from "src/services/regulation-policy/policy";
import { checkIsReadOnly } from "src/utils/general";

const SubsectionContent = ({
  documentType,
  documentID,
  subsection,
  filter,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  subsection: any;
  filter?: string;
  editSections?: any;
  setEditSections?: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const isPolicy = documentType === "policies";

  const updatePolicySections = UpdatePolicySections();

  return (
    <section className="text-b1-reg">
      {editSections &&
      Object.keys(editSections).includes(subsection.generated_id) ? (
        <section className="grid gap-2">
          <article className="flex items-center place-content-end gap-4">
            <button
              disabled={isReadOnly}
              className="red-button"
              onClick={() =>
                setEditSections(
                  Object.fromEntries(
                    Object.entries(editSections).filter(
                      ([key]) => key !== subsection.generated_id
                    )
                  )
                )
              }
            >
              Discard
            </button>
            {subsection.content !==
              editSections[subsection.generated_id].content && (
              <button
                disabled={isReadOnly}
                className="blue-button"
                onClick={() => {
                  updatePolicySections.mutate({
                    policyID: documentID,
                    editSections: editSections,
                  });
                  setEditSections({});
                }}
              >
                Save
              </button>
            )}
          </article>

          <MDXEditor
            className="min-w-full prose dark:bg-white rounded-xl"
            markdown={editSections[subsection.generated_id]?.content}
            plugins={[
              toolbarPlugin({
                toolbarContents: () => (
                  <>
                    <UndoRedo />
                    <BoldItalicUnderlineToggles />
                    <StrikeThroughSupSubToggles />
                    <BlockTypeSelect />
                    <CodeToggle />
                    <CreateLink />
                    <InsertCodeBlock />
                    <InsertFrontmatter />
                    <InsertImage />
                    <InsertTable />
                    <InsertThematicBreak />
                    <ListsToggle />
                    <DiffSourceToggleWrapper />
                  </>
                ),
              }),
              headingsPlugin(),
              listsPlugin(),
              quotePlugin(),
              thematicBreakPlugin(),
              markdownShortcutPlugin(),
              tablePlugin(),
              imagePlugin(),
              frontmatterPlugin(),
              diffSourcePlugin({
                diffMarkdown: editSections[subsection.generated_id]?.content,
                viewMode: "rich-text",
                readOnlyDiff: true,
              }),
              linkDialogPlugin(),
              codeBlockPlugin(),
              codeMirrorPlugin({
                codeBlockLanguages: {
                  js: "JavaScript",
                  py: "Python",
                  "": "Text",
                },
              }),
            ]}
            onChange={(content: string) =>
              setEditSections({
                ...editSections,
                [subsection.generated_id]: {
                  content,
                },
              })
            }
          />
        </section>
      ) : (
        <section className="grid gap-2">
          {isPolicy &&
            editSections &&
            !editSections[subsection.generated_id]?.content &&
            filter !== "Suggest New Mapping" && (
              <button
                disabled={isReadOnly}
                className="place-self-end white-text-button"
                onClick={() =>
                  setEditSections({
                    ...editSections,
                    [subsection.generated_id]: {
                      content: subsection.content,
                    },
                  })
                }
              >
                <FontAwesomeIcon icon={faEdit} />
                Edit
              </button>
            )}
          <ReactMarkdown
            className="px-6 py-4 text-b2-reg markdown"
            remarkPlugins={[remarkGfm]}
          >
            {subsection.content}
          </ReactMarkdown>
        </section>
      )}
    </section>
  );
};

export default SubsectionContent;
