/* eslint-disable react-hooks/exhaustive-deps */
import { faEye, faWarning } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import { convertToDate, convertToUTCString } from "src/utils/general";
import DeleteAssessment from "./DeleteAssessment";
import EditAssessment from "./EditAssessment";
import { GetAssessmentList } from "src/services/questionnaire-rfp";
import { KeyStringVal } from "src/types/general";
import { useGRCStore } from "src/stores/grc";
import Loader from "src/components/Loader/Loader";
import AllTags from "../../../../components/GRC/AllTags";

const AssessmentList = () => {
  const { setSelectedGRCAssessment } = useGRCStore();

  const [selectedSourceType, setSelectedSourceType] =
    useState<string>("policies");
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);

  const { data: assessmentList, status: assessmentStatus } = GetAssessmentList(
    pageNumber,
    selectedTags,
    sessionStorage.source_type
  );

  const totalCount = assessmentList?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    if (!sessionStorage.source_type) {
      sessionStorage.source_type = "policies";
      setSelectedSourceType("policies");
    } else if (sessionStorage.source_type === "sop")
      setSelectedSourceType("sop");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <AllTags
        documentType="frameworks"
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      {/* <nav className="flex flex-wrap items-center gap-4 text-sm">
        {["policies"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize border-b-2 ${
                sessionStorage.source_type === tab.toLowerCase()
                  ? "dark:bg-blue-600/30 dark:border-blue-600"
                  : "dark:bg-filter/10 dark:hover:bg-filter/30 duration-100 dark:border-checkbox"
              } rounded-full`}
              onClick={() => {
                setSelectedSourceType(tab.toLowerCase());
                sessionStorage.source_type = tab.toLowerCase();
              }}
            >
              {tab}
            </button>
          );
        })}
      </nav> */}
      {assessmentStatus === "loading" ? (
        <Loader />
      ) : assessmentList?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <ul className="flex flex-col flex-grow gap-4">
            {assessmentList?.data.map((assessment: any, index: number) => {
              const dueToday =
                convertToDate(assessment.due_date).setHours(0, 0, 0, 0) ===
                new Date().setHours(0, 0, 0, 0);
              const pastDue = assessment.due_date < Date.now() * 1000;
              return (
                <li
                  key={index}
                  className="grid gap-4 px-6 py-4 dark:bg-gray-800 rounded-xl"
                >
                  <header className="flex items-start justify-between gap-20">
                    <article className="flex flex-col flex-grow gap-1">
                      <article className="flex items-center gap-4">
                        <h4 className="text-subt1-semi">{assessment.name}</h4>
                        {(pastDue || dueToday) && (
                          <span
                            className={`flex items-center gap-2 px-4 py-1 w-max text-b2-reg ${
                              pastDue
                                ? "bg-red-600"
                                : dueToday
                                ? "bg-orange-600 text-black"
                                : ""
                            } rounded`}
                          >
                            <FontAwesomeIcon icon={faWarning} />
                            {pastDue ? "Past Due" : "Due Today"}{" "}
                          </span>
                        )}
                      </article>
                      <article className="flex items-center gap-2 text-b1-reg dark:text-gray-400">
                        <h4>Due Date</h4>
                        <p>{convertToUTCString(assessment.due_date)}</p>
                      </article>
                    </article>
                    <article className="flex items-center">
                      <EditAssessment
                        selectedAssessment={assessment}
                        selectedSourceType={sessionStorage.source_type}
                      />
                      <DeleteAssessment selectedAssessment={assessment} />
                      <button
                        className="flex items-center gap-2 px-4 py-1 text-b2-reg dark:text-blue-600 dark:hover:text-blue-600/70 duration-100"
                        onClick={() => {
                          setSelectedGRCAssessment(assessment);
                          sessionStorage.assessment_id =
                            assessment.assessment_id;
                          sessionStorage.assessment_name = assessment.name;
                          sessionStorage.enable_sources =
                            assessment.enable_sources;
                          sessionStorage.enable_questionbank =
                            assessment.enable_questionbank;
                        }}
                      >
                        <FontAwesomeIcon icon={faEye} /> View questions
                      </button>
                    </article>
                  </header>
                  <article className="flex items-center gap-4">
                    {assessment.requesting_party && (
                      <article className="flex items-center gap-1 px-2 py-1 dark:bg-gray-700 rounded">
                        <span className="text-b1-reg">Requesting Party</span>{" "}
                        <span className="text-b1-semi dark:text-blue-500">
                          {assessment.requesting_party}
                        </span>
                      </article>
                    )}
                    {assessment.owner && (
                      <article className="flex items-center gap-1 px-2 py-1 dark:bg-gray-700 rounded">
                        <span className="text-b1-reg">Owner</span>{" "}
                        <span className="text-b1-semi dark:text-blue-500">
                          {assessment.owner}
                        </span>
                      </article>
                    )}
                  </article>
                  {assessment.tags.length > 0 && (
                    <ul className="flex flex-wrap items-center gap-4 text-b2-reg">
                      {assessment.tags.map(
                        (tag: KeyStringVal, index: number) => {
                          return (
                            <li
                              key={index}
                              className="px-4 py-1 dark:bg-checkbox rounded-full"
                            >
                              {tag.title}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  )}
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/frameworks-placeholder.svg"
            alt="frameworks placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Questionnaire and Assessment</h4>
            <h4>No assessments available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default AssessmentList;
