/* eslint-disable react-hooks/exhaustive-deps */
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import TextFilter from "src/components/Filter/General/TextFilter";
import { KeyStringVal } from "src/types/general";
import { convertToUTCString, parseURL } from "src/utils/general";
import DeleteSOP from "../DeleteSOP";
import {
  GetProcedureMetadata,
  GetSOPVersions,
} from "src/services/business-continuity/sop";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import UpdateSOPVersion from "./UpdateSOPVersion";
import ReturnPage from "src/components/Button/ReturnPage";

const ProcedureMetadata = ({
  sopID,
  selectedSOPVersion,
  setSelectedSOPVersion,
}: {
  sopID: string;
  selectedSOPVersion: string;
  setSelectedSOPVersion: (selectedSOPVersion: string) => void;
}) => {
  const parsed = parseURL();
  const navigate = useNavigate();

  const { data: metadata } = GetProcedureMetadata(sopID);
  const { data: sopVersions } = GetSOPVersions(sopID);

  const versionID =
    sopVersions?.find(
      (version: KeyStringVal) => version.version === selectedSOPVersion
    )?.sop_version_id || "";
  const sopVersionID = String(parsed.sop_version_id) || "";

  const versions = sopVersions?.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.version],
    []
  );
  const sopName = metadata?.sop_name;

  useEffect(() => {
    if (versions?.length > 0 && sopVersionID && selectedSOPVersion === "") {
      const filteredSOPVersion =
        sopVersions?.find(
          (version: KeyStringVal) => version.sop_version_id === sopVersionID
        )?.version || "";

      setSelectedSOPVersion(filteredSOPVersion);
    }
  }, [versions]);

  useEffect(() => {
    if (selectedSOPVersion !== "") {
      const filteredSOPVersionID =
        sopVersions?.find(
          (version: KeyStringVal) => version.version === selectedSOPVersion
        )?.sop_version_id || "";

      parsed.sop_version_id = filteredSOPVersionID;
      navigate(`${window.location.pathname}?${queryString.stringify(parsed)}`);
    }
  }, [selectedSOPVersion]);

  return (
    <>
      <header className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Business Continuity</span>
          <span>/</span>
          <span>Standard Operating Procedure</span>
        </h4>
      </header>
      {metadata && (
        <header className="grid gap-4">
          <article className="grid content-start gap-1">
            <header className="flex items-center justify-between gap-4">
              <h4 className="py-1 break-words text-left text-t1-semi">
                {sopName}
              </h4>
              <article className="flex items-center gap-4">
                <TextFilter
                  label="Version"
                  list={versions}
                  value={selectedSOPVersion}
                  setValue={setSelectedSOPVersion}
                />
                <UpdateSOPVersion
                  sopName={sopName}
                  sopID={sopID}
                  setSelectedSOPVersion={setSelectedSOPVersion}
                />
                <DeleteSOP versionID={versionID} />
              </article>
            </header>
            <span className="flex flex-wrap items-center gap-3 text-b2-reg dark:text-gray-400 divide-x dark:divide-gray-400">
              {convertToUTCString(metadata.last_updated_at)}
            </span>
          </article>
          {sopVersions?.length > 1 && (
            <a
              href={`/business-continuity/sop/policy-drift/details?sop_id=${sopID}&sop_name=${metadata.sop_name}`}
              className="white-text-button"
            >
              Drift
              <FontAwesomeIcon icon={faArrowRightLong} />
            </a>
          )}
        </header>
      )}
    </>
  );
};

export default ProcedureMetadata;
