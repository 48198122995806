import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

export const entityPrefix = "grc/entities";

export const GetEntities = (
  pageNumber?: number,
  tags?: string[],
  columns?: string[],
  queryList?: any
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-entities", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            tags,
            ...(columns?.length >= 5 && { selected_columns: columns }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddEntity = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-entities"]),
    }
  );

export const EditEntity = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${entityPrefix}/${entityID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entity-metadata"]);
      },
    }
  );

export const RestoreEntity = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-entity-metadata"]),
    }
  );

export const ArchiveEntity = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-entity-metadata"]),
    }
  );

export const GetEntityMetadata = (entityID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-entity-metadata", entityID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${entityPrefix}/${entityID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityID !== "",
      keepPreviousData: false,
    }
  );

export const SearchEntity = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ entity, signal }: { entity: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/searches`,
          {
            third_party_name: entity,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const AssignTagToEntity = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entitys"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-entity"]);
      },
    }
  );

export const RemoveTagFromEntity = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entitys"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-entity"]);
      },
    }
  );

export const GetControlsForEntity = (
  entityID: string,
  pageNumber?: number,
  tags?: string[] | undefined
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-controls-for-entity", entityID, pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/controls/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityID !== "",
      keepPreviousData: false,
    }
  );

export const GetVendorsForEntity = (entityID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendors-for-entity", entityID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/vendors`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityID !== "",
      keepPreviousData: false,
    }
  );

export const AddVendorToEntity = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityID,
      vendor,
      signal,
    }: {
      entityID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/vendors/add`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-entity"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const RemoveVendorFromEntity = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityID,
      vendor,
      signal,
    }: {
      entityID: string;
      vendor: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/vendors/remove`,
          vendor,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors-for-entity"]);
        queryClient.invalidateQueries(["get-entity-metadata"]);
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const GetAssessmentListForEntity = (
  entityID: string,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-assessment-list-for-entity", entityID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/assessments`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddEntityAttachments = () =>
  useMutation<any, unknown, any, string>(
    async ({
      entityID,
      formData,
      signal,
    }: {
      entityID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entities"]);
        queryClient.invalidateQueries(["get-entity-attachments"]);
      },
    }
  );

export const RemoveEntityAttachment = (
  entityID: string,
  attachmentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/removedocument?attachment_id=${attachmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-entity-attachments"]),
    }
  );

export const DownloadEntityAttachment = (entityID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      attachmentID,
      signal,
    }: {
      attachmentID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/download?attachment_id=${attachmentID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-entity-attachments"]),
    }
  );

export const GetEntityAttachments = (entityID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-entity-attachments", entityID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${entityPrefix}/${entityID}/listdocuments`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: entityID !== "",
      keepPreviousData: false,
    }
  );
