import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const SectionTabs = ({
  documentType,
  selectedTab,
  filter,
  setFilter,
  setAddSectionsToPolicy,
  setSelectedAddedSections,
}: {
  documentType: string;
  selectedTab: string;
  filter: string;
  setFilter: (filter: string) => void;
  setAddSectionsToPolicy?: any;
  setSelectedAddedSections?: any;
}) => {
  const isPolicy = documentType === "policies";

  return (
    <FilterLayout label="Quick Filter">
      <ul className="flex flex-col flex-grow gap-1">
        {[
          "All",
          "Mapped To Policy",
          "Relevant Sections",
          `Overlaps With Framework`,
          "Only Mapping",
          `No Mapping${isPolicy ? "" : " with Policy"}`,
          "Suggested Content",
        ].map((option) => {
          if (
            (selectedTab === "Sections" && option === "Relevant Sections") ||
            (!isPolicy &&
              ["Only Mapping", "Suggested Content"].includes(option)) ||
            (isPolicy &&
              [
                "Mapped To Policy",
                "Relevant Sections",
                "Overlaps With Framework",
              ].includes(option))
          )
            return null;
          return (
            <li key={option} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={filter === option}
                onClick={() => {
                  let filteredOption = "";
                  if (option === "Suggested Content") {
                    filteredOption = "Suggest New Mapping";
                    setFilter("Suggest New Mapping");
                  } else {
                    filteredOption = option;
                    setFilter(option);
                  }
                  sessionStorage.section_tab = filteredOption;
                  if (setAddSectionsToPolicy && setSelectedAddedSections) {
                    setAddSectionsToPolicy(false);
                    setSelectedAddedSections([]);
                  }
                }}
              />
              <label htmlFor="">{option}</label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default SectionTabs;
