import React, { useState } from "react";
import { sortNumericData } from "src/utils/general";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";
import RedliningDetail from "./RedliningDetail";
import Sort from "src/components/GRC/Sort";
import { redliningSortingTypes } from "../../../../constants/grc";
import { KeyStringVal } from "src/types/general";
import { GetRedliningList } from "../../../../services/grc";
import Loader from "src/components/Loader/Loader";
import StarterKit from "@tiptap/starter-kit";
import { Markdown } from "tiptap-markdown";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import TextStyle from "@tiptap/extension-text-style";
import { Color } from "@tiptap/extension-color";
import Link from "@tiptap/extension-link";
import { Editor } from "@tiptap/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import Collabora from "./Collabora/Collabora";
import MessageLayout from "src/layouts/MessageLayout";

const RedliningList = ({ documentID }: { documentID: string }) => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [query, setQuery] = useState<string>("");
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "asc",
    order_by: "created_at",
  });
  const [selectedHighlight, setSelectedHighlight] = useState("");

  const { data: redliningList, status: redliningListStatus } =
    GetRedliningList(documentID);

  const filteredHighlights = redliningList?.redlines.filter((redline: any) =>
    `${redline.old_content.text} ${redline.instructions}`
      ?.toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );
  const sortedHighlights = sortNumericData(
    filteredHighlights,
    sort.order_by,
    sort.direction
  );
  const totalCount = filteredHighlights?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const extensions = [
    StarterKit,
    Markdown,
    Document,
    Paragraph,
    Text,
    TextStyle,
    Color,
    Link.configure({
      openOnClick: false,
      autolink: true,
      defaultProtocol: "https",
      HTMLAttributes: {
        class: "decoration-red-500 line-through",
      },
    }).extend({
      inclusive: false,
    }),
  ];

  const editor = new Editor({
    content: redliningList?.markdown_content,
    extensions: extensions,
  });

  return (
    <section className="relative">
      {redliningListStatus === "loading" ? (
        <Loader />
      ) : (
        <section className="flex flex-col flex-grow gap-4">
          <MessageLayout type="info">
            Select text and click{" "}
            <img
              src="/general/logos/unoaired.svg"
              alt="uno-logo"
              className="w-4 h-4"
            />{" "}
            to start redlining
          </MessageLayout>
          <header className="flex items-start justify-between gap-10"></header>
          <section className="grid grid-cols-10 gap-4 pb-24 w-full h-screen">
            <Collabora documentID={documentID} />
            <aside className="col-span-3 flex flex-col flex-grow gap-4 p-6 min-h-full dark:text-white dark:bg-gray-800 overflow-auto scrollbar rounded-xl">
              <header className="flex items-center gap-2 w-full">
                <article className="flex items-center gap-2 px-3 w-full border dark:border-gray-700 rounded">
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className="dark:text-gray-500"
                  />
                  <input
                    type="input"
                    placeholder="Search"
                    value={query}
                    onChange={(e) => {
                      setQuery(e.target.value);
                      setPageNumber(1);
                    }}
                    className="pr-4 py-1 w-full h-7 text-b1-reg placeholder:text-gray-500 dark:bg-transparent focus:outline-none rounded"
                  />
                </article>
                <Sort
                  sortingTypes={redliningSortingTypes}
                  sort={sort}
                  setSort={setSort}
                  showOnLeft
                />
              </header>
              <ul className="flex flex-col flex-grow gap-4 overflow-x-hidden overflow-y-auto scrollbar">
                {sortedHighlights
                  ?.slice(beginning - 1, end + 1)
                  .map((redline: any, index: number) => {
                    return (
                      <RedliningDetail
                        key={index}
                        documentID={documentID}
                        redline={redline}
                        editor={editor}
                        selectedHighlight={selectedHighlight}
                        setSelectedHighlight={setSelectedHighlight}
                      />
                    );
                  })}
              </ul>
              <TablePagination
                totalPages={totalPages}
                beginning={beginning}
                end={end}
                totalCount={totalCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </aside>
          </section>
        </section>
      )}
    </section>
  );
};

export default RedliningList;
