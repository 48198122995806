import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GeneralTemporalDatepicker from "../../../../components/Datepicker/GeneralTemporalDatepicker";
import { AddAssessmentCycle } from "../../../../services/third-party-risk/assessments/assessment-cycles";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";
import RegularInput from "src/components/Input/RegularInput";
import { GetVendorGroups } from "../../../../services/third-party-risk/vendors/vendor-groups";
import ToastLayout from "../../../../layouts/ToastLayout";
import CategoryInput from "../../../../components/Input/CategoryInput";
import { checkIsReadOnly, checkIsSuperAdmin } from "src/utils/general";
import { KeyStringVal } from "src/types/general";

const NewAssessment = () => {
  const isReadOnly = checkIsReadOnly();
  const isSuperAdmin = checkIsSuperAdmin();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const { data: allVendorGroups } = GetVendorGroups();
  const { data: questionSets } = GetCustomQuestionSets();

  const addAssessmentCycle = AddAssessmentCycle();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAssessmentCycle.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            title: "",
            purpose: "Annual Vendor Risk Assessment",
            other: false,
            start_date: Date.now() * 1000,
            end_date: Date.now() * 1000,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 mb-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add New Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <GeneralTemporalDatepicker
            startKeyName="start_date"
            endKeyName="end_date"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <section className="grid gap-4 p-4 border dark:border-gray-700 rounded-sm">
            <h4 className="text-b1-semi">
              Purpose <span className="text-h5 text-red-500">*</span>
            </h4>
            <article className="grid content-start text-b2-reg">
              <article className="flex items-start gap-2">
                <input
                  type="radio"
                  checked={inputs.purpose === "Annual Vendor Risk Assessment"}
                  className="blue-radio"
                  onChange={() => {
                    setInputs({
                      ...inputs,
                      purpose: "Annual Vendor Risk Assessment",
                      other: false,
                    });
                  }}
                />
                <label>Annual Vendor Risk Assessment</label>
              </article>
              <article className="flex items-start gap-2">
                <input
                  type="radio"
                  checked={inputs.other}
                  className="blue-radio"
                  onChange={() =>
                    setInputs({
                      ...inputs,
                      other: true,
                      purpose: "",
                    })
                  }
                />
                <article className="grid gap-1 ww-full">
                  <label>Other</label>
                  {inputs.other && (
                    <textarea
                      value={inputs.purpose}
                      onChange={(e) =>
                        setInputs({ ...inputs, purpose: e.target.value })
                      }
                      className="text-b2-reg dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                    />
                  )}
                </article>
              </article>
            </article>
          </section>
          <section className="grid gap-4 text-b1-reg p-4 border dark:border-gray-700 rounded-sm">
            <h4 className="text-b1-semi">
              Vendor Groups <span className="text-h5 text-red-500">*</span>{" "}
            </h4>
            <article className="grid md:grid-cols-2 gap-4">
              {allVendorGroups?.data.map((vendorGroup: any) => {
                const questionSetNames = [
                  ...new Set(
                    questionSets?.data?.reduce(
                      (pV: string[], cV: KeyStringVal) => {
                        if (!isSuperAdmin) {
                          if (!cV.global_questionset && cV.state !== "DRAFT")
                            return [...pV, cV.name];
                          else return [...pV];
                        } else {
                          if (cV.state !== "DRAFT") return [...pV, cV.name];
                          return [...pV];
                        }
                      },
                      []
                    )
                  ),
                ] as string[];
                return (
                  <CategoryInput
                    label={vendorGroup.name}
                    keyName={vendorGroup.generated_id}
                    list={questionSetNames}
                    inputs={inputs}
                    setInputs={setInputs}
                    allowUnselect
                  />
                );
              })}
            </article>
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.title === "" ||
                Object.keys(inputs).length < 4 ||
                inputs.start_date === inputs.end_date
              }
              className="blue-button"
              onClick={() => {
                const vendorGroups = { ...inputs };
                delete vendorGroups.title;
                delete vendorGroups.purpose;
                delete vendorGroups.other;
                Object.entries(vendorGroups).forEach((keyVal) => {
                  const questionSetID = questionSets?.data.find(
                    (questionSet: KeyStringVal) =>
                      questionSet.name === keyVal[1]
                  )?.generated_id;
                  vendorGroups[keyVal[0]] = questionSetID;
                });

                addAssessmentCycle.mutate({
                  inputs: {
                    ...inputs,
                    vendor_groups: vendorGroups,
                  },
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAssessmentCycle.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New assessment has been created"
      />
    </>
  );
};

export default NewAssessment;
