/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { checkIsReadOnly } from "src/utils/general";
import CategoryInput from "src/components/Input/CategoryInput";
import { EditException } from "src/services/erc/exceptions/exceptions";
import { Disclosure } from "@headlessui/react";
import GeneralTemporalDatepicker from "src/components/Datepicker/GeneralTemporalDatepicker";
import MultiUserInput from "src/components/Input/MultiUserInput";
import { KeyStringVal } from "src/types/general";

const UpdateException = ({
  exceptionID,
  exception,
}: {
  exceptionID: string;
  exception: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const editException = EditException(exceptionID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="edit-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs({
            ...exception,
            owners:
              exception.owners?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
            reviewers:
              exception.reviewers?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
            approvers:
              exception.approvers?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.user_id],
                []
              ) || [],
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Exception
          </h3>
          <section className="grid grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Serial Number"
              keyName="serial_number"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <GeneralTemporalDatepicker
            label="Valid"
            startKeyName="valid_from"
            endKeyName="valid_to"
            inputs={inputs}
            setInputs={setInputs}
          />
          <section className="grid grid-cols-2 gap-4">
            <CategoryInput
              label="State"
              keyName="state"
              list={["IDENTIFIED", "IN_EVALUATION", "IN_REVIEW", "CLOSED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Priority"
              keyName="priority"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Notes"
              keyName="notes"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
            <RegularInput
              label="Justification"
              keyName="justification"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
            <CategoryInput
              label="Reason"
              keyName="reason"
              list={["Fix Unavailable", "Awaiting Maintenance Window"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">Risk Info</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CategoryInput
                      label="Risk Impact Level"
                      keyName="exception_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Risk Likelihood Level"
                      keyName="exception_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <RegularInput
                      label="Description"
                      keyName="exception_description"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <RegularInput
                      label="Impact Analysis"
                      keyName="exception_impact_analysis"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <RegularInput
                      label="Mitigation Plan"
                      keyName="exception_mitigation_plan"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <MultiUserInput
                      label="Owners"
                      keyName="owners"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editException.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateException;
