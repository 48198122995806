import React from "react";
import OverallStats from "./OverallStats";
import VendorRisksByState from "./VendorRisksByState";
import VendorByType from "./VendorByType";
import InherentRiskDistribution from "./InherentRiskDistribution";
import VendorByTier from "./VendorByTier";
import OverallRiskScore from "./OverallRiskScore";

const VendorDashboard = () => {
  return (
    <section className="grid grid-cols-2 gap-5 pb-24">
      <OverallStats />
      <InherentRiskDistribution />
      <VendorRisksByState />
      <VendorByType />
      <VendorByTier />
      <OverallRiskScore />
    </section>
  );
};

export default VendorDashboard;
