import React from "react";
import { GetControlFilters } from "../../../services/third-party-risk/assessments/vendor-assessment";
import DropdownLayout from "src/layouts/DropdownLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const ControlFilters = ({
  reviewID,
  controls,
  inputs,
  setInputs,
}: {
  reviewID: string;
  controls: any;
  inputs: any;
  setInputs: any;
}) => {
  const { data: controlFilters } = GetControlFilters(reviewID);

  return (
    <>
      <ul className="flex flex-wrap items-center gap-10">
        {controlFilters &&
          Object.keys(controlFilters).map((keyName: string) => {
            if (controlFilters[keyName]?.length === 0) return null;

            return (
              <li key={keyName}>
                <DropdownLayout
                  label={keyName.replaceAll("_", " ")}
                  value={`${
                    inputs[keyName]?.length > 0
                      ? `${inputs[keyName]?.length} selected`
                      : ""
                  }`}
                  placeholder="Select"
                  width="w-[20rem]"
                >
                  {controlFilters[keyName]?.map(
                    (curVal: string, index: number) => {
                      const selected = inputs[keyName].includes(curVal);
                      return (
                        <button
                          key={index}
                          className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-800/70 duration-100"
                          onClick={() => {
                            if (selected)
                              setInputs({
                                ...inputs,
                                [keyName]: inputs[keyName].filter(
                                  (val) => val !== curVal
                                ),
                              });
                            else
                              setInputs({
                                ...inputs,
                                [keyName]: [...inputs[keyName], curVal],
                              });
                          }}
                        >
                          {selected && <FontAwesomeIcon icon={faCheck} />}
                          <p>{curVal}</p>
                        </button>
                      );
                    }
                  )}
                </DropdownLayout>
              </li>
            );
          })}
      </ul>
    </>
  );
};

export default ControlFilters;
