import React, { useState } from "react";
import StackedBarChart from "src/components/Chart/StackedBarChart";
import { GetControlIssuesByMonth } from "src/services/erc/controls/control-dashboard";

const ControlIssuesByMonth = () => {
  const [sectionProps, setSectionProps] = useState({});

  const { data } = GetControlIssuesByMonth();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <StackedBarChart
        title="Control Issues by Month"
        data={data}
        xKey="name"
        xLabel="Month"
        hideLegend
        sectionProps={sectionProps}
        setSectionProps={setSectionProps}
      />
    </section>
  );
};

export default ControlIssuesByMonth;
