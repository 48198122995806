/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useEffect, useRef, useState } from "react";
import OTPInput from "../Login/TwoFA/OTPInput";
import SessionLayout from "../../layouts/SessionLayout";
import {
  convertToUTCShortString,
  convertToUTCString,
  parseURL,
  removeDuplicateFiles,
} from "../../utils/general";
import React from "react";
import TablePagination from "../../components/General/TablePagination";
import { pageSize } from "../../constants/general";
import jwt_decode from "jwt-decode";
import AttachEvidence from "./AttachEvidence";
import {
  SubmitQuestions,
  SaveDrafts,
  UploadAdditionalDocs,
  GetEntityQuestions,
  RetrieveJWTForEntity,
} from "src/services/entity-webform";
import AllTags from "./AllTags";
import { faCheck, faLink, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import { KeyStringVal } from "src/types/general";
import UploadAdditionalDocuments from "./UploadAdditionalDocuments";

const EntityWebform = () => {
  const parsed = parseURL();
  const entityID = String(parsed.entity_id);
  const assessmentID = String(parsed.assessment_id);
  const entityJwt = sessionStorage.entityJwt
    ? (jwt_decode(sessionStorage.entityJwt) as any)
    : "";

  const inputRef = useRef([]) as MutableRefObject<HTMLInputElement[]>;

  const [otpCode, setOTPCode] = useState<string>("");
  const [nav, setNav] = useState<number>(1);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [responses, setResponses] = useState({});
  const [additionalDocs, setAdditionalDocs] = useState<any>({
    files: [],
    document_types: [],
    frameworks: [],
  });
  const [evidences, setEvidences] = useState<any>({
    files: [],
    evidence_file: {},
  });
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [saved, setSaved] = useState<boolean>(false);

  const retrieveJWT = RetrieveJWTForEntity(entityID, assessmentID);
  const { data: questions, status: questionStatus } = GetEntityQuestions(
    assessmentID,
    entityJwt,
    selectedTags
  );
  const submitResponses = SubmitQuestions(assessmentID);
  const saveDrafts = SaveDrafts(assessmentID);
  const uploadDocuments = UploadAdditionalDocs(assessmentID);

  const totalCount = questions?.pager?.total_results || 0;
  const totalPages = questions?.pager?.num_pages || 0;
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const numRequiredEvidenceAttached =
    questions?.data.filter(
      (qa: any) =>
        qa.attachment_mandatory &&
        evidences.evidence_file[qa.question_id]?.length > 0
    )?.length || 0;
  const numRequiredQuestionsFilledOut =
    questions?.data.filter(
      (qa: any) =>
        qa.question_mandatory &&
        ((qa.question_type === "Text" && responses[qa.question_id]?.response) ||
          (qa.question_type !== "Text" &&
            responses[qa.question_id]?.choice?.length > 0))
    )?.length || 0;
  const numQuestionsFilledOut =
    questions?.data.filter(
      (qa: any) =>
        (qa.question_type === "Text" && responses[qa.question_id]?.response) ||
        (qa.question_type !== "Text" &&
          responses[qa.question_id]?.choice?.length > 0)
    )?.length || 0;

  useEffect(() => {
    if (otpCode.length === 6) {
      const formData = new FormData();
      formData.append("hotp_code", String(otpCode));
      retrieveJWT.mutate(
        {
          formData,
        },
        {
          onSuccess: (data) => {
            if (data) {
              setNav(2);
              sessionStorage.entityJwt = data;
            } else {
              setOTPCode("");
              if (inputRef?.current) inputRef?.current[0]?.focus();
            }
          },
        }
      );
    }
  }, [otpCode]);

  useEffect(() => {
    if (questions?.data?.length > 0) {
      let updatedResponses = responses;
      let updatedEvidences = evidences;
      questions?.data.forEach((qa: any) => {
        updatedResponses = {
          ...updatedResponses,
          ...(!(qa.question_id in updatedResponses) && {
            [qa.question_id]: {
              ...qa,
              response: qa.answer,
              choice: qa.multi_answers || [],
            },
          }),
        };
        const fileNames = qa.attachment_evidences?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.filename],
          []
        );
        let fileObjects = [] as any;
        qa.attachment_evidences?.forEach((evidence: KeyStringVal) => {
          fileObjects = [
            ...fileObjects,
            new File([evidence.filename], evidence.filename),
          ];
        });
        const uniqueFileNames = [
          ...new Set([
            ...(updatedEvidences.evidence_file[qa.question_id] || []),
            ...fileNames,
          ]),
        ];
        updatedEvidences = {
          files: removeDuplicateFiles([
            ...updatedEvidences.files,
            ...fileObjects,
          ]),
          evidence_file: {
            ...updatedEvidences.evidence_file,
            ...(uniqueFileNames?.length > 0 && {
              [qa.question_id]: uniqueFileNames,
            }),
          },
        };
      });
      setResponses(updatedResponses);
      setEvidences(updatedEvidences);
    }
  }, [questions, pageNumber]);

  useEffect(() => {
    setPageNumber(1);
  }, [selectedTags]);

  return (
    <SessionLayout>
      <h4 className="text-t1-semi">Entity Assessment</h4>

      {/* if current time passes the expiration time  */}
      {entityJwt?.scope?.expired ||
      Date.now() * 1000 > entityJwt?.scope?.end_date ? (
        <section className="grid py-10 gap-3 text-center dark:bg-gray-900">
          <p>The link has expired......</p>
          <p>Check with your customer to send a new email.</p>
        </section>
      ) : nav === 1 ? (
        <section className="grid content-start gap-4 p-10 dark:bg-gray-900">
          <p className="text-xl tracking wide">Enter OTP</p>

          {retrieveJWT.status === "success" && !retrieveJWT.data && (
            <p className="p-2 mx-auto w-max text-b2-reg text-left uppercase dark:bg-inner border dark:border-error rounded-sm">
              OTP code is not correct. Please try again.
            </p>
          )}

          <OTPInput
            otpCode={otpCode}
            setOTPCode={setOTPCode}
            codeLength={6}
            inputRef={inputRef}
          />
        </section>
      ) : nav === 2 ? (
        <section className="flex flex-col flex-grow gap-4">
          {entityJwt && entityJwt.scope && (
            <header className="flex flex-col flex-grow gap-4 p-5 text-b1-reg text-left w-full dark:bg-gray-800 rounded">
              <article className="grid gap-2">
                <h4 className="text-t1-reg">Assesssment</h4>
                <article className="flex flex-wrap items-center justify-between gap-20 pb-2">
                  <h2 className="text-t1-reg">
                    {entityJwt.scope.assessment_name}
                  </h2>
                  <article className="flex flex-wrap items-center gap-3">
                    {convertToUTCShortString(entityJwt.scope.start_date)} -{" "}
                    {convertToUTCShortString(entityJwt.scope.end_date)}
                  </article>
                </article>
              </article>
              <article className="grid gap-2">
                <h4 className="text-t1-reg">Entity</h4>
                <span>{entityJwt.scope.object_name}</span>
              </article>
            </header>
          )}
          <h4>Entity Questions</h4>
          <span className="text-b3-reg">
            Please fill out the response for each question
          </span>
          <UploadAdditionalDocuments
            inputs={additionalDocs}
            setInputs={setAdditionalDocs}
          />
          <article className="fixed bottom-10 left-10 flex flex-col gap-4 text-subt1-semi z-10">
            <AllTags
              assessmentID={assessmentID}
              selectedTags={selectedTags}
              setSelectedTags={setSelectedTags}
            />
            {questions?.metadata?.mandatory_attachments > 0 && (
              <p className="flex flex-col gap-1">
                {numRequiredEvidenceAttached} /{" "}
                {questions?.metadata?.mandatory_attachments}{" "}
                <span className="text-b1-semi">
                  required attachments responded
                </span>
              </p>
            )}
            {questions?.metadata?.mandatory_questions > 0 && (
              <p className="flex flex-col gap-1">
                {numRequiredQuestionsFilledOut} /{" "}
                {questions?.metadata?.mandatory_questions}{" "}
                <span className="text-b1-semi">
                  required questions uploaded
                </span>
              </p>
            )}
            <p className="flex flex-col gap-1">
              {numQuestionsFilledOut} / {totalCount}{" "}
              <span className="text-b1-semi">questions responded</span>
            </p>
          </article>
          <ul className="flex flex-col flex-grow gap-4">
            {questions?.data?.map((qa: any) => {
              const questionID = qa.question_id;
              return (
                <li
                  key={questionID}
                  className="grid gap-2 p-5 w-full dark:bg-gray-800 rounded"
                >
                  {qa.tags?.length > 0 && (
                    <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
                      <span>Tags</span>
                      {qa.tags.map((tag: string, index: number) => {
                        return (
                          <span key={index} className="dark:text-blue-500">
                            {tag}
                          </span>
                        );
                      })}
                    </article>
                  )}
                  <p className="text-left">
                    {" "}
                    {qa.question_mandatory && (
                      <span className="text-b1-reg text-red-500">*</span>
                    )}{" "}
                    Q: {qa.question}
                  </p>
                  {["Yes_No", "Multiple Choice", "Multiple Select"].includes(
                    qa.question_type
                  ) ? (
                    <section className="grid gap-1 w-full max-h-[30rem] overflow-auto scrollbar">
                      {qa.multi_choices?.map((choice: KeyStringVal) => {
                        const filteredAnswers = responses[questionID]?.choice;
                        const selected = filteredAnswers?.some(
                          (choiceID: string) =>
                            choiceID === choice.multi_choice_id
                        );
                        return (
                          <article
                            key={choice.choice}
                            className="flex items-start gap-1"
                          >
                            {qa.question_type === "Multiple Select" ? (
                              <input
                                type="checkbox"
                                className="white-checkbox"
                                checked={selected}
                                onChange={() => {
                                  if (selected)
                                    setResponses({
                                      ...responses,
                                      [questionID]: {
                                        ...responses[questionID],
                                        choice: filteredAnswers?.filter(
                                          (choiceID: string) =>
                                            choiceID !== choice.multi_choice_id
                                        ),
                                      },
                                    });
                                  else
                                    setResponses({
                                      ...responses,
                                      [questionID]: {
                                        ...responses[questionID],
                                        choice: [
                                          ...filteredAnswers,
                                          choice.multi_choice_id,
                                        ],
                                      },
                                    });
                                }}
                              />
                            ) : (
                              <input
                                type="radio"
                                className="blue-radio"
                                checked={selected}
                                onChange={() =>
                                  setResponses({
                                    ...responses,
                                    [questionID]: {
                                      ...responses[questionID],
                                      choice: [choice.multi_choice_id],
                                    },
                                  })
                                }
                              />
                            )}
                            <label htmlFor="">{choice.choice}</label>
                          </article>
                        );
                      })}
                    </section>
                  ) : null}
                  <article className="flex items-start gap-2 w-full">
                    A:{" "}
                    <textarea
                      value={responses[questionID]?.response}
                      onChange={(e) =>
                        setResponses({
                          ...responses,
                          [questionID]: {
                            ...responses[questionID],
                            response: e.target.value,
                          },
                        })
                      }
                      className="px-4 py-2 w-full h-32 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                    />
                  </article>
                  <section className="flex flex-wrap items-center gap-3">
                    {evidences.evidence_file[questionID]?.length > 0 && (
                      <article className="flex flex-wrap flex-grow items-center gap-4 p-4 text-b2-reg dark:bg-gray-800 rounded-lg">
                        <h4 className="text-b1-semi">Evidences</h4>
                        <ul className="flex flex-wrap gap-2">
                          {evidences.evidence_file[questionID].map(
                            (fileName: string, idx: number) => {
                              return (
                                <li
                                  key={idx}
                                  className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 rounded"
                                >
                                  <FontAwesomeIcon
                                    icon={faLink}
                                    className="dark:text-blue-500"
                                  />
                                  <h4>{fileName}</h4>
                                  <FontAwesomeIcon
                                    icon={faXmark}
                                    className="hidden group-hover:block cursor-pointer red-button"
                                    onClick={(e) =>
                                      setEvidences({
                                        files: [
                                          ...evidences.files.filter(
                                            (_: any, fileIndex: number) =>
                                              fileIndex !== idx
                                          ),
                                        ],
                                        evidence_file: {
                                          ...evidences.evidence_file,
                                          [questionID]: evidences.evidence_file[
                                            questionID
                                          ].filter(
                                            (curFileName: string) =>
                                              fileName !== curFileName
                                          ),
                                        },
                                      })
                                    }
                                  />
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </article>
                    )}
                    <AttachEvidence
                      questionID={questionID}
                      inputs={evidences}
                      setInputs={setEvidences}
                      attachmentMandatory={qa.attachment_mandatory}
                    />
                  </section>
                </li>
              );
            })}
          </ul>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
          {saved && (
            <motion.article
              initial={{ y: 3, opacity: 0 }}
              animate={{
                y: 0,
                opacity: 1,
                transition: { duration: 0.3 },
              }}
              className="flex items-center gap-2 text-b2-reg"
            >
              <FontAwesomeIcon
                icon={faCheck}
                className="w-3 h-3 dark:text-contact"
              />
              Successfully saved
            </motion.article>
          )}
          <article className="flex items-center place-self-center gap-4">
            {Object.entries(responses).some(
              (keyVal: any) =>
                keyVal[1]?.response || keyVal[1]?.choice?.length > 0
            ) && (
              <button
                disabled={saveDrafts.status === "loading"}
                className="place-self-end blue-button"
                onClick={() => {
                  const formData = new FormData();

                  if (evidences.files.length > 0)
                    evidences.files.forEach((file: any) =>
                      formData.append("files", file)
                    );
                  formData.append(
                    "evidence_metadata",
                    JSON.stringify(evidences.evidence_file)
                  );
                  formData.append("questions", JSON.stringify(responses));

                  saveDrafts.mutate(
                    {
                      formData,
                    },
                    {
                      onSuccess: () => {
                        setSaved(true);
                        setTimeout(() => {
                          setSaved(false);
                        }, 5000);
                      },
                    }
                  );
                }}
              >
                Save Draft
              </button>
            )}
            <button
              disabled={
                questionStatus === "loading" ||
                submitResponses.status === "loading" ||
                numRequiredEvidenceAttached <
                  questions?.metadata?.mandatory_attachments ||
                numRequiredQuestionsFilledOut <
                  questions?.metadata?.mandatory_questions ||
                selectedTags.length > 0
              }
              className="blue-button"
              onClick={() => {
                const additionalFormData = new FormData();

                if (additionalDocs.files.length > 0)
                  additionalDocs.files.forEach((file: any) =>
                    additionalFormData.append("files", file)
                  );
                if (additionalDocs.document_types.length > 0)
                  additionalDocs.document_types.forEach((documentType: any) =>
                    additionalFormData.append("document_types", documentType)
                  );
                if (additionalDocs.frameworks.length > 0)
                  additionalDocs.frameworks.forEach((framework: any) =>
                    additionalFormData.append("frameworks", framework)
                  );

                if (additionalDocs.files.length > 0)
                  uploadDocuments.mutate({
                    formData: additionalFormData,
                  });

                const formData = new FormData();

                if (evidences.files.length > 0)
                  evidences.files.forEach((file: any) =>
                    formData.append("files", file)
                  );
                formData.append(
                  "evidence_metadata",
                  JSON.stringify(evidences.evidence_file)
                );
                formData.append("questions", JSON.stringify(responses));

                submitResponses.mutate(
                  {
                    formData,
                  },
                  {
                    onSuccess: () => setNav(3),
                  }
                );
              }}
            >
              Submit
            </button>
          </article>
        </section>
      ) : nav === 3 ? (
        <section className="grid content-start p-10 gap-4 w-full h-full text-b2-reg text-center dark:bg-gray-800">
          <h4 className="text-b1-reg">{entityJwt?.scope?.object_name}</h4>
          <p>
            You have successfully submitted the entity assesssment! You can
            close this browser now.
          </p>
          <span className="text-b3-reg">
            Submitted at: {convertToUTCString(Date.now() * 1000)}
          </span>
        </section>
      ) : null}
    </SessionLayout>
  );
};

export default EntityWebform;
