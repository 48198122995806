import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { attributeColors, pageSize } from "src/constants/general";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";
import DeleteCustomQuestionSet from "./DeleteCustomQuestionSet";
import CustomQuestionSetDetail from "./CustomQuestionSetDetail";
import AddToVendorGroups from "./AddToVendorGroups";
import TableLayout from "src/layouts/TableLayout";
import { handleSort, sortRows } from "src/utils/general";
import { Sort } from "src/types/dashboard";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CustomQuestionSets = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedQuestionSet, setSelectedQuestionSet] = useState({});
  const [sort, setSort] = useState<Sort>({
    order: "asc",
    orderBy: "question set",
  });

  const { data: questionSets, status: questionSetStatus } =
    GetCustomQuestionSets(pageNumber);

  const sortedRows = questionSets && sortRows(questionSets?.data, sort);
  const totalCount = questionSets?.pager?.total_results || 0;
  const totalPages = questionSets?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;
  const filteredHeaders = ["question set", "vendor groups", "global"];

  return (
    <>
      {!sessionStorage.custom_question_set_id ? (
        <section className="flex flex-col flex-grow gap-4">
          {questionSetStatus === "loading" ? (
            <Loader />
          ) : questionSets?.data.length > 0 ? (
            <section className="flex flex-col flex-grow gap-4 pb-24">
              <TableLayout fullHeight>
                <thead className="sticky -top-1.5 dark:bg-gray-900">
                  <tr>
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      return (
                        <th
                          scope="col"
                          key={colIndex}
                          className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                        >
                          <article className="capitalize flex gap-10 justify-between">
                            <article className="flex items-center gap-4">
                              <h4 className="break-words">{col}</h4>
                            </article>
                            <button aria-label="sort">
                              <FontAwesomeIcon
                                icon={faSort}
                                className="mt-0.5 dark:text-gray-700"
                                onClick={() => handleSort(col, setSort)}
                              />
                            </button>
                          </article>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {sortedRows?.map((row: any, index: number) => {
                    return (
                      <tr
                        key={index}
                        data-test="table-row"
                        className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                        onClick={() => {
                          setSelectedQuestionSet(row);
                          sessionStorage.custom_question_set_id =
                            row.generated_id;
                        }}
                      >
                        {filteredHeaders?.map(
                          (col: string, colIndex: number) => {
                            return (
                              <td
                                key={`${index}-${colIndex}`}
                                className="relative px-6 py-3 last:pr-16 text-left break-words"
                              >
                                {col === "vendor groups" ? (
                                  <AddToVendorGroups
                                    questionSetID={row.generated_id}
                                  />
                                ) : col === "global" ? (
                                  <p
                                    className={`text-left ${
                                      attributeColors[
                                        String(
                                          row.global_questionset
                                        )?.toLowerCase()
                                      ]
                                    }`}
                                  >
                                    {String(row.global_questionset)}{" "}
                                  </p>
                                ) : (
                                  <p>{row.name}</p>
                                )}
                                {colIndex === filteredHeaders?.length - 1 && (
                                  <DeleteCustomQuestionSet
                                    questionSetID={row.generated_id}
                                  />
                                )}
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </TableLayout>
              <TablePagination
                totalPages={totalPages}
                beginning={beginning}
                end={end}
                totalCount={totalCount}
                pageNumber={pageNumber}
                setPageNumber={setPageNumber}
              />
            </section>
          ) : (
            <section className="flex items-center place-content-center gap-10 w-full h-full">
              <img
                src="/grc/third-party-risk-placeholder.svg"
                alt="question sets placeholder"
                className="w-40 h-40"
              />
              <article className="grid gap-3">
                <h4 className="text-t1-bold">Question Sets</h4>
                <h4>No question sets available</h4>
              </article>
            </section>
          )}
        </section>
      ) : (
        <CustomQuestionSetDetail
          selectedQuestionSet={selectedQuestionSet}
          setSelectedQuestionSet={setSelectedQuestionSet}
        />
      )}
    </>
  );
};

export default CustomQuestionSets;
