/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  Legend,
  Tooltip,
} from "recharts";
import {
  chartDataColors,
  severities,
  severityChartColors,
} from "src/constants/general";
import CustomLegend from "./CustomLegend";
import CustomTooltip from "./Tooltip/CustomTooltip";

const HorizontalStackedBarChart = ({
  data,
  title,
  xKey,
  hideLegend,
  children,
  hasSeverity,
  sectionProps,
  setSectionProps,
}: {
  data: any;
  title?: string;
  xKey: string;
  hideLegend?: boolean;
  children?: any;
  hasSeverity?: boolean;
  sectionProps: any;
  setSectionProps: (sectionProps: any) => void;
}) => {
  const keys = hasSeverity
    ? severities
    : data?.length > 0
    ? (Object.keys(data[0]).filter((k) => k !== xKey) as string[])
    : [];

  useEffect(() => {
    setSectionProps(
      keys.reduce(
        (a: any, cV: string) => {
          a[cV] = false;
          return a;
        },
        { hover: null }
      )
    );
  }, []);

  return (
    <>
      {data ? (
        data.length > 0 ? (
          <section className="grid grid-cols-1 gap-4 w-full h-[20rem]">
            <h4 className="text-center text-subt1-semi">{title}</h4>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart
                data={data}
                layout="vertical"
                style={{ cursor: "pointer" }}
                margin={{
                  top: 0,
                  right: 20,
                  left: 60,
                  bottom: 0,
                }}
              >
                <XAxis
                  type="number"
                  tick={{ fill: "white" }}
                  tickLine={{ stroke: "white" }}
                  style={{
                    fontSize: "1rem",
                    width: "1rem",
                  }}
                />
                <YAxis
                  type="category"
                  dataKey={xKey}
                  tickFormatter={(v) => {
                    return `${v.slice(0, 10)}${v.length > 10 ? "..." : ""}`;
                  }}
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: "white" }}
                  style={{
                    fontSize: "1rem",
                    width: "1rem",
                  }}
                />
                {!hideLegend && (
                  <Legend
                    content={
                      <CustomLegend
                        keys={keys}
                        hasSeverity={hasSeverity}
                        sectionProps={sectionProps}
                        setSectionProps={setSectionProps}
                      />
                    }
                    verticalAlign="top"
                    height={50}
                  />
                )}

                {keys.map((key: string, index: number) => {
                  const filteredKey = key.toLowerCase();
                  const color =
                    severityChartColors[filteredKey] ||
                    chartDataColors[index % 20];
                  return (
                    <Bar
                      key={key}
                      dataKey={key}
                      stackId="a"
                      fill={color}
                      hide={sectionProps[key] === true}
                      fillOpacity={Number(
                        sectionProps.hover === key || !sectionProps.hover
                          ? 1
                          : 0.6
                      )}
                    />
                  );
                })}

                {children ? (
                  children
                ) : (
                  <Tooltip
                    cursor={{ fill: "#23394F", fillOpacity: 0.4 }}
                    content={
                      <CustomTooltip
                        keys={keys}
                        xKey={xKey}
                        hasSeverity={hasSeverity}
                      />
                    }
                  />
                )}
              </BarChart>
            </ResponsiveContainer>
          </section>
        ) : (
          <p className="text-b1-reg">No data available</p>
        )
      ) : null}
    </>
  );
};

export default HorizontalStackedBarChart;
