import {
  faChartBar,
  faCheck,
  faChevronRight,
  faComment,
  faExclamationTriangle,
  faEyeSlash,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import EvidenceList from "./EvidenceList";
import { handleClickMapping } from "src/utils/grc";
import ControlContent from "./ControlContent";
import { AnimatePresence } from "framer-motion";
import DiscussionNotes from "../../../AgreementProposalReviews/AgreementProposalReview/AgreementReview/DiscussionNotes/DiscussionNotes";
import { useGRCStore } from "src/stores/grc";
import { GetGRCDiscussionCount } from "../../../../services/grc";
import Analysis from "./Analysis";
import {
  GetAnalysisStatus,
  GetEvidenceReport,
  StartEvidenceAnalysis,
  UploadEvidenceReport,
} from "src/services/audit-management";
import { ColorRing } from "react-loader-spinner";
import MappedPolicySections from "./MappedPolicySections";
import { checkIsReadOnly } from "src/utils/general";
import RequestEvidence from "./RequestEvidence";
import { KeyStringVal } from "src/types/general";

const Control = ({
  documentType,
  documentID,
  documentName,
  auditID,
  control,
}: {
  documentType: string;
  documentID: string;
  documentName: string;
  auditID: string;
  control: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const {
    showGRCPanel,
    selectedAnchorID,
    setShowGRCPanel,
    setSelectedAnchorID,
    setSelectedGRCPanelTab,
  } = useGRCStore();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [inputs, setInputs] = useState({
    exclude: false,
    testing_procedure: "inquiry",
    instructions: "",
    exclude_notes: "",
    users: [],
    due_date: Date.now() * 1000,
  });

  const controlID = control.generated_id;
  const selectedTab = "Controls Coverage";

  const { data: discussionCounts } = GetGRCDiscussionCount(auditID);
  const startAnalysis = StartEvidenceAnalysis(auditID, controlID);
  const { data: analysisStatus } = GetAnalysisStatus(auditID, controlID);
  const { data: evidenceReport } = GetEvidenceReport(auditID, controlID);
  const uploadEvidenceReport = UploadEvidenceReport(auditID, controlID);

  const discussionCount =
    discussionCounts?.find(
      (anchor: KeyStringVal) => anchor.anchor_id === controlID
    )?.count || 0;

  return (
    <article className="relative flex flex-col flex-grow gap-3 px-6 py-4 dark:bg-[#151F2B] rounded-lg">
      <article className="flex items-start justify-between gap-10">
        <article className="grid gap-2 w-2/5">
          <h4 className="flex items-start gap-2 text-b1-semi">
            {control.sub_section_id !== "-" && control.sub_section_id}{" "}
            {control.sub_section_title !== "-" && control.sub_section_title}
          </h4>
        </article>
        <article className="flex items-center place-content-end gap-2 text-b2-reg">
          <article className="flex items-center gap-2 dark:bg-gray-800 rounded">
            <input
              disabled={isReadOnly}
              type="checkbox"
              checked={evidenceReport?.exclude}
              onClick={() => {
                if (evidenceReport?.exclude)
                  uploadEvidenceReport.mutate({
                    report: {
                      exclude: false,
                      testing_procedure:
                        evidenceReport?.testing_procedure || "",
                      instructions: evidenceReport?.instructions || "",
                      exclude_notes: evidenceReport?.exclude_notes || "",
                      users: evidenceReport?.users || [],
                      due_date: evidenceReport?.due_date || Date.now() * 1000,
                    },
                  });
                else {
                  setShowModal(true);
                  setInputs({ ...inputs, exclude: true });
                }
              }}
              className="white-checkbox"
            />
            <label className="w-max">Exclude</label>
          </article>
          <RequestEvidence
            auditID={auditID}
            controlID={controlID}
            showModal={showModal}
            setShowModal={setShowModal}
            inputs={inputs}
            setInputs={setInputs}
          />
          <button
            disabled={isReadOnly || analysisStatus?.status === "pending"}
            className="small-black-button"
            onClick={() => startAnalysis.mutate({})}
          >
            {analysisStatus?.status === "pending" ? (
              <ColorRing
                visible={true}
                height="20"
                width="20"
                ariaLabel="color-ring-loading"
                wrapperStyle={{}}
                wrapperClass="color-ring-wrapper"
                colors={["#e15b64", "#f47e60", "#f8b26a", "#abbd81", "#849b87"]}
              />
            ) : (
              <FontAwesomeIcon icon={faChartBar} />
            )}
            <h4>Start Analysis</h4>
          </button>
          <button
            className="flex items-center gap-1 px-4 py-1 capitalize dark:bg-[#33333366]/40 rounded-lg"
            onClick={() => {
              setShowGRCPanel(!showGRCPanel);
              setSelectedGRCPanelTab("comments");
              setSelectedAnchorID(controlID);
            }}
          >
            <FontAwesomeIcon icon={faComment} />
            Comments
            <span>({discussionCount})</span>
          </button>
        </article>
      </article>

      <article className="flex items-center place-content-end gap-2">
        <a
          href="/grc/mapping"
          className="flex items-center gap-2 dark:text-blue-600 dark:hover:text-blue-600/80 duration-100"
          onClick={() =>
            handleClickMapping(
              documentType,
              documentName,
              documentID,
              selectedTab,
              control,
              "Relevant Sections",
              control.mapped_self_control_sections
            )
          }
        >
          <FontAwesomeIcon icon={faChevronRight} />
          <p>
            {control.mapped_self_control_sections === 0
              ? "Relevant Sections"
              : `${control.mapped_self_control_sections} Relevant Section${
                  control.mapped_self_control_sections === 1 ? "" : "s"
                }`}
          </p>
        </a>
        {control.mapped_policy_sections > 0 && (
          <a
            href="/grc/mapping"
            className="flex items-center gap-2 dark:text-blue-600 dark:hover:text-blue-600/80 duration-100"
            onClick={() =>
              handleClickMapping(
                "controls",
                "Control",
                controlID,
                selectedTab,
                control,
                "Policy",
                control.mapped_policy_sections,
                "",
                controlID
              )
            }
          >
            <FontAwesomeIcon icon={faChevronRight} />
            <p>
              {control.mapped_policy_sections === 0
                ? "Policy"
                : `${control.mapped_policy_sections} Mapped to Policy`}
            </p>
          </a>
        )}
      </article>

      <article className="flex flex-wrap items-center gap-2">
        {!control.has_evidence && (
          <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-yellow-500"
            />
            No Evidence Uploaded
          </span>
        )}
        {control.excluded && (
          <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
            <FontAwesomeIcon icon={faEyeSlash} className="text-yellow-500" />
            Excluded
          </span>
        )}
        {control.analysis_generated && (
          <span className="flex items-center gap-1 px-2 py-1 text-b1-reg dark:bg-gray-800 rounded">
            <FontAwesomeIcon icon={faCheck} className="text-no" />
            Analysis Generated
          </span>
        )}
        {control.metadata_ && (
          <ul className="flex flex-wrap items-center gap-2">
            {Object.entries(control.metadata_).map((keyVal, index) => {
              return (
                <li
                  key={keyVal[0]}
                  className="flex items-center gap-2 px-2 py-1 dark:bg-gray-800 rounded"
                >
                  {keyVal[0] === "excluded" && keyVal[1] ? (
                    <span>Excluded</span>
                  ) : (
                    <>
                      <h4 className="capitalize">
                        {keyVal[0].replaceAll("_", " ")}
                      </h4>
                      {Array.isArray(keyVal[1]) ? (
                        keyVal[1].map((value) => {
                          return (
                            <span
                              key={value}
                              className="text-b1-reg dark:text-blue-600 dark:bg-gray-800"
                            >
                              {value}
                            </span>
                          );
                        })
                      ) : (
                        <span className="flex items-center gap-1 text-b1-reg dark:text-blue-600 dark:bg-gray-800">
                          {keyVal[1]}
                        </span>
                      )}
                    </>
                  )}
                </li>
              );
            })}
          </ul>
        )}
        <ul className="flex flex-wrap items-center gap-2">
          {[
            "category",
            "sub_category",
            "type",
            "classification",
            "frequency",
            "is_key_control",
          ].map((key) => {
            if (control[key] == null) return null;
            return (
              <li
                key={key}
                className="flex items-center gap-2 px-2 py-1 dark:bg-gray-800 rounded"
              >
                <h4 className="capitalize">{key.replaceAll("_", " ")}</h4>
                <span className="flex items-center gap-1 text-b1-reg dark:text-blue-600 dark:bg-gray-800">
                  {control[key]}
                </span>
              </li>
            );
          })}
        </ul>
      </article>

      {["success", "fail"].includes(analysisStatus?.status) && (
        <section className="flex items-center gap-4 px-4 py-2 w-max dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
          <article
            className={`flex items-center gap-3 ${
              analysisStatus?.status === "success"
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            <p
              className={`${
                analysisStatus?.status === "success"
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              Analysis{" "}
              {analysisStatus?.status === "success" ? "Completed" : "Failed"}
            </p>
            <FontAwesomeIcon
              icon={analysisStatus?.status === "success" ? faCheck : faXmark}
            />
          </article>
          {analysisStatus?.status === "success" && (
            <Analysis auditID={auditID} controlID={controlID} />
          )}
          <button
            disabled={isReadOnly}
            className="small-black-button"
            onClick={() => startAnalysis.mutate({})}
          >
            <FontAwesomeIcon icon={faChartBar} />
            <h4>Re-run Analysis</h4>
          </button>
        </section>
      )}
      {control.content && <ControlContent subsection={control} />}

      {!evidenceReport?.exclude && (
        <EvidenceList auditID={auditID} controlID={controlID} />
      )}

      <MappedPolicySections subsection={control} />

      <AnimatePresence exitBeforeEnter>
        {showGRCPanel && selectedAnchorID === controlID && (
          <DiscussionNotes
            documentID={auditID}
            anchorID={controlID}
            title={control.sub_section_title}
          />
        )}
      </AnimatePresence>
    </article>
  );
};

export default Control;
