import React from "react";
import DonutChart from "src/components/Chart/DonutChart";
import { GetControlByRisks } from "src/services/erc/controls/control-dashboard";

const ControlByRisks = () => {
  const { data } = GetControlByRisks();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <DonutChart data={data} xKey="name" title="Risks by Controls" />
    </section>
  );
};

export default ControlByRisks;
