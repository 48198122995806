import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import {
  faChevronDown,
  faChevronRight,
  faDownload,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ModalLayout from "src/layouts/ModalLayout";
import { DeleteControlEvidence } from "src/services/audit-management";
import { KeyStringVal } from "src/types/general";

const EvidenceDetail = ({
  auditID,
  controlID,
  evidence,
}: {
  auditID: string;
  controlID: string;
  evidence: any;
}) => {
  const uri = evidence.file_uri;
  const fileType = uri.slice(uri.lastIndexOf(".") + 1, uri.indexOf("?"));

  const [show, setShow] = useState<boolean>(false);

  const deleteEvidence = DeleteControlEvidence(auditID, controlID);

  const handleOnClose = () => setShow(false);

  return (
    <li>
      <button
        className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 dark:hover:bg-gray-700/70 duration-100 rounded"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faLink} className="dark:text-blue-500" />
        <h4>{evidence.name}</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <header className="grid gap-2">
            <h4 className="tracking-wide text-subt1-semi text-center">
              PREVIEW of {evidence.name}
            </h4>
            <button
              className="blue-button"
              onClick={() => {
                let a = document.createElement("a");
                a.href = uri;
                a.target = "_blank";
                a.click();
              }}
            >
              <FontAwesomeIcon icon={faDownload} /> Download as File
            </button>
            <DocViewer
              documents={[
                {
                  uri,
                  fileType,
                },
              ]}
              pluginRenderers={DocViewerRenderers}
              config={{
                header: {
                  disableHeader: false,
                  disableFileName: true,
                  retainURLParams: false,
                },
              }}
            />
          </header>
          {evidence.description && (
            <ReactMarkdown
              className="px-6 py-4 markdown"
              remarkPlugins={[remarkGfm]}
            >
              {evidence.description}
            </ReactMarkdown>
          )}
          {evidence.content && (
            <ReactMarkdown
              className="px-6 py-4 markdown"
              remarkPlugins={[remarkGfm]}
            >
              {evidence.content}
            </ReactMarkdown>
          )}
          {evidence.qa?.length > 0 && (
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="flex items-center gap-2 w-max text-sm">
                    <h4>Q&A</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                      className="dark:text-gray-400"
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="grid gap-3 px-4 text-sm border-l-1 dark:border-checkbox">
                    {evidence.qa ? (
                      evidence.qa.length > 0 ? (
                        <ul className="flex flex-col flex-grow divide-y-1 dark:divide-checkbox/30 overflow-auto scrollbar">
                          {evidence.qa.map(
                            (qa: KeyStringVal, index: number) => {
                              return (
                                <Disclosure key={index}>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex items-center gap-2 p-4 w-full dark:bg-expand">
                                        <p className="text-left">
                                          {qa.question}
                                        </p>
                                        <FontAwesomeIcon
                                          icon={
                                            open
                                              ? faChevronDown
                                              : faChevronRight
                                          }
                                          className="dark:text-gray-400"
                                        />
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="grid gap-4 p-4 dark:bg-account">
                                        <p className="text-left break-words">
                                          {qa.answer}
                                        </p>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              );
                            }
                          )}
                        </ul>
                      ) : (
                        <p>No questions available</p>
                      )
                    ) : null}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          )}
          {evidence.tags?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
              {evidence.tags.map((tag: string, index: number) => (
                <span key={index} className="dark:text-blue-500">
                  {tag}
                </span>
              ))}
            </article>
          )}
          <article className="flex items-center place-content-center gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="red-button"
              onClick={() => {
                deleteEvidence.mutate({
                  evidenceID: evidence.evidence_id,
                });
                setShow(false);
              }}
            >
              Delete evidence
            </button>
          </article>
        </section>
      </ModalLayout>
    </li>
  );
};

export default EvidenceDetail;
