import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { GetRiskBlueprintMetadata } from "../../../../../services/erc/risks/risk-blueprints";
import ArchiveRestoreRiskBlueprint from "./ArchiveRestoreRiskBlueprint";
import UpdateRiskBlueprint from "./UpdateRiskBlueprint";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/GRC/MetadataField";
import { Disclosure } from "@headlessui/react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import ReturnPage from "src/components/Button/ReturnPage";

const RiskBlueprintMetadata = ({
  riskBlueprintID,
}: {
  riskBlueprintID: string;
}) => {
  const navigate = useNavigate();
  const customerID = getCustomerID();

  const { data: metadata } = GetRiskBlueprintMetadata(riskBlueprintID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Risk Blueprint</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(allUsers, metadata.last_updated_by)} (
                    {getEmailFromUserID(allUsers, metadata.last_updated_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, metadata.created_by)} (
                    {getEmailFromUserID(allUsers, metadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={metadata}
                />
                {/* <ArchiveRestoreRiskBlueprint
                  riskBlueprintID={riskBlueprintID}
                  blueprint={metadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-4 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField metadata={metadata} label="Type" keyName="type" />
              <MetadataField
                metadata={metadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={metadata}
                label="Source Name"
                keyName="source_name"
              />
              <MetadataField
                metadata={metadata}
                label="Source Id"
                keyName="source_id"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              {metadata.threats?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Threats</h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.threats.map((threat) => {
                      return (
                        <li
                          key={threat.threat_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/threats/details?threat_id=${threat.threat_id}`
                            )
                          }
                        >
                          {threat.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {metadata.vulnerabilities?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vulnerabilities
                  </h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.vulnerabilities.map((vuln) => {
                      return (
                        <li
                          key={vuln.vulnerability_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/vulns/details?vuln_id=${vuln.vulnerability_id}`
                            )
                          }
                        >
                          {vuln.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {metadata.entity_types?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Entity Types
                  </h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.entity_types.map((entityType) => {
                      return (
                        <li
                          key={entityType.vulnerability_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/entity-types/details?entity_type_id=${entityType.entity_type_id}`
                            )
                          }
                        >
                          {entityType.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {metadata.control_blueprints?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Control Blueprints
                  </h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.control_blueprints.map((blueprint) => {
                      return (
                        <li
                          key={blueprint.vulnerability_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/control-blueprints/details?control_blueprint_id=${blueprint.control_blueprint_id}`
                            )
                          }
                        >
                          {blueprint.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
            </section>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-subt1-semi">
                      Owners, Reviewers, & Approvers
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-2 gap-4">
                      <MetadataField
                        metadata={metadata}
                        label="Owners"
                        keyName="owners"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Reviewers"
                        keyName="reviewers"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Approvers"
                        keyName="approvers"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Create Risks Automatically"
                        keyName="create_risks_automatically"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
        </header>
      )}
    </>
  );
};

export default RiskBlueprintMetadata;
