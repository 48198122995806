/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faChevronRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Switch } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Loader from "src/components/Loader/Loader";
import {
  GetPoliciesFromGroup,
  GetPolicyGroups,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import ViewInFile from "src/pages/RegulationPolicy/Document/ViewInFile/ViewInFile";
import PoliciesFilter from "src/components/Filter/RegulationPolicy/PoliciesFilter";
import PolicyGroupFilter from "src/components/Filter/RegulationPolicy/PolicyGroupFilter";
import { GetControlCoverage } from "src/services/erc/controls/controls";

const PoliciesSelection = ({
  index,
  selectedPolicyGroups,
  setSelectedPolicyGroups,
  policyGroupSpace,
  setPolicyGroupSpace,
}: {
  index: number;
  selectedPolicyGroups: KeyStringVal[];
  setSelectedPolicyGroups: (selectedPolicyGroups: KeyStringVal[]) => void;
  policyGroupSpace: number;
  setPolicyGroupSpace: (policyGroupSpace: number) => void;
}) => {
  const [selectedPolicyIDs, setSelectedPolicyIDs] = useState<string[]>([]);
  const [isGaps, setIsGaps] = useState<boolean>(false);

  const { data: policyGroups } = GetPolicyGroups();
  const { data: policies } = GetPoliciesFromGroup(
    selectedPolicyGroups[index]?.policy_group_id
      ? selectedPolicyGroups[index].policy_group_id
      : ""
  );
  const { data: policyMappings, status: mappingStatus } =
    GetControlCoverage(selectedPolicyIDs);

  const coverage = isGaps
    ? Math.round((100 - Number(policyMappings?.coverage)) * 100) / 100
    : Number(policyMappings?.coverage);

  useEffect(() => {
    if (policies?.data.length > 0 && selectedPolicyIDs.length === 0)
      setSelectedPolicyIDs(
        policies?.data?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
          []
        )
      );
  }, [policies]);

  useEffect(() => {
    if (
      policyGroups?.length > 0 &&
      index === 0 &&
      !selectedPolicyGroups[index]?.policy_group_id
    ) {
      const newPolicyGroups = selectedPolicyGroups;
      const defaultGroup = policyGroups?.find(
        (policyGroup: KeyStringVal) =>
          policyGroup.title.toLowerCase() === "default"
      );
      newPolicyGroups.splice(index, 1, defaultGroup);
      setSelectedPolicyGroups([...newPolicyGroups]);
    }
  }, [policyGroups]);

  return (
    <section className="grid content-start gap-4 mb-4 w-full">
      <header className="flex items-start gap-4">
        <article className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-900 rounded-lg">
          <PolicyGroupFilter
            selectedPolicyGroups={selectedPolicyGroups}
            setSelectedPolicyGroups={setSelectedPolicyGroups}
            setSelectedPolicyIDs={setSelectedPolicyIDs}
            policyGroups={policyGroups}
            index={index}
          />
          {selectedPolicyGroups[index]?.title && (
            <PoliciesFilter
              selectedPolicyIDs={selectedPolicyIDs}
              setSelectedPolicyIDs={setSelectedPolicyIDs}
              policies={policies}
            />
          )}
        </article>
        {index !== 0 && (
          <button
            className="red-trash-button"
            onClick={() => {
              setPolicyGroupSpace(policyGroupSpace - 1);
              if (selectedPolicyGroups[index]) {
                const newPolicyGroups = selectedPolicyGroups;
                newPolicyGroups.splice(index, 1);
                setSelectedPolicyGroups(newPolicyGroups);
              }
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        )}
      </header>

      {mappingStatus === "loading" ? (
        <Loader />
      ) : mappingStatus === "success" ? (
        <section className="flex flex-col flex-grow gap-4 w-full">
          <article className="flex flex-col flex-grow gap-4 p-8 text-center dark:bg-gray-800 rounded-lg">
            <h2 className="text-b1-semi">{isGaps ? "Gaps" : "Coverage"}</h2>
            <article className="mx-auto w-40 h-40">
              <CircularProgressbarWithChildren
                strokeWidth={10}
                value={coverage}
                maxValue={100}
                styles={buildStyles({
                  trailColor: "#111827",
                  pathColor: "#fcba03",
                })}
              >
                <span className="text-subt1-semi">{coverage}%</span>
              </CircularProgressbarWithChildren>
            </article>
            <article className="flex items-center gap-2 mx-auto">
              <Switch
                checked={isGaps}
                onChange={setIsGaps}
                className={`${
                  isGaps ? "bg-[#30CD9A]" : "bg-gray-500"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    isGaps ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <span className="text-b2-semi">Gaps</span>
            </article>
            <article className="flex items-center gap-4 mx-auto text-b2-semi">
              Controls Mapped: {policyMappings.num_mapped}/
              {policyMappings.total}
            </article>
          </article>
          {policyMappings ? (
            coverage ? (
              policyMappings.mappings.length > 0 ? (
                <ul className="grid gap-4">
                  {policyMappings.mappings.map(
                    (control: any, controlIndex: number) => {
                      return (
                        <li
                          key={controlIndex}
                          className="grid content-start gap-3 p-4 dark:bg-gray-900 rounded-xl"
                        >
                          <header className="flex items-center gap-4">
                            <span className="px-2 py-1 w-max text-b2-reg dark:bg-gray-800 rounded">
                              CONTROL
                            </span>

                            <h4 className="text-subt1-semi">{control.name} </h4>
                          </header>
                          {control.control_blueprint && (
                            <article className="flex flex-col flex-grow gap-2">
                              <h5 className="text-b2-reg">Control Blueprint</h5>
                              <h5 className="text-subt1-semi">
                                {control.control_blueprint.name}
                              </h5>
                              {control.control_blueprint.description && (
                                <Disclosure defaultOpen>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className="flex items-center gap-2 text-b2-reg">
                                        <FontAwesomeIcon
                                          icon={
                                            open
                                              ? faChevronDown
                                              : faChevronRight
                                          }
                                        />
                                        <p>
                                          {open ? "Hide" : "Show"} Description
                                        </p>
                                      </Disclosure.Button>
                                      <Disclosure.Panel>
                                        <p className="text-b2-reg">
                                          {
                                            control.control_blueprint
                                              ?.description
                                          }
                                        </p>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              )}
                              <article className="flex flex-wrap items-center gap-4">
                                {control.control_blueprint.type && (
                                  <article className="grid content-start gap-1">
                                    <h4 className="dark:text-gray-300 text-b2-reg">
                                      Type
                                    </h4>
                                    <span className="text-b1-semi">
                                      {control.control_blueprint.type}
                                    </span>
                                  </article>
                                )}
                                {control.control_blueprint.category && (
                                  <article className="grid content-start gap-1">
                                    <h4 className="dark:text-gray-300 text-b2-reg">
                                      Category
                                    </h4>
                                    <span className="text-b1-semi">
                                      {control.control_blueprint.category}
                                    </span>
                                  </article>
                                )}
                                {control.control_blueprint.classification && (
                                  <article className="grid content-start gap-1">
                                    <h4 className="dark:text-gray-300 text-b2-reg">
                                      Classification
                                    </h4>
                                    <span className="text-b1-semi">
                                      {control.control_blueprint.classification}
                                    </span>
                                  </article>
                                )}
                              </article>
                            </article>
                          )}
                          {control.metadata_ && (
                            <ul className="flex items-center gap-2">
                              {Object.entries(control.metadata_).map(
                                (keyVal, index) => {
                                  if (
                                    !keyVal[1] ||
                                    (Array.isArray(keyVal[1]) &&
                                      keyVal[1]?.length === 0)
                                  )
                                    return null;
                                  return (
                                    <li
                                      key={keyVal[0]}
                                      className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded"
                                    >
                                      <h4 className="capitalize">
                                        {keyVal[0].replaceAll("_", " ")}
                                      </h4>
                                      {Array.isArray(keyVal[1]) ? (
                                        keyVal[1].map((value) => {
                                          return (
                                            <span
                                              key={value}
                                              className="text-b1-reg dark:text-blue-500"
                                            >
                                              {value}
                                            </span>
                                          );
                                        })
                                      ) : (
                                        <span className="flex items-center gap-1 text-b1-reg dark:text-blue-500">
                                          {keyVal[1]}
                                        </span>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                          <p className="text-b1-reg">
                            {control.control_content}
                          </p>
                          <article className="flex flex-col flex-grow gap-7">
                            {control.mapped_policies?.map(
                              (policy: any, policyIndex: number) => {
                                return (
                                  <article
                                    key={policyIndex}
                                    className="grid gap-3 p-4 dark:bg-gray-800 rounded-lg"
                                  >
                                    <h4 className="text-b1-semi">
                                      {policy.policy_name}
                                    </h4>
                                    <ul className="flex flex-col flex-grow gap-3">
                                      {policy.policy_sections?.map(
                                        (
                                          policySection: any,
                                          policySectionIndex: number
                                        ) => {
                                          return (
                                            <li
                                              key={policySectionIndex}
                                              className="grid gap-2 px-6 py-4 dark:bg-gray-700 rounded-lg"
                                            >
                                              <article className="flex items-center justify-between gap-4 py-1 border-b dark:border-gray-500">
                                                <h4 className="text-b1-semi">
                                                  {
                                                    policySection.policy_sub_section_id
                                                  }{" "}
                                                  {policySection.policy_sub_section_title !==
                                                    "-" &&
                                                    policySection.policy_sub_section_title}
                                                </h4>
                                                {policySection
                                                  .policy_page_metadata
                                                  ?.length > 0 && (
                                                  <ViewInFile
                                                    generatedID={String(
                                                      policySectionIndex
                                                    )}
                                                    section={policySection}
                                                    bbox={
                                                      policySection.policy_page_metadata
                                                    }
                                                    documentType="policies"
                                                  />
                                                )}
                                              </article>
                                              {policySection.policy_content && (
                                                <p className="text-b1-reg">
                                                  {policySection.policy_content}
                                                </p>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </article>
                                );
                              }
                            )}
                          </article>
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : (
                <p className="mx-auto">No policy sections available</p>
              )
            ) : policyMappings.no_mappings.length > 0 ? (
              <ul className="grid gap-4">
                {policyMappings.no_mappings.map(
                  (control: any, controlIndex: number) => {
                    return (
                      <li
                        key={controlIndex}
                        className="grid content-start gap-3 p-4 dark:bg-gray-900 rounded-xl"
                      >
                        <h4 className="text-subt1-semi">{control.name}</h4>
                        <span>{control.control_reference_id}</span>
                        {control.control_blueprint && (
                          <article className="flex flex-col flex-grow gap-2">
                            <h5 className="text-b2-reg">Control Blueprint</h5>
                            <h5 className="text-subt1-semi">
                              {control.control_blueprint.name}
                            </h5>
                            {control.control_blueprint.description && (
                              <Disclosure defaultOpen>
                                {({ open }) => (
                                  <>
                                    <Disclosure.Button className="flex items-center gap-2 text-b2-reg">
                                      <FontAwesomeIcon
                                        icon={
                                          open ? faChevronDown : faChevronRight
                                        }
                                      />
                                      <p>
                                        {open ? "Hide" : "Show"} Description
                                      </p>
                                    </Disclosure.Button>
                                    <Disclosure.Panel>
                                      <p className="text-b2-reg">
                                        {control.control_blueprint?.description}
                                      </p>
                                    </Disclosure.Panel>
                                  </>
                                )}
                              </Disclosure>
                            )}
                            <article className="flex flex-wrap items-center gap-4">
                              {control.control_blueprint.type && (
                                <article className="grid content-start gap-1">
                                  <h4 className="dark:text-gray-300 text-b2-reg">
                                    Type
                                  </h4>
                                  <span className="text-b1-semi">
                                    {control.control_blueprint.type}
                                  </span>
                                </article>
                              )}
                              {control.control_blueprint.category && (
                                <article className="grid content-start gap-1">
                                  <h4 className="dark:text-gray-300 text-b2-reg">
                                    Category
                                  </h4>
                                  <span className="text-b1-semi">
                                    {control.control_blueprint.category}
                                  </span>
                                </article>
                              )}
                              {control.control_blueprint.classification && (
                                <article className="grid content-start gap-1">
                                  <h4 className="dark:text-gray-300 text-b2-reg">
                                    Classification
                                  </h4>
                                  <span className="text-b1-semi">
                                    {control.control_blueprint.classification}
                                  </span>
                                </article>
                              )}
                            </article>
                          </article>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            ) : (
              <p className="mx-auto">No policy sections available</p>
            )
          ) : null}
        </section>
      ) : null}
    </section>
  );
};

export default PoliciesSelection;
