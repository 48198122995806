/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "../../../types/general";
import { GetNonGlobalQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";

const AssessmentQuestionSetFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  required,
}: {
  label?: string;
  keyName: string;
  inputs: any;
  setInputs: any;
  required?: boolean;
}) => {
  const { data: questionSets } = GetNonGlobalQuestionSets();

  const questionSetID = inputs[keyName] || "";
  const questionSetName =
    questionSets?.data.find(
      (questionSet: KeyStringVal) => questionSet.generated_id === questionSetID
    )?.name || "";

  return (
    <DropdownLayout
      label={label}
      value={questionSetName}
      placeholder="Select"
      labelAbove
      required={required}
    >
      {questionSets?.data.map((questionSet: KeyStringVal, index: number) => {
        if (questionSet.state === "DRAFT") return null;

        const selected = inputs[keyName] === questionSet.generated_id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              if (selected)
                setInputs({
                  ...inputs,
                  [keyName]: "",
                });
              else
                setInputs({
                  ...inputs,
                  [keyName]: questionSet.generated_id,
                });
            }}
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{questionSet.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default AssessmentQuestionSetFilter;
