/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { Group, UserDetails } from "../../../types/settings";

const GroupFilter = ({
  allGroups,
  details,
  label,
  value,
  setValue,
  list,
}: {
  allGroups: Group[];
  details: UserDetails;
  label: string;
  value: string;
  setValue: (value: UserDetails) => void;
  list: string[];
}) => {
  return (
    <DropdownLayout label={label} value={value} showAbove labelAbove>
      {list?.map((item: string, index: number) => {
        const selected = value === item;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              setValue({
                ...details,
                group_ids: [
                  ...new Set([
                    ...details.group_ids,
                    allGroups?.find((group: Group) => group.group_name === item)
                      ?.group_id || "",
                  ]),
                ],
              });
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default GroupFilter;
