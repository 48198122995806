import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import Markdown from "react-markdown";

const SubsectionContent = ({
  subsection,
  opened,
  setOpened,
}: {
  subsection: any;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
}) => {
  return (
    <>
      {subsection.content && (
        <Disclosure>
          {({ open }) => {
            const show = opened;

            return (
              <section>
                <Disclosure.Button
                  className="flex items-center gap-2 text-b2-reg"
                  onClick={() => {
                    if (opened != null && setOpened) setOpened(!opened);
                  }}
                >
                  <FontAwesomeIcon
                    icon={show ? faChevronDown : faChevronRight}
                  />
                  <h4>{show ? "Hide" : "Show"} content</h4>
                </Disclosure.Button>
                <Transition
                  show={show}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel>
                    <Markdown>{subsection.content}</Markdown>
                  </Disclosure.Panel>
                </Transition>
              </section>
            );
          }}
        </Disclosure>
      )}
    </>
  );
};

export default SubsectionContent;
