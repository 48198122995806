import {
  faChevronDown,
  faChevronRight,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useState } from "react";
import { userColors } from "src/constants/general";
import { DeleteRedlining } from "src/services/grc";
import { GetAllUsers } from "src/services/settings/users";
import { KeyStringVal } from "src/types/general";
import {
  checkIsReadOnly,
  convertToUTCShortString,
  getCustomerID,
} from "src/utils/general";
import { SuggestRedliningInstructions } from "../../../../services/grc";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { sendMessageToEditor } from "./Collabora/Collabora";
import { decodeJWT } from "../../../../utils/general";

const RedliningDetail = ({
  documentID,
  redline,
  editor,
  selectedHighlight,
  setSelectedHighlight,
}: {
  documentID: string;
  redline: any;
  editor: any;
  selectedHighlight: any;
  setSelectedHighlight: any;
}) => {
  const jwt = decodeJWT();
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const [editID, setEditID] = useState<string>("");
  const [editedSuggestion, setEditedSuggestion] = useState<string>("");

  const deleteRedlining = DeleteRedlining(documentID);
  const { data: allUsers } = GetAllUsers(customerID, false);
  const saveSuggestions = SuggestRedliningInstructions(documentID);

  const userEmail = allUsers?.find(
    (user: KeyStringVal) => user.user_id === redline.author_id
  )?.user_email;

  const handleReplaceSuggestion = (newEdit: string) => {
    sendMessageToEditor({
      MessageId: "Send_UNO_Command",
      Values: { Command: ".uno:TrackChanges" },
    });
    sendMessageToEditor({
      MessageId: "CallPythonScript",
      SendTime: Date.now(),
      ScriptFile: "Redline.py",
      Function: "replaceSuggestion",
      Values: {
        originalText: {
          type: "string",
          value: redline.old_content.text,
        },
        suggestion: {
          type: "string",
          value: newEdit,
        },
        userId: {
          type: "string",
          value: jwt?.sub,
        },
        documentId: {
          type: "string",
          value: documentID,
        },
      },
    });
    sendMessageToEditor({
      MessageId: "Send_UNO_Command",
      Values: { Command: ".uno:TrackChanges" },
    });
  };

  return (
    <li
      className={`group grid gap-2 px-6 py-4 cursor-pointer ${
        selectedHighlight === redline.id
          ? "dark:bg-gray-600"
          : "dark:bg-gray-700 dark:hover:bg-gray-700/60 duration-100"
      } rounded-md`}
    >
      <section
        className="grid gap-2"
        onClick={() => {
          if (!isReadOnly) {
            const { node } = editor.view.domAtPos(redline.start);
            if (node) {
              (node as any).scrollIntoView();
            }

            setSelectedHighlight(redline.id);
            sendMessageToEditor({
              MessageId: "CallPythonScript",
              SendTime: Date.now(),
              ScriptFile: "Redline.py",
              Function: "selectSuggestion",
              Values: {
                suggestion: { type: "string", value: redline.new_edits },
                userId: { type: "string", value: jwt?.sub },
                documentId: { type: "string", value: documentID },
              },
            });
          }
        }}
      >
        <header className="flex items-center justify-between gap-4 text-sm">
          {userEmail && (
            <article className="flex items-center gap-1">
              <span
                className={`grid content-center w-5 h-5 capitalize text-center text-[0.65rem] dark:text-white font-medium bg-gradient-to-b ${
                  userColors[userEmail[0]?.toLowerCase()]
                } shadow-sm dark:shadow-checkbox rounded-full`}
              >
                {userEmail[0]}
              </span>{" "}
              <p className="text-subt1-semi">{userEmail} </p>
            </article>
          )}
          <article className="flex items-center gap-2">
            <span className="text-b2-reg dark:text-gray-400">
              {convertToUTCShortString(redline.created_at)}
            </span>
            <button
              disabled={isReadOnly}
              className="hidden group-hover:block place-self-start red-trash-button"
              onClick={(e) => {
                e.stopPropagation();
                deleteRedlining.mutate({
                  redliningID: redline.id,
                });
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </button>
          </article>
        </header>
        {redline.is_failed ? (
          <p className="bg-reset/20 rounded-md">{redline.failed_status}</p>
        ) : !redline.is_ready ? (
          <p>Not readily available</p>
        ) : (
          <blockquote className="grid gap-4 break-words text-b2-reg">
            <ReactMarkdown
              className="text-b2-reg min-w-full dark:text-white decoration-red-500 line-through prose overflow-auto"
              remarkPlugins={[remarkGfm]}
            >
              {redline.old_content.text}
            </ReactMarkdown>
            <p>{redline.new_edits || redline.instructions}</p>
          </blockquote>
        )}
      </section>

      {redline.is_ready && redline.text_response?.length > 0 && (
        <Disclosure>
          {({ open }) => (
            <section className="grid content-start gap-3">
              <Disclosure.Button className="flex items-center gap-2 w-max text-sm">
                <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} />
                <p>Suggestions</p>
              </Disclosure.Button>
              <Disclosure.Panel>
                <section className="grid gap-2 divide-y-1 dark:divide-white/30">
                  {redline.text_response?.map(
                    (suggestion: string, index: number) => {
                      return (
                        <article
                          key={index}
                          className="group grid gap-1 px-3 py-1 text-left text-sm"
                        >
                          {editID === `${redline.id}-${index}` ? (
                            <article className="grid gap-2">
                              <textarea
                                disabled={isReadOnly}
                                spellCheck="false"
                                autoComplete="off"
                                value={editedSuggestion}
                                onChange={(e) =>
                                  setEditedSuggestion(e.target.value)
                                }
                                className="py-1 w-full h-[15rem] text-sm focus:outline-none dark:placeholder:text-checkbox dark:text-white dark:bg-account dark:border-transparent dark:focus:ring-0 dark:focus:border-transparent resize-none overflow-auto scrollbar"
                              />
                              <article className="flex items-center place-self-end gap-4">
                                <button
                                  disabled={isReadOnly}
                                  className="red-button"
                                  onClick={() => {
                                    setEditID("");
                                    setEditedSuggestion("");
                                  }}
                                >
                                  Discard
                                </button>
                                <button
                                  disabled={isReadOnly}
                                  className="blue-button"
                                  onClick={() => {
                                    setEditID("");
                                    setEditedSuggestion("");
                                    saveSuggestions.mutate({
                                      anchorID: redline.id,
                                      redlining: {
                                        old_content: redline.old_content,
                                        new_edits: editedSuggestion,
                                        start: redline.start,
                                        end: redline.end,
                                      },
                                    });
                                    handleReplaceSuggestion(editedSuggestion);
                                  }}
                                >
                                  Save
                                </button>
                              </article>
                            </article>
                          ) : (
                            <article className="grid gap-2">
                              <p className="p-2 dark:bg-gray-900 rounded-md">
                                {suggestion}
                              </p>
                              <article className="flex items-center place-self-end gap-4">
                                <button
                                  disabled={isReadOnly}
                                  className="edit-button"
                                  onClick={() => {
                                    setEditID(`${redline.id}-${index}`);
                                    setEditedSuggestion(suggestion);
                                  }}
                                >
                                  Edit
                                </button>
                                <button
                                  disabled={isReadOnly}
                                  className="blue-button"
                                  onClick={() => {
                                    saveSuggestions.mutate({
                                      anchorID: redline.id,
                                      redlining: {
                                        old_content: redline.old_content,
                                        new_edits: suggestion,
                                        start: redline.start,
                                        end: redline.end,
                                      },
                                    });
                                    handleReplaceSuggestion(suggestion);
                                  }}
                                >
                                  Select
                                </button>
                              </article>
                            </article>
                          )}
                        </article>
                      );
                    }
                  )}
                </section>
              </Disclosure.Panel>
            </section>
          )}
        </Disclosure>
      )}
    </li>
  );
};

export default RedliningDetail;
