/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { MutableRefObject, useEffect, useRef, useState } from "react";
import {
  CreateAccount,
  TestCredentials,
  UploadCredentials,
} from "../../../../services/settings/integrations";
import { Credentials } from "../../../../types/settings";
import {
  checkIsReadOnly,
  getCustomerID,
  parseURL,
} from "../../../../utils/general";
import ConnectionDetails from "../ConnectionDetails";
import TestButton from "../TestButton";
import IntegrationLayout from "../../../../layouts/IntegrationLayout";
import RegularInput from "src/components/Input/RegularInput";
import JiraProjectsFilter from "src/components/Filter/Settings/JiraProjects";

const JIRA = ({
  setShowIntegrationDetails,
  selectedAccountID,
}: {
  setShowIntegrationDetails: (integrationDetails: boolean) => void;
  selectedAccountID: string;
}) => {
  const parsed = parseURL();
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const testRef = useRef() as MutableRefObject<HTMLElement>;
  const [credentials, setCredentials] = useState<Credentials>({
    jira_url: "",
    jira_user: "",
    jira_api_token: "",
    jira_projects: [],
    jira_jql: "",
  });

  const createAccount = CreateAccount(customerID);
  const testCredentials = TestCredentials(customerID, parsed.integration);
  const uploadCredentials = UploadCredentials(
    customerID,
    setShowIntegrationDetails
  );

  const disabled =
    isReadOnly ||
    testCredentials.status === "loading" ||
    createAccount.status === "loading" ||
    uploadCredentials.status === "loading";

  useEffect(() => {
    if (testCredentials.status === "success")
      testRef?.current?.scrollIntoView();
  }, [testCredentials.status]);

  return (
    <IntegrationLayout
      selectedAccountID={selectedAccountID}
      testCredentials={testCredentials}
      uploadCredentials={uploadCredentials}
      testRef={testRef}
      disabled={disabled}
      credentials={credentials}
      createAccount={createAccount}
    >
      <section className="grid md:grid-cols-2 gap-4">
        <RegularInput
          label="URL"
          keyName="jira_url"
          inputs={credentials}
          setInputs={setCredentials}
          disabled={disabled}
          required
        />
        <RegularInput
          label="User"
          keyName="jira_user"
          inputs={credentials}
          setInputs={setCredentials}
          disabled={disabled}
          required
        />
        <RegularInput
          label="API Token"
          keyName="jira_api_token"
          inputs={credentials}
          setInputs={setCredentials}
          disabled={disabled}
          required
        />
        <JiraProjectsFilter
          label="Projects"
          keyName="jira_projects"
          inputs={credentials}
          setInputs={setCredentials}
          selectedAccountID={selectedAccountID}
          disabled={
            disabled ||
            credentials.jira_url === "" ||
            credentials.jira_user === "" ||
            credentials.jira_api_token === "" ||
            selectedAccountID === ""
          }
        />
      </section>
      <RegularInput
        label="JQL"
        keyName="jira_jql"
        inputs={credentials}
        setInputs={setCredentials}
        disabled={disabled}
        textarea
      />
      <TestButton
        disabled={
          credentials.jira_url === "" ||
          credentials.jira_user === "" ||
          credentials.jira_api_token === "" ||
          testCredentials.status === "loading" ||
          (testCredentials.data &&
            Object.keys(testCredentials.data.errors).length === 0 &&
            Object.keys(testCredentials.data.result.failed).length === 0)
        }
        handleOnClick={() =>
          testCredentials.mutate({
            credentials,
          })
        }
        handleOnClose={() => setShowIntegrationDetails(false)}
      />

      {/* show which connections passed/failed */}
      {testCredentials.data &&
        Object.keys(testCredentials.data.errors).length === 0 && (
          <ConnectionDetails
            testRef={testRef}
            failed={testCredentials.data.result.failed}
            passed={testCredentials.data.result.passed}
          />
        )}
    </IntegrationLayout>
  );
};

export default JIRA;
