/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import LoaderForm from "./LoaderForm";
import {
  checkIsReadOnly,
  decodeJWT,
  getAccessToken,
} from "../../../../../utils/general";
import { apiVersion, baseURL } from "../../../../../constants/general";
import InsertRedlining from "./InsertRedlining";
import { KeyStringVal } from "../../../../../types/general";

export const sendMessageToEditor = (message) => {
  document
    .getElementById("collabora-online-viewer")
    .contentWindow.postMessage(JSON.stringify(message), "*");
  // window.frames[0].postMessage(JSON.stringify(message), "*");
};

const Collabora = ({ documentID }: { documentID: string }) => {
  const accessToken = getAccessToken();
  const jwt = decodeJWT();
  const isReadOnly = checkIsReadOnly();

  const [inputs, setInputs] = useState<any>({
    startLoading: false,
    wopiUrl: "",
    token: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedText, setSelectedText] = useState<string>("");
  const [newText, setNewText] = useState<KeyStringVal>({ new_text: "" });
  const iframeRef = useRef(null);

  const handleSubmit = () => {
    fetch(`${baseURL}/api/${apiVersion}/grc/redlining/get_url/${documentID}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const wopiUrl = `${data.wopiClientUrl}WOPISrc=${data.wopiSrc}`;
        setInputs({
          startLoading: true,
          wopiUrl: wopiUrl,
          token: accessToken,
        });
      });
  };

  const handlePostMessage = (event) => {
    const message = JSON.parse(event.data);

    // Handle different PostMessage events
    switch (message.MessageId) {
      case "App_LoadingStatus":
        // Handle loading status updates
        if (message.Values.Status === "Document_Loaded") {
          // insert button
          sendMessageToEditor({ MessageId: "Host_PostmessageReady" });
          sendMessageToEditor({
            MessageId: "Insert_Button",
            SendTime: Date.now(),
            Values: {
              id: "redline",
              imgurl: "unoandroidred.png",
              hint: "Custom Redlining",
              mobile: false,
              tablet: false,
              label: "redline",
              insertBefore: "insertannotation",
            },
          });
        }
        break;
      case "Clicked_Button":
        if (!isReadOnly) {
          setTimeout(() => {
            sendMessageToEditor({ MessageId: "Host_PostmessageReady" });
            sendMessageToEditor({
              MessageId: "CallPythonScript",
              SendTime: Date.now(),
              ScriptFile: "Redline.py",
              Function: "redlineTextPython",
              Values: {
                userId: { type: "string", value: jwt?.sub },
                documentId: {
                  type: "string",
                  value: documentID,
                },
              },
            });
          }, 100);
          setShowModal(true);
        }
        break;
      case "CallPythonScript-Result":
        if (
          !isReadOnly &&
          message.Values.commandName.includes("redlineTextPython")
        ) {
          const value = message.Values.result.value;
          setSelectedText(value[0].value.toString());
        }
      // Handle other PostMessage events as needed
    }
  };

  useEffect(() => {
    const iframe = iframeRef.current;

    if (iframe) {
      window.addEventListener("message", handlePostMessage);

      return () => {
        window.removeEventListener("message", handlePostMessage);
      };
    }
  }, []);

  useEffect(() => {
    if (inputs.startLoading) setInputs({ ...inputs, startLoading: false });
  }, [inputs.startLoading]);

  useEffect(() => {
    handleSubmit();
  }, []);

  return (
    <section className="col-span-7 flex flex-col flex-grow gap-4 min-h-full">
      {inputs.startLoading && (
        <LoaderForm url={inputs.wopiUrl} token={inputs.token} />
      )}
      {!isReadOnly && showModal && (
        <InsertRedlining
          documentID={documentID}
          showModal={showModal}
          setShowModal={setShowModal}
          selectedText={selectedText}
          newText={newText}
          setNewText={setNewText}
        />
      )}
      <iframe
        ref={iframeRef}
        title="Collabora Online Viewer"
        id="collabora-online-viewer"
        name="collabora-online-viewer"
        allow="clipboard-read *; clipboard-write *"
        className="w-full h-[50rem]"
      ></iframe>
    </section>
  );
};

export default Collabora;
