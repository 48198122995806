import { MouseEventHandler } from "react";

const TestButton = ({
  disabled,
  handleOnClick,
  handleOnClose,
}: {
  disabled: boolean;
  handleOnClick: MouseEventHandler<HTMLButtonElement>;
  handleOnClose: any;
}) => {
  return (
    <article className="flex items-center place-content-end gap-4">
      <button className="black-button" onClick={handleOnClose}>
        Cancel
      </button>
      <button
        type="submit"
        disabled={disabled}
        className="place-self-end blue-button"
        onClick={handleOnClick}
      >
        Done
      </button>
    </article>
  );
};

export default TestButton;
