import React from "react";
import {
  GetFailedAttestation,
  GetKeyControlCounts,
  GetTotalControlCounts,
} from "src/services/erc/controls/control-dashboard";

const OverallStats = () => {
  const { data: keyControlCounts } = GetKeyControlCounts();
  const { data: totalControlCounts } = GetTotalControlCounts();
  const { data: failedAttestation } = GetFailedAttestation();

  return (
    <section className="grid grid-cols-3 gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">Key Controls</h4>
        <span className="text-h4 dark:text-blue-500">
          {keyControlCounts?.count || "-"}
        </span>
      </article>
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">Total Controls</h4>
        <span className="text-h4 dark:text-blue-500">
          {totalControlCounts?.count || "-"}
        </span>
      </article>
      <article className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 dark:bg-gray-900 border-b-1 dark:border-gray-700/70 rounded-lg">
        <h4 className="capitalize text-b1-semi">Failed Attestation</h4>
        <span className="text-h4 dark:text-blue-500">
          {failedAttestation?.count || -""}
        </span>
      </article>
    </section>
  );
};

export default OverallStats;
