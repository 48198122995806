import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSort } from "@fortawesome/free-solid-svg-icons";
import TableLayout from "../../../../../../../layouts/TableLayout";
import {
  checkIsReadOnly,
  handleSort,
  sortRows,
} from "../../../../../../../utils/general";
import { GetVendorAuditReports } from "../../../../../../../services/third-party-risk/vendors/vendors";
import RowValue from "src/components/General/RowValue";

const PrivacyDocumentList = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const [sort, setSort] = useState<KeyStringVal>({
    direction: "desc",
    order_by: "created_at",
  });
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: reviewList, status: reviewStatus } = GetVendorAuditReports(
    vendorID,
    assessmentID,
    sort,
    pageNumber,
    "PRIVACY"
  );

  const headers = reviewList && JSON.parse(reviewList.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = reviewList && sortRows(reviewList?.data, sort);
  const totalCount = reviewList?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4">
      {reviewStatus === "loading" ? (
        <Loader />
      ) : reviewList?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    onClick={() =>
                      navigate(
                        `/third-party-risk/privacy/details?review_id=${row.review_id}&framework_id=${row.framework_ids[0]}&audit_id=${row.audit_id}`
                      )
                    }
                  >
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left break-words"
                        >
                          <RowValue row={row} col={col} />
                          {colIndex === filteredHeaders.length - 1 && (
                            <button
                              disabled={isReadOnly}
                              className="absolute top-1/3 right-5 download-button"
                              onClick={(e) => {
                                e.stopPropagation();
                                let a = document.createElement("a");
                                a.href = row.download_uri;
                                a.target = "_blank";
                                a.click();
                              }}
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </button>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="reviews placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Privacy Documents</h4>
            <h4>No privacy documents available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default PrivacyDocumentList;
