import React, { useState } from "react";
import { CreateCustomer } from "src/services/settings/organization";
import { CustomerInput } from "src/types/settings";
import { checkIsSuperAdmin } from "src/utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";
import CheckboxInput from "src/components/Input/CheckboxInput";

const NewCustomer = () => {
  const isSuperAdmin = checkIsSuperAdmin();

  const [show, setShow] = useState<boolean>(false);
  const [org, setOrg] = useState<CustomerInput>({
    customer_name: "",
    customer_alias: "",
    address: "",
    auth_scheme: "BASIC",
    auth_provider: "UNO",
    domain: "",
    site_admin: false,
  });

  const createCustomer = CreateCustomer();

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="blue-button"
        onClick={() => {
          setShow(true);
          setOrg({
            customer_name: "",
            customer_alias: "",
            address: "",
            auth_scheme: "BASIC",
            auth_provider: "UNO",
            domain: "",
            site_admin: false,
          });
        }}
      >
        Add Customer
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Customer</h3>
          <RegularInput
            label="Customer Name"
            keyName="customer_name"
            inputs={org}
            setInputs={setOrg}
          />
          <RegularInput
            label="Customer Alias"
            keyName="customer_alias"
            inputs={org}
            setInputs={setOrg}
          />
          <RegularInput
            label="Address"
            keyName="address"
            inputs={org}
            setInputs={setOrg}
          />
          {isSuperAdmin && (
            <CheckboxInput
              label="Site Admin"
              keyName="site_admin"
              inputs={org}
              setInputs={setOrg}
            />
          )}

          {/* check if using external provider */}
          <article className="flex items-start gap-2">
            <input
              type="checkbox"
              className="white-checkbox"
              onChange={() => {
                if (org.auth_scheme === "BASIC")
                  setOrg({
                    ...org,
                    auth_scheme: "OIDC",
                    auth_provider: "GOOGLE",
                  });
                else
                  setOrg({
                    ...org,
                    auth_scheme: "BASIC",
                    auth_provider: "UNO",
                  });
              }}
            />
            <label>Use OIDC via Google Login</label>
          </article>

          {/* enter domain name if using external provider */}
          {org.auth_scheme === "OIDC" && (
            <RegularInput
              label="Domain"
              keyName="domain"
              inputs={org}
              setInputs={setOrg}
            />
          )}
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={org.customer_name === "" || org.customer_alias === ""}
              className="blue-button"
              onClick={() => {
                createCustomer.mutate({ customer: org });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewCustomer;
