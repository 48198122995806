/* eslint-disable react-hooks/exhaustive-deps */
import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { showVariants } from "src/constants/general";
import { businessContinuityTabs } from "src/constants/grc";
import PageLayout from "src/layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";
import SOPList from "./SOP/SOPList";
import BIAList from "./BIA/BIAList";
import NewSOP from "./SOP/NewSOP";
import NewBIA from "./BIA/NewBIA";

const BusinessContinuity = () => {
  const { GRCCategory, setGRCCategory } = useGRCStore();

  useEffect(() => {
    sessionStorage.page = "Business Continuity";
    if (businessContinuityTabs.includes(sessionStorage.GRCCategory))
      setGRCCategory(sessionStorage.GRCCategory);
    else setGRCCategory("standard operating procedures");
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-4 p-4 pb-24 w-full min-h-full"
      >
        <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
          <nav className="flex flex-wrap items-center gap-1 p-1 dark:bg-gray-900 rounded-lg">
            {businessContinuityTabs.map((tab) => {
              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    GRCCategory === tab
                      ? "dark:text-white dark:bg-gray-800"
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={() => {
                    sessionStorage.GRCCategory = tab;
                    setGRCCategory(tab);
                  }}
                >
                  {tab}
                </button>
              );
            })}
          </nav>
          {GRCCategory === "standard operating procedures" ? (
            <NewSOP />
          ) : (
            <NewBIA />
          )}
        </section>
        {GRCCategory === "standard operating procedures" ? (
          <SOPList />
        ) : (
          <BIAList />
        )}
      </motion.main>
    </PageLayout>
  );
};

export default BusinessContinuity;
