import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { GetQuestionTypes } from "src/services/third-party-risk/questions/global-questions";
import { checkIsReadOnly } from "src/utils/general";

const QuestionTypeFilter = ({
  inputs,
  setInputs,
  isPublished,
}: {
  inputs: any;
  setInputs: any;
  isPublished?: boolean;
}) => {
  const isReadOnly = checkIsReadOnly();

  const { data: questionTypes } = GetQuestionTypes();

  return (
    <Popover className="relative text-b2-reg">
      <Popover.Button disabled={isReadOnly || isPublished}>
        <article className="flex items-center justify-between gap-2 px-4 py-2 w-[12rem] dark:bg-gray-900 border dark:border-gray-700 divide-x dark:divide-gray-700 rounded">
          <h4>
            {inputs.question_type === "Yes_No" ? "Y/N" : inputs.question_type}
          </h4>
          <FontAwesomeIcon
            icon={faFilter}
            className="pl-2 dark:text-blue-600"
          />
        </article>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
      >
        <Popover.Panel className="absolute left-0 top-12 z-10">
          {({ close }) => (
            <section className="grid gap-1 p-4 w-full max-h-[30rem] dark:bg-gray-700 black-shadow overflow-auto scrollbar rounded-lg">
              {questionTypes?.fields.map((curQuestionType: string) => {
                return (
                  <article
                    key={curQuestionType}
                    className="flex items-start gap-1"
                  >
                    <input
                      type="radio"
                      className="blue-radio"
                      checked={
                        inputs.question_type.toLowerCase() ===
                        curQuestionType.toLowerCase()
                      }
                      onChange={() => {
                        setInputs({
                          ...inputs,
                          question_type: curQuestionType,
                          multi_answers: [],
                        });
                      }}
                    />
                    <label htmlFor="">
                      {curQuestionType === "Yes_No" ? "Y/N" : curQuestionType}
                    </label>
                  </article>
                );
              })}
            </section>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default QuestionTypeFilter;
