import React, { useState } from "react";
import UpdateQAPair from "./UpdateQAPair";
import DeleteQAPair from "./DeleteQAPair";

const QADetail = ({
  questionBankID,
  question,
}: {
  questionBankID: string;
  question: any;
}) => {
  const questionID = question.generated_id;

  const [show, setShow] = useState<boolean>(false);

  const filteredHeaders = ["questions", "answers"];

  return (
    <tr className="relative p-5 gap-3 w-full break-words dark:bg-gray-800">
      {filteredHeaders?.map((col: string, colIndex: number) => {
        return (
          <td
            key={`${questionID}-${colIndex}`}
            className="relative px-6 py-3 last:pr-20 text-left"
          >
            <article className="flex items-start gap-2">
              {col === "answers" ? (
                <article className="grid content-start gap-3 w-full">
                  <p>
                    {question.response?.slice(
                      0,
                      show ? question.response?.length : 100
                    )}
                    {question.response?.length > 100 && !show && "..."}
                  </p>
                  {question.response?.length >= 100 && (
                    <button
                      className="w-max underline dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                      onClick={() => setShow(!show)}
                    >
                      {show ? "Hide" : "Show"}
                    </button>
                  )}
                </article>
              ) : (
                <p>{question.question}</p>
              )}
              {colIndex === filteredHeaders.length - 1 && (
                <article className="flex items-center absolute right-5 top-1/3">
                  <UpdateQAPair
                    questionBankID={questionBankID}
                    questionID={questionID}
                    question={question}
                  />
                  <DeleteQAPair
                    questionBankID={questionBankID}
                    questionID={questionID}
                  />
                </article>
              )}
            </article>
          </td>
        );
      })}
    </tr>
  );
};

export default QADetail;
