import React, { useState } from "react";
import { parseURL, sortRows } from "../../../../../utils/general";
import {
  GetControlReportStats,
  GetControlReportTable,
} from "../../../../../services/regulation-policy/overview";
import PageLayout from "../../../../../layouts/PageLayout";
import { motion } from "framer-motion";
import { initialSort, showVariants } from "../../../../../constants/general";
import ReturnPage from "../../../../../components/Button/ReturnPage";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import TableLayout from "src/layouts/TableLayout";
import SortColumn from "src/components/Button/SortColumn";
import { ListHeader } from "../../../../../types/general";
import { Switch } from "@headlessui/react";
import ExportFile from "./ExportFile";
import RowValue from "src/components/General/RowValue";

const CoverageReport = () => {
  const parsed = parseURL();

  const [sort, setSort] = useState(initialSort);
  const [isGaps, setIsGaps] = useState<boolean>(false);

  const frameworkID = String(parsed.report_id) || "";
  const frameworkName = String(parsed.report_name) || "";
  const reportType = String(parsed.report_type) || "";
  const coverage = isGaps
    ? (100 - Number(parsed.coverage)).toFixed(2) || 0
    : Number(parsed.coverage);

  const type =
    reportType === "control"
      ? isGaps
        ? "control_gaps"
        : "control"
      : isGaps
      ? "gaps"
      : "coverage";

  const { data: controlStats } = GetControlReportStats(frameworkID, type);
  const { data: controlTable } = GetControlReportTable(frameworkID, type);

  const sortedTable = sortRows(controlTable?.data, sort);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="grid content-start gap-4 p-4 w-full h-full text-sm overflow-auto scrollbar"
      >
        <header className="grid gap-4">
          <article className="flex items-center gap-2">
            <ReturnPage />
            <h4 className="flex items-center gap-2 text-b3-reg">
              <span className="dark:text-gray-500">Overview</span>{" "}
              <span>/</span>
              <span>{frameworkName}</span>
            </h4>
          </article>
        </header>
        <section className="flex flex-row flex-grow gap-4">
          <article className="flex flex-col flex-grow gap-4 p-8 text-center dark:bg-gray-800 rounded-lg">
            <h2 className="text-subt1-semi">{isGaps ? "Gaps" : "Coverage"}</h2>
            <article className="mx-auto w-56 h-56">
              <CircularProgressbarWithChildren
                strokeWidth={10}
                value={coverage}
                maxValue={100}
                styles={buildStyles({
                  trailColor: "#111827",
                  pathColor: "#fcba03",
                })}
              >
                <span className="text-h5">{coverage}%</span>
              </CircularProgressbarWithChildren>
            </article>
            <article className="flex items-center gap-2 mx-auto">
              <Switch
                checked={isGaps}
                onChange={setIsGaps}
                className={`${
                  isGaps ? "bg-[#30CD9A]" : "bg-gray-500"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    isGaps ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <span className="text-b1-semi">Gaps</span>
            </article>
          </article>
          {reportType !== "control" &&
            controlStats &&
            Object.keys(controlStats).length > 0 && (
              <section className="flex flex-col flex-grow gap-10 p-8 dark:bg-gray-800 rounded-lg">
                {Object.keys(controlStats).map((key) => {
                  if (Object.keys(controlStats[key]).length === 0) return null;
                  return (
                    <article
                      key={key}
                      className="flex flex-col flex-grow gap-2"
                    >
                      <h4 className="text-center text-subt1-semi capitalize">
                        {key}
                      </h4>
                      <ul className="grid grid-cols-2 content-start gap-x-5 gap-y-2 w-full text-b1-reg">
                        {Object.entries(controlStats[key]).map((keyVal) => {
                          const pct =
                            (keyVal[1][isGaps ? "unmapped" : "mapped"] /
                              keyVal[1].total) *
                            100;
                          return (
                            <li
                              key={keyVal[0]}
                              className={`px-2 py-1 w-full ${
                                pct >= 66.6
                                  ? "bg-green-700"
                                  : pct >= 33.3
                                  ? "bg-yellow-600"
                                  : "bg-red-700"
                              } rounded`}
                            >
                              {keyVal[0]}:{" "}
                              {keyVal[1][isGaps ? "unmapped" : "mapped"]} /{" "}
                              {keyVal[1].total}
                            </li>
                          );
                        })}
                      </ul>
                    </article>
                  );
                })}
              </section>
            )}
        </section>

        <section className="grid gap-2 text-base">
          <ExportFile documentID={frameworkID} type={type} />
          {controlTable ? (
            sortedTable?.length > 0 ? (
              <TableLayout fullHeight>
                <thead className="sticky -top-1.5 dark:bg-gray-900">
                  <tr>
                    {controlTable.header.map((col: ListHeader) => {
                      return (
                        <th
                          scope="col"
                          key={col.display_name}
                          className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                        >
                          <article className="capitalize flex gap-10 justify-between">
                            <h4>{col.display_name}</h4>
                            <SortColumn
                              propertyName={col.property_name}
                              setSort={setSort}
                            />
                          </article>
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="divide-y dark:divide-gray-700">
                  {sortedTable?.map((row: any, rowIndex: number) => {
                    return (
                      <tr
                        key={rowIndex}
                        className="relative p-5 gap-3 break-words dark:bg-gray-800"
                      >
                        {controlTable.header.map(
                          (col: ListHeader, index: number) => {
                            return (
                              <td
                                key={index}
                                className="relative px-6 py-3 last:pr-16 text-left break-words"
                              >
                                <RowValue row={row} col={col.property_name} />
                              </td>
                            );
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </TableLayout>
            ) : (
              <p>No data available</p>
            )
          ) : null}
        </section>
      </motion.main>
    </PageLayout>
  );
};

export default CoverageReport;
