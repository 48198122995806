import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import {
  GetSourceControls,
  GetSourceNames,
} from "../../../services/erc/controls/control-blueprints";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SourceControlFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  allowUnselect,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  allowUnselect?: boolean;
  required?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: sourceNames } = GetSourceNames();
  const frameworkID =
    sourceNames?.find(
      (framework) => framework.framework_short_name === inputs.source_name
    )?.generated_framework_id || "";

  const { data: sourceControls } = GetSourceControls(frameworkID);

  const controls = [
    ...new Set(
      sourceControls?.data.reduce(
        (pV, cV) => [...pV, cV.control_id.replaceAll(" ", "")],
        []
      )
    ),
  ] as string[];

  const filteredItems = controls?.filter((item: string) =>
    item
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <article className="grid text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="text-b1-reg text-red-500">*</span>}
      </header>{" "}
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={query ? query : inputs[keyName]}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {filteredItems?.map((item: string) => {
              const selected = inputs[keyName] === item;
              return (
                <button
                  key={item}
                  className={`relative flex items-center gap-2 capitalize px-4 py-1 text-left break-words dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (allowUnselect && selected)
                      setInputs({
                        ...inputs,
                        [keyName]: "",
                        source_sub_control_id: "",
                      });
                    else
                      setInputs({
                        ...inputs,
                        [keyName]: item,
                        source_sub_control_id: "",
                      });
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} />}
                  {item}
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default SourceControlFilter;
