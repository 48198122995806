import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React from "react";
import { GetPoliciesFromGroup } from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";

const Policies = ({
  policyGroup,
  policyGroupID,
  selectedPolicyIDs,
  setSelectedPolicyIDs,
}: {
  policyGroup: any;
  policyGroupID: string;
  selectedPolicyIDs: string[];
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
}) => {
  const { data: policies } = GetPoliciesFromGroup(policyGroupID);

  const policyIDs = policies?.data?.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
    []
  );

  const allSelected = policyIDs?.every((policyID: string) =>
    selectedPolicyIDs.includes(policyID)
  );

  return (
    <Disclosure defaultOpen>
      {({ open }) => (
        <>
          <header className="flex items-center justify-between gap-4">
            <article className="flex items-start gap-4">
              <input
                type="checkbox"
                checked={allSelected}
                onChange={() => {
                  if (allSelected)
                    setSelectedPolicyIDs(
                      selectedPolicyIDs.filter(
                        (policyID) => !policyIDs.includes(policyID)
                      )
                    );
                  else
                    setSelectedPolicyIDs([...selectedPolicyIDs, ...policyIDs]);
                }}
                className="white-checkbox"
              />
              <h3 className="text-lg">{policyGroup.title}</h3>
            </article>
            <Disclosure.Button className="flex items-center justify-between gap-4 text-b1-reg">
              <FontAwesomeIcon
                icon={open ? faChevronUp : faChevronDown}
                className="dark:text-blue-600"
              />
            </Disclosure.Button>
          </header>

          <Disclosure.Panel className="grid gap-4">
            {policies ? (
              policies?.data?.length > 0 ? (
                <section className="grid gap-4">
                  <ul className="flex flex-col flex-grow gap-3 pl-8 pb-2 text-b1-reg overflow-auto scrollbar">
                    {policies?.data?.map(
                      (policy: KeyStringVal, index: number) => {
                        const selected = selectedPolicyIDs.includes(
                          policy.policy_id
                        );
                        return (
                          <li
                            key={index}
                            className="flex flex-row flex-grow items-start gap-4 break-words"
                          >
                            <input
                              type="checkbox"
                              checked={selected}
                              className="white-checkbox"
                              onChange={() => {
                                if (selected)
                                  setSelectedPolicyIDs(
                                    selectedPolicyIDs.filter(
                                      (policyID) =>
                                        policy.policy_id !== policyID
                                    )
                                  );
                                else
                                  setSelectedPolicyIDs([
                                    ...selectedPolicyIDs,
                                    policy.policy_id,
                                  ]);
                              }}
                            />
                            <article className="flex items-start gap-2 px-2 py-1 w-full dark:bg-gray-700 rounded">
                              <img
                                src={policy.thumbnail_uri}
                                alt={policy.regulatory_authority}
                                className="w-6 h-6 rounded-full"
                              />
                              <h4>{policy.policy_name}</h4>
                            </article>
                          </li>
                        );
                      }
                    )}
                  </ul>
                </section>
              ) : (
                <p>No policies available</p>
              )
            ) : null}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default Policies;
