/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import AssessmentList from "./AssessmentList/AssessmentList";
import Questions from "./Questions/Questions";
import { useERCStore } from "src/stores/erc";
import { SearchGRC } from "src/services/grc";

const Assessments = () => {
  const { selectedERCTab, setSelectedERCTab } = useERCStore();

  const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);

  const search = SearchGRC();

  useEffect(() => {
    if (sessionStorage.selectedERCTab)
      setSelectedERCTab(sessionStorage.selectedERCTab);
    if (selectedERCTab === "") setSelectedERCTab("assessments");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-4 w-full">
      <nav className="flex flex-wrap items-center">
        {["assessments", "questions"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize ${
                selectedERCTab === tab
                  ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => {
                setSelectedERCTab(tab);
                sessionStorage.selectedERCTab = tab;
              }}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {selectedERCTab === "assessments" ? (
        <AssessmentList />
      ) : (
        <Questions
          selectedQuestions={selectedQuestions}
          setSelectedQuestions={setSelectedQuestions}
          search={search}
        />
      )}
    </section>
  );
};

export default Assessments;
