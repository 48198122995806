import React from "react";
import PieWithNeedle from "src/components/Chart/PieWithNeedle";
import { GetOverallRiskScore } from "src/services/third-party-risk/vendors/vendor-dashboard";

const OverallRiskScore = () => {
  const { data } = GetOverallRiskScore();

  return (
    <section className="flex flex-col flex-grow gap-5 p-6 dark:bg-gray-800 rounded-2xl">
      <PieWithNeedle title="Overall Risk Score" value={data?.value} />
    </section>
  );
};

export default OverallRiskScore;
