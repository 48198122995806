import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

export const vendorPrefix = "grc/tprm/third-parties";

export const GetVendors = (
  pageNumber?: number,
  tags?: string[] | undefined,
  groups?: string[],
  names?: string[],
  columns?: string[],
  queryList?: any
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-vendors", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            ...(tags?.length > 0 && { tags }),
            ...(groups?.length > 0 && { group_name_list: groups }),
            ...(names?.length > 0 && { vendor_name_list: names }),
            ...(columns?.length >= 5 && { selected_columns: columns }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const GetFrameworksWithControls = (onlyFlagged: boolean) =>
  useQuery<any, unknown, any, (string | boolean)[]>(
    ["get-frameworks-with-controls", onlyFlagged],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/settings/framework-controls`,
          { only_flagged: onlyFlagged },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {}
  );

export const FlagControls = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/settings/flagged-controls`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-frameworks-with-controls"]),
    }
  );

export const AddVendor = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const AddVendorLogo = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vendorID,
      formData,
      signal,
    }: {
      vendorID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/logo`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
      },
    }
  );

export const EditVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-metadata"]);
      },
    }
  );

export const EditVendorLogo = () =>
  useMutation<any, unknown, any, string>(
    async ({
      vendorID,
      formData,
      signal,
    }: {
      vendorID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/logo`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
      },
    }
  );

export const RemoveVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const ActivateVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/activate`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const DeactivateVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/deactivate`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-assessment-cycle-vendor-list"]);
      },
    }
  );

export const GetVendorMetadata = (vendorID: string, assessmentID?: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-vendor-metadata", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const SearchVendor = () =>
  useMutation<any, unknown, any, string[]>(
    async ({ vendor, signal }: { vendor: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/searches`,
          {
            third_party_name: vendor,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorAuditReports = (
  vendorID: string,
  assessmentID: string,
  order: KeyStringVal,
  pageNumber: number,
  category?: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    [
      "get-vendor-audit-reports",
      vendorID,
      assessmentID,
      order,
      pageNumber,
      category,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/tprm/assessments`,
          {
            third_party_id: vendorID,
            assessment_id: assessmentID,
            ...(order.order_by !== "" && { order: order }),
            page_number: pageNumber,
            page_size: pageSize,
            ...(category && { category: category }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined,
  documentID: string,
  pageNumber: number,
  filter: string,
  tags?: string[]
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    [
      "get-vendor-responses",
      vendorID,
      assessmentID,
      documentID,
      pageNumber,
      filter,
      tags,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/all${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            document_id: documentID,
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            filter,
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const SubmitVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string[]>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-vendor-responses"]),
    }
  );

export const AddVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      question,
      response,
      signal,
    }: {
      question: string;
      response: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/new${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question, response },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const EditVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      question,
      response,
      signal,
    }: {
      questionID: string;
      question: string;
      response: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question, response },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const RemoveVendorResponse = (
  vendorID: string,
  assessmentID: string | undefined,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const ExportVendorResponses = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/questions/as-file${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorTags = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-tags"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/tags`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const CreateTagForVendors = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/tags`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-tags"]);
      },
    }
  );

export const DeleteTagFromVendors = () =>
  useMutation<any, unknown, any, string>(
    async ({ tagID, signal }: { tagID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${vendorPrefix}/tags/${tagID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-tags"]);
      },
    }
  );

export const GetTagsForVendor = (vendorID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-tags-for-vendor", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/tags`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-vendor"]);
      },
    }
  );

export const RemoveTagFromVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendors"]);
        queryClient.invalidateQueries(["get-vendor-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-vendor"]);
      },
    }
  );

export const UploadDocuments = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/upload-documents${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetDocumentTypes = () =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-document-types"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/document-types`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorDocuments = (vendorID: string, assessmentID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-documents", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const RemoveDocument = (
  vendorID: string,
  assessmentID: string,
  documentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/${documentID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-documents"]);
      },
    }
  );

export const PostQuestions = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questions,
      signal,
    }: {
      questions: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { questions },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const RemoveResponse = (
  vendorID: string,
  assessmentID: string | undefined,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/qna/delete-response?question_id=${questionID}${
            assessmentID ? `&assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const ResubmitQuestion = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      signal,
    }: {
      questionID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/resubmit${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { question_id: questionID },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const GetDocumentQnAList = (
  vendorID: string,
  assessmentID: string,
  pageNumber: number,
  query: string
) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-document-qna-list", assessmentID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question-history${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            query: query,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetVendorQuestions = (
  vendorID: string,
  assessmentID: string | undefined
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-questions", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question/all${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            page_number: 1,
            page_size: 10000,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetVendorDrafts = (vendorID: string, assessmentID: string) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-vendor-drafts", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question/drafts${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      refetchInterval: 60000,
    }
  );

export const GetDraftStatus = (vendorID: string, draftID: string) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-draft-status", vendorID, draftID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question/drafts/${draftID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "" && !["1", "2"].includes(draftID),
      keepPreviousData: false,
      refetchInterval: 60000,
    }
  );

export const MarkDraftAsComplete = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      draftID,
      questions,
      signal,
    }: {
      draftID: string;
      questions: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question/drafts/${draftID}`,
          {
            questions: questions,
          },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-drafts", vendorID]);
      },
    }
  );

export const SubmitQuestionnaire = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question/drafts/new${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-drafts"]);
        queryClient.invalidateQueries(["get-draft-status"]);
      },
    }
  );

export const GetQnANotes = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-qna-notes", vendorID, assessmentID, questionID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/${questionID}/get-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const PostQnANote = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      user,
      notes,
      signal,
    }: {
      user: string;
      notes: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/${questionID}/assign-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { assigned_to_user: user, notes_txt: notes },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-qna-notes"]);
      },
    }
  );

export const GetVendorResponseNotes = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useQuery<any, unknown, any, (string | (string | null)[])[]>(
    ["get-vendor-response-notes", vendorID, assessmentID, questionID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/${questionID}/get-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const PostVendorResponseNote = (
  vendorID: string,
  assessmentID: string,
  questionID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      user,
      notes,
      signal,
    }: {
      user: string;
      notes: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/responses/${questionID}/add-notes${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { assigned_to_user: user, notes_txt: notes },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-vendor-responses"]);
      },
    }
  );

export const ExportResponses = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      includesCitations,
      questions,
      signal,
    }: {
      includesCitations: boolean;
      questions: any;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question-history/export${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { include_citations: includesCitations, questions: questions },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-responses-status",
          vendorID,
          assessmentID,
        ]);
      },
    }
  );

export const GetExportResponsesStatus = (
  vendorID: string,
  assessmentID: string
) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-export-responses-status", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question-history/export/status${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const DownloadResponses = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question-history/export${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-responses-status",
          vendorID,
          assessmentID,
        ]);
      },
    }
  );

export const GetQuestionBanks = (pageNumber: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-question-banks", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/question-banks/all`,
          { page_size: pageSize, page_number: pageNumber },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const AddQAPair = () =>
  useMutation<any, unknown, any, string>(
    async ({
      questionSetID,
      question,
      answer,
      signal,
    }: {
      questionSetID: string;
      question: string;
      answer: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/question-sets/${questionSetID}/questions/add`,
          { to_add: [{ question: question, answer: answer }] },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-custom-question-sets"]);
      },
    }
  );

export const EditAnswer = (vendorID: string, assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      questionID,
      answer,
      signal,
    }: {
      questionID: string;
      answer: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/question-history/${questionID}${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          { answer: answer },
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-document-qna-list"]);
      },
    }
  );

export const SearchVendorGroup = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      groupName,
      signal,
    }: {
      groupName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/search_group`,
          { group_name: groupName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SearchVendorName = () =>
  useMutation<any, unknown, any, string[]>(
    async ({
      vendorName,
      signal,
    }: {
      vendorName: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/search_vendor`,
          { vendor_name: vendorName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const SendEmailToVendor = (vendorID: string, assessmentID?: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/send-email${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetVendorFieldValues = (fieldName: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-vendor-field-values", fieldName],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/field/${fieldName}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: fieldName !== "",
      keepPreviousData: false,
    }
  );

export const AddAssessmentToVendor = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/adhoc-assessment`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-assessments-for-vendor"]),
    }
  );

export const GetAssessmentsForVendor = (
  vendorID: string,
  assessmentID: string,
  pageNumber: number
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-assessments-for-vendor", vendorID, assessmentID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/assessments`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            assessment_id: assessmentID,
            category: "VENDOR",
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "" && assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetRiskClassifications = (
  vendorID: string,
  assessmentID: string
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-classifications", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/risks/classification${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetRisksForVendor = (vendorID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-for-vendor", vendorID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/risks/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetEntitiesForVendor = (vendorID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-entities-for-vendor", vendorID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/entities/getall`,
          pageNumber
            ? {
                page_number: pageNumber,
                page_size: pageSize,
              }
            : null,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const GetInherentCheck = (vendorID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-inherent-check", vendorID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/inherent-check`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "",
      keepPreviousData: false,
    }
  );

export const PreAssessmentCheck = (vendorID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/pre-inherent-check`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-inherent-check"]);
      },
    }
  );

export const GetAssessmentRisksForVendor = (
  vendorID: string,
  assessmentID: string,
  classification: string,
  pageNumber?: number
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    [
      "get-assessment-risks-for-vendor",
      vendorID,
      assessmentID,
      classification,
      pageNumber,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/assessment-risks/getall${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            classification,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "" && assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetDocumentMetrics = (vendorID: string, assessmentID: string) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-document-metrics", vendorID, assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/${vendorID}/documents/metrics${
            assessmentID ? `?assessment_id=${assessmentID}` : ""
          }`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: vendorID !== "" && assessmentID !== "",
    }
  );

export const ExportAllVendors = () =>
  useMutation<any, unknown, any, (string | (string | null)[] | null)[]>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        await client.post(`/api/${apiVersion}/${vendorPrefix}/exportall`, {
          signal,
        });
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-vendors-export-status"]),
    }
  );

export const GetVendorsExportStatus = () =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-vendors-export-status"],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${vendorPrefix}/exportall/status`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      refetchInterval: 30000,
      keepPreviousData: false,
    }
  );

export const DownloadAllVendors = () =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${vendorPrefix}/exportall/download`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-vendors-export-status"]),
    }
  );
