import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
} from "src/utils/general";
import { GetAttachmentMetadata } from "src/services/erc/attachments/attachments";
import ReturnPage from "src/components/Button/ReturnPage";
import { GetAllUsers } from "src/services/settings/users";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AttachmentMetadata = ({ attachmentID }: { attachmentID: string }) => {
  const customerID = getCustomerID();

  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: metadata } = GetAttachmentMetadata(attachmentID);

  const uri = metadata?.attachment_url;

  const fileType = uri?.slice(uri?.lastIndexOf(".") + 1, uri?.indexOf("?"));

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Attachment</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-10">
              <article className="flex flex-col flex-grow gap-1">
                <h2 className="text-subt1-semi">{metadata.attachment_name}</h2>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Uploaded by{" "}
                    {getEmailFromUserID(allUsers, metadata.uploaded_by)}
                  </h4>
                  <span>| {convertToUTCString(metadata.uploaded_at)}</span>
                </article>
              </article>
            </header>
            <section className="flex flex-col flex-grow gap-4">
              <article className="flex items-center place-content-end gap-4">
                <button
                  className="blue-button"
                  onClick={() => {
                    let a = document.createElement("a");
                    a.href = uri;
                    a.target = "_blank";
                    a.click();
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download as File
                </button>
              </article>
              <DocViewer
                documents={[
                  {
                    uri,
                    fileType,
                  },
                ]}
                pluginRenderers={DocViewerRenderers}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                }}
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default AttachmentMetadata;
