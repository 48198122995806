/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { AddControl } from "../../../../services/erc/controls/controls";
import ToastLayout from "../../../../layouts/ToastLayout";
import UserInput from "../../../../components/Input/UserInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import { checkIsReadOnly } from "src/utils/general";
import CategoryInput from "src/components/Input/CategoryInput";
import ControlFilter from "src/components/Filter/ERC/ControlFilter";
import {
  SearchCategory,
  SearchClassification,
  SearchFrequency,
  SearchSubCategory,
  SearchType,
} from "src/services/erc/erc";
import EntityFilter from "src/components/Filter/ERC/EntityFilter";

const NewControl = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const addControl = AddControl();
  const categories = SearchCategory();
  const subCategories = SearchSubCategory();
  const types = SearchType();
  const classifications = SearchClassification();
  const frequencies = SearchFrequency();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addControl.reset();

  useEffect(() => {
    categories.mutate({
      inputs: {
        control_category: "",
      },
    });
    types.mutate({
      inputs: {
        control_type: "",
      },
    });
    classifications.mutate({
      inputs: {
        control_classification: "",
      },
    });
    frequencies.mutate({
      inputs: {
        frequency: "",
      },
    });
  }, []);

  useEffect(() => {
    if (inputs.category)
      subCategories.mutate({
        inputs: {
          control_category: inputs.category,
          control_sub_category: "",
        },
      });
  }, [inputs.category]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            control_reference_id: "",
            reviewer: null,
            same_as_entity_owner: false,
            grc_entity_id: "",
            owner: null,
            category: "",
            sub_category: "",
            classification: "",
            type: "",
            frequency: "",
            parent_control_id: null,
            description: "",
            is_key_control: false,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Control</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Control</h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="grc_entity_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <ControlFilter
              label="Parent Control"
              keyName="parent_control_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Control Reference Id"
              keyName="control_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Is Key Control"
              keyName="is_key_control"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Category"
              keyName="category"
              list={categories.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Subcategory"
              keyName="sub_category"
              list={subCategories.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Type"
              keyName="type"
              list={types.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="Classification"
              keyName="classification"
              list={classifications.data}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              disabled={inputs.same_as_entity_owner}
              allowUnselect
            />
            <CategoryInput
              label="Frequency"
              keyName="frequency"
              list={frequencies.data}
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <CheckboxInput
              label="Same as entity owner"
              keyName="same_as_entity_owner"
              inputs={inputs}
              setInputs={setInputs}
            />
            <UserInput
              label="Reviewer"
              keyName="reviewer"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                !inputs.name || !inputs.grc_entity_id || !inputs.frequency
              }
              className="blue-button"
              onClick={() => {
                addControl.mutate({
                  inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addControl.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New control has been created"
      />
    </>
  );
};

export default NewControl;
