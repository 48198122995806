/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkIsReadOnly } from "src/utils/general";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import Condition from "./Condition";
import { KeyStringVal } from "src/types/general";
import { GetTableColumns } from "src/services/grc";

const QueryBuilder = ({
  prefix,
  refetch,
  queryList,
  setQueryList,
}: {
  prefix: string;
  refetch: any;
  queryList: any;
  setQueryList: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [activeItem, setActiveItem] = useState<any>({});

  const { data: fieldValues } = GetTableColumns(prefix);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = queryList.findIndex(
        (response: KeyStringVal) => response.id === e.active.id
      );
      const newIdx = queryList.findIndex(
        (response: KeyStringVal) => response.id === e.over!.id
      );
      setQueryList(arrayMove(queryList, oldIdx, newIdx));
    }
  };

  const handleAddCondition = (condition: string) => {
    setQueryList([
      ...queryList,
      {
        id: queryList.length + 1,
        key: "",
        operator: "",
        value: "",
        condition: condition,
      },
    ]);
  };
  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end black-button"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faSearch} />
        <h4>Query Builder</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Query Builder
          </h3>
          <section className="grid content-start gap-4 p-4 h-[27rem] dark:bg-gray-900 rounded-xl overflow-auto scrollbar">
            <DndContext
              sensors={sensors}
              onDragStart={(e) =>
                setActiveItem(
                  queryList.find(
                    (item: KeyStringVal) => item.id === e.active.id
                  )
                )
              }
              onDragEnd={reorderQuestionsList}
            >
              <SortableContext items={queryList}>
                {queryList?.map((condition: any) => {
                  return (
                    <Condition
                      key={condition.id}
                      fieldValues={fieldValues}
                      condition={condition}
                      queryList={queryList}
                      setQueryList={setQueryList}
                    />
                  );
                })}
              </SortableContext>

              <DragOverlay
                dropAnimation={{
                  duration: 150,
                  easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
                }}
              >
                {activeItem ? (
                  <Condition
                    fieldValues={fieldValues}
                    condition={activeItem}
                    queryList={queryList}
                    setQueryList={setQueryList}
                  />
                ) : null}
              </DragOverlay>
            </DndContext>
            <article className="flex items-center place-content-end gap-4">
              {["AND", "OR"].map((condition) => {
                return (
                  <button
                    key={condition}
                    className="small-blue-text-button"
                    onClick={() => handleAddCondition(condition)}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    {condition} CONDITION
                  </button>
                );
              })}
            </article>
          </section>

          <article className="flex items-center place-content-end gap-4">
            <button
              className="red-text-button"
              onClick={() => {
                setQueryList([]);
                refetch();
                handleOnClose();
              }}
            >
              Clear
            </button>
            <button
              disabled={
                queryList.length === 0 ||
                (queryList.length > 0 &&
                  queryList.some(
                    (condition: KeyStringVal) =>
                      !condition.key || !condition.operator || !condition.value
                  ))
              }
              className="small-blue-button"
              onClick={() => {
                refetch();
                handleOnClose();
              }}
            >
              <h4>Run Query</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default QueryBuilder;
