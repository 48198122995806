/* eslint-disable react-hooks/exhaustive-deps */
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DraftDetail from "./DraftDetail/DraftDetail";
import { KeyStringArrayVal, KeyStringVal } from "src/types/general";
import {
  GetVendorDrafts,
  MarkDraftAsComplete,
  PostQuestions,
} from "../../../../../../../services/third-party-risk/vendors/vendors";
import { GetCustomQuestions } from "../../../../../../../services/third-party-risk/questions/custom-question-sets";
import { checkIsReadOnly } from "src/utils/general";

const DraftList = ({
  vendorID,
  assessmentID,
  inputs,
  setInputs,
  setNav,
  selectedQuestionSet,
  setSelectedQuestionSet,
}: {
  vendorID: string;
  assessmentID: string;
  inputs: any;
  setInputs: any;
  setNav: (nav: number) => void;
  selectedQuestionSet: KeyStringVal;
  setSelectedQuestionSet: (selectedQuestionSet: KeyStringVal) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [draftIsEdited, setDraftIsEdited] = useState<boolean>(false);

  const markDraftAsComplete = MarkDraftAsComplete(vendorID);
  const postQuestions = PostQuestions(vendorID, assessmentID);
  const { data: drafts } = GetVendorDrafts(vendorID, assessmentID);
  const { data: customQuestions } = GetCustomQuestions(
    selectedQuestionSet.generated_id,
    1
  );

  const questions = inputs.drafts
    .reduce(
      (pV: string[], cV: KeyStringArrayVal) => [...pV, ...cV.questions],
      []
    )
    .filter((question: string) => question !== "");

  const disableSubmit =
    isReadOnly ||
    questions?.length === 0 ||
    inputs.drafts.some(
      (curDraft: KeyStringVal) => curDraft.questions.length === 0
    );

  const handleSubmit = () => {
    drafts?.forEach((draft: any) => {
      markDraftAsComplete.mutate({
        draftID: draft.draft_question_list_id,
        questions: draft.questions,
      });
    });
    postQuestions.mutate({
      questions,
    });
    setInputs({ drafts: [] });
    setNav(2);
    setDraftIsEdited(false);
    setSelectedQuestionSet({ generated_id: "", document_name: "" });
  };

  useEffect(() => {
    if (customQuestions?.data.length > 0) {
      let tempDrafts = inputs.drafts;
      const draftIDs = inputs.drafts.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.draft_question_list_id],
        []
      );
      const questions = customQuestions.data.reduce(
        (pV, cV) => [...pV, cV.question],
        []
      );
      if (!draftIDs.includes("2"))
        tempDrafts = [
          ...inputs.drafts,
          {
            draft_question_list_id: "2",
            draft_document_name: selectedQuestionSet.name,
            questions: questions,
          },
        ];
      else
        tempDrafts = tempDrafts.map((tempDraft: KeyStringVal) => {
          if (tempDraft.draft_question_list_id === "2")
            return {
              ...tempDraft,
              draft_document_name: selectedQuestionSet.name,
              questions: questions,
            };
          else return tempDraft;
        });
      setInputs({
        drafts: tempDrafts,
      });
    }
  }, [customQuestions]);

  useEffect(() => {
    if (drafts?.length > 0) {
      let tempDrafts = inputs.drafts;
      const draftIDs = inputs.drafts.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.draft_question_list_id],
        []
      );
      drafts.forEach((curDraft: KeyStringVal) => {
        if (!draftIDs.includes(curDraft.draft_question_list_id))
          tempDrafts = [...tempDrafts, curDraft];
        else {
          const desiredDraft = tempDrafts.find(
            (tempDraft: KeyStringVal) =>
              tempDraft.draft_question_list_id ===
              curDraft.draft_question_list_id
          );
          if (
            curDraft.questions.length > 0 &&
            desiredDraft?.questions.length === 0
          ) {
            tempDrafts = tempDrafts.map((tempDraft: KeyStringVal) => {
              if (
                tempDraft.draft_question_list_id ===
                curDraft.draft_question_list_id
              )
                return { ...tempDraft, questions: curDraft.questions };
              else return tempDraft;
            });
          }
        }
      });

      setInputs({
        drafts: tempDrafts,
      });
    }
  }, [drafts]);

  window.addEventListener("beforeunload", (event) => {
    if (draftIsEdited) {
      event.preventDefault();
      event.returnValue = "";
    }
  });

  return (
    <>
      {inputs.drafts.length > 0 && (
        <section className="grid gap-4 p-6 dark:bg-gray-800 rounded-xl">
          <h4 className="text-t1-semi">Draft Questions</h4>
          <section className="grid gap-10">
            {inputs.drafts.map((draft: any, draftIndex: number) => {
              return (
                <DraftDetail
                  key={draftIndex}
                  vendorID={vendorID}
                  draft={draft}
                  inputs={inputs}
                  setInputs={setInputs}
                  setDraftIsEdited={setDraftIsEdited}
                  setSelectedQuestionSet={setSelectedQuestionSet}
                />
              );
            })}
          </section>
          <article className="relative group place-self-end mt-5">
            <button
              disabled={disableSubmit}
              className="blue-button"
              onClick={() => handleSubmit()}
            >
              <FontAwesomeIcon icon={faSearch} /> Submit
            </button>
            {disableSubmit && (
              <span className="hidden group-hover:block absolute top-16 left-1/2 -translate-x-1/2 p-4 w-max dark:bg-reset rounded-md">
                Cannot submit until all the documents have come back with
                questions
              </span>
            )}
          </article>
        </section>
      )}
    </>
  );
};

export default DraftList;
