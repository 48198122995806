/* eslint-disable no-restricted-globals */
import { useState } from "react";
import { attributeColors, baseURL, pageSize } from "../../../constants/general";
import {
  GetAllAccounts,
  StoreSlackOauth,
  UpdateAccount,
} from "../../../services/settings/integrations";
import { Account } from "../../../types/settings";
import { getCustomerID, parseURL } from "../../../utils/general";
import TablePagination from "src/components/General/TablePagination";
import TableLayout from "src/layouts/TableLayout";
import TestCredential from "./TestCredential";

const Configured = () => {
  const parsed = parseURL();
  const customerID = getCustomerID();

  const [pageNumber, setPageNumber] = useState<number>(1);

  const updateAccount = UpdateAccount(customerID);
  const { data: allAccounts } = GetAllAccounts(customerID);
  StoreSlackOauth(
    String(parsed.code || ""),
    String(parsed.state || ""),
    `${baseURL}/settings/details?section=integrations&integration=SLACK`
  );

  const configuredAccounts = allAccounts?.filter(
    (account: Account) => account.integration_type === parsed.integration
  );

  const filteredHeaders = ["account #", "status", "tags"];
  const totalCount = configuredAccounts?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {configuredAccounts?.length > 0 ? (
        <section className="grid content-start gap-4 overflow-auto scrollbar">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  return (
                    <th
                      scope="col"
                      key={col}
                      className="py-3 px-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{col}</h4>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {configuredAccounts?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 break-words dark:bg-gray-800"
                  >
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative py-3 px-3 last:pr-24 text-left break-all"
                        >
                          {col === "tags" ? (
                            <ul className="flex flex-wrap items-center gap-3">
                              {row.integration_tags?.map((tag: string) => {
                                return (
                                  <li
                                    key={tag}
                                    className="flex items-center gap-3 px-4 py-1 dark:bg-gray-700 rounded"
                                  >
                                    <p>{tag}</p>
                                  </li>
                                );
                              })}
                            </ul>
                          ) : col === "status" ? (
                            <p
                              className={`text-left cursor-pointer ${
                                attributeColors[
                                  String(row.current_state === 1)?.toLowerCase()
                                ]
                              }`}
                              onClick={() =>
                                updateAccount.mutate({
                                  accountID: row.integration_id,
                                  account: {
                                    current_state:
                                      row.current_state === 1
                                        ? "DISABLED"
                                        : "ENABLED",
                                  },
                                })
                              }
                            >
                              {row.current_state === 1 ? "Enabled" : "Disabled"}
                            </p>
                          ) : (
                            <p>{row.customer_cloud_id}</p>
                          )}
                          {colIndex === filteredHeaders.length - 1 && (
                            <TestCredential account={row} />
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="controlIssues placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Accounts</h4>
            <h4>No accounts available</h4>
          </article>
        </section>
      )}
    </>
  );
};

export default Configured;
