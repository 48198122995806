/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { faComments, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RefObject, useEffect, useRef } from "react";
import Discussion from "./Discussion";
import PanelLayout from "../../../../../layouts/PanelLayout";
import { useGRCStore } from "src/stores/grc";

const DiscussionNotes = ({
  documentID,
  anchorID,
  title,
}: {
  documentID: string;
  anchorID: string;
  title: string;
}) => {
  const { showGRCPanel, setShowGRCPanel } = useGRCStore();

  const panelRef = useRef() as RefObject<HTMLElement>;

  useEffect(() => {
    // remove panel if clicked on outside of panel
    const handleClickOutside = (event: { target: any }) => {
      if (!panelRef?.current?.contains(event.target)) {
        setShowGRCPanel(false);
      }
    };
    // remove panel if escape panel
    const handleEscape = (event: {
      key: string;
      preventDefault: () => void;
    }) => {
      if (event.key === "Escape") {
        event.preventDefault();
        setShowGRCPanel(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscape);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscape);
    };
  }, [panelRef]);

  return (
    <PanelLayout showPanel={showGRCPanel} panelRef={panelRef}>
      <section className="flex flex-col flex-grow gap-4 h-full overflow-auto scrollbar">
        <header className="flex items-center justify-between gap-4 border-b dark:border-gray-700">
          <article className="flex items-center gap-2 text-b1-reg">
            <FontAwesomeIcon icon={faComments} className="dark:text-blue-600" />
            <h4>Comments</h4>
          </article>
          <button onClick={() => setShowGRCPanel(false)}>
            <FontAwesomeIcon
              icon={faXmark}
              className="w-6 h-6 dark:hover:text-gray-700 duration-100"
            />
          </button>
        </header>

        <Discussion documentID={documentID} anchorID={anchorID} />
      </section>
    </PanelLayout>
  );
};

export default DiscussionNotes;
