/* eslint-disable no-restricted-globals */
import { faXmark, faTag, faBed } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import {
  AddCustomerModule,
  GetAvailableModules,
  GetCustomerModules,
  RemoveCustomerModule,
} from "src/services/settings/organization";
import { UpdateCustomerModule } from "../../../services/settings/organization";

const CustomerModules = ({ customerID }: { customerID: string }) => {
  const { data: availableModules } = GetAvailableModules();
  const { data: customerModules } = GetCustomerModules(customerID);
  const addModule = AddCustomerModule(customerID);
  const removeModule = RemoveCustomerModule(customerID);
  const updateModule = UpdateCustomerModule(customerID);

  const filteredAvailableModules =
    availableModules &&
    Object.keys(availableModules).filter(
      (module: string) => customerModules && !customerModules[module]
    );

  return (
    <section className="flex flex-wrap items-center gap-4 text-b2-reg">
      {customerModules && Object.keys(customerModules) && (
        <ul className="flex flex-wrap items-center gap-3">
          {Object.entries(customerModules)?.map((keyVal) => {
            const module = keyVal[0];
            const state = keyVal[1];
            return (
              <li
                key={module}
                className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded"
              >
                <p>{module}</p>
                <article className="group relative">
                  <button
                    className={`w-4 h-4 px-1 ${
                      state === "greyed"
                        ? "dark:text-gray-600 dark:hover:bg-gray-500/70"
                        : "dark:text-blue-500 dark:hover:text-blue-400/70"
                    } duration-100 rounded-full`}
                    onClick={() =>
                      updateModule.mutate({
                        modules: {
                          [module]: state === "greyed" ? "enabled" : "greyed",
                        },
                      })
                    }
                  >
                    <FontAwesomeIcon icon={faBed} />
                  </button>
                  <span>{state === "greyed" ? "Disabled" : "Disable"}</span>
                </article>
                <button
                  className="hover-icon"
                  onClick={() =>
                    removeModule.mutate({ modules: { [module]: "greyed" } })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button className="blue-tag-button">
          <FontAwesomeIcon icon={faTag} />
          <h4>Add Module</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max max-h-[10rem] gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded">
            {filteredAvailableModules?.length > 0 ? (
              <nav className="grid content-start w-full h-full overflow-auto scrollbar">
                {filteredAvailableModules?.map((module: string) => {
                  return (
                    <button
                      key={module}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={() =>
                        addModule.mutate({
                          modules: { [module]: "enabled" },
                        })
                      }
                    >
                      <p>{module}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded">
                <h4>No modules</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default CustomerModules;
