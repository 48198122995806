/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "../../../types/general";
import { GetCustomQuestionSets } from "src/services/third-party-risk/questions/custom-question-sets";

const QuestionSetFilter = ({
  label,
  fileIndex,
  inputs,
  setInputs,
}: {
  label?: string;
  fileIndex: number;
  inputs: any;
  setInputs: any;
}) => {
  const { data: questionSets } = GetCustomQuestionSets();

  const questionSetID = inputs["question_sets"][fileIndex] || "";
  const questionSetName =
    questionSets?.data.find(
      (questionSet: KeyStringVal) => questionSet.generated_id === questionSetID
    )?.name || "";

  return (
    <DropdownLayout
      label={label}
      value={questionSetName}
      placeholder="Select"
      width="w-[18rem]"
      showAbove
    >
      {questionSets?.data.map((questionSet: KeyStringVal, index: number) => {
        const selected = questionSetID === questionSet.generated_id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() =>
              setInputs({
                ...inputs,
                assessments: inputs.assessments.map(
                  (assessmentID: string, curFileIndex: number) => {
                    if (fileIndex === curFileIndex) {
                      return "";
                    }
                    return assessmentID;
                  }
                ),
                question_sets: inputs.question_sets.map(
                  (questionSetID: string, curFileIndex: number) => {
                    if (fileIndex === curFileIndex) {
                      return questionSet.generated_id;
                    }
                    return questionSetID;
                  }
                ),
              })
            }
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{questionSet.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default QuestionSetFilter;
