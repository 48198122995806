/* eslint-disable react-hooks/exhaustive-deps */
import AssessmentList from "./Assessments/AssessmentList/AssessmentList";
import AssessmentDetail from "./Assessments/AssessmentDetail/AssessmentDetail";
import { useEffect, useState } from "react";
import QuestionBankList from "./QuestionBanks/QuestionBankList/QuestionBankList";
import QuestionBankQuestions from "./QuestionBanks/QuestionBankDetail/QuestionBankDetail";
import { motion } from "framer-motion";
import React from "react";
import { showVariants } from "../../constants/general";
import PageLayout from "../../layouts/PageLayout";
import { useGRCStore } from "src/stores/grc";
import NewAssessment from "./Assessments/AssessmentList/NewAssessment";
import UploadQuestionBank from "./QuestionBanks/QuestionBankList/UploadQuestionBank/UploadQuestionBank";

const QuestionnaireRFP = () => {
  const { selectedGRCAssesment } = useGRCStore();

  const [selectedTab, setSelectedTab] = useState<string>("assessments");

  useEffect(() => {
    sessionStorage.page = "Questionnaire & RFP";
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-4 p-4 pb-24 w-full min-h-full"
      >
        <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
          <nav className="flex flex-wrap items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
            {["assessments", "question banks"].map((tab) => {
              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    selectedTab === tab
                      ? "dark:text-white dark:bg-gray-800"
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={() => setSelectedTab(tab)}
                >
                  {tab}
                </button>
              );
            })}
          </nav>
          {selectedTab === "assessments" && !sessionStorage.assessment_id ? (
            <NewAssessment selectedSourceType={sessionStorage.source_type} />
          ) : (
            selectedTab === "question banks" &&
            !sessionStorage.question_bank_id && <UploadQuestionBank />
          )}
        </section>
        {selectedTab === "assessments" ? (
          !sessionStorage.assessment_id ? (
            <AssessmentList />
          ) : (
            <AssessmentDetail />
          )
        ) : selectedTab === "question banks" ? (
          !sessionStorage.question_bank_id ? (
            <QuestionBankList />
          ) : (
            <QuestionBankQuestions />
          )
        ) : null}
      </motion.main>
    </PageLayout>
  );
};

export default QuestionnaireRFP;
