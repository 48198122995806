import React from "react";
import { GetGRCDocumentMetadata } from "src/services/grc";
import {
  GetAuditMetadata,
  GetInternalAuditMetrics,
} from "src/services/audit-management";
import { convertToUTCShortString, convertToUTCString } from "src/utils/general";
import DeleteAudit from "./DeleteAudit";
import CloseAudit from "./CloseAudit";
import ExportFile from "./ExportFile";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import ReturnPage from "src/components/Button/ReturnPage";

const AuditMetadata = ({
  documentType,
  documentID,
  auditID,
}: {
  documentType: string;
  documentID: string;
  auditID: string;
}) => {
  const { data: auditMetadata } = GetAuditMetadata(auditID);
  const { data: auditMetrics } = GetInternalAuditMetrics(
    auditID,
    auditMetadata?.is_closed || false
  );
  const { data: documentMetadata } = GetGRCDocumentMetadata(
    documentType,
    documentID
  );

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Third Party Risk</span>{" "}
          <span>/</span>
          <span>Internal Audit</span>
        </h4>
      </article>
      <header className="grid gap-4">
        {auditMetadata && (
          <section className="flex flex-col flex-grow gap-1">
            <article className="flex items-start justify-between gap-4">
              <article className="flex flex-wrap items-start gap-2 w-3/5">
                <h2 className="text-t1-semi">{auditMetadata.name} </h2>
                {documentMetadata && (
                  <article className="flex items-start gap-2 px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="mt-1 dark:text-gray-400"
                    />
                    <span>
                      {documentMetadata.framework_name ||
                        documentMetadata.policy_name}
                    </span>
                  </article>
                )}
                {documentMetadata && (
                  <>
                    {documentMetadata.last_updated_at && (
                      <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                        {convertToUTCShortString(
                          documentMetadata.last_updated_at
                        )}
                      </span>
                    )}
                    {documentMetadata.regulatory_date && (
                      <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                        {convertToUTCShortString(
                          documentMetadata.regulatory_date
                        )}
                      </span>
                    )}
                    {(documentMetadata.regulatory_authority ||
                      documentMetadata.customer_name) && (
                      <span className="px-2 py-1 break-words text-b1-reg dark:text-gray-400 dark:bg-gray-900 rounded">
                        {documentMetadata.regulatory_authority ||
                          documentMetadata.customer_name}
                      </span>
                    )}
                  </>
                )}
              </article>
              <article className="flex items-center place-content-end gap-4">
                <DeleteAudit auditID={auditID} />
                {auditMetadata.is_closed ? (
                  <article className="flex items-center gap-4">
                    <ExportFile auditID={auditID} />
                    <span className="px-4 py-2 dark:bg-gray-700 rounded-xl">
                      Closed
                    </span>
                  </article>
                ) : (
                  <CloseAudit auditID={auditID} />
                )}
              </article>
            </article>
            <article className="flex flex-wrap items-start justify-between gap-10">
              <span className="text-b1-reg dark:text-gray-400">
                created at {convertToUTCString(auditMetadata.created_at)}
              </span>
              {auditMetrics && (
                <article className="grid grid-cols-4 text-b1-reg rounded-xl">
                  <article className="flex flex-col flex-grow px-2 py-1 w-full text-center bg-green-700">
                    <span>{auditMetrics.compliant}</span>
                    Compliant
                  </article>
                  <article className="flex flex-col flex-grow px-2 py-1 w-full text-center bg-yellow-600">
                    <span>{auditMetrics["partially compliant"]}</span>
                    Partially Compliant
                  </article>
                  <article className="flex flex-col flex-grow px-2 py-1 w-full text-center bg-red-700">
                    <span>{auditMetrics["non-compliant"]}</span>
                    Non-Compliant
                  </article>
                  <article className="flex flex-col flex-grow px-2 py-1 w-full text-center bg-gray-600">
                    <span>{auditMetrics["no-analysis"]}</span>
                    No Analysis
                  </article>
                </article>
              )}
            </article>
          </section>
        )}
      </header>
    </>
  );
};

export default AuditMetadata;
