import React, { useState } from "react";
import Loader from "src/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import TableLayout from "src/layouts/TableLayout";
import {
  checkIsReadOnly,
  getCustomerID,
  getEmailFromUserID,
  handleSort,
  sortRows,
} from "src/utils/general";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialSort, pageSize } from "src/constants/general";
import { Sort } from "src/types/dashboard";
import TablePagination from "src/components/General/TablePagination";
import { GetRisks, riskPrefix } from "../../../../services/erc/risks/risks";
import AllERCTags from "src/components/GRC/AllERCTags";
import RiskNameFilter from "src/components/Filter/ERC/RiskNameFilter";
import RiskIDFilter from "src/components/Filter/ERC/RiskIDFilter";
import ExportFile from "./ExportFile";
import ColumnFilters from "src/components/GRC/ColumnFilters";
import { GetAllUsers } from "src/services/settings/users";
import { useERCStore } from "src/stores/erc";
import RowValue from "src/components/General/RowValue";
import { KeyStringVal } from "src/types/general";
import { GetEntities } from "src/services/erc/entities/entities";
import { GetRiskBlueprints } from "src/services/erc/risks/risk-blueprints";
import QueryBuilder from "src/components/GRC/QueryBuilder/QueryBuilder";
import RiskTags from "./Risk/RiskTags";
import BulkAction from "./BulkAction";

const RiskList = () => {
  const navigate = useNavigate();
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const { selectedERCFilters, setSelectedERCFilters } = useERCStore();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [queryList, setQueryList] = useState<any>([]);
  const [selectedRisks, setSelectedRisks] = useState<any>([]);

  const { data: allUsers } = GetAllUsers(customerID, false);
  const {
    data: risks,
    status: risksStatus,
    refetch,
  } = GetRisks(
    pageNumber,
    selectedTags,
    selectedERCFilters.names,
    selectedERCFilters.serial_numbers,
    selectedERCFilters.columns,
    queryList
  );
  const { data: entities } = GetEntities();
  const { data: riskBlueprints } = GetRiskBlueprints();

  const headers = risks && JSON.parse(risks.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = risks && sortRows(risks?.data, sort);
  const totalCount = risks?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;
  const riskIDs = risks?.data.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.risk_id],
    []
  );
  const selectedAll =
    selectedRisks.length > 0 &&
    risks?.data.every((risk: KeyStringVal) =>
      selectedRisks.includes(risk.risk_id)
    );

  const handleSelectAll = () => {
    if (selectedAll)
      setSelectedRisks(
        selectedRisks.filter((riskID: string) => !riskIDs?.includes(riskID))
      );
    else {
      const riskIDs = risks?.data
        .map((risk: KeyStringVal) => {
          if (selectedRisks.includes(risk.risk_id)) return null;
          else return risk.risk_id;
        })
        .filter(Boolean);
      setSelectedRisks([...selectedRisks, ...riskIDs]);
    }
  };

  return (
    <section className="flex flex-col flex-grow gap-4">
      <AllERCTags
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
      />
      <article className="flex flex-wrap items-center gap-4">
        <ColumnFilters
          filters={selectedERCFilters}
          setFilters={setSelectedERCFilters}
          prefix={riskPrefix}
          refetch={refetch}
        />
        <RiskNameFilter
          label="Names"
          keyName="names"
          inputs={selectedERCFilters}
          setInputs={setSelectedERCFilters}
          refetch={refetch}
        />
        <RiskIDFilter
          label="Serial Number"
          keyName="serial_numbers"
          inputs={selectedERCFilters}
          setInputs={setSelectedERCFilters}
          refetch={refetch}
        />
        <QueryBuilder
          prefix={riskPrefix}
          refetch={refetch}
          queryList={queryList}
          setQueryList={setQueryList}
        />
      </article>
      {risksStatus === "loading" ? (
        <Loader />
      ) : risks?.data?.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4 pb-24">
          <article className="flex items-center place-self-end gap-4">
            <BulkAction selectedRisks={selectedRisks} />
            <ExportFile />
          </article>
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string, colIndex: number) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        {colIndex === 0 && (
                          <input
                            type="checkbox"
                            disabled={isReadOnly}
                            checked={selectedAll}
                            className="blue-checkbox"
                            onChange={(e) => {
                              e.stopPropagation();

                              handleSelectAll();
                            }}
                          />
                        )}
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                const entity =
                  entities?.data?.find(
                    (entity: KeyStringVal) =>
                      entity.grc_entity_id === row.grc_entity_id
                  )?.name || "-";
                const riskBlueprint =
                  riskBlueprints?.data.find(
                    (blueprint: KeyStringVal) =>
                      blueprint.risk_blueprint_id === row.risk_blueprint_id
                  )?.name || "-";

                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    onClick={() => {
                      sessionStorage.ERCCategory = "risks";
                      navigate(`/erc/risks/details?risk_id=${row.risk_id}`);
                    }}
                  >
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      const selected = selectedRisks.includes(row.risk_id);

                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left"
                        >
                          <article className="flex items-start gap-10">
                            {colIndex === 0 && (
                              <input
                                type="checkbox"
                                disabled={isReadOnly}
                                checked={selected}
                                className="blue-checkbox"
                                onClick={(e) => {
                                  e.stopPropagation();

                                  if (selected)
                                    setSelectedRisks(
                                      selectedRisks.filter(
                                        (riskID: string) =>
                                          riskID !== row.risk_id
                                      )
                                    );
                                  else
                                    setSelectedRisks([
                                      ...selectedRisks,
                                      row.risk_id,
                                    ]);
                                }}
                              />
                            )}
                            {[
                              "owners",
                              "owners_text",
                              "reviewers",
                              "reviewers_text",
                              "approvers",
                              "approvers_text",
                            ].includes(col) ? (
                              <article className="flex flex-col flex-grow gap-1">
                                {row[`${col}_text`]?.map((user: string) => {
                                  return (
                                    <span key={user} className="text-b1-semi">
                                      {user}
                                    </span>
                                  );
                                })}
                                {row[col]?.map((user: any, idx: number) => {
                                  let value = "";
                                  if (typeof user === "string")
                                    value = getEmailFromUserID(allUsers, user);
                                  else
                                    value = getEmailFromUserID(
                                      allUsers,
                                      user.user_id
                                    );
                                  return (
                                    <span key={idx} className="text-b1-semi">
                                      {value}
                                    </span>
                                  );
                                })}
                              </article>
                            ) : col === "threats" ? (
                              <ul className="flex flex-col flex-grow gap-1">
                                {row.threats.map((threat: KeyStringVal) => {
                                  return (
                                    <li
                                      key={threat.threat_id}
                                      className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                                      onClick={() =>
                                        navigate(
                                          `/erc/threats/details?threat_id=${threat.threat_id}`
                                        )
                                      }
                                    >
                                      {threat.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : col === "vulnerabilities" ? (
                              <ul className="flex flex-col flex-grow gap-1">
                                {row.vulnerabilities.map(
                                  (vuln: KeyStringVal) => {
                                    return (
                                      <li
                                        key={vuln.vulnerability_id}
                                        className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                                        onClick={() =>
                                          navigate(
                                            `/erc/vulns/details?vuln_id=${vuln.vulnerability_id}`
                                          )
                                        }
                                      >
                                        {vuln.name}
                                      </li>
                                    );
                                  }
                                )}
                              </ul>
                            ) : col === "controls" ? (
                              <ul className="flex flex-col flex-grow gap-1">
                                {row.controls.map((control: KeyStringVal) => {
                                  return (
                                    <li
                                      key={control.control_id}
                                      className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                                      onClick={() =>
                                        navigate(
                                          `/erc/controls/details?control_id=${control.control_id}`
                                        )
                                      }
                                    >
                                      {control.name}
                                    </li>
                                  );
                                })}
                              </ul>
                            ) : col === "ref_ids" && row.ref_ids ? (
                              <ul className="flex flex-col flex-grow gap-1">
                                {row.ref_ids &&
                                  Object.entries(row.ref_ids).map((keyVal) => {
                                    return (
                                      <li
                                        key={keyVal[0]}
                                        className="text-b1-semi"
                                      >
                                        {keyVal[0]}: {keyVal[1]}
                                      </li>
                                    );
                                  })}
                              </ul>
                            ) : col === "grc_entity_id" ? (
                              entity
                            ) : col === "risk_blueprint_id" ? (
                              riskBlueprint
                            ) : col === "tags" ? (
                              <RiskTags riskID={row.risk_id} tags={row.tags} />
                            ) : (
                              <RowValue row={row} col={col} />
                            )}
                          </article>
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="risks placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Risks</h4>
            <h4>No risks available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default RiskList;
