/* eslint-disable react-hooks/exhaustive-deps */
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useGRCStore } from "src/stores/grc";
import UploadQuestionnaire from "./UploadQuestionnaire";
import DraftList from "./DraftList/DraftList";
import ResponseList from "./ResponseList/ResponseList";
import AskAQuestion from "./AskAQuestion";
import SelectQuestionnairesFilter from "src/components/Filter/AuditsAssessments/SelectQuestionnaireFilter";
import { KeyStringVal } from "src/types/general";

const AssessmentDetail = () => {
  const { setSelectedGRCAssessment } = useGRCStore();

  const [nav, setNav] = useState<number>(1);
  const [inputs, setInputs] = useState<any>({ drafts: [] });
  const [selectedQuestionnaire, setSelectedQuestionnaire] =
    useState<KeyStringVal>({ generated_id: "", document_name: "" });

  const assessmentID = sessionStorage.assessment_id;

  useEffect(() => {
    if (nav === 2) setTimeout(() => setNav(1), 5000);
  }, [nav]);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <article className="flex items-center gap-2">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-b3-reg dark:hover:text-gray-700/70 duration-100"
          onClick={() => {
            sessionStorage.removeItem("assessment_id");
            sessionStorage.removeItem("source_type");
            setSelectedGRCAssessment({
              name: "",
              assessment_id: "",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Questionnaire & RFP</span>{" "}
          <span>/</span>
          <span>Assessment</span>
        </h4>
      </article>
      <header className="flex items-center justify-between gap-4">
        <h3 className="text-t1-semi">{sessionStorage.assessment_name}</h3>
        <section className="flex items-center place-content-end gap-4">
          <SelectQuestionnairesFilter
            selectedQuestionnaire={selectedQuestionnaire}
            setSelectedQuestionnaire={setSelectedQuestionnaire}
          />
          <AskAQuestion inputs={inputs} setInputs={setInputs} />
          <UploadQuestionnaire assessmentID={assessmentID} />
        </section>
      </header>
      <section className="grid content-start gap-10 w-full h-full">
        {nav === 1 ? (
          <DraftList
            assessmentID={assessmentID}
            inputs={inputs}
            setInputs={setInputs}
            setNav={setNav}
            selectedQuestionnaire={selectedQuestionnaire}
            setSelectedQuestionnaire={setSelectedQuestionnaire}
          />
        ) : (
          <section className="grid content-start gap-4">
            <article className="grid gap-3 text-center">
              <h4 className="text-xl">
                Uno is processing your questions. We will notify you when ready!
              </h4>
              <img
                src="/grc/draft-questions-processing.png"
                alt="questions processing"
                className="h-[20rem] mx-auto"
              />
            </article>
            <button
              className="px-4 py-2 mx-auto h-max dark:hover:bg-blue-600/30 duration-100 dark:bg-blue-600 rounded-md"
              onClick={() => setNav(1)}
            >
              Ask another
            </button>
          </section>
        )}
        <ResponseList assessmentID={assessmentID} />
      </section>
    </section>
  );
};

export default AssessmentDetail;
