/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Subsection from "./Subsection/Subsection";
import SectionTabs from "./SectionTabs";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";
import { GetDocumentStatus, GetGRCDocumentMetadata } from "src/services/grc";
import {
  GetAudit,
  GetAuditStatus,
} from "src/services/third-party-risk/assessments/vendor-assessment";
import {
  faWarning,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ExportFile from "../ExportFile";

const Sections = ({
  documentType,
  documentID,
  reviewID,
  auditID,
  selectedTab,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  reviewID: string;
  auditID: string;
  selectedTab: string;
  editSections: any;
  setEditSections: any;
}) => {
  const isControls = selectedTab === "Controls Coverage";

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filter, setFilter] = useState<string>("All");

  const { data: documentStatus } = GetDocumentStatus(documentType, documentID);
  const { data: auditStatus } = GetAuditStatus(auditID);
  const { data: metadata } = GetGRCDocumentMetadata(documentType, documentID);

  const { data: audit, status: auditSectionsStatus } = GetAudit(
    selectedTab,
    auditID,
    documentID,
    pageNumber,
    filter
  );

  const table = audit;
  const tableStatus = auditSectionsStatus;
  const sectionStatus = isControls ? documentStatus : auditStatus;
  const documentName = isControls
    ? metadata?.framework_name
    : table?.audit_name;

  const sortedTable = table?.data;
  const totalCount = table?.pager?.total_results || 0;
  const totalPages = table?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {sectionStatus?.status === "failed" ? (
        <section className="grid place-content-center gap-10 w-full h-full text-center">
          <img src="/errors/503.svg" alt="error" className="mx-auto h-72" />
          <h4>
            Oops! something went wrong! We will reach out to you shortly to help
            resolve the issue. Thanks for your patience.
          </h4>
        </section>
      ) : sectionStatus?.status === "parsing" ? (
        <article className="flex items-center place-content-center gap-4">
          <img
            src={`/grc/${documentType}-placeholder.svg`}
            alt="placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4>
              Your document has been received and is currently being processed
            </h4>
            <img
              src="/grc/data-parsing.svg"
              alt="data parsing"
              className="w-10 h-10"
            />
          </article>
        </article>
      ) : tableStatus === "loading" ? (
        <Loader />
      ) : (
        tableStatus === "success" && (
          <section className="flex flex-col flex-grow content-start gap-4 h-full">
            <article className="flex items-center justify-between gap-10">
              <SectionTabs
                selectedTab={selectedTab}
                filter={filter}
                setFilter={setFilter}
              />
              <ExportFile reviewID={reviewID} documentID={reviewID} />
            </article>
            {sortedTable ? (
              sortedTable.length > 0 ? (
                <ul className="grid gap-4">
                  {sortedTable.map((section: any, sectionIndex: number) => {
                    return (
                      <li
                        key={sectionIndex}
                        className="grid content-start gap-3 p-4 bg-gray-800 rounded-xl"
                      >
                        <h4 className="flex items-start gap-2 text-b1-semi">
                          {section.section_id}{" "}
                          {!["-", "", null].includes(section.section_title) &&
                            section.section_title}
                        </h4>
                        {isControls && (
                          <article className="flex items-center gap-2">
                            {section.flag_sub_control === 1 && (
                              <span className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
                                <FontAwesomeIcon
                                  icon={faWarning}
                                  className="text-red-500"
                                />{" "}
                                No Citations
                              </span>
                            )}
                            {section.mapped_sub_controls /
                              section.total_sub_controls <
                              0.5 && (
                              <span className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  className="text-orange-500"
                                />{" "}
                                Attention Needed
                              </span>
                            )}
                          </article>
                        )}
                        <article className="grid gap-4">
                          {section.sub_sections.map(
                            (subsection: any, subSectionIndex: number) => {
                              return (
                                <Subsection
                                  key={subSectionIndex}
                                  documentType="third-party"
                                  documentID={documentID}
                                  documentName={documentName}
                                  reviewID={reviewID}
                                  auditID={auditID}
                                  subsection={subsection}
                                  selectedTab={selectedTab}
                                  editSections={editSections}
                                  setEditSections={setEditSections}
                                />
                              );
                            }
                          )}
                        </article>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No results found</p>
              )
            ) : null}
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        )
      )}
    </>
  );
};

export default Sections;
