import { useMutation, useQuery } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { controlPrefix } from "../controls/controls";
import { controlIssuePrefix } from "../controls/control-issues";
import { policyPrefix } from "src/services/regulation-policy/policy";

export const exceptionPrefix = "grc/exception";

export const GetExceptions = (
  pageNumber?: number,
  columns?: string[],
  queryList?: any
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-exceptions", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${exceptionPrefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddException = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${exceptionPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-exceptions"]),
    }
  );

export const EditException = (exceptionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${exceptionPrefix}/${exceptionID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-exception-metadata"]),
    }
  );

export const RemoveException = (exceptionID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${exceptionPrefix}/${exceptionID}/delete `,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-exceptions"]),
    }
  );

export const GetExceptionMetadata = (exceptionID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-exception-metadata", exceptionID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${exceptionPrefix}/${exceptionID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: exceptionID !== "",
      keepPreviousData: false,
    }
  );

export const GetObjectTypes = (objectType: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-object-types", objectType],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${
            objectType === "POLICY"
              ? `${policyPrefix}`
              : objectType === "CONTROL"
              ? `${controlPrefix}`
              : `${controlIssuePrefix}`
          }/getall`,
          {},
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: objectType !== "",
      keepPreviousData: false,
    }
  );
