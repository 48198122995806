/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { SearchFrameworkControls } from "../../../../services/erc/controls/framework-controls";
import FilterLayout from "src/layouts/FilterLayout";

const Filters = ({
  filters,
  setFilters,
  refetch,
  includeOnly,
}: {
  filters: any;
  setFilters: any;
  refetch: any;
  includeOnly?: string[];
}) => {
  const [query, setQuery] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");

  const search = SearchFrameworkControls();

  const handleSelect = (type: string, value: string) => {
    if (value === "All" && search?.data?.length > 0) {
      if (filters[type].length < search.data?.length) {
        setFilters({
          ...filters,
          [type]: search.data,
        });
      } else setFilters({ ...filters, [type]: [] });
    } else if (filters[type].includes(value))
      setFilters({
        ...filters,
        [type]: filters[type].filter((curVal: string) => curVal !== value),
      });
    else setFilters({ ...filters, [type]: [...filters[type], value] });
  };

  useEffect(() => {
    if (selectedType !== "")
      search.mutate({
        type: selectedType,
        query: "",
      });
  }, [selectedType]);

  return (
    <section className="flex flex-wrap items-center gap-4">
      {Object.keys(filters).map((type) => {
        if (includeOnly && !includeOnly.includes(type)) return null;

        return (
          <FilterLayout label={type} onClick={() => setSelectedType(type)}>
            <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="dark:text-gray-500"
              />
              <input
                type="input"
                autoComplete="off"
                spellCheck="false"
                placeholder="Search"
                value={query}
                onChange={(e) => {
                  setQuery(e.target.value);
                  search.mutate({
                    type: selectedType,
                    query: e.target.value,
                  });
                }}
                className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
              />
            </article>
            {search.data ? (
              search.data.length > 0 ? (
                <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
                  <li className="flex items-start gap-2">
                    <input
                      type="checkbox"
                      className="transparent-checkbox"
                      checked={filters[type].length === search?.data?.length}
                      onClick={() => handleSelect(type, "All")}
                    />
                    <label htmlFor="" className="w-max">
                      All
                    </label>
                  </li>
                  {search.data.map((value: string) => {
                    const selected = filters[type].includes(value);
                    return (
                      <li key={value} className="flex items-start gap-2 px-4">
                        <input
                          type="checkbox"
                          className="transparent-checkbox"
                          checked={selected}
                          onChange={() => {
                            if (selected)
                              setFilters({
                                ...filters,
                                [type]: filters[type].filter(
                                  (curVal: string) => curVal !== value
                                ),
                              });
                            else
                              setFilters({
                                ...filters,
                                [type]: [...filters[type], value],
                              });
                          }}
                        />
                        <label htmlFor="" className="capitalize">
                          {value}
                        </label>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p className="px-4 py-1">No results found</p>
              )
            ) : null}
            <article className="w-full border-t dark:border-gray-600">
              <article className="sticky bottom-0 flex items-center gap-2 pt-2 place-self-end">
                <button
                  className="red-text-button"
                  onClick={() =>
                    setFilters({
                      ...filters,
                      [type]: [],
                    })
                  }
                >
                  Clear
                </button>
                <button className="small-blue-button" onClick={() => refetch()}>
                  Apply
                </button>
              </article>
            </article>
          </FilterLayout>
        );
      })}
    </section>
  );
};

export default Filters;
