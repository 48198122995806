import {
  faInfoCircle,
  faXmark,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { showVariants } from "src/constants/general";
import { GetDocumentStatus, GetGRCDocumentMetadata } from "src/services/grc";
import {
  convertToUTCShortString,
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import DeleteAuditReport from "./DeleteAuditReport";
import { GetAssessmentMetadata } from "src/services/third-party-risk/assessments/vendor-assessment";
import { GetAllUsers } from "src/services/settings/users";
import ReturnPage from "src/components/Button/ReturnPage";

const AuditReportMetadata = ({
  documentType,
  documentID,
  reviewID,
}: {
  documentType: string;
  documentID: string;
  reviewID: string;
}) => {
  const customerID = getCustomerID();

  const [isVisible, setVisible] = useState<boolean>(true);

  const { data: reviewMetadata } = GetAssessmentMetadata(reviewID);
  const { data: documentStatus } = GetDocumentStatus(documentType, documentID);
  const { data: documentMetadata } = GetGRCDocumentMetadata(
    documentType,
    documentID
  );
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <header className="flex flex-col flex-grow gap-4">
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Third Party Risk</span>{" "}
          <span>/</span>
          <span>Audit Report</span>
        </h4>
      </article>
      {reviewMetadata && (
        <article className="flex flex-col flex-grow gap-1">
          <h2 className="text-2xl">
            {reviewMetadata.name} | {reviewMetadata.audit_name}
          </h2>
          <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
            <h4>
              Created by {getNamefromUserID(allUsers, reviewMetadata.user_id)} (
              {getEmailFromUserID(allUsers, reviewMetadata.user_id)})
            </h4>
            <span>| {convertToUTCString(reviewMetadata.created_at)}</span>
          </article>
        </article>
      )}
      {documentMetadata && (
        <article className="flex items-center justify-between gap-20">
          <article className="flex items-start gap-2">
            <article className="flex flex-col flex-grow gap-1">
              <article className="flex items-center justify-between gap-4">
                <h4 className="text-t1-semi break-words">
                  {documentMetadata.framework_name ||
                    documentMetadata.policy_name}
                </h4>
                <article className="flex items-center gap-10">
                  {isVisible ? (
                    documentStatus?.status === "failed" ? (
                      <motion.article
                        variants={showVariants}
                        initial="hidden"
                        animate="visible"
                        className="flex items-center gap-2 px-4 py-2 text-sm dark:bg-reset/30 border dark:border-reset rounded-sm"
                      >
                        <FontAwesomeIcon icon={faWarning} /> Error processing
                        your document!
                        <button onClick={() => setVisible(!isVisible)}>
                          <FontAwesomeIcon icon={faXmark} />
                        </button>
                      </motion.article>
                    ) : (
                      documentStatus?.status === "parsing" && (
                        <motion.article
                          variants={showVariants}
                          initial="hidden"
                          animate="visible"
                          className="flex items-center gap-2 px-4 py-2 text-sm dark:bg-event/30 border dark:border-event rounded-sm"
                        >
                          <FontAwesomeIcon icon={faInfoCircle} /> Uno is
                          currently processing the document!
                          <button onClick={() => setVisible(!isVisible)}>
                            <FontAwesomeIcon icon={faXmark} />
                          </button>
                        </motion.article>
                      )
                    )
                  ) : null}
                </article>
              </article>
              <article className="flex flex-wrap items-center gap-3 text-b2-reg dark:text-gray-400 divide-x dark:divide-gray-400">
                {documentMetadata.last_updated_at && (
                  <span>
                    {convertToUTCShortString(documentMetadata.last_updated_at)}
                  </span>
                )}
                {documentMetadata.regulatory_date && (
                  <span>
                    {convertToUTCShortString(documentMetadata.regulatory_date)}
                  </span>
                )}
                {(documentMetadata.regulatory_authority ||
                  documentMetadata.customer_name) && (
                  <span className="pl-3">
                    {documentMetadata.regulatory_authority ||
                      documentMetadata.customer_name}
                  </span>
                )}
              </article>
            </article>
          </article>
          <DeleteAuditReport reviewID={reviewID} />
        </article>
      )}
    </header>
  );
};

export default AuditReportMetadata;
