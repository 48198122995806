import React from "react";
import { convertToUTCString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetControlBlueprintMetadata } from "../../../../../services/erc/controls/control-blueprints";
import MetadataField from "src/components/GRC/MetadataField";
import ArchiveRestoreBlueprint from "./ArchiveRestoreBlueprint";
import UpdateControlBlueprint from "./UpdateControlBlueprint";
import ReturnPage from "src/components/Button/ReturnPage";

const ControlBlueprintMetadata = ({ blueprintID }: { blueprintID: string }) => {
  const { data: metadata } = GetControlBlueprintMetadata(blueprintID);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Control Blueprint</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-10">
              <article className="flex flex-col flex-grow gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>Last Updated by {metadata.last_updated_by}</h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>Created by {metadata.created_by}</h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateControlBlueprint
                  blueprintID={blueprintID}
                  blueprint={metadata}
                />
                {/* <ArchiveRestoreBlueprint
                  blueprintID={blueprintID}
                  blueprint={metadata}
                /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
              {metadata.create_controls_automatically_enabled && (
                <article
                  className={`flex items-center gap-1 ${attributeColors.true}`}
                >
                  Create Controls Automatically
                </article>
              )}
            </section>
            <MetadataField
              metadata={metadata}
              label="Criteria"
              keyName="control_criteria"
            />
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <MetadataField
              metadata={metadata}
              label="Sub Control Description"
              keyName="sub_control_description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField metadata={metadata} label="Type" keyName="type" />
              <MetadataField
                metadata={metadata}
                label="Function"
                keyName="function"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField
                metadata={metadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={metadata}
                label="Owner"
                keyName="owner"
                singleUser
              />
              <MetadataField
                metadata={metadata}
                label="Compliance Score"
                keyName="compliance_score"
              />
              <MetadataField
                metadata={metadata}
                label="Source Name"
                keyName="source_name"
              />
              <MetadataField
                metadata={metadata}
                label="Source Control Id"
                keyName="source_control_id"
              />
              <MetadataField
                metadata={metadata}
                label="Source Sub-Control Id"
                keyName="source_sub_control_id"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default ControlBlueprintMetadata;
