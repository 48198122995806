/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { UpdateUser } from "../../../services/settings/users";
import { getCustomerID } from "../../../utils/general";

const UserActionFilter = ({
  list,
  multiSelect,
  setMultiSelect,
}: {
  list: string[];
  multiSelect: string[];
  setMultiSelect: (multiSelect: string[]) => void;
}) => {
  const customerID = getCustomerID();

  const updateUser = UpdateUser(customerID);

  return (
    <DropdownLayout value="">
      {list?.map((item: string, index: number) => (
        <button
          key={index}
          className="relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 rounded-lg"
          onClick={() => {
            setMultiSelect([]);
            if (item === "Activate") {
              multiSelect.map((userID) =>
                updateUser.mutate({
                  userID: userID,
                  user: { action: "activate" },
                })
              );
            } else if (item === "Deactivate") {
              multiSelect.map((userID) =>
                updateUser.mutate({
                  userID: userID,
                  user: { action: "delete" },
                })
              );
            }
          }}
        >
          <p>{item}</p>
        </button>
      ))}
    </DropdownLayout>
  );
};

export default UserActionFilter;
