import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { AddRiskBlueprint } from "../../../../services/erc/risks/risk-blueprints";
import ToastLayout from "../../../../layouts/ToastLayout";
import MultiUserInput from "src/components/Input/MultiUserInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import AssessmentQuestionnaireFilter from "src/components/Filter/ERC/AssessmentQuestionnaireFilter";
import CategoryInput from "src/components/Input/CategoryInput";
import ThreatsFilter from "src/components/Filter/ERC/ThreatsFilter";
import VulnerabilitiesFilter from "src/components/Filter/ERC/VulnerabilitiesFilter";
import MultiEntityTypeFilter from "src/components/Filter/ERC/MultiEntityTypeFilter";
import MultiControlBlueprintFilter from "src/components/Filter/ERC/MultiControlBlueprintFilter";
import SourceControlFilter from "src/components/Filter/ERC/SourceControlFilter";
import SourceNameFilter from "src/components/Filter/ERC/SourceNameFilter";
import { Disclosure } from "@headlessui/react";
import { checkIsReadOnly } from "src/utils/general";

const NewRiskBlueprint = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const addRiskBlueprint = AddRiskBlueprint();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRiskBlueprint.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            threats: [],
            vulnerabilities: [],
            category: "",
            sub_category: "",
            classification: "",
            type: "",
            owner_same_as_entity_owner: true,
            source_name: "",
            source_id: "",
            owners: [],
            owners_text: [],
            reviewers: [],
            reviewers_text: [],
            approvers: [],
            approvers_text: [],
            status: "DRAFT",
            deadline: Date.now() * 1000,
            create_risks_automatically: true,
            inherent_risk_score: 0,
            inherent_risk_impact_level: "MODERATE",
            inherent_risk_likelihood_level: "POSSIBLE",
            target_risk_impact_level: "MODERATE",
            target_risk_likelihood_level: "POSSIBLE",
            risk_assessment_questionnaire_id: "",
            entity_types: [],
            control_blueprints: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Risk Blueprint</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Risk Blueprint
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <ThreatsFilter
              label="Threats"
              inputs={inputs}
              setInputs={setInputs}
            />
            <VulnerabilitiesFilter
              label="Vulnerabilities"
              inputs={inputs}
              setInputs={setInputs}
            />
            <SourceNameFilter
              label="Source Name"
              keyName="source_name"
              inputs={inputs}
              setInputs={setInputs}
            />
            {inputs.source_name && (
              <SourceControlFilter
                label="Source Id"
                keyName="source_id"
                inputs={inputs}
                setInputs={setInputs}
              />
            )}
            <RegularInput
              label="State"
              keyName="state"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiEntityTypeFilter
              label="Entity Types"
              keyName="entity_types"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiControlBlueprintFilter
              label="Control Blueprints"
              keyName="control_blueprints"
              inputs={inputs}
              setInputs={setInputs}
            />
            <AssessmentQuestionnaireFilter
              label="Risk Assessment"
              keyName="risk_assessment_questionnaire_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">Risk Score</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CategoryInput
                      label="Inherent Risk Impact Level"
                      keyName="inherent_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Inherent Risk Likelihood Level"
                      keyName="inherent_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Target Risk Impact Level"
                      keyName="target_risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Target Risk Likelihood Level"
                      keyName="target_risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CheckboxInput
                      label="Owner Same as Entity Owner"
                      keyName="owner_same_as_entity_owner"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    {!inputs.owner_same_as_entity_owner && (
                      <MultiUserInput
                        label="Owners"
                        keyName="owners"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                        allowNewValue
                      />
                    )}
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <CheckboxInput
                      label="Create Risks Automatically"
                      keyName="create_risks_automatically"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addRiskBlueprint.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRiskBlueprint.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New risk blueprint has been created"
      />
    </>
  );
};

export default NewRiskBlueprint;
