import React from "react";
import { GetQuestionSetTags } from "src/services/third-party-risk/questions/custom-question-sets";

const AllQuestionSetTags = ({
  questionSetID,
  selectedTags,
  setSelectedTags,
}: {
  questionSetID: string;
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
}) => {
  const { data: tags } = GetQuestionSetTags(questionSetID);

  return (
    <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
      {tags?.map((tag: string) => {
        return (
          <li
            key={tag}
            className={`flex items-center gap-2 px-4 py-1 cursor-pointer dark:text-white ${
              selectedTags.includes(tag)
                ? "dark:bg-blue-500"
                : "dark:hover:bg-blue-500/30 duration-100"
            } border dark:border-blue-400 rounded`}
            onClick={() => {
              if (selectedTags.includes(tag))
                setSelectedTags(
                  selectedTags.filter((curTag) => tag !== curTag)
                );
              else setSelectedTags([...selectedTags, tag]);
            }}
          >
            <p>{tag}</p>
          </li>
        );
      })}
    </ul>
  );
};

export default AllQuestionSetTags;
