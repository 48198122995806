import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import DeleteReview from "./DeleteReview";
import { GetPrivacyReviewMetadata } from "src/services/third-party-risk/assessments/privacy-review";
import ReturnPage from "src/components/Button/ReturnPage";

const ReviewMetadata = ({
  documentType,
  documentID,
  reviewID,
  auditID,
  editSections,
  setEditSections,
  documentModified,
  setDocumentModified,
}: {
  documentType: string;
  documentID: string;
  reviewID: string;
  auditID: string;
  editSections: any;
  setEditSections: any;
  documentModified: any;
  setDocumentModified: any;
}) => {
  const { data: metadata } = GetPrivacyReviewMetadata(auditID);

  return (
    <header className="flex flex-col flex-grow gap-4">
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Third Party Risk</span>{" "}
          <span>/</span>
          <span>Privacy Document</span>
        </h4>
      </article>
      {metadata && (
        <article className="flex flex-wrap items-center justify-between gap-20">
          <h2 className="text-subt1-semi">{metadata.audit_name}</h2>
          <article className="flex items-center gap-2 pb-1">
            <span className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
              {convertToUTCShortString(metadata.created_at)}
            </span>
            <DeleteReview reviewID={reviewID} />
          </article>
        </article>
      )}
    </header>
  );
};

export default ReviewMetadata;
