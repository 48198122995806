import { useQuery, useMutation } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { queryClient } from "src/App";

export const controlBlueprintPrefix = "grc/grc-controls/blueprints";
const frameworkControlPrefix = "grc/grc-controls/framework-controls";

export const GetControlBlueprints = (
  pageNumber?: number,
  tags?: string[] | undefined,
  columns?: string[],
  queryList?: string[]
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-blueprints", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/getall`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            tags,
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddControlBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-control-blueprints"]),
    }
  );

export const EditControlBlueprint = (blueprintID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${blueprintID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]),
    }
  );

export const GetControlBlueprintMetadata = (blueprintID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-blueprint-metadata", blueprintID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${blueprintID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: blueprintID !== "",
      keepPreviousData: false,
    }
  );

export const GetSourceNames = () =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-source-names"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${frameworkControlPrefix}/frameworks`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetSourceControls = (frameworkID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-source-controls", frameworkID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${frameworkControlPrefix}/frameworks/${frameworkID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: frameworkID !== "",
      keepPreviousData: false,
    }
  );

export const GetEntityTypesForBlueprint = (
  blueprintID: string,
  pageNumber?: number,
  tags?: string[] | undefined
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-entity-types-for-blueprint", blueprintID, pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${blueprintID}/entity-types`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: blueprintID !== "",
      keepPreviousData: false,
    }
  );

export const AddEntityTypeToBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({
      blueprintID,
      entityType,
      signal,
    }: {
      blueprintID: string;
      entityType: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${blueprintID}/entity-types/add`,
          entityType,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entity-types-for-blueprint"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
        queryClient.invalidateQueries(["get-entity-types"]);
      },
    }
  );

export const RemoveEntityTypeFromBlueprint = () =>
  useMutation<any, unknown, any, string>(
    async ({
      blueprintID,
      entityType,
      signal,
    }: {
      blueprintID: string;
      entityType: string[];
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${blueprintID}/entity-types/remove`,
          entityType,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-entity-types-for-blueprint"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
        queryClient.invalidateQueries(["get-entity-types"]);
      },
    }
  );

export const RestoreControlBlueprint = (controlBlueprintID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${controlBlueprintID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-blueprints"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
      },
    }
  );

export const ArchiveControlBlueprint = (controlBlueprintID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlBlueprintPrefix}/${controlBlueprintID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-blueprints"]);
        queryClient.invalidateQueries(["get-control-blueprint-metadata"]);
      },
    }
  );
