/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentFilter = ({
  label,
  selectedDocument,
  setSelectedDocument,
  list,
}: {
  label?: string;
  selectedDocument: KeyStringVal;
  setSelectedDocument: (selectedDocument: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  return (
    <DropdownLayout
      label={label}
      value={selectedDocument.document_name || selectedDocument.name}
      width="w-[30rem]"
      placeholder="Select document"
    >
      {list?.map((document: KeyStringVal, index: number) => {
        const selected =
          selectedDocument.document_name === document.document_name ||
          selectedDocument.name === document.name;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => setSelectedDocument(document)}
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{document.document_name || document.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DocumentFilter;
