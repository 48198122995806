import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { riskLifecycleStates } from "src/constants/erc";
import ModalLayout from "src/layouts/ModalLayout";
import { GetAllUsers } from "src/services/settings/users";
import {
  checkIsReadOnly,
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  sortNumericData,
} from "src/utils/general";
import {
  GetRiskLifecycleEvents,
  GetRiskLifecycleState,
  GetRiskMetadata,
  UpdateRiskState,
} from "src/services/erc/risks/risks";
import { Disclosure } from "@headlessui/react";
import CategoryInput from "src/components/Input/CategoryInput";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MultiControlFilter from "src/components/Filter/ERC/MultiControlFilter";
import { KeyStringVal } from "src/types/general";

const LifecycleFlow = ({ riskID }: { riskID: string }) => {
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<string>("");
  const [inputs, setInputs] = useState<any>({
    notes: "",
    treatment: "",
    treatment_type: "MITIGATE",
    treatment_plan: "",
    treatment_status: "",
    treatment_notes: "",
    compensating_controls: "",
    deadline: Date.now() * 1000,
    control_type: "",
    inherent_risk_impact_level: "MODERATE",
    inherent_risk_likelihood_level: "POSSIBLE",
    residual_risk_impact_level: "MODERATE",
    residual_risk_likelihood_level: "POSSIBLE",
    risk_acceptance_via_email: "",
    cost_benefit_analysis: "",
    controls: [],
  });

  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: metadata } = GetRiskMetadata(riskID);
  const { data: controlState } = GetRiskLifecycleState(riskID);
  const updateState = UpdateRiskState(riskID);
  const { data: lifecycleEvents } = GetRiskLifecycleEvents(riskID);

  const curStateIdx = riskLifecycleStates.findIndex(
    (state) => state === controlState?.state
  );
  const totalStates = riskLifecycleStates.length;

  const handleOnClose = () => setShow("");

  const handleGoBack = (stateIdx: number) => {
    const state = stateIdx === 2 ? "IN_EVALUATION" : "IDENTIFIED";
    updateState.mutate({
      inputs: {
        ...inputs,
        state_to: state,
        risk_id: riskID,
      },
    });
    handleOnClose();
  };

  const handleGoForward = (stateIdx: number) => {
    const state =
      stateIdx === 0
        ? "IN_EVALUATION"
        : stateIdx === 1
        ? "IN_TREATMENT"
        : stateIdx === 2
        ? "MONITORING"
        : "CLOSED";

    updateState.mutate({
      inputs: {
        ...inputs,
        state_to: state,
        risk_id: riskID,
      },
    });
    handleOnClose();
  };

  return (
    <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      <h4 className="text-t1-semi">Lifecycle Flow</h4>
      <ol className="items-start sm:flex w-full">
        {riskLifecycleStates.map((state, stateIdx) => {
          const events = lifecycleEvents?.filter(
            (event: KeyStringVal) => event.state === state
          );
          const sortedEvents = sortNumericData(
            events,
            "state_change_date",
            "desc"
          );
          const sortedAllEvents = sortNumericData(
            lifecycleEvents,
            "state_change_date",
            "desc"
          );

          return (
            <li key={state} className="relative mb-6 sm:mb-0 w-full">
              <article className="flex items-start">
                <button
                  disabled={
                    isReadOnly ||
                    !metadata?.allowed_lifecycle_actions?.includes(state) ||
                    stateIdx < curStateIdx ||
                    stateIdx >= curStateIdx + 1 ||
                    curStateIdx === totalStates
                  }
                  className={`flex items-center justify-center w-32 h-full px-2 py-1 ${
                    curStateIdx !== totalStates && stateIdx === curStateIdx
                      ? "dark:text-[#4F71E3] dark:bg-gray-900 dark:ring-blue-600"
                      : stateIdx < curStateIdx || curStateIdx === totalStates
                      ? "dark:text-green-600 dark:bg-gray-900 dark:ring-green-600"
                      : "dark:text-gray-400 dark:bg-gray-900 dark:ring-gray-400"
                  } sm:ring-2 shrink-0 rounded z-10`}
                  onClick={() => {
                    setShow(state);
                    setInputs({
                      notes: "",
                      treatment: metadata?.treatment,
                      treatment_type: metadata?.treatment_type,
                      treatment_plan:
                        metadata?.generated_treatment_plan ||
                        metadata?.treatment_plan,
                      treatment_status: metadata?.treatment_status,
                      treatment_notes: metadata?.treatment_notes,
                      compensating_controls: metadata?.compensating_controls,
                      deadline: Date.now() * 1000,
                      control_type: metadata?.control_type,
                      inherent_risk_impact_level:
                        metadata?.inherent_risk_impact_level,
                      inherent_risk_likelihood_level:
                        metadata?.inherent_risk_likelihood_level,
                      residual_risk_impact_level:
                        metadata?.residual_risk_impact_level,
                      residual_risk_likelihood_level:
                        metadata?.residual_risk_likelihood_level,
                      risk_acceptance_via_email:
                        metadata?.risk_acceptance_via_email,
                      cost_benefit_analysis: metadata?.cost_benefit_analysis,
                      controls:
                        metadata?.controls?.reduce(
                          (pV: string[], cV: KeyStringVal) => [
                            ...pV,
                            cV.control_id,
                          ],
                          []
                        ) || [],
                    });
                  }}
                >
                  <h3 className="text-b1-reg">{state?.replaceAll("_", " ")}</h3>
                </button>
                {stateIdx !== totalStates - 1 && (
                  <article className="hidden sm:flex mt-4 w-full h-[1px] dark:bg-gray-500"></article>
                )}
                <ModalLayout showModal={show === state} onClose={handleOnClose}>
                  <section className="grid gap-4">
                    <h3 className="flex items-center gap-2 text-t1-semi">
                      Update State
                    </h3>
                    <Disclosure>
                      {({ open }) => (
                        <section className="grid content-start gap-3">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4 className="text-subt1-semi">Risk Score</h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <section className="flex flex-col flex-grow gap-4">
                              <section className="grid md:grid-cols-2 gap-4">
                                <CategoryInput
                                  label="Inherent Risk Impact Level"
                                  keyName="inherent_risk_impact_level"
                                  list={[
                                    "NEGLIGIBLE",
                                    "MINOR",
                                    "MODERATE",
                                    "SIGNIFICANT",
                                    "SEVERE",
                                  ]}
                                  inputs={inputs}
                                  setInputs={setInputs}
                                  allowUnselect
                                />
                                <CategoryInput
                                  label="Inherent Risk Likelihood Level"
                                  keyName="inherent_risk_likelihood_level"
                                  list={[
                                    "VERY_UNLIKELY",
                                    "UNLIKELY",
                                    "POSSIBLE",
                                    "LIKELY",
                                    "VERY_LIKELY",
                                  ]}
                                  inputs={inputs}
                                  setInputs={setInputs}
                                  allowUnselect
                                />
                              </section>
                              <MultiControlFilter
                                label="Controls"
                                keyName="controls"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <section className="grid md:grid-cols-2 gap-4">
                                <CategoryInput
                                  label="Residual Risk Impact Level"
                                  keyName="residual_risk_impact_level"
                                  list={[
                                    "NEGLIGIBLE",
                                    "MINOR",
                                    "MODERATE",
                                    "SIGNIFICANT",
                                    "SEVERE",
                                  ]}
                                  inputs={inputs}
                                  setInputs={setInputs}
                                  allowUnselect
                                />
                                <CategoryInput
                                  label="Residual Risk Likelihood Level"
                                  keyName="residual_risk_likelihood_level"
                                  list={[
                                    "VERY_UNLIKELY",
                                    "UNLIKELY",
                                    "POSSIBLE",
                                    "LIKELY",
                                    "VERY_LIKELY",
                                  ]}
                                  inputs={inputs}
                                  setInputs={setInputs}
                                  allowUnselect
                                />
                              </section>
                            </section>
                          </Disclosure.Panel>
                        </section>
                      )}
                    </Disclosure>
                    <Disclosure>
                      {({ open }) => (
                        <section className="grid content-start gap-3">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4 className="text-subt1-semi">Risk Treatment</h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <section className="grid md:grid-cols-2 gap-4">
                              <RegularInput
                                label="Treatment"
                                keyName="treatment"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <CategoryInput
                                label="Treatment Type"
                                keyName="treatment_type"
                                list={[
                                  "ACCEPT",
                                  "AVOID",
                                  "MITIGATE",
                                  "TRANSFER",
                                ]}
                                inputs={inputs}
                                setInputs={setInputs}
                                allowUnselect
                              />
                              <CategoryInput
                                label="Treatment Status"
                                keyName="treatment_status"
                                list={["Open", "Closed", "Resolved", "Pending"]}
                                inputs={inputs}
                                setInputs={setInputs}
                                allowUnselect
                              />
                              <GeneralSnapshotDatepicker
                                label="Deadline"
                                keyName="deadline"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <CategoryInput
                                label="Control Type"
                                keyName="control_type"
                                list={[
                                  "CORRECTIVE",
                                  "DETECTIVE",
                                  "PREVENTATIVE",
                                ]}
                                inputs={inputs}
                                setInputs={setInputs}
                                allowUnselect
                              />
                              <RegularInput
                                label="Risk Acceptance via Email"
                                keyName="risk_acceptance_via_email"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <RegularInput
                                label="Cost / Benefit Analysis"
                                keyName="cost_benefit_analysis"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <RegularInput
                                label="Treatment Plan"
                                keyName="treatment_plan"
                                inputs={inputs}
                                setInputs={setInputs}
                                textarea
                              />
                              <RegularInput
                                label="Treatment Notes"
                                keyName="treatment_notes"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                              <RegularInput
                                label="Compensating Controls"
                                keyName="compensating_controls"
                                inputs={inputs}
                                setInputs={setInputs}
                              />
                            </section>
                          </Disclosure.Panel>
                        </section>
                      )}
                    </Disclosure>
                    <Disclosure>
                      {({ open }) => (
                        <section className="grid content-start gap-3">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4 className="text-subt1-semi">
                              Lifecycle Events
                            </h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel>
                            <section className="flex flex-col flex-grow">
                              {sortedAllEvents?.length > 0 ? (
                                sortedAllEvents?.map(
                                  (event: any, index: number) => {
                                    return (
                                      <section
                                        key={index}
                                        className="grid gap-2 p-4 dark:bg-gray-900 rounded-md"
                                      >
                                        <header className="flex flex-col flex-grow gap-1">
                                          <span className="px-2 py-1 w-max text-b2-semi dark:text-green-600 dark:bg-gray-900 sm:ring-2 dark:ring-green-600 rounded">
                                            {event.state.replaceAll("_", " ")}
                                          </span>
                                          <article className="flex flex-col flex-grow">
                                            <article className="text-b2-semi">
                                              {getEmailFromUserID(
                                                allUsers,
                                                event.state_change_by
                                              )}
                                            </article>
                                            <span className="text-b3-reg dark:text-gray-400">
                                              {convertToUTCString(
                                                event.state_change_date
                                              )}
                                            </span>
                                          </article>
                                        </header>
                                        <p className="text-b3-reg">
                                          {event.notes}
                                        </p>
                                      </section>
                                    );
                                  }
                                )
                              ) : (
                                <p className="text-b2-reg">No events</p>
                              )}
                            </section>
                          </Disclosure.Panel>
                        </section>
                      )}
                    </Disclosure>
                    <RegularInput
                      label="Notes"
                      keyName="notes"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <article className="flex items-center place-content-center gap-4">
                      {[1, 2, 4].includes(stateIdx) && (
                        <button
                          className="red-button"
                          onClick={() => handleGoBack(stateIdx)}
                        >
                          {stateIdx === 4 ? "Back to Draft" : "Reject"}
                        </button>
                      )}
                      <button
                        className="blue-button"
                        onClick={() => handleGoForward(stateIdx)}
                      >
                        {stateIdx === 0
                          ? "Request Evaluation"
                          : stateIdx === 1
                          ? "Request Review"
                          : stateIdx === 2
                          ? "Approve"
                          : "Close"}
                      </button>
                    </article>
                  </section>
                </ModalLayout>
              </article>
              <article className="mt-3 sm:pe-8">
                <section className="flex flex-col flex-grow pr-5">
                  {sortedEvents?.map((event: any, index: number) => {
                    return (
                      <section
                        key={index}
                        className="grid gap-2 p-4 dark:bg-gray-900 rounded-md"
                      >
                        <header className="flex flex-col flex-grow">
                          <article className="text-b2-semi">
                            {getEmailFromUserID(
                              allUsers,
                              event.state_change_by
                            )}
                          </article>
                          <span className="text-b3-reg dark:text-gray-400">
                            {convertToUTCString(event.state_change_date)}
                          </span>
                        </header>
                        <p className="text-b3-reg">{event.notes}</p>
                      </section>
                    );
                  })}
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default LifecycleFlow;
