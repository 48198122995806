/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { EditEntity } from "../../../../../services/erc/entities/entities";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import CategoryInput from "src/components/Input/CategoryInput";
import UserInput from "src/components/Input/UserInput";
import { SearchCountry } from "src/services/grc";
import VendorFilter from "src/components/Filter/ERC/VendorFilter";
import { checkIsReadOnly } from "src/utils/general";

const UpdateEntity = ({
  entityID,
  entity,
}: {
  entityID: string;
  entity: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const countries = SearchCountry();
  const editEntity = EditEntity(entityID);

  const preDefinedEntityType = ["APPLICATION", "DATABASE"].includes(
    inputs.entity_type_name
  );

  const handleOnClose = () => setShow(false);

  useEffect(() => {
    countries.mutate({});
  }, []);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="edit-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs({
            name: entity.name,
            external_reference_id: entity.external_reference_id,
            entity_type_name: entity.entity_type?.name,
            entity_type: entity.entity_type?.entity_type_id,
            description: entity.description,
            owner: entity.owner,
            owner_text: entity.owner_text,
            provider_id: entity.provider_id,
            managed_by: entity.managed_by,
            managed_by_text: entity.managed_by_text,
            internal_or_3rd_party:
              entity.additional_properties?.internal_or_3rd_party,
            hosting_locations: entity.additional_properties?.hosting_locations,
            storage_format: entity.additional_properties?.storage_format,
            hosting_type: entity.additional_properties?.hosting_type,
            hosting_provider: entity.additional_properties?.hosting_provider,
            technical_security_measures:
              entity.additional_properties?.technical_security_measures,
            organizational_security_measures:
              entity.additional_properties?.organizational_security_measures,
            other_security_measures:
              entity.additional_properties?.other_security_measures,
            data_retention: entity.additional_properties?.data_retention,
            criticality: entity.additional_properties?.criticality,
            security_classification:
              entity.additional_properties?.security_classification,
            asset_type: entity.additional_properties?.asset_type,
            next_review_date:
              entity.additional_properties?.next_review_date ||
              Date.now() * 1000,
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit Entity</h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              allowNewValue
              allowUnselect
            />
            <RegularInput
              label="External Reference Id"
              keyName="external_reference_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <VendorFilter
              label="Provider"
              keyName="provider_id"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <UserInput
              label="Managed By"
              keyName="managed_by"
              inputs={inputs}
              setInputs={setInputs}
              allowNewValue
              allowUnselect
              sendToOriginal
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              textarea
            />
            {preDefinedEntityType && (
              <>
                <CategoryInput
                  label="Internal or 3rd Party"
                  keyName="internal_or_3rd_party"
                  list={["Internal", "3rd Party"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Locations"
                  keyName="hosting_locations"
                  list={countries.data?.data}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Storage Format"
                  keyName="storage_format"
                  list={[
                    "Backup Tapes",
                    "Cloud Storage",
                    "Data Store",
                    "FTP Server",
                    "File Cabinet",
                    "File Warehouse",
                    "Portable Media",
                    "Shared Drive",
                    "Web Server",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Type"
                  keyName="hosting_type"
                  list={["Cloud", "On Premise"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Hosting Provider"
                  keyName="hosting_provider"
                  list={[
                    "Akamai",
                    "Amazon Web Services",
                    "Atlantic.Net",
                    "CloudSigma",
                    "CloudStitch",
                    "Digital Ocean Cloud",
                    "Google Cloud",
                    "HP Helion Eucalyptus",
                    "Heroku",
                    "IBM SoftLayer Cloud",
                    "Joyent",
                    "Kyup",
                    "Linode Cloud",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Technical Security Measures"
                  keyName="technical_security_measures"
                  list={[
                    "Access Control Lists",
                    "Anonymization",
                    "Anti-Malware",
                    "Breach Detection Tools",
                    "Data Backup",
                    "Encryption",
                    "Firewalls",
                    "Intrusion Detection Tools",
                    "Logging",
                    "Logical Access Control",
                    "Mobile Device Management (MDM) Tools",
                    "Multi-Factor Authentication",
                    "Network Authentication",
                    "Pseudonymization",
                    "Regular Software Updates",
                    "Vulnerability Detection Tools",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Organizational Security Measures"
                  keyName="organizational_security_measures"
                  list={[
                    "Acceptable Use Policies",
                    "Access Reviews",
                    "Awareness and Training",
                    "Background Checks",
                    "Business Continuity Plans",
                    "Data Processing Agreements",
                    "Disaster Recovery Plans",
                    "Incident Management Plans",
                    "Need-To-Know Restrictions",
                    "Non-Disclosure Agreements",
                    "Password Policies",
                    "Penetration Tests",
                    "Regular Test Plan",
                    "Secure Disposal",
                    "Secure Premises",
                    "Segmented Access Control",
                    "Supervision",
                    "Surveillance",
                    "Tabletop Exercises",
                    "Vendor Assessments",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <RegularInput
                  label="Other Security Measures"
                  keyName="other_security_measures"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <RegularInput
                  label="Data Retention"
                  keyName="data_retention"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <CategoryInput
                  label="Criticality"
                  keyName="criticality"
                  list={["Partner", "Critical", "High", "Medium", "Low"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Security Classification"
                  keyName="security_classification"
                  list={[
                    "Sensitive/Regulated",
                    "Restricted",
                    "Confidential",
                    "Public",
                    "Internal",
                  ]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <CategoryInput
                  label="Asset Type"
                  keyName="asset_type"
                  list={["Tangible", "In tangible"]}
                  inputs={inputs}
                  setInputs={setInputs}
                  allowUnselect
                />
                <GeneralSnapshotDatepicker
                  label="Next Review Date"
                  keyName="next_review_date"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </>
            )}
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                if (preDefinedEntityType)
                  editEntity.mutate({
                    inputs: {
                      grc_entity_update: {
                        name: inputs.name,
                        description: inputs.description,
                        external_reference_id: inputs.external_reference_id,
                        owner: inputs.owner,
                        owner_text: inputs.owner_text,
                        status: inputs.status,
                        entity_type: inputs.entity_type,
                        provider_id: inputs.provider_id,
                        managed_by: inputs.managed_by,
                        managed_by_text: inputs.managed_by_text,
                      },
                      additional_properties: {
                        internal_or_3rd_party: inputs.internal_or_3rd_party,
                        hosting_locations: inputs.hosting_locations,
                        storage_format: inputs.storage_format,
                        hosting_type: inputs.hosting_type,
                        hosting_provider: inputs.hosting_provider,
                        technical_security_measures:
                          inputs.technical_security_measures,
                        organizational_security_measures:
                          inputs.organizational_security_measures,
                        other_security_measures: inputs.other_security_measures,
                        data_retention: inputs.data_retention,
                        criticality: inputs.criticality,
                        security_classification: inputs.security_classification,
                        asset_type: inputs.asset_type,
                        next_review_date: inputs.next_review_date,
                      },
                    },
                  });
                else
                  editEntity.mutate({
                    inputs: {
                      grc_entity_update: inputs,
                      additional_properties: {},
                    },
                  });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateEntity;
