/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import VendorList from "./VendorList/VendorList";
import VendorGroups from "./VendorGroupList/VendorGroupList";
import NewVendorGroup from "./VendorGroupList/NewVendorGroup";
import NewVendor from "./VendorList/NewVendor";
import Settings from "./VendorList/Settings";
import VendorDashboard from "./VendorDashboard/VendorDashboard";

const Vendors = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  useEffect(() => {
    setSelectedTab("vendors");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["vendors", "vendor dashboard", "vendor groups"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => {
                  if (tab === "vendor groups")
                    sessionStorage.removeItem("vendor_group_id");

                  setSelectedTab(tab);
                }}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "vendors" ? (
          <article className="flex items-center gap-4">
            <Settings />
            <NewVendor />
          </article>
        ) : selectedTab === "vendor groups" ? (
          <NewVendorGroup />
        ) : null}
      </header>
      {selectedTab === "vendors" ? (
        <VendorList />
      ) : selectedTab === "vendor dashboard" ? (
        <VendorDashboard />
      ) : selectedTab === "vendor groups" ? (
        <VendorGroups />
      ) : null}
    </section>
  );
};

export default Vendors;
