/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { GetCustomerEnvs } from "../../../services/settings/environments";
import { CustomerEnv } from "../../../types/settings";
import CreateEnvironment from "./CreateEnvironment";
import { getCustomerID, parseURL } from "../../../utils/general";
import DeleteEnvironment from "./DeleteEnvironment";
import RenameEnvironment from "./RenameEnvironment";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import EnvironmentDetails from "./EnvironmentDetails";

const Environments = () => {
  const navigate = useNavigate();
  const parsed = parseURL();
  const customerID = getCustomerID();

  const { data: customerEnvs } = GetCustomerEnvs(customerID);

  const allEnvTypes = [
    ...new Set(
      customerEnvs?.reduce(
        (pV: string[], cV: CustomerEnv) => [...pV, cV.env_type],
        []
      )
    ),
  ] as string[];

  return (
    <>
      {!parsed.selected_env ? (
        <section className="flex flex-col flex-grow gap-6 w-full h-full">
          <header className="flex items-center justify-between gap-4">
            <h4 className="text-subt1-semi">
              Environments ({customerEnvs?.length})
            </h4>
            <CreateEnvironment />
          </header>
          <ul className="grid grid-cols-1 md:grid-cols-3 xl:grid-cols-4 gap-3">
            {allEnvTypes?.map((envType: string) => {
              return (
                <li
                  key={envType}
                  className="flex flex-col flex-grow gap-4 p-4 cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-700/70 duration-100 divide-y dark:divide-gray-700 black-shadow rounded-xl"
                  onClick={() =>
                    navigate(
                      `/settings/details?${queryString.stringify(
                        parsed
                      )}&selected_env=${envType}`
                    )
                  }
                >
                  <p className="place-self-center text-center text-b1-semi">
                    {envType}
                  </p>
                  {envType !== "DEFAULT" && (
                    <article className="flex items-center p-2 place-content-center">
                      <RenameEnvironment envType={envType} />
                      <DeleteEnvironment envType={envType} />
                    </article>
                  )}
                </li>
              );
            })}
          </ul>
        </section>
      ) : (
        <EnvironmentDetails />
      )}
    </>
  );
};

export default Environments;
