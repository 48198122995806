import React, { useState } from "react";
import MultipleEvidenceFileInput from "src/components/Input/MultipleEvidenceFileInput";
import ModalLayout from "src/layouts/ModalLayout";

const AttachEvidence = ({
  questionID,
  inputs,
  setInputs,
  attachmentMandatory,
}: {
  questionID: string;
  inputs: any;
  setInputs: any;
  attachmentMandatory?: boolean;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button className="black-button" onClick={() => setShow(true)}>
        {attachmentMandatory && (
          <span className="text-b1-reg text-red-500">*</span>
        )}
        <h4>Attach Evidence</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Attach Evidence
          </h3>
          <MultipleEvidenceFileInput
            label="Evidence"
            types={[
              "png",
              "svg",
              "pdf",
              "docx",
              "csv",
              "txt",
              "xlsx",
              "jpg",
              "jpeg",
            ]}
            inputs={inputs}
            setInputs={setInputs}
            questionID={questionID}
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="blue-button" onClick={() => handleOnClose()}>
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AttachEvidence;
