import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useEffect, useState } from "react";
import { KeyStringVal } from "src/types/general";

const ConditionKey = ({
  keyName,
  list,
  queryList,
  setQueryList,
  id,
  allowUnselect,
}: {
  keyName: string;
  list: any;
  queryList: any;
  setQueryList: (queryList: any) => void;
  id: string;
  allowUnselect?: boolean;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");
  const [updatedList, setUpdatedList] = useState<KeyStringVal[]>([]);

  const filteredItems = updatedList?.filter((item: KeyStringVal) =>
    item.display_name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );
  const conditionKey = queryList.find(
    (curCondition: KeyStringVal) => curCondition.id === id
  )?.key;

  useEffect(() => {
    if (list?.length > 0) setUpdatedList(list);
  }, [list]);

  return (
    <article className="grid content-start text-b2-reg">
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Select field"
            value={query || conditionKey}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {filteredItems?.map((item: KeyStringVal) => {
              if (!["string", "integer"].includes(item.data_type)) return null;
              const selected = conditionKey === item.property_name;

              return (
                <button
                  key={item.property_name}
                  className={`relative flex items-center gap-2 capitalize px-4 py-1 text-left break-words dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (allowUnselect && selected)
                      setQueryList(
                        queryList.map((curCondition: KeyStringVal) => {
                          if (curCondition.id === id)
                            return {
                              ...curCondition,
                              [keyName]: "",
                            };
                          else return curCondition;
                        })
                      );
                    else
                      setQueryList(
                        queryList.map((curCondition: KeyStringVal) => {
                          if (curCondition.id === id)
                            return {
                              ...curCondition,
                              [keyName]: item.property_name,
                            };
                          else return curCondition;
                        })
                      );
                  }}
                >
                  {item.display_name.replaceAll("_", " ")}
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default ConditionKey;
