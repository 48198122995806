/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  riskScoreColors,
  riskScoreMappings,
  riskScores,
} from "src/constants/erc";
import { GetInherentRiskDistribution } from "src/services/third-party-risk/vendors/vendor-dashboard";

const InherentRiskDistribution = () => {
  const { data } = GetInherentRiskDistribution();

  return (
    <>
      {data && (
        <section className="flex flex-col flex-grow gap-5 p-4 dark:bg-gray-800 rounded-2xl">
          <section className="flex flex-col flex-grow gap-5">
            <h4 className="indent-12 text-center text-t1-semi">
              Inherent Risk Distribution
            </h4>
            <section className="grid grid-cols-6 grid-rows-6">
              {[...Array(36).keys()].map((idx) => {
                const value =
                  riskScoreMappings[idx] &&
                  data?.find(
                    (score) =>
                      score.inherent_risk_impact_level ===
                        riskScoreMappings[idx][0] &&
                      score.inherent_risk_likelihood_level ===
                        riskScoreMappings[idx][1]
                  )?.count;
                return (
                  <article
                    key={idx}
                    className={`place-content-center w-full h-20 text-center text-b2-reg ${
                      idx % 6 === 0 || idx >= 30 ? "" : riskScoreColors[idx]
                    } ${
                      idx % 6 === 0 || idx >= 30
                        ? ""
                        : "border-1 dark:border-gray-900"
                    } break-all`}
                  >
                    {idx !== 30 && riskScores[idx]?.replaceAll("_", " ")}
                    <span>{value}</span>
                  </article>
                );
              })}
            </section>
          </section>
        </section>
      )}
    </>
  );
};

export default InherentRiskDistribution;
