import React from "react";
import { GetTagsForAssessment } from "src/services/erc/assessments/assessments";

const AllTags = ({
  assessmentID,
  selectedTags,
  setSelectedTags,
}: {
  assessmentID: string;
  selectedTags: string[];
  setSelectedTags: (selectedTags: string[]) => void;
}) => {
  const { data: allTags } = GetTagsForAssessment(assessmentID);

  return (
    <>
      {allTags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 p-6 max-w-[15rem] max-h-[30rem] text-b1-reg dark:bg-gray-800 overflow-auto scrollbar rounded-2xl">
          {allTags?.map((tag: string) => {
            return (
              <li
                key={tag}
                className={`flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white ${
                  selectedTags.includes(tag)
                    ? "dark:bg-blue-500"
                    : "dark:bg-gray-700 dark:hover:bg-gray-600/70 duration-100"
                } rounded`}
                onClick={() => {
                  if (selectedTags.includes(tag))
                    setSelectedTags(
                      selectedTags.filter((curTag) => tag !== curTag)
                    );
                  else setSelectedTags([...selectedTags, tag]);
                }}
              >
                <p>{tag}</p>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

export default AllTags;
