/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import { KeyNumVal, KeyStringVal } from "src/types/general";
import NewPolicy from "./NewPolicy";
import {
  AddPoliciesToPolicyGroup,
  GetPoliciesFromGroup,
  GetPolicyGroups,
  GetPolicyMapping,
  RemovePoliciesFromPolicyGroup,
} from "src/services/regulation-policy/policy";
import { useNavigate } from "react-router-dom";
import { checkIsReadOnly, convertToUTCShortString } from "src/utils/general";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import {
  faChevronDown,
  faChevronRight,
  faCopy,
  faMinus,
  faPlus,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Popover, Transition } from "@headlessui/react";
import Loader from "src/components/Loader/Loader";
import GeneratePolicy from "./GeneratePolicy";
import RowValue from "src/components/General/RowValue";

const Policies = ({
  policyGroup,
  selectedPolicyGroup,
  setSelectedPolicyGroup,
}: {
  policyGroup: string;
  selectedPolicyGroup: boolean;
  setSelectedPolicyGroup: (selectedPolicyGroup: boolean) => void;
}) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedPolicyID, setSelectedPolicyID] = useState<string>("");
  const [selectedPolicyIDs, setSelectedPolicyIDs] = useState<string[]>([]);
  const [filters, setFilters] = useState<any>({
    in_progress: false,
  });
  const [showGeneratePolicy, setShowGeneratePolicy] = useState<boolean>(false);

  const selectedPolicyGroupID = sessionStorage.selectedPolicyGroupID;

  const { data: policyGroups } = GetPolicyGroups();
  const { data: policies, status: policyStatus } = GetPoliciesFromGroup(
    selectedPolicyGroupID,
    pageNumber
  );
  const { data: policyMapping } = GetPolicyMapping(selectedPolicyID);
  const addPoliciesToPolicyGroup = AddPoliciesToPolicyGroup();
  const removePoliciesFromPolicyGroup = RemovePoliciesFromPolicyGroup();

  const filteredPolicyGroups = policyGroups?.filter(
    (policyGroup: KeyStringVal) =>
      policyGroup.policy_group_id !== selectedPolicyGroupID
  );
  const filteredPolicies = filters.in_progress
    ? policies?.data?.filter((doc: KeyNumVal) => doc.estimated_time_left > 0)
    : policies?.data;
  const totalCount = policies?.pager.total_results || 0;
  const totalPages = policies?.pager.num_pages || 0;
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const handleReturn = () => {
    sessionStorage.removeItem("selectedPolicyGroupID");
    setSelectedPolicyGroup(!selectedPolicyGroup);
  };

  return (
    <section className="flex flex-col flex-grow gap-4 py-4 w-full h-full">
      {policyStatus === "loading" ? (
        <Loader />
      ) : policyStatus === "success" ? (
        policies?.data?.length > 0 ? (
          <section className="flex flex-col flex-grow gap-3 pb-24 w-full h-full overflow-auto scrollbar">
            <article className="flex items-center justify-between gap-10">
              <article className="flex items-center gap-2">
                <button
                  className="dark:hover:text-gray-700/70 duration-100"
                  onClick={handleReturn}
                >
                  <FontAwesomeIcon icon={faArrowLeftLong} className="w-4 h-4" />
                </button>
                <h4 className="flex items-center gap-2 text-b3-reg">
                  <span className="dark:text-gray-500">Policy Group</span>{" "}
                  <span>/</span>
                  <span>{policyGroup}</span>
                </h4>
              </article>
            </article>

            {policyGroup === "Generated-Policies" ? (
              <article className="flex items-center place-self-end gap-4">
                <button
                  disabled={isReadOnly}
                  className="small-black-button"
                  onClick={() => setShowGeneratePolicy(!showGeneratePolicy)}
                >
                  <FontAwesomeIcon
                    icon={showGeneratePolicy ? faMinus : faPlus}
                  />
                  <h4>Generate Policy</h4>
                </button>
                {showGeneratePolicy && (
                  <GeneratePolicy
                    selectedPolicyGroup={selectedPolicyGroup}
                    setSelectedPolicyGroup={setSelectedPolicyGroup}
                    setShowGeneratePolicy={setShowGeneratePolicy}
                  />
                )}
              </article>
            ) : (
              <NewPolicy />
            )}
            <article className="flex items-center gap-10">
              <article className="flex items-center gap-4 divide-x dark:divide-checkbox">
                <button
                  disabled={
                    isReadOnly ||
                    selectedPolicyIDs.length === filteredPolicies?.length
                  }
                  className="dark:disabled:text-gray-400 dark:hover:text-checkbox/60 duration-100"
                  onClick={() => {
                    setSelectedPolicyIDs(
                      filteredPolicies?.reduce(
                        (pV: string[], cV: KeyStringVal) => [
                          ...pV,
                          cV.policy_id,
                        ],
                        []
                      )
                    );
                  }}
                >
                  Select All
                </button>
                <button
                  disabled={isReadOnly || selectedPolicyIDs.length === 0}
                  className="pl-5 dark:disabled:text-gray-400 dark:hover:text-checkbox/60 duration-100"
                  onClick={() => setSelectedPolicyIDs([])}
                >
                  Deselect All
                </button>
              </article>
              {selectedPolicyIDs.length > 0 && (
                <article className="flex items-center gap-4">
                  <Popover
                    as="article"
                    className="relative inline-block text-left"
                  >
                    <article className="relative group">
                      <span className="hidden group-hover:grid p-2 w-max absolute bottom-7 dark:bg-tooltip rounded-md">
                        Copy policies to another policy group
                      </span>
                      <Popover.Button
                        disabled={isReadOnly}
                        className="white-text-button"
                      >
                        <FontAwesomeIcon icon={faCopy} />
                        <h4>Copy to</h4>
                      </Popover.Button>
                    </article>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="absolute left-2 grid w-max -mr-5 mt-2 gap-2 origin-top-right focus:outline-none text-sm dark:text-white dark:bg-account z-50 rounded-sm">
                        {({ close }) => (
                          <>
                            {filteredPolicyGroups ? (
                              filteredPolicyGroups.length > 0 ? (
                                <nav className="grid content-start w-full max-h-60 z-50 overflow-auto scrollbar">
                                  {filteredPolicyGroups.map(
                                    (policyGroup: KeyStringVal) => {
                                      return (
                                        <button
                                          key={policyGroup.policy_group_id}
                                          className="flex items-center gap-3 px-4 pr-10 py-2 w-full text-left dark:bg-account dark:hover:bg-mention duration-100"
                                          onClick={() => {
                                            setSelectedPolicyIDs([]);
                                            addPoliciesToPolicyGroup.mutate({
                                              policyGroupID:
                                                policyGroup.policy_group_id,
                                              policyIDs: selectedPolicyIDs,
                                            });
                                            close();
                                          }}
                                        >
                                          <p className="grid text-sm">
                                            {policyGroup.title}
                                          </p>
                                        </button>
                                      );
                                    }
                                  )}
                                </nav>
                              ) : (
                                <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none text-b2-reg dark:text-white dark:bg-account z-50 rounded-sm">
                                  <h4>No policy groups</h4>
                                </section>
                              )
                            ) : null}
                          </>
                        )}
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                  {!["default", "all"].includes(policyGroup.toLowerCase()) && (
                    <>
                      or
                      <button
                        disabled={isReadOnly}
                        className="red-button"
                        onClick={() => {
                          setSelectedPolicyIDs([]);
                          removePoliciesFromPolicyGroup.mutate({
                            policyGroupID: selectedPolicyGroupID,
                            policyIDs: selectedPolicyIDs,
                          });
                        }}
                      >
                        Remove
                      </button>
                    </>
                  )}
                </article>
              )}
              <article className="flex items-start gap-2">
                <input
                  type="checkbox"
                  checked={filters.in_progress}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      in_progress: !filters.in_progress,
                    })
                  }
                  className="white-checkbox"
                />
                <label>In progress</label>
              </article>
            </article>
            <ul className="flex flex-col flex-grow gap-3 overflow-auto scrollbar">
              {filteredPolicies?.map((policy: any, index: number) => {
                return (
                  <li key={index} className="flex items-start gap-3">
                    <input
                      type="checkbox"
                      checked={
                        isReadOnly ||
                        selectedPolicyIDs.includes(policy.policy_id)
                      }
                      className="mt-5 w-10 h-10 border-0 dark:focus:ring-0 dark:text-blue-500 dark:focus:border-blue-600 focus:ring dark:focus:ring-offset-0 dark:focus:ring-blue-600 focus:ring-opacity-50"
                      onChange={() => {
                        if (selectedPolicyIDs.includes(policy.policy_id)) {
                          setSelectedPolicyIDs(
                            selectedPolicyIDs.filter(
                              (id: string) => id !== policy.policy_id
                            )
                          );
                        } else
                          setSelectedPolicyIDs([
                            ...selectedPolicyIDs,
                            policy.policy_id,
                          ]);
                      }}
                    />
                    <article
                      className="grid gap-4 px-6 py-4 w-full dark:bg-gray-800 hover:p-6 cursor-pointer dark:hover:bg-gray-700/70 duration-100 rounded-xl"
                      onClick={() => {
                        sessionStorage.GRCCategory = "policies";
                        navigate(
                          `/regulation-policy/document/details?document_type=policies&document_id=${policy.policy_id}&policy_version_id=${policy.latest_version_id}`
                        );
                      }}
                    >
                      <article className="grid">
                        <article className="flex items-center gap-2">
                          <h4 className="text-b1-reg">{policy.policy_name}</h4>
                          <RowValue row={policy} col="status" />
                        </article>
                        <span className="text-b2-reg dark:text-gray-400">
                          {policy.last_updated_at &&
                            convertToUTCShortString(
                              Number(policy.last_updated_at)
                            )}
                          {policy.customer_name && (
                            <span> {`| ${policy.customer_name}`}</span>
                          )}{" "}
                        </span>
                      </article>
                      <article className="grid gap-3">
                        <article className="flex items-center gap-4">
                          <span>
                            Total sections: {policy.total_sections || "N/A"}
                          </span>
                          <span>
                            Mapped sections: {policy.mapped_sections || "N/A"}
                          </span>
                        </article>
                        <Disclosure>
                          {({ open }) => (
                            <section className="grid content-start gap-3">
                              <Disclosure.Button
                                className="flex items-center gap-2 w-max text-sm"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setSelectedPolicyID(policy.policy_id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={open ? faChevronDown : faChevronRight}
                                />
                                <p>{open ? "Hide" : "Show"} frameworks</p>
                              </Disclosure.Button>
                              <Disclosure.Panel>
                                {policyMapping ? (
                                  policyMapping.length > 0 ? (
                                    <section className="flex flex-col flex-grow gap-3 overflow-auto scrollbar">
                                      {policyMapping.map((framework: any) => {
                                        return (
                                          <article
                                            key={framework.framework_id}
                                            className="grid gap-2 px-4 py-2 border-l dark:border-black"
                                          >
                                            <h4>{framework.framework_name}</h4>
                                            <Disclosure>
                                              {({ open }) => (
                                                <section className="grid content-start gap-3 text-sm">
                                                  <Disclosure.Button
                                                    className="flex items-center gap-2 w-max"
                                                    onClick={() =>
                                                      setSelectedPolicyID(
                                                        policy.policy_id
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={
                                                        open
                                                          ? faChevronDown
                                                          : faChevronRight
                                                      }
                                                    />
                                                    <p>
                                                      {open ? "Hide" : "Show"}{" "}
                                                      {
                                                        framework
                                                          .framework_mapped_section_ids
                                                          .length
                                                      }{" "}
                                                      mapped section
                                                      {framework
                                                        .framework_mapped_section_ids
                                                        .length !== 1 && "s"}
                                                    </p>
                                                  </Disclosure.Button>
                                                  <Disclosure.Panel>
                                                    <article className="grid gap-2 max-h-[10rem] overflow-auto scrollbar">
                                                      {framework.framework_mapped_section_ids.map(
                                                        (
                                                          section: KeyStringVal
                                                        ) => {
                                                          return (
                                                            <a
                                                              key={
                                                                section.mapped_section_id
                                                              }
                                                              href="/grc/mapping"
                                                              className="grid gap-2 px-2 py-1 hover:underlined dark:bg-gray-700 dark:hover:bg-gray-700/70 rounded-md"
                                                              onClick={() => {
                                                                sessionStorage.document_type =
                                                                  "frameworks";
                                                                sessionStorage.document_name =
                                                                  framework.framework_name;
                                                                sessionStorage.document_id =
                                                                  framework.framework_id ||
                                                                  "";
                                                                sessionStorage.generated_id =
                                                                  section.mapped_section_id ||
                                                                  "";
                                                                sessionStorage.section_title =
                                                                  section.section_title ||
                                                                  "";
                                                                sessionStorage.sub_section_title =
                                                                  section.sub_section_title ||
                                                                  "";
                                                                sessionStorage.sub_section_id =
                                                                  section.sub_section_id ||
                                                                  "";
                                                                sessionStorage.content =
                                                                  section.content;
                                                                sessionStorage.policy_id =
                                                                  policy.policy_id ||
                                                                  "";
                                                              }}
                                                            >
                                                              {section.section_title && (
                                                                <h4 className="flex items-start gap-2 text-xl border-b dark:border-black">
                                                                  {section.section_id !==
                                                                    "-" &&
                                                                    section.section_id}{" "}
                                                                  {
                                                                    section.section_title
                                                                  }
                                                                </h4>
                                                              )}
                                                              <h4 className="flex items-start gap-2 text-base">
                                                                {section.sub_section_id !==
                                                                  "-" &&
                                                                  section.sub_section_id}{" "}
                                                                {
                                                                  section.sub_section_title
                                                                }
                                                              </h4>
                                                            </a>
                                                          );
                                                        }
                                                      )}
                                                    </article>
                                                  </Disclosure.Panel>
                                                </section>
                                              )}
                                            </Disclosure>
                                          </article>
                                        );
                                      })}
                                    </section>
                                  ) : (
                                    <p>No frameworks available</p>
                                  )
                                ) : null}
                              </Disclosure.Panel>
                            </section>
                          )}
                        </Disclosure>
                      </article>
                    </article>
                  </li>
                );
              })}
            </ul>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        ) : (
          <section className="grid place-content-center gap-4 mt-10">
            <button
              className="flex gap-2 items-center mx-auto tracking-wide text-lg dark:text-gray-400 dark:hover:text-checkbox/50 duration-100"
              onClick={handleReturn}
            >
              <FontAwesomeIcon icon={faArrowLeftLong} />
              <span>Return</span>
            </button>
            <section className="flex items-center place-content-center gap-10 w-full h-full">
              <img
                src="/grc/policies-placeholder.svg"
                alt="policies placeholder"
                className="w-40 h-40"
              />
              <article className="grid content-start gap-2">
                <h4 className="text-t1-bold">Policies</h4>
                <h4>No policies available</h4>
                {policyGroup !== "Generated-Policies" && <NewPolicy />}
              </article>
            </section>
          </section>
        )
      ) : null}
    </section>
  );
};

export default Policies;
