import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { IsDocReadOnly } from "src/services/grc";

const DocumentTabs = ({
  documentID,
  selectedTab,
  setSelectedTab,
}: {
  documentID: string;
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  const { data: isDocReadOnly, status: readOnlyStatus } =
    IsDocReadOnly(documentID);

  const readOnly = readOnlyStatus === "success" && isDocReadOnly;

  return (
    <section className="flex flex-col flex-grow gap-4">
      <nav className="flex flex-wrap items-center gap-6 text-b1-reg">
        {["Agreement Review", "Redlining"].map((tab) => {
          if (tab === "Redlining" && readOnly) return null;
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center ${
                selectedTab === tab
                  ? "text-b1-semi border-b-2 dark:text-blue-600 dark:border-blue-600"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {readOnly && (
        <p className="flex items-center gap-2 p-4 dark:bg-gray-900 border dark:border-blue-500 rounded-xl">
          <FontAwesomeIcon icon={faInfoCircle} className="text-blue-500" />{" "}
          Redlining is not available for non Microsoft Word (.docx) documents
        </p>
      )}
    </section>
  );
};

export default DocumentTabs;
