import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { checkIsReadOnly } from "src/utils/general";
import { AddDRLItem } from "src/services/erc/attachments/document-requests";
import MultipleFileInput from "src/components/Input/MultipleFileInput";
import RegularInput from "src/components/Input/RegularInput";
import CategoryInput from "src/components/Input/CategoryInput";
import MultiUserInput from "src/components/Input/MultiUserInput";

const NewItem = ({ documentRequestID }: { documentRequestID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const add = AddDRLItem(documentRequestID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({});
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Item
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Item</h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Control Id"
              keyName="control_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={[
                "OPEN",
                "IN_PROGRESS",
                "RESOLVED",
                "IN_REVIEW",
                "REOPENED",
                "CLOSED",
                "ARCHIVED",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <RegularInput
              label="Document Requested"
              keyName="document_requested"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiUserInput
              label="Assigned To"
              keyName="assigned_to"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <MultipleFileInput
            label="Attachments"
            keyName="files"
            types={["pdf", "docx", "xlsx", "jpeg", "jpg", "mp4"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                if (inputs.control_id)
                  formData.append("control_id", inputs.control_id);
                if (inputs.status) formData.append("status", inputs.status);
                if (inputs.document_requested)
                  formData.append(
                    "document_requested",
                    inputs.document_requested
                  );
                if (inputs.notes) formData.append("notes", inputs.notes);
                if (inputs.assigned_to?.length > 0)
                  inputs.assigned_to.forEach((user: any) =>
                    formData.append("assigned_to", user)
                  );
                if (inputs.files?.length > 0)
                  inputs.files.forEach((file: any) =>
                    formData.append("files", file)
                  );

                add.mutate({
                  formData,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default NewItem;
