import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment } from "react";
import { GetGroupUsers, UpdateGroup } from "../../../services/settings/groups";
import { User } from "../../../types/settings";
import {
  checkIsSuperOrSiteAdmin,
  getCustomerID,
  getEmailFromUserID,
} from "../../../utils/general";
import { GetAllUsers } from "src/services/settings/users";
import { Menu, Transition } from "@headlessui/react";
import { KeyStringVal } from "src/types/general";

const Users = ({ selectedGroup }: { selectedGroup: string }) => {
  const customerID = getCustomerID();
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const { data: allUsers } = GetAllUsers(customerID, isSuperOrSiteAdmin);
  const { data: groupUsers } = GetGroupUsers(customerID, selectedGroup);
  const updateGroup = UpdateGroup(customerID);

  const groupUserIDs = groupUsers?.reduce(
    (pV: string[], cV: User) => [...pV, cV.user_id],
    []
  );
  const filteredUsers = allUsers?.filter(
    (user: User) => !groupUserIDs?.includes(user)
  );

  return (
    <section className="flex flex-wrap items-center gap-2">
      {groupUserIDs?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 text-b2-reg">
          {groupUserIDs.map((userID: string) => {
            const userEmail = getEmailFromUserID(allUsers, userID);
            if (!userEmail) return null;

            return (
              <li
                key={userID}
                className="flex items-center gap-2 px-2 py-1 dark:text-white dark:bg-gray-700 rounded"
              >
                <p>{userEmail}</p>
                <button
                  className="hover-icon"
                  onClick={() =>
                    updateGroup.mutate({
                      groupID: selectedGroup,
                      group: {
                        user_ids: groupUserIDs.filter(
                          (userID: string) => userID !== selectedGroup
                        ),
                      },
                    })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}
      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button
          className="flex items-center gap-2 px-2 py-1 cursor-pointer text-b2-reg dark:bg-blue-500 dark:hover:bg-blue-400/70 duration-100 rounded"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Add User</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded z-10">
            {filteredUsers?.length > 0 ? (
              <nav className="grid content-start w-full h-full max-h-[10rem] dark:bg-blue-900 overflow-auto scrollbar">
                {filteredUsers?.map((user: KeyStringVal) => {
                  return (
                    <button
                      key={user.user_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        updateGroup.mutate({
                          groupID: selectedGroup,
                          group: {
                            user_ids: [...groupUserIDs, user.user_id],
                          },
                        });
                      }}
                    >
                      <p>{user.user_email}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-900 rounded">
                <h4>No users found</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default Users;
