/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronRight,
  faPencil,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import SuggestNewSections from "../../../SuggestNewSections";
import ViewInFile from "../../../ViewInFile/ViewInFile";
import SubsectionContent from "./SubsectionContent";
import {
  GetSuggestSection,
  GenerateSOP,
  GetDuplicateSections,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import DuplicateSections from "./DuplicateSections";
import { handleClickMapping } from "src/utils/grc";
import {
  GetSectionIDsAndTitles,
  UpdateSectionIDTitle,
} from "src/services/regulation-policy/framework";
import { Popover, Transition } from "@headlessui/react";
import { checkIsGRCAdmin, checkIsReadOnly } from "src/utils/general";

const Subsection = ({
  documentName,
  documentType,
  documentID,
  docID,
  selectedTab,
  subsection,
  sectionIndex,
  searchedRowIndex,
  subSectionIndex,
  pageNumber,
  filter,
  search,
  sectionRef,
  editSections,
  setEditSections,
  selectedAddedSections,
  setSelectedAddedSections,
  suggestFramework,
  addSectionsToPolicy,
  hideLink,
}: {
  documentName: string;
  documentType: string;
  documentID: string;
  docID?: string;
  selectedTab?: string;
  subsection: any;
  sectionIndex: number;
  searchedRowIndex?: number;
  subSectionIndex: number;
  pageNumber?: number;
  filter?: string;
  search?: boolean;
  sectionRef?: any;
  editSections?: any;
  setEditSections?: any;
  selectedAddedSections?: any;
  setSelectedAddedSections?: any;
  suggestFramework?: KeyStringVal;
  addSectionsToPolicy?: boolean;
  hideLink?: boolean;
}) => {
  const isGRCAdmin = checkIsGRCAdmin();
  const isReadOnly = checkIsReadOnly();

  const [opened, setOpened] = useState<boolean>(false);
  const [addNew, setAddNew] = useState<boolean>(false);
  const [newSectionID, setNewSectionID] = useState<string>("");
  const [newSectionTitle, setNewSectionTitle] = useState<string>("");

  const suggestSection = GetSuggestSection();
  const generateSOP = GenerateSOP();
  const duplicateSections = GetDuplicateSections();
  const updateSectionIdAndTitle = UpdateSectionIDTitle(String(docID));
  const { data: sectionIdsAndTitles } = GetSectionIDsAndTitles(String(docID));

  const isPolicy = documentType === "policies";
  const generatedID = subsection.generated_id;
  const showDuplicateSections =
    isPolicy && subsection.duplicate_section_count > 0;

  return (
    <article
      ref={(el) => {
        if (sectionRef && sectionRef.current)
          sectionRef.current[(sectionIndex + 1) * subSectionIndex] = el;
      }}
      className="grid content-start gap-3 p-4 dark:bg-gray-900 rounded-lg"
    >
      {subsection.framework_name && (
        <h3 className="text-b1-semi">{subsection.framework_name}</h3>
      )}
      <article className="flex flex-col flex-grow gap-4 text-sm">
        <article className="flex items-center gap-2">
          {addSectionsToPolicy && (
            <input
              type="checkbox"
              disabled={isReadOnly}
              checked={selectedAddedSections.some(
                (addedSection: any) =>
                  addedSection.index === `${sectionIndex}-${subSectionIndex}`
              )}
              className="white-checkbox"
              onChange={() => {
                if (
                  selectedAddedSections.some(
                    (addedSection: any) =>
                      `${sectionIndex}-${subSectionIndex}` ===
                      addedSection.index
                  )
                ) {
                  setSelectedAddedSections(
                    selectedAddedSections.filter(
                      (addedSection: any) =>
                        `${sectionIndex}-${subSectionIndex}` !==
                        addedSection.index
                    )
                  );
                } else {
                  suggestSection.mutate(
                    {
                      versionID: docID,
                      index: subSectionIndex,
                    },
                    {
                      onSuccess: (suggestNumber) => {
                        generateSOP.mutate(
                          {
                            versionID: docID,
                            documentID: subsection.document_id,
                            generatedID: generatedID,
                          },
                          {
                            onSuccess: (generateSOP) => {
                              setSelectedAddedSections([
                                ...selectedAddedSections,
                                {
                                  index: `${sectionIndex}-${subSectionIndex}`,
                                  section_number: suggestNumber,
                                  content: generateSOP,
                                },
                              ]);
                            },
                          }
                        );
                      },
                    }
                  );
                }
              }}
            />
          )}
          <article className="flex items-center justify-between gap-10 w-full">
            <h4 className="flex items-start gap-2 text-b1-semi break-all">
              {filter !== "Suggest New Mapping" && subsection.sub_section_id}.{" "}
              {subsection.sub_section_title !== "-" &&
                subsection.sub_section_title}
            </h4>
            {isGRCAdmin && documentType === "frameworks" && (
              <Popover className="relative">
                <Popover.Button
                  disabled={isReadOnly}
                  className="group white-text-button"
                >
                  <FontAwesomeIcon icon={faPencil} />
                  Move To
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                  afterEnter={() => {
                    setAddNew(false);
                    setNewSectionID("");
                    setNewSectionTitle("");
                  }}
                >
                  <Popover.Panel className="pointer-events-auto absolute top-5 right-0 break-words z-50">
                    {({ close }) => (
                      <article className="grid gap-4 p-4 w-max max-h-[20rem] dark:bg-black rounded-md overflow-auto scrollbar">
                        {!addNew ? (
                          <button
                            disabled={isReadOnly}
                            className="blue-button"
                            onClick={() => setAddNew(true)}
                          >
                            <FontAwesomeIcon icon={faPlus} />
                            Add New Section
                          </button>
                        ) : (
                          <article className="grid gap-2">
                            <article className="flex items-center gap-2">
                              <input
                                type="input"
                                disabled={isReadOnly}
                                placeholder="Section Id"
                                className="px-5 w-2/5 h-10 bg-transparent focus:outline-none border-none focus:ring-1 dark:focus:ring-signin dark:bg-task rounded-md"
                                onChange={(e) =>
                                  setNewSectionID(e.target.value)
                                }
                              />
                              <input
                                type="input"
                                disabled={isReadOnly}
                                placeholder="Section Title"
                                className="px-5 w-full h-10 bg-transparent focus:outline-none border-none focus:ring-1 dark:focus:ring-signin dark:bg-task rounded-md"
                                onChange={(e) =>
                                  setNewSectionTitle(e.target.value)
                                }
                              />
                            </article>
                            <article className="flex items-center gap-3">
                              <button
                                disabled={isReadOnly}
                                className="red-button"
                                onClick={() => {
                                  setAddNew(false);
                                  setNewSectionID("");
                                  setNewSectionTitle("");
                                }}
                              >
                                Discard
                              </button>
                              <button
                                disabled={isReadOnly}
                                className="blue-button"
                                onClick={() => {
                                  updateSectionIdAndTitle.mutate({
                                    generatedID: generatedID,
                                    sectionID: newSectionID,
                                    sectionTitle: newSectionTitle,
                                  });
                                  setAddNew(false);
                                  setNewSectionID("");
                                  setNewSectionTitle("");
                                  close();
                                }}
                              >
                                Add
                              </button>
                            </article>
                          </article>
                        )}
                        {sectionIdsAndTitles?.map(
                          (section: KeyStringVal, sectionIndex: number) => {
                            return (
                              <button
                                key={sectionIndex}
                                disabled={isReadOnly}
                                className="p-4 w-full cursor-pointer dark:bg-gray-700 dark:hover:bg-gray-700/70"
                                onClick={() => {
                                  updateSectionIdAndTitle.mutate({
                                    generatedID: generatedID,
                                    sectionID: section.section_id,
                                    sectionTitle: section.section_title,
                                  });
                                  close();
                                }}
                              >
                                {section.section_id}. {section.section_title}
                              </button>
                            );
                          }
                        )}
                      </article>
                    )}
                  </Popover.Panel>
                </Transition>
              </Popover>
            )}
          </article>
        </article>
        <article className="flex flex-wrap items-center gap-10 w-full">
          {subsection.metadata_ &&
            Object.entries(subsection.metadata_).length > 0 && (
              <ul className="flex flex-wrap items-center gap-2">
                {Object.entries(subsection.metadata_).map(
                  (keyVal, index: number) => {
                    return (
                      <li
                        key={keyVal[0]}
                        className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded"
                      >
                        <h4 className="capitalize">
                          {keyVal[0].replaceAll("_", " ")}
                        </h4>
                        {Array.isArray(keyVal[1]) ? (
                          keyVal[1].map((value) => {
                            return (
                              <span key={value} className="dark:text-blue-500">
                                {value}
                              </span>
                            );
                          })
                        ) : (
                          <span className="dark:text-blue-500">
                            {keyVal[1]}
                          </span>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
            )}
          {!hideLink && (
            <>
              {!isPolicy ? (
                <>
                  <a
                    href="/grc/mapping"
                    className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                    onClick={() =>
                      handleClickMapping(
                        documentType,
                        documentName,
                        docID,
                        selectedTab,
                        subsection,
                        "Policy",
                        subsection.mapped_policy_sections
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    <p>
                      {subsection.mapped_policy_sections === 0
                        ? "Policy"
                        : `${subsection.mapped_policy_sections} Mapped to Policy`}
                    </p>
                  </a>
                  {selectedTab === "Controls" && (
                    <a
                      href="/grc/mapping"
                      className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                      onClick={() =>
                        handleClickMapping(
                          documentType,
                          documentName,
                          docID,
                          selectedTab,
                          subsection,
                          "Relevant Sections",
                          subsection.mapped_self_control_sections
                        )
                      }
                    >
                      <FontAwesomeIcon icon={faChevronRight} />
                      <p>
                        {subsection.mapped_self_control_sections === 0
                          ? "Relevant Sections"
                          : `${
                              subsection.mapped_self_control_sections
                            } Relevant Section${
                              subsection.mapped_self_control_sections === 1
                                ? ""
                                : "s"
                            }`}
                      </p>{" "}
                    </a>
                  )}
                  <a
                    href="/grc/mapping"
                    className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                    onClick={() =>
                      handleClickMapping(
                        documentType,
                        documentName,
                        docID,
                        selectedTab,
                        subsection,
                        "RFS",
                        subsection.mapped_framework_sections
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    <p>
                      {subsection.mapped_framework_sections === 0
                        ? "Framework"
                        : `${subsection.mapped_framework_sections} Overlap${
                            subsection.mapped_framework_sections === 1
                              ? "s"
                              : ""
                          } with Framework`}
                    </p>{" "}
                  </a>
                </>
              ) : (
                <>
                  <a
                    href="/grc/mapping"
                    className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                    onClick={() =>
                      handleClickMapping(
                        documentType,
                        documentName,
                        docID,
                        selectedTab,
                        subsection,
                        "RFS",
                        subsection.mapped_sections
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    <p>
                      {subsection.mapped_sections === 0
                        ? "Framework"
                        : `${subsection.mapped_sections} Mapped to Framework`}
                    </p>{" "}
                  </a>
                  <a
                    href="/grc/mapping"
                    className="flex flex-wrap items-center gap-2 dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
                    onClick={() =>
                      handleClickMapping(
                        documentType,
                        documentName,
                        docID,
                        selectedTab,
                        subsection,
                        "Control",
                        subsection.mapped_control_section
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faChevronRight} />
                    <p>
                      {subsection.mapped_control_section === 0
                        ? "Control"
                        : `${subsection.mapped_control_section} Mapped to Control`}
                    </p>{" "}
                  </a>
                  {subsection.content && filter === "Suggest New Mapping" && (
                    <SuggestNewSections
                      section={subsection}
                      index={Number(pageNumber) * (sectionIndex + 1)}
                      versionID={String(docID)}
                      suggestFramework={suggestFramework}
                    />
                  )}
                </>
              )}
            </>
          )}
        </article>
      </article>
      <section
        className={`grid ${
          subsection.page_metadata?.length > 0 ? "grid-cols-2" : "grid-cols-1"
        } content-start gap-10 w-full`}
      >
        {subsection.content && (
          <SubsectionContent
            documentType={documentType}
            documentID={documentID}
            subsection={subsection}
            filter={filter}
            editSections={editSections}
            setEditSections={setEditSections}
          />
        )}
        {subsection.page_metadata?.length > 0 && (
          <ViewInFile
            generatedID={generatedID}
            section={subsection}
            bbox={subsection.page_metadata}
            editSections={editSections}
            setEditSections={setEditSections}
            documentType={documentType}
            opened={opened}
            setOpened={setOpened}
            isNotModal
          />
        )}
      </section>
      {showDuplicateSections && (
        <DuplicateSections
          documentType={documentType}
          generatedID={generatedID}
          duplicateSections={duplicateSections}
        />
      )}
    </article>
  );
};

export default Subsection;
