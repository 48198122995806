import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { GetDocumentTags } from "src/services/grc";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly } from "src/utils/general";

const Tags = ({ inputs, setInputs }: { inputs: any; setInputs: any }) => {
  const isReadOnly = checkIsReadOnly();

  const { data: allTags } = GetDocumentTags("frameworks");

  const filteredTags = allTags?.filter(
    (curTag: KeyStringVal) =>
      !inputs.tags.some((tag: KeyStringVal) => tag.tag_id === curTag.tag_id)
  );

  return (
    <section className="flex flex-wrap items-center gap-4 p-2 text-b2-reg dark:bg-gray-900 rounded">
      {inputs.tags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {inputs.tags?.map((tag: KeyStringVal, index: number) => {
            return (
              <li
                key={index}
                className="flex items-center gap-3 px-4 py-1 dark:bg-gray-700 rounded"
                onClick={() => {
                  if (
                    inputs.tags.some(
                      (curTag: KeyStringVal) => curTag.tag_id === tag.tag_id
                    )
                  )
                    setInputs({
                      ...inputs,
                      tags: inputs.tags.filter(
                        (curTag: KeyStringVal) => curTag.tag_id !== tag.tag_id
                      ),
                    });
                  else setInputs({ ...inputs, tags: [...inputs.tags, tag] });
                }}
              >
                <p>{tag.tag_title || tag.title}</p>
                <button
                  disabled={isReadOnly}
                  className="hover-icon"
                  onClick={(e) =>
                    setInputs({
                      ...inputs,
                      tags: inputs.tags.filter(
                        (curTag: KeyStringVal) => curTag.tag_id !== tag.tag_id
                      ),
                    })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}
      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button className="dark-blue-button">
          <FontAwesomeIcon icon={faPlus} />
          <h4>Assign Tag</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max -mr-5 mt-2 gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
            {allTags?.length > 0 ? (
              <nav className="grid content-start w-full max-h-60 overflow-auto scrollbar">
                {filteredTags?.map((tag: KeyStringVal) => {
                  return (
                    <button
                      key={tag.tag_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={() =>
                        setInputs({ ...inputs, tags: [...inputs.tags, tag] })
                      }
                    >
                      <p>{tag.tag_title}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
                <h4>No new tags</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default Tags;
