import React from "react";
import PieWithNeedle from "src/components/Chart/PieWithNeedle";
import { GetOverallRiskScore } from "src/services/erc/risks/risk-dashboard";

const OverallRiskScore = () => {
  const { data } = GetOverallRiskScore();

  return (
    <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      <PieWithNeedle title="Overall Risk Score" value={data?.value} />
    </section>
  );
};

export default OverallRiskScore;
