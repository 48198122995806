/* eslint-disable react-hooks/exhaustive-deps */
import { faArrowRightLong, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import RegionFilter from "src/components/Filter/RegulationPolicy/RegionFilter";
import VerticalFilter from "src/components/Filter/RegulationPolicy/VerticalFilter";
import FileInput from "src/components/Input/FileInput";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import {
  CreateFramework,
  ParseFrameworkMetadata,
} from "src/services/regulation-policy/framework";
import AdditionalRegAuth from "./AdditionalRegAuth";
import { checkIsReadOnly } from "src/utils/general";
import CheckboxInput from "src/components/Input/CheckboxInput";
import CategoryInput from "src/components/Input/CategoryInput";

const NewDocument = ({ documentType }: { documentType: string }) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const documentTypePhrase =
    documentType === "frameworks" ? "Framework" : "Circular";

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    file: "",
    file_uri: "",
    regulatory_authority: "",
    additional_regulatory_authorities: [],
    framework_name: "",
    alias: "",
    thumbnail: "",
    regulatory_date: Date.now() * 1000,
    regions: [],
    verticals: [],
    parse_only: false,
    custom: false,
  });

  const uploadFramework = CreateFramework();
  const parseFrameworkMetadata = ParseFrameworkMetadata();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => uploadFramework.reset();

  useEffect(() => {
    if (inputs.file !== "") {
      const formData = new FormData();

      formData.append("file", inputs.file);
      if (inputs.file_uri) formData.append("file_uri", inputs.file_uri);

      parseFrameworkMetadata.mutate({
        formData,
      });
    }
  }, [inputs.file]);

  useEffect(() => {
    if (parseFrameworkMetadata.data)
      setInputs({
        ...inputs,
        ...(!Boolean(inputs.regulatory_authority) && {
          regulatory_authority:
            parseFrameworkMetadata.data.regulatory_authority || "",
        }),
        ...(!Boolean(inputs.framework_name) && {
          framework_name: parseFrameworkMetadata.data.framework_name || "",
        }),
        ...(parseFrameworkMetadata.data.regulatory_date && {
          regulatory_date: parseFrameworkMetadata.data.regulatory_date,
        }),
      });
  }, [parseFrameworkMetadata.data]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            file: "",
            file_uri: "",
            regulatory_authority: "",
            additional_regulatory_authorities: [],
            framework_name: "",
            alias: "",
            thumbnail: "",
            regulatory_date: Date.now() * 1000,
            regions: [],
            verticals: [],
            parse_only: false,
            custom: false,
            non_english_content: false,
            source_language: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4 className="w-max">Upload {documentTypePhrase}</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <>
          {uploadFramework.data !== undefined ? (
            <section className="grid content-start gap-4 py-4 h-full mx-auto text-center">
              <img
                src="/general/checkmark.svg"
                alt="checkmark"
                className="w-12 h-12 mx-auto"
              />
              <span className="text-2xl italic">{inputs.framework_name}</span>
              <h3 className="text-lg">
                has been uploaded. GRC AI Agents are on it! Will notify you as
                soon as the document is read, parsed, understood, and analyzed
                by the autonomous agent.
              </h3>
              <button
                className="px-4 py-2 mx-auto w-max dark:bg-filter/60 dark:hover:bg-filter/30 duration-100 rounded-full"
                onClick={() => {
                  navigate(
                    `/regulation-policy/document/details?document_type=${documentType}&document_id=${uploadFramework.data?.generated_id}`
                  );
                  handleOnCloseConfirmation();
                }}
              >
                <h4>
                  Go to {documentTypePhrase}{" "}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </h4>
              </button>
            </section>
          ) : (
            <section className="grid content-start gap-4 py-4 h-full overflow-auto scrollbar">
              <h3 className="flex items-center gap-2 text-t1-semi">
                Upload {documentTypePhrase}
              </h3>
              <section className="grid content-start gap-7">
                <section className="grid content-start md:grid-cols-2 gap-10">
                  <RegularInput
                    label={`${documentTypePhrase} Name`}
                    keyName="framework_name"
                    inputs={inputs}
                    setInputs={setInputs}
                    required
                  />
                  <RegularInput
                    label="Alias"
                    keyName="alias"
                    inputs={inputs}
                    setInputs={setInputs}
                    required
                  />
                  <RegularInput
                    label="Regulatory Authority"
                    keyName="regulatory_authority"
                    inputs={inputs}
                    setInputs={setInputs}
                    required
                  />
                  {documentTypePhrase === "Framework" && (
                    <AdditionalRegAuth
                      documentType={documentTypePhrase}
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  )}
                  <GeneralSnapshotDatepicker
                    label="Published at"
                    keyName="regulatory_date"
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <RegionFilter
                    label="Regions"
                    keyName="regions"
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  <VerticalFilter
                    label="Verticals"
                    keyName="verticals"
                    inputs={inputs}
                    setInputs={setInputs}
                  />
                  {documentType === "circulars" && (
                    <>
                      <CheckboxInput
                        label="Non-English Content"
                        keyName="non_english_content"
                        inputs={inputs}
                        setInputs={setInputs}
                      />
                      {inputs.non_english_content && (
                        <CategoryInput
                          label="Source Language"
                          keyName="source_language"
                          list={[
                            "German (de)",
                            "Spanish (es)",
                            "French (fr)",
                            "Hindi (hi)",
                            "Italian (it)",
                            "Japanese (ja)",
                            "Korean (ko)",
                            "Thai (th)",
                            "Portuguese (pt)",
                            "Chinese Simplified (zh)",
                          ]}
                          inputs={inputs}
                          setInputs={setInputs}
                          allowUnselect
                        />
                      )}
                    </>
                  )}
                </section>
                <RegularInput
                  label="File URL (optional)"
                  keyName="file_uri"
                  inputs={inputs}
                  setInputs={setInputs}
                />
                <FileInput
                  label={documentTypePhrase}
                  keyName="file"
                  types={["pdf", "docx", "xlsx"]}
                  inputs={inputs}
                  setInputs={setInputs}
                />
              </section>
              <article className="flex items-center place-content-end gap-4">
                <button className="black-button" onClick={handleOnClose}>
                  Cancel
                </button>
                <button
                  disabled={
                    inputs.regulatory_authority === "" ||
                    inputs.framework_name === "" ||
                    (inputs.file === "" && inputs.file_uri === "")
                  }
                  className="blue-button"
                  onClick={() => {
                    const formData = new FormData();

                    formData.append("type", documentTypePhrase.toUpperCase());
                    formData.append(
                      "regulatory_authority",
                      inputs.regulatory_authority
                    );
                    formData.append("regulatory_date", inputs.regulatory_date);
                    formData.append("framework_name", inputs.framework_name);
                    formData.append("alias", inputs.alias);
                    formData.append("parse_only", inputs.parse_only);
                    formData.append("custom", inputs.custom);
                    formData.append(
                      "non_english_content",
                      inputs.non_english_content
                    );
                    formData.append("source_language", inputs.source_language);
                    if (inputs.file) formData.append("file", inputs.file);
                    if (inputs.file_uri)
                      formData.append("file_uri", inputs.file_uri);
                    if (inputs.additional_regulatory_authorities.length > 0)
                      inputs.additional_regulatory_authorities.forEach(
                        (auth: string) =>
                          formData.append(
                            "additional_regulatory_authorities",
                            auth
                          )
                      );
                    if (inputs.regions.length > 0)
                      inputs.regions.forEach((region: string) =>
                        formData.append("regions", region)
                      );
                    if (inputs.verticals.length > 0)
                      inputs.verticals.forEach((vertical: string) =>
                        formData.append("verticals", vertical)
                      );
                    uploadFramework.mutate({
                      formData,
                    });
                    handleOnClose();
                  }}
                >
                  Done
                </button>
              </article>
            </section>
          )}
        </>
      </ModalLayout>
    </>
  );
};

export default NewDocument;
