import React, { useState } from "react";
import Loader from "src/components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import TableLayout from "src/layouts/TableLayout";
import { handleSort, sortRows } from "src/utils/general";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { initialSort, pageSize } from "src/constants/general";
import { Sort } from "src/types/dashboard";
import TablePagination from "src/components/General/TablePagination";
import { GetAssessmentCycleVendorList } from "../../../../../services/third-party-risk/assessments/assessment-cycles";
import DeleteVendor from "./DeleteVendor";
import RowValue from "src/components/General/RowValue";

const VendorList = ({ assessmentID }: { assessmentID: string }) => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>(initialSort);

  const { data: vendors, status: vendorsStatus } = GetAssessmentCycleVendorList(
    assessmentID,
    pageNumber
  );

  const headers = vendors && JSON.parse(vendors.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = vendors && sortRows(vendors?.data, sort);

  const totalCount = vendors?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4">
      <h4 className="text-subt1-semi">Vendors</h4>
      {vendorsStatus === "loading" ? (
        <Loader />
      ) : vendors?.data?.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {filteredHeaders?.map((col: string) => {
                  const column = headers.properties[col];
                  if (!column) return null;

                  return (
                    <th
                      scope="col"
                      key={col}
                      className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                    >
                      <article className="capitalize flex gap-10 justify-between">
                        <h4 className="break-words">{column.title}</h4>
                        <button aria-label="sort">
                          <FontAwesomeIcon
                            icon={faSort}
                            className="mt-0.5 dark:text-gray-700"
                            onClick={() => handleSort(col, setSort)}
                          />
                        </button>
                      </article>
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((row: any, index: number) => {
                return (
                  <tr
                    key={index}
                    data-test="table-row"
                    className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                    onClick={() => {
                      sessionStorage.GRCCategory = "vendors";
                      navigate(
                        `/third-party-risk/vendors/details?vendor_id=${row.third_party_id}&assessment_id=${assessmentID}`
                      );
                    }}
                  >
                    {filteredHeaders?.map((col: string, colIndex: number) => {
                      return (
                        <td
                          key={`${index}-${colIndex}`}
                          className="relative px-6 py-3 last:pr-16 text-left break-words"
                        >
                          <RowValue row={row} col={col} />
                          {colIndex === filteredHeaders.length - 1 && (
                            <article className="absolute top-1/3 right-5">
                              <DeleteVendor
                                vendorID={row.third_party_id}
                                assessmentID={assessmentID}
                              />
                            </article>
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="vendors placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Vendors</h4>
            <h4>No vendors available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default VendorList;
