import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";

const PolicyGroupFilter = ({
  selectedPolicyGroups,
  setSelectedPolicyGroups,
  setSelectedPolicyIDs,
  policyGroups,
  index,
}: {
  selectedPolicyGroups: any;
  setSelectedPolicyGroups: any;
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
  policyGroups: any;
  index: number;
}) => {
  return (
    <DropdownLayout
      label="Policy Group"
      value={selectedPolicyGroups[index]?.title}
      placeholder="Select"
    >
      {policyGroups?.map((policyGroup: any, policyGroupIdx: number) => {
        const selected =
          selectedPolicyGroups[policyGroupIdx]?.title === policyGroup.title;

        return (
          <button
            key={policyGroupIdx}
            className={`relative flex items-center gap-2 capitalize px-4 py-1 text-left break-words dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              const newPolicyGroups = selectedPolicyGroups;
              newPolicyGroups.splice(index, 1, policyGroup);
              setSelectedPolicyGroups([...newPolicyGroups]);
              setSelectedPolicyIDs([]);
            }}
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{policyGroup.title}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default PolicyGroupFilter;
