import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import VulnMetadata from "./VulnMetadata";
import AssociateRiskWithVulnFilter from "src/components/Filter/ERC/AssociateRiskWithVulnFilter";

const Vuln = () => {
  const parsed = parseURL();

  const vulnID = String(parsed.vuln_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "risks";
    sessionStorage.threat_vuln = "vulnerabilities";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <VulnMetadata vulnID={vulnID} />
        <AssociateRiskWithVulnFilter vulnID={vulnID} />
      </main>
    </PageLayout>
  );
};

export default Vuln;
