/* eslint-disable react-hooks/exhaustive-deps */
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { ExportVendorResponses } from "../../../../../../../services/third-party-risk/vendors/vendors";
import { checkIsReadOnly } from "src/utils/general";

const ExportResponses = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const isReadOnly = checkIsReadOnly();

  const exportResponses = ExportVendorResponses(vendorID, assessmentID);

  const onDownload = () => {
    exportResponses.mutate(
      {},
      {
        onSuccess: (data) => {
          const url = data;
          let a = document.createElement("a");
          a.href = url;
          a.download = "responses.docx";
          a.target = "_blank";
          a.click();
        },
      }
    );
  };

  useEffect(() => {
    exportResponses.mutate({});
  }, []);

  return (
    <section className="place-self-end text-b1-reg">
      {exportResponses.data && (
        <button
          disabled={isReadOnly}
          className="blue-button"
          onClick={onDownload}
        >
          <FontAwesomeIcon icon={faDownload} />
          Download Assessment
        </button>
      )}
    </section>
  );
};

export default ExportResponses;
