/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";
import { GetRiskComplianceControlFilters } from "src/services/regulation-policy/regulation-policy";

const MultiControlFilters = ({
  documentType,
  documentID,
  inputs,
  setInputs,
  required,
}: {
  documentType: string;
  documentID: string;
  inputs: any;
  setInputs: (inputs: any) => void;
  required?: boolean;
}) => {
  const { data: controlFilters } = GetRiskComplianceControlFilters(
    documentType,
    documentID
  );

  return (
    <ul className="flex flex-wrap items-center gap-4">
      {controlFilters &&
        Object.keys(controlFilters).map((keyName: string) => {
          if (
            controlFilters[keyName]?.length === 0 ||
            (documentType === "policies" && keyName === "sub_domain")
          )
            return null;
          return (
            <li key={keyName}>
              <DropdownLayout
                label={`${keyName.replaceAll("_", " ")} ${
                  required ? "(required)" : ""
                }`}
                value={`${
                  inputs[keyName].length
                    ? `${inputs[keyName].length} selected`
                    : "Select"
                }`}
                placeholder="Select"
                width="w-[20rem]"
              >
                <article className="flex flex-col flex-grow gap-1">
                  {controlFilters[keyName]?.map(
                    (curVal: string, index: number) => {
                      const selected = inputs[keyName].includes(curVal);
                      return (
                        <button
                          key={index}
                          className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                            selected ? "dark:bg-gray-800" : ""
                          } rounded-lg`}
                          onClick={() => {
                            if (inputs[keyName].includes(curVal))
                              setInputs({
                                ...inputs,
                                [keyName]: inputs[keyName].filter(
                                  (curValue: string) => curValue !== curVal
                                ),
                              });
                            else
                              setInputs({
                                ...inputs,
                                [keyName]: [...inputs[keyName], curVal],
                              });
                          }}
                        >
                          {selected && <FontAwesomeIcon icon={faCheck} />}
                          <p>{curVal}</p>
                        </button>
                      );
                    }
                  )}
                </article>
              </DropdownLayout>
            </li>
          );
        })}
    </ul>
  );
};

export default MultiControlFilters;
