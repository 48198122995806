import { faArrowLeftLong, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import Loader from "src/components/Loader/Loader";
import { GetQuestionBankQuestions } from "src/services/questionnaire-rfp";
import { useGRCStore } from "src/stores/grc";
import NewQAPair from "./NewQAPair";
import TableLayout from "src/layouts/TableLayout";
import { handleSort, sortRows } from "src/utils/general";
import { Sort } from "src/types/dashboard";
import QADetail from "./QADetail";

const QuestionBankDetail = () => {
  const { setSelectedGRCQuestionBank } = useGRCStore();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>({
    order: "asc",
    orderBy: "question",
  });

  const questionBankID = sessionStorage.question_bank_id;

  const { data: questions, status: questionStatus } = GetQuestionBankQuestions(
    questionBankID,
    pageNumber
  );

  const sortedRows = questions && sortRows(questions?.data, sort);
  const totalCount = questions?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4">
      {/* <header className="flex items-center gap-4">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-sm dark:text-gray-400 dark:hover:text-checkbox/50 duration-100"
          onClick={() => {
            sessionStorage.removeItem("question_bank_id");
            setSelectedGRCQuestionBank({
              name: "",
              question_bank_id: "",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h4 className="text-2xl">{sessionStorage.question_bank_name}</h4>
      </header> */}
      <article className="flex items-center gap-2">
        <button
          className="flex gap-2 items-center w-max tracking-wide text-b3-reg dark:hover:text-gray-700/70 duration-100"
          onClick={() => {
            sessionStorage.removeItem("question_bank_id");
            setSelectedGRCQuestionBank({
              name: "",
              question_bank_id: "",
            });
          }}
        >
          <FontAwesomeIcon icon={faArrowLeftLong} />
        </button>
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Questionnaire & RFP</span>{" "}
          <span>/</span>
          <span>Question Bank</span>
        </h4>
      </article>
      <article className="flex items-center justify-between gap-4">
        <h4 className="text-t1-semi">{sessionStorage.question_bank_name}</h4>
        <NewQAPair questionBankID={questionBankID} />
      </article>
      {questionStatus === "loading" ? (
        <Loader />
      ) : questions?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <TableLayout fullHeight>
            <thead className="sticky -top-1.5 dark:bg-gray-900">
              <tr>
                {["questions", "answers"]?.map(
                  (col: string, colIndex: number) => {
                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <h4 className="break-words">{col}</h4>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody className="divide-y dark:divide-gray-700">
              {sortedRows?.map((question: any, questionIndex: number) => {
                return (
                  <QADetail
                    key={questionIndex}
                    questionBankID={questionBankID}
                    question={question}
                  />
                );
              })}
            </tbody>
          </TableLayout>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/frameworks-placeholder.svg"
            alt="frameworks placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Question Banks</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default QuestionBankDetail;
