import React, { useState } from "react";
import ActivityDetail from "./ActivityDetail";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import AuditTrailTypesFilter from "src/components/Filter/RegulationPolicy/AuditTrailTypesFilter";
import AuditTrailUsersFilter from "src/components/Filter/RegulationPolicy/AuditTrailUsersFilter";
import { GetGRCActivity } from "src/services/regulation-policy/overview";
import Loader from "src/components/Loader/Loader";

const Activities = () => {
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [inputs, setInputs] = useState({
    types: [],
    users: [],
  });

  const {
    data: actvities,
    status: activityStatus,
    refetch,
  } = GetGRCActivity(pageNumber, inputs.types, inputs.users);

  const totalCount = actvities?.pager.total_results || 0;
  const totalPages = actvities?.pager.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {activityStatus === "loading" ? (
        <Loader />
      ) : (
        <section className="flex flex-col flex-grow gap-4 pb-24">
          <article className="flex items-center place-self-end gap-4">
            <AuditTrailTypesFilter
              label="Activity Types"
              keyName="types"
              inputs={inputs}
              setInputs={setInputs}
              refetch={refetch}
            />
            <AuditTrailUsersFilter
              label="Users"
              keyName="users"
              inputs={inputs}
              setInputs={setInputs}
              refetch={refetch}
            />
          </article>
          {actvities ? (
            actvities.data.length > 0 ? (
              <section className="flex flex-col flex-grow content-start gap-7 text-sm">
                {actvities.data.map((activity: any, index: number) => {
                  return <ActivityDetail key={index} activity={activity} />;
                })}
                <TablePagination
                  totalPages={totalPages}
                  beginning={beginning}
                  end={end}
                  totalCount={totalCount}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </section>
            ) : (
              <span>No activities available</span>
            )
          ) : null}
        </section>
      )}
    </>
  );
};

export default Activities;
