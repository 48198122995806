/* eslint-disable react-hooks/exhaustive-deps */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";
import { GetRiskComplianceControlFilters } from "src/services/regulation-policy/regulation-policy";
import { KeyStringVal } from "src/types/general";

const ControlFilters = ({
  documentType,
  documentID,
  inputs,
  setInputs,
  required,
}: {
  documentType: string;
  documentID: string;
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  required?: boolean;
}) => {
  const { data: controlFilters } = GetRiskComplianceControlFilters(
    documentType,
    documentID
  );

  return (
    <ul className="flex items-center gap-4">
      {controlFilters &&
        Object.keys(controlFilters).map((keyName: string) => {
          if (
            controlFilters[keyName]?.length === 0 ||
            (documentType === "policies" && keyName === "sub_domain")
          )
            return null;
          return (
            <li key={keyName}>
              <DropdownLayout
                label={`${keyName.replaceAll("_", " ")} ${
                  required ? "(required)" : ""
                }`}
                value={inputs[keyName]}
                placeholder="Select"
                width="w-[20rem]"
              >
                {controlFilters[keyName]?.map(
                  (curVal: string, index: number) => {
                    const selected = inputs[keyName] === curVal;
                    return (
                      <button
                        key={index}
                        className="relative flex items-center gap-2 px-4 py-1 w-full break-words text-left dark:hover:bg-gray-800/70 duration-100"
                        onClick={() => {
                          if (selected) setInputs({ ...inputs, [keyName]: "" });
                          else setInputs({ ...inputs, [keyName]: curVal });
                        }}
                      >
                        {selected && <FontAwesomeIcon icon={faCheck} />}
                        <p>{curVal}</p>
                      </button>
                    );
                  }
                )}
              </DropdownLayout>
            </li>
          );
        })}
    </ul>
  );
};

export default ControlFilters;
