import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import ToastLayout from "src/layouts/ToastLayout";
import {
  AddQuestionToQuestionSet,
  GetCustomQuestionSets,
} from "src/services/third-party-risk/questions/custom-question-sets";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly } from "src/utils/general";

const AddQuestionsToQuestionSet = ({
  selectedQuestions,
}: {
  selectedQuestions: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const { data: questionSets } = GetCustomQuestionSets();
  const addQuestion = AddQuestionToQuestionSet();

  const draftQuestionSets = questionSets?.data.filter(
    (questionSet: KeyStringVal) => questionSet.state === "DRAFT"
  );

  const handleOnCloseConfirmation = () => addQuestion.reset();

  return (
    <>
      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button
          disabled={isReadOnly || selectedQuestions.length === 0}
          className="place-self-end black-button"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Add to Question Set</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 top-12 grid origin-top-right max-h-[20rem] focus:outline-none dark:text-white dark:bg-gray-800 divide-y dark:divide-gray-500 black-shadow overflow-auto scrollbar rounded-lg z-10">
            {draftQuestionSets?.map((questionSet: KeyStringVal) => {
              return (
                <button
                  key={questionSet.generated_id}
                  className="px-4 py-2 text-left dark:hover:bg-gray-700 duration-100"
                  onClick={() => {
                    const questionIDs = selectedQuestions.reduce(
                      (pV: string[], cV: KeyStringVal) => [
                        ...pV,
                        cV.generated_id,
                      ],
                      []
                    );
                    addQuestion.mutate({
                      questionSetID: questionSet.generated_id,
                      inputs: {
                        question_ids: questionIDs,
                      },
                    });
                  }}
                >
                  {questionSet.name}
                </button>
              );
            })}
          </Menu.Items>
        </Transition>
      </Menu>
      <ToastLayout
        showModal={addQuestion.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Questions have been added to the existing question set"
      />
    </>
  );
};

export default AddQuestionsToQuestionSet;
