/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import BIAMetadata from "./BIAMetadata";

const BIA = () => {
  const parsed = parseURL();

  const biaID = String(parsed.bia_id) || "";

  useEffect(() => {
    sessionStorage.GRCCategory = "business impact analysis";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 h-full w-full overflow-auto scrollbar">
        <BIAMetadata biaID={biaID} />
      </main>
    </PageLayout>
  );
};

export default BIA;
