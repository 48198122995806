/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { KeyStringVal } from "src/types/general";
import { pageSize } from "src/constants/general";
import Response from "./Response";
import {
  GetGlobalQuestions,
  ReorderGlobalQuestion,
} from "src/services/third-party-risk/questions/global-questions";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { arrayMove, SortableContext } from "@dnd-kit/sortable";
import CreateManualSet from "./CreateManualSet";
import RemoveQuestions from "./RemoveQuestions";
import {
  checkIsReadOnly,
  handleSort,
  sortRows,
} from "../../../../../utils/general";
import AllTags from "./AllTags";
import { faMinus, faPlus, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableLayout from "../../../../../layouts/TableLayout";
import { Sort } from "../../../../../types/dashboard";
import AddQuestionsToQuestionSet from "./AddQuestionsToQuestionSet";

const GlobalQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedQuestions: any[];
  setSelectedQuestions: (selectedQuestions: any[]) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<Sort>({
    order: "asc",
    orderBy: "order_key",
  });
  const [inputs, setInputs] = useState<any>({
    name: "",
    description: "",
    save_global: false,
  });
  const [list, setList] = useState([]);
  const [activeItem, setActiveItem] = useState<any>({});
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [show, setShow] = useState<boolean>(false);

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: { distance: 10 },
  });
  const sensors = useSensors(pointerSensor);

  const { data: globalQuestions, status: globalQuestionsStatus } =
    GetGlobalQuestions(pageNumber, selectedTags);
  const reorderQuestion = ReorderGlobalQuestion();

  const headers =
    globalQuestions && JSON.parse(globalQuestions.header.metadata);
  const filteredHeaders =
    headers &&
    Object.keys(headers.properties).filter((col: string) => {
      if (headers.properties[col]?.hidden === "True") return null;
      return col;
    });

  const sortedRows = globalQuestions && sortRows(globalQuestions?.data, sort);
  const totalCount = globalQuestions?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  const allQuestionIDs = globalQuestions?.data.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.generated_id],
    []
  ) as string[];
  const selectedQuestionIDs = selectedQuestions?.reduce(
    (pV, cV) => [...pV, cV.generated_id],
    []
  ) as string[];
  const selectedAll =
    selectedQuestions.length > 0 &&
    allQuestionIDs?.every((questionID) =>
      selectedQuestionIDs?.includes(questionID)
    );

  const reorderQuestionsList = (e: DragEndEvent) => {
    if (!e.over) return;

    if (e.active.id !== e.over.id) {
      const oldIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.active.id
      );
      const newIdx = list.findIndex(
        (response: KeyStringVal) => response.generated_id === e.over!.id
      );
      setList(arrayMove(list, oldIdx, newIdx));
      reorderQuestion.mutate({
        questionID: e.active.id,
        order_key: newIdx - oldIdx,
      });
    }
  };

  useEffect(() => {
    if (sortedRows) setList(sortedRows);
    if (globalQuestions?.data)
      setSelectedQuestions(
        selectedQuestions.map((question) => {
          const updatedQuestion = globalQuestions.data.find(
            (curQ: KeyStringVal) => curQ.generated_id === question.generated_id
          );
          if (updatedQuestion) return updatedQuestion;
          else return question;
        })
      );
  }, [globalQuestions]);

  return (
    <section className="flex flex-col flex-grow gap-4 pb-24">
      <AllTags selectedTags={selectedTags} setSelectedTags={setSelectedTags} />
      {globalQuestionsStatus === "loading" ? (
        <Loader />
      ) : globalQuestions?.data.length > 0 ? (
        <section className="flex flex-col flex-grow gap-4">
          <article className="flex items-center place-content-end gap-4">
            <AddQuestionsToQuestionSet selectedQuestions={selectedQuestions} />
            <button
              disabled={isReadOnly || selectedQuestions.length === 0}
              className="black-button"
              onClick={() => {
                setShow(!show);
                setInputs({
                  name: "",
                  description: "",
                  save_global: false,
                });
              }}
            >
              <FontAwesomeIcon icon={show ? faMinus : faPlus} />
              <h4>Create Question Set</h4>
            </button>
            <RemoveQuestions
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
            />
          </article>
          {show && (
            <CreateManualSet
              setShow={setShow}
              inputs={inputs}
              setInputs={setInputs}
              selectedQuestions={selectedQuestions}
              setSelectedQuestions={setSelectedQuestions}
              headers={headers}
              filteredHeaders={filteredHeaders}
            />
          )}
          <DndContext
            sensors={sensors}
            onDragStart={(e) =>
              setActiveItem(
                list.find(
                  (item: KeyStringVal) => item.generated_id === e.active.id
                )
              )
            }
            onDragEnd={reorderQuestionsList}
          >
            <TableLayout fullHeight>
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {filteredHeaders?.map((col: string, colIndex: number) => {
                    const column = headers.properties[col];
                    if (!column) return null;

                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <article className="flex items-center gap-4">
                            {colIndex === 0 && (
                              <input
                                type="checkbox"
                                disabled={isReadOnly}
                                checked={selectedAll}
                                onChange={() => {
                                  if (selectedAll)
                                    setSelectedQuestions(
                                      selectedQuestions.filter(
                                        (question) =>
                                          !allQuestionIDs.includes(
                                            question.generated_id
                                          )
                                      )
                                    );
                                  else
                                    setSelectedQuestions([
                                      ...selectedQuestions,
                                      ...globalQuestions.data,
                                    ]);
                                }}
                                className="ml-4 white-checkbox"
                              />
                            )}
                            <h4 className="break-words">{column.title}</h4>
                          </article>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                <SortableContext items={list}>
                  {list?.map((qa: any) => {
                    return (
                      <Response
                        key={qa.generated_id}
                        qa={qa}
                        selectedQuestions={selectedQuestions}
                        setSelectedQuestions={setSelectedQuestions}
                        filteredHeaders={filteredHeaders}
                      />
                    );
                  })}
                </SortableContext>
              </tbody>
            </TableLayout>

            <DragOverlay
              dropAnimation={{
                duration: 150,
                easing: "cubic-bezier(0.18, 0.67, 0.6, 1.22)",
              }}
            >
              {activeItem ? (
                <Response
                  qa={activeItem}
                  selectedQuestions={selectedQuestions}
                  setSelectedQuestions={setSelectedQuestions}
                  filteredHeaders={filteredHeaders}
                />
              ) : null}
            </DragOverlay>
          </DndContext>
          <TablePagination
            totalPages={totalPages}
            beginning={beginning}
            end={end}
            totalCount={totalCount}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
          />
        </section>
      ) : (
        <section className="flex items-center place-content-center gap-10 w-full h-full">
          <img
            src="/grc/third-party-risk-placeholder.svg"
            alt="question sets placeholder"
            className="w-40 h-40"
          />
          <article className="grid gap-3">
            <h4 className="text-t1-bold">Global Questions</h4>
            <h4>No questions available</h4>
          </article>
        </section>
      )}
    </section>
  );
};

export default GlobalQuestions;
