/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AddVendorToRisk,
  GetVendorsForRisk,
  RemoveVendorFromRisk,
} from "src/services/erc/risks/risks";
import { GetVendors } from "src/services/third-party-risk/vendors/vendors";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { checkIsReadOnly } from "src/utils/general";

const AssociateVendorWithRiskFilter = ({ riskID }: { riskID: string }) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: vendorsForRisk } = GetVendorsForRisk(riskID);
  const { data: vendors } = GetVendors();
  const add = AddVendorToRisk();
  const remove = RemoveVendorFromRisk();

  const filteredItems = vendors?.data.filter((item: KeyStringVal) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <section className="flex flex-col flex-grow gap-3">
      <article className="flex items-center gap-3 text-b2-reg">
        <header className="flex items-center gap-1 justify-self-start text-subt1-semi">
          <h4>Associated Vendors</h4>
        </header>
        <article
          onMouseLeave={() => setShowDropdown(false)}
          onClick={() => setShowDropdown(true)}
          className="relative px-4 py-2 w-[15rem] h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded z-20"
        >
          <article className="flex items-center justify-between gap-2">
            <input
              type="input"
              disabled={isReadOnly}
              autoComplete="off"
              spellCheck="false"
              placeholder={`${vendorsForRisk?.length || 0} selected`}
              value={query}
              onChange={(e) => {
                if (!showDropdown) setShowDropdown(true);
                setQuery(e.target.value);
              }}
              className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
            />
            <span className="flex items-center pointer-events-none">
              <ChevronDownIcon
                className="w-5 h-5 dark:text-gray-500"
                aria-hidden="true"
              />
            </span>
          </article>
          {!isReadOnly && showDropdown && (
            <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
              {filteredItems?.map((vendor: KeyStringVal, index: number) => {
                const selected = vendorsForRisk?.some(
                  (member: KeyStringVal) =>
                    member.third_party_id === vendor.third_party_id
                );

                return (
                  <button
                    key={index}
                    className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                      selected ? "dark:bg-gray-800" : ""
                    } rounded-lg`}
                    onClick={() => {
                      if (selected)
                        remove.mutate({
                          riskID,
                          vendor: [vendor.third_party_id],
                        });
                      else
                        add.mutate({
                          riskID,
                          vendor: [vendor.third_party_id],
                        });
                    }}
                  >
                    {selected && <FontAwesomeIcon icon={faCheck} />}
                    <p>{vendor.name}</p>
                  </button>
                );
              })}
            </article>
          )}
        </article>
      </article>
      {vendorsForRisk?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-4 p-6 text-b1-reg dark:bg-gray-800 rounded-2xl">
          {vendorsForRisk?.map((vendor: KeyStringVal) => {
            return (
              <li
                key={vendor.third_party_id}
                className="flex items-center gap-3 px-4 py-1 cursor-pointer dark:text-white dark:bg-blue-500 dark:hover:bg-blue-400/70 duration-100 rounded"
                onClick={() =>
                  navigate(
                    `/third-party-risk/vendors/details?vendor_id=${vendor.third_party_id}`
                  )
                }
              >
                <p>{vendor.name}</p>
                <button
                  disabled={isReadOnly}
                  className="hover-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    remove.mutate({
                      riskID,
                      vendor: [vendor.third_party_id],
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default AssociateVendorWithRiskFilter;
