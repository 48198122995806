import React from "react";
import DonutChart from "src/components/Chart/DonutChart";
import { GetControlsByState } from "src/services/erc/controls/control-dashboard";

const ControlByState = () => {
  const { data } = GetControlsByState();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <DonutChart data={data} xKey="name" title="Control by State" />
    </section>
  );
};

export default ControlByState;
