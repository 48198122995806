import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { RemoveGlobalQuestion } from "src/services/third-party-risk/questions/global-questions";
import { checkIsReadOnly } from "src/utils/general";

const RemoveQuestions = ({
  selectedQuestions,
  setSelectedQuestions,
}: {
  selectedQuestions: string[];
  setSelectedQuestions: (selectedQuestions: string[]) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);

  const removeQuestion = RemoveGlobalQuestion();

  const deleting = removeQuestion.status === "loading";
  const questionIDs = selectedQuestions?.reduce(
    (pV, cV) => [...pV, cV.generated_id],
    []
  );

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly || deleting || selectedQuestions.length === 0}
        className="red-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
        }}
      >
        Delete All
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Delete Questions</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to delete these questions?
          </h4>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={deleting}
              className="red-button"
              onClick={() => {
                removeQuestion.mutate({
                  questionIDs: questionIDs,
                });
                setSelectedQuestions([]);
              }}
            >
              Delete
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default RemoveQuestions;
