import React from "react";
import RiskByMonth from "./RiskByMonth";
import RiskByCategory from "./RiskByCategory";
import ControlsbyType from "./ControlsbyType";
import RiskByState from "./RiskByState";
import InherentRiskDistribution from "./InherentRiskDistribution";
import OverallRiskScore from "./OverallRiskScore";

const RiskDashboard = () => {
  return (
    <section className="grid grid-cols-2 gap-4 pb-24">
      <RiskByMonth />
      <RiskByState />
      <InherentRiskDistribution />
      <RiskByCategory />
      <ControlsbyType />
      <OverallRiskScore />
    </section>
  );
};

export default RiskDashboard;
