/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useState } from "react";
import { parseURL } from "../../../utils/general";
import ModalLayout from "../../../layouts/ModalLayout";
import { integrationTypes } from "src/constants/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

const NewConfiguration = () => {
  const parsed = parseURL();

  const [show, setShow] = useState<boolean>(false);

  const handleOnClose = () => setShow(false);

  const Integration = integrationTypes[String(parsed.integration)];

  return (
    <>
      <button className="blue-button" onClick={() => setShow(true)}>
        <FontAwesomeIcon icon={faPlus} />
        <p>Add Account</p>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <h3 className="flex items-center gap-2 text-t1-semi">Add Account</h3>
        {Integration && (
          <Integration
            setShowIntegrationDetails={setShow}
            selectedAccountID=""
          />
        )}
      </ModalLayout>
    </>
  );
};

export default NewConfiguration;
