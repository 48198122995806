/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { CSS } from "@dnd-kit/utilities";
import { useSortable } from "@dnd-kit/sortable";
import {
  faGripVertical,
  faPlus,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConditionValue from "src/components/Filter/QueryBuilder/ConditionValue";
import ConditionKey from "src/components/Filter/QueryBuilder/ConditionKey";
import ConditionOperator from "src/components/Filter/QueryBuilder/ConditionOperator";
import { KeyStringVal } from "src/types/general";
const Condition = ({
  fieldValues,
  condition,
  queryList,
  setQueryList,
}: {
  fieldValues: string[];
  condition: any;
  queryList: any;
  setQueryList: any;
}) => {
  const id = condition.id;

  const { attributes, listeners, transform, transition, setNodeRef } =
    useSortable({
      id,
    });

  const handleChangeCondition = (condition: string) => {
    setQueryList(
      queryList.map((curCondition: KeyStringVal) => {
        if (curCondition.id === id)
          return {
            ...curCondition,
            condition: condition,
          };
        else return curCondition;
      })
    );
  };

  return (
    <li className="flex flex-col flex-grow gap-1">
      <article
        style={{
          transform: CSS.Transform.toString(transform),
          transition: transition,
        }}
        ref={setNodeRef}
        {...attributes}
        {...listeners}
        className="flex items-center p-2 gap-4 w-full list-none dark:bg-gray-700 rounded-lg"
      >
        <button className="pl-2 hover:cursor-grab">
          <FontAwesomeIcon icon={faGripVertical} />
        </button>
        <article className="grid grid-cols-3 gap-4 w-full">
          <ConditionKey
            list={fieldValues}
            keyName="key"
            queryList={queryList}
            setQueryList={setQueryList}
            id={id}
          />
          <ConditionOperator
            list={fieldValues}
            keyName="operator"
            queryList={queryList}
            setQueryList={setQueryList}
            id={id}
          />
          <ConditionValue
            keyName="value"
            queryList={queryList}
            setQueryList={setQueryList}
            id={id}
          />
        </article>
        <button
          className="justify-self-end red-trash-button"
          onClick={() =>
            setQueryList(
              queryList.filter(
                (curCondition: KeyStringVal) => curCondition.id !== id
              )
            )
          }
        >
          <FontAwesomeIcon icon={faTrashCan} />
        </button>
      </article>
      <article className="flex items-center place-content-end">
        {["AND", "OR"].map((curCondition) => {
          const selected = condition.condition === curCondition;
          return (
            <button
              key={curCondition}
              disabled={selected}
              className={`${
                selected ? "small-green-text-button" : "small-blue-text-button"
              }`}
              onClick={() => handleChangeCondition(curCondition)}
            >
              {!selected && <FontAwesomeIcon icon={faPlus} />}
              {curCondition}
            </button>
          );
        })}
      </article>
    </li>
  );
};

export default Condition;
