/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetSOPTagNamesAndValues } from "src/services/business-continuity/sop";

const DepartmentFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: any;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: sopTags } = GetSOPTagNamesAndValues();

  const filteredList = sopTags
    ? sopTags.filter((auth: string) =>
        auth
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(query.toLowerCase().replace(/\s+/g, ""))
      )
    : [];

  const handleSelect = (value: string) => {
    if (inputs[keyName]) setInputs({ ...inputs, [keyName]: "" });
    else setInputs({ ...inputs, [keyName]: value });
  };

  return (
    <FilterLayout label={label}>
      <article className="flex flex-col flex-grow gap-2">
        <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
        </article>
        <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
          {filteredList?.map((item: string, idx: number) => {
            const selected = inputs[keyName] === item;
            return (
              <li key={idx} className="flex items-start gap-2">
                <input
                  type="checkbox"
                  className="transparent-checkbox"
                  checked={selected}
                  onClick={() => handleSelect(item)}
                />
                <label htmlFor="">{item}</label>
              </li>
            );
          })}
        </ul>
      </article>
    </FilterLayout>
  );
};

export default DepartmentFilter;
