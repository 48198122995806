import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringVal } from "src/types/general";

const assessmentPrefix = "grc/assessments-lifecycle";

export const GetAssessmentList = (
  pageNumber: number,
  module: string,
  objectType: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-assessment-list", pageNumber, module, objectType],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/getall`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            module: module,
            object_type: objectType,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddAssessment = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/new`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-assessment-list"]);
      },
    }
  );

export const GetAssessmentMetadata = (
  assessmentID: string,
  objectID: string,
  module: string,
  objectType: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    ["get-assessment-metadata", assessmentID, objectID, module, objectType],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}?object_id=${objectID}&module=${module}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: assessmentID !== "",
      keepPreviousData: false,
    }
  );

export const GetDocumentsForAssessment = (
  assessmentID: string,
  objectID: string,
  module: string,
  objectType: string
) =>
  useQuery<any, unknown, any, (string | number | KeyStringVal | undefined)[]>(
    [
      "get-documents-for-assessment",
      assessmentID,
      objectID,
      module,
      objectType,
    ],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/documents?object_id=${objectID}&module=${module}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetResponsesForAssessment = (
  objectID: string,
  assessmentID: string | undefined,
  documentID: string,
  pageNumber: number,
  module: string,
  objectType: string,
  tags?: string[]
) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringVal | string[] | undefined)[]
  >(
    [
      "get-responses-for-assessment",
      objectID,
      assessmentID,
      documentID,
      pageNumber,
      module,
      objectType,
      tags,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/responses/all?object_id=${objectID}`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            module: module,
            object_type: objectType,
            document_id: documentID,
            tags,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: objectID !== "",
      keepPreviousData: false,
    }
  );

export const UploadDocs = (
  assessmentID: string,
  objectID: string,
  module: string,
  objectType: string
) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/upload-documents?object_id=${objectID}&module=${module}&object_type=${objectType}`,
          formData,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-documents-for-assessment"]);
      },
    }
  );

export const RemoveDocument = (
  entityID: string,
  assessmentID: string,
  documentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/documents/${documentID}?object_id=${entityID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-documents-for-assessment"]);
      },
    }
  );

export const SendAssessmentEmail = (
  objectID: string,
  assessmentID: string,
  module: string,
  objectType: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/send-email?object_id=${objectID}&module=${module}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetRiskClassifications = (
  assessmentID: string,
  entityID: string
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-risks-classifications", assessmentID, entityID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/risks/classification`,
          {
            module: "ERC",
            object_type: "ENTITY",
            object_id: entityID,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetRisksForAssessment = (
  assessmentID: string,
  entityID: string,
  classification: string,
  pageNumber?: number
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    [
      "get-risks-for-assessment",
      assessmentID,
      entityID,
      classification,
      pageNumber,
    ],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/risks/getall`,
          {
            ...(pageNumber && {
              pager: {
                page_number: pageNumber,
                page_size: pageSize,
              },
            }),
            module: "ERC",
            object_type: "ENTITY",
            object_id: entityID,
            classification,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const EditAssessment = (assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-threat-metadata"]);
      },
    }
  );

export const RestoreAssessment = (assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threat-metadata"]),
    }
  );

export const ArchiveAssessment = (assessmentID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-threat-metadata"]),
    }
  );

export const GetTagsForAssessment = (assessmentID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-tags-for-assessment", assessmentID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/tags`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );

export const GetAssessmentMetrics = (
  assessmentID: string,
  objectID: string,
  module: string,
  objectType: string
) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-assessment-metrics", assessmentID, objectID, module, objectType],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${assessmentPrefix}/${assessmentID}/documents/metrics?object_id=${objectID}&module=${module}&object_type=${objectType}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    }
  );
