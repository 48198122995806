/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const SectionTabs = ({
  selectedTab,
  filter,
  setFilter,
}: {
  selectedTab: string;
  filter: string;
  setFilter: (filter: string) => void;
}) => {
  return (
    <FilterLayout label="Quick Filter">
      <ul className="flex flex-col flex-grow gap-1">
        {[
          "All",
          "Overlaps with Audit",
          `Overlaps With Framework`,
          "No Mapping",
          "No Overlaps with Audit",
          "Flagged Controls",
        ].map((option) => {
          if (
            (selectedTab === "Audit Report" &&
              [
                "Overlaps with Audit",
                "No Overlaps with Audit",
                "Flagged Controls",
              ].includes(option)) ||
            (selectedTab === "Controls Coverage" &&
              ["Overlaps With Framework", "No Mapping"].includes(option))
          )
            return null;
          return (
            <li key={option} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={filter === option}
                onClick={() => setFilter(option)}
              />
              <label htmlFor="">{option}</label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default SectionTabs;
