import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const RefIDFilter = ({
  inputs,
  setInputs,
}: {
  inputs: any;
  setInputs: any;
}) => {
  return (
    <section className="flex flex-col flex-grow">
      <h4 className="text-b2-reg">Ref-Ids</h4>
      {inputs.ref_ids && Object.entries(inputs.ref_ids).length > 0 && (
        <ul className="flex flex-col flex-grow gap-2 pb-2">
          {Object.entries(inputs.ref_ids).map((keyVal: any, index: number) => {
            return (
              <li key={keyVal[0]} className="flex items-center gap-2">
                <input
                  spellCheck="false"
                  autoComplete="off"
                  value={keyVal[0]}
                  placeholder="Key"
                  autoFocus
                  onChange={(e) => {
                    const refIDs = Object.entries(inputs.ref_ids);
                    const newKey = e.target.value;
                    refIDs[index][0] = newKey;
                    const updatedRefIDs = Object.fromEntries(refIDs);

                    setInputs({
                      ...inputs,
                      ref_ids: updatedRefIDs,
                    });
                  }}
                  type="input"
                  className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                />
                <input
                  spellCheck="false"
                  autoComplete="off"
                  value={keyVal[1]}
                  placeholder="Value"
                  onChange={(e) =>
                    setInputs({
                      ...inputs,
                      ref_ids: {
                        ...inputs.ref_ids,
                        [keyVal[0]]: e.target.value,
                      },
                    })
                  }
                  type="input"
                  className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                />
                <button
                  className="red-trash-button"
                  onClick={() => {
                    let refIds = inputs.ref_ids;
                    delete refIds[keyVal[0]];
                    setInputs({ ...inputs, ref_ids: refIds });
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}
      <button
        className="blue-button"
        onClick={() =>
          setInputs({
            ...inputs,
            ref_ids: {
              ...(inputs.ref_ids || {}),
              [String(Object.keys(inputs.ref_ids || {}).length + 1)]: "",
            },
          })
        }
      >
        Add Ref-Id
      </button>
    </section>
  );
};

export default RefIDFilter;
