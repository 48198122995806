/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { GetAssessmentCycleList } from "../../../services/third-party-risk/assessments/assessment-cycles";
import { KeyStringVal } from "../../../types/general";

const AssessmentFilter = ({
  label,
  fileIndex,
  inputs,
  setInputs,
}: {
  label?: string;
  fileIndex: number;
  inputs: any;
  setInputs: any;
}) => {
  const { data: assessmentList } = GetAssessmentCycleList(1, "VENDOR");

  const assessmentID = inputs["assessments"][fileIndex] || "";
  const assessmentName =
    assessmentList?.data.find(
      (assessment) => assessment.assessment_id === assessmentID
    )?.title || "";

  return (
    <DropdownLayout
      label={label}
      value={assessmentName}
      placeholder="Select"
      width="w-[18rem]"
      showAbove
    >
      {assessmentList?.data.map((assessment: KeyStringVal, index: number) => {
        const selected = assessmentID === assessment.assessment_id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() =>
              setInputs({
                ...inputs,
                assessments: inputs.assessments.map(
                  (assessmentID, curFileIndex) => {
                    if (fileIndex === curFileIndex) {
                      return assessment.assessment_id;
                    }
                    return assessmentID;
                  }
                ),
                question_sets: inputs.question_sets.map(
                  (questionSetID, curFileIndex) => {
                    if (fileIndex === curFileIndex) {
                      return "";
                    }
                    return questionSetID;
                  }
                ),
              })
            }
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{assessment.title}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default AssessmentFilter;
