/* eslint-disable react-hooks/exhaustive-deps */
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React from "react";
import Columns from "./Columns";

const Sheet = ({
  fileInfo,
  setFileInfo,
  sheet,
}: {
  fileInfo: any;
  setFileInfo: any;
  sheet: any;
}) => {
  return (
    <article
      key={sheet}
      className="grid gap-1 p-4 text-b2-reg dark:bg-gray-700 rounded-md"
    >
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center gap-2">
              <h4 className="text-b1-semi">{sheet}</h4>
              <FontAwesomeIcon icon={open ? faChevronDown : faChevronRight} />
            </Disclosure.Button>
            <Disclosure.Panel>
              <Columns
                fileInfo={fileInfo}
                setFileInfo={setFileInfo}
                sheet={sheet}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </article>
  );
};

export default Sheet;
