/* eslint-disable no-shadow */
import React from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

const RADIAN = Math.PI / 180;
const data = [
  { name: "A", value: 33.33, color: "#22c55e" },
  { name: "B", value: 33.33, color: "#eab308" },
  { name: "C", value: 33.33, color: "#ef4444" },
];
const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;

const NEEDLE_COLOR = "#d0d000";

const PieWithNeedle = ({ title, value }: { title: string; value: number }) => {
  const Needle = ({ cx, cy }) => {
    const ang = 180.0 * (1 - value / 100);

    const NEEDLE_BASE_RADIUS_PX = 5;
    const NEEDLE_LENGTH_PX = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const xp = cx + NEEDLE_LENGTH_PX * cos;
    const yp = cy + NEEDLE_LENGTH_PX * sin;
    return (
      <g>
        <circle
          cx={cx}
          cy={cy}
          r={NEEDLE_BASE_RADIUS_PX}
          fill={NEEDLE_COLOR}
          stroke="none"
        />
        <path
          d={`M${cx} ${cy}L${cx} ${cy} L${xp} ${yp}`}
          strokeWidth={2}
          stroke={NEEDLE_COLOR}
          fill={NEEDLE_COLOR}
        />
      </g>
    );
  };

  const args = {
    cx: `calc(100%-${cx}px)`,
    cy,
    data,
    dataKey: "value",
    endAngle: 0,
    fill: "#8884d8",
    innerRadius: iR,
    outerRadius: oR,
    startAngle: 180,
    stroke: "none",
  };

  return (
    <section className="grid grid-cols-1 mx-auto w-full h-[20rem]">
      <h4 className="text-center text-subt1-semi">
        {title} ({value}%)
      </h4>
      <ResponsiveContainer width="100%" height="100%">
        <PieChart>
          <Pie {...args}>
            {data.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={entry.color} />
            ))}
          </Pie>
          <Pie
            {...args}
            dataKey="value"
            activeIndex={2}
            activeShape={Needle}
            fill="none"
          />
          {/* {needle(value, data, cx, cy, iR, oR, '#d0d000')} */}
        </PieChart>
      </ResponsiveContainer>
    </section>
  );
};

export default PieWithNeedle;
