import React from "react";
import DonutChart from "src/components/Chart/DonutChart";
import { GetRisksByState } from "src/services/erc/risks/risk-dashboard";

const RiskByState = () => {
  const { data } = GetRisksByState();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <DonutChart data={data} xKey="name" title="Risk by State" />
    </section>
  );
};

export default RiskByState;
