/* eslint-disable react-hooks/exhaustive-deps */
import {
  faArrowRightLong,
  faInfo,
  faWarning,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import TextFilter from "src/components/Filter/General/TextFilter";
import { GetDocumentStatus, GetGRCDocumentMetadata } from "src/services/grc";
import {
  GetPolicyGroups,
  GetPolicyVersions,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import {
  checkIsReadOnly,
  convertToUTCShortString,
  parseURL,
} from "src/utils/general";
import DeletePolicy from "../../Policies/DeletePolicy";
import DocumentTags from "./DocumentTags";
import RegionsVerticals from "./RegionsVerticals";
import UpdatePolicyVersion from "./UpdatePolicyVersion";
import { useNavigate } from "react-router-dom";
import DocumentSearch from "../DocumentDetail/DocumentSearch";
import ReturnPage from "src/components/Button/ReturnPage";
import NewException from "./NewException";
import { toastBorderColors } from "src/constants/general";

const DocumentMetadata = ({
  documentType,
  documentID,
  selectedPolicyVersion,
  setSelectedPolicyVersion,
  search,
  setSearch,
  query,
  setQuery,
}: {
  documentType: string;
  documentID: string;
  selectedPolicyVersion: string;
  setSelectedPolicyVersion: (selectedPolicyVersion: string) => void;
  search: boolean;
  setSearch: (search: boolean) => void;
  query: string;
  setQuery: (query: string) => void;
}) => {
  const parsed = parseURL();
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();

  const [isVisible, setVisible] = useState<boolean>(true);

  const { data: metadata } = GetGRCDocumentMetadata(documentType, documentID);
  const { data: policyVersions } = GetPolicyVersions(documentType, documentID);
  const { data: policyGroups } = GetPolicyGroups();

  const policyGroup = policyGroups?.find(
    (group: KeyStringVal) =>
      group.policy_group_id === sessionStorage.selectedPolicyGroupID
  )?.title;

  const versionID =
    policyVersions?.find(
      (version: KeyStringVal) => version.version === selectedPolicyVersion
    )?.version_id || "";

  const policyVersionID = String(parsed.policy_version_id) || "";
  const { data: documentStatus } = GetDocumentStatus(
    documentType,
    documentID,
    versionID
  );

  const documentName = metadata?.framework_name || metadata?.policy_name;
  const versions = policyVersions?.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.version],
    []
  );
  const isPolicy = documentType === "policies";

  useEffect(() => {
    if (isPolicy && versions?.length > 0 && selectedPolicyVersion === "") {
      if (!parsed.policy_version_id) setSelectedPolicyVersion(versions[0]);
      else {
        const filteredPolicyVersion =
          policyVersions?.find(
            (version: KeyStringVal) => version.version_id === policyVersionID
          )?.version || "";
        setSelectedPolicyVersion(filteredPolicyVersion);
      }
    }
  }, [versions]);

  useEffect(() => {
    if (selectedPolicyVersion !== "") {
      const filteredPolicyVersionID = policyVersions?.find(
        (version: KeyStringVal) => version.version === selectedPolicyVersion
      )?.version_id;
      navigate(
        `${window.location.pathname}?document_type=${parsed.document_type}&document_id=${parsed.document_id}&policy_version_id=${filteredPolicyVersionID}`
      );
    }
  }, [selectedPolicyVersion]);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Regulation & Policy</span>{" "}
          <span>/</span>
          <span className="capitalize">
            {documentType === "policies" ? (
              <span>Policy Group {policyGroup}</span>
            ) : (
              <span>{documentType.replace("s", "")}</span>
            )}
          </span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-4">
          {isVisible ? (
            documentStatus?.status === "failed" ? (
              <article className={toastBorderColors.warning}>
                <FontAwesomeIcon icon={faWarning} className="text-red-500" />
                <p>Error processing your document!</p>
                <button onClick={() => setVisible(!isVisible)}>
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </article>
            ) : (
              documentStatus?.status === "parsing" && (
                <article className={toastBorderColors.info}>
                  <FontAwesomeIcon icon={faInfo} className="text-red-500" />
                  <p>Uno is currently processing the document!</p>
                  <button onClick={() => setVisible(!isVisible)}>
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                </article>
              )
            )
          ) : null}
          <article className="flex items-center justify-between gap-20">
            <article className="grid content-start gap-1 w-full">
              <article className="flex items-start justify-between gap-6">
                <article className="grid gap-1">
                  <article className="flex items-center gap-2">
                    <h4 className="py-1 break-words text-left text-t1-semi">
                      {metadata.framework_name || metadata.policy_name}
                    </h4>
                    {metadata.source_language && (
                      <span className="px-2 py-1 text-b3-semi dark:bg-gray-900 rounded-lg">
                        Translated from {metadata.source_language}
                      </span>
                    )}
                  </article>
                  <article className="flex flex-wrap items-center gap-3 text-b2-reg dark:text-gray-400 divide-x dark:divide-gray-400">
                    {metadata.last_updated_at && (
                      <span>
                        {convertToUTCShortString(metadata.last_updated_at)}
                      </span>
                    )}
                    {metadata.regulatory_date && (
                      <span>
                        {convertToUTCShortString(metadata.regulatory_date)}
                      </span>
                    )}
                    {(metadata.regulatory_authority ||
                      metadata.customer_name) && (
                      <span className="pl-3">
                        {metadata.regulatory_authority ||
                          metadata.customer_name}
                      </span>
                    )}
                  </article>
                </article>
                <article className="flex items-center gap-6">
                  {metadata?.policy_name && (
                    <>
                      <TextFilter
                        label="Version"
                        list={versions}
                        value={selectedPolicyVersion}
                        setValue={setSelectedPolicyVersion}
                        isReadOnly={isReadOnly}
                      />
                      <UpdatePolicyVersion
                        documentName={documentName}
                        documentID={documentID}
                        setSelectedPolicyVersion={setSelectedPolicyVersion}
                      />
                      <NewException policy={metadata} />
                      {metadata.policy_name &&
                        documentStatus?.status === "ready" && (
                          <article className="flex items-center gap-4">
                            <DeletePolicy versionID={versionID} />
                          </article>
                        )}
                    </>
                  )}
                </article>
              </article>
              <DocumentSearch
                search={search}
                query={query}
                setQuery={setQuery}
                setSearch={setSearch}
              />
            </article>
          </article>
          {isPolicy && policyVersions?.length > 1 && (
            <article className="flex items-center gap-4">
              <a
                href={`/regulation-policy/document/policy-drift/details?policy_id=${documentID}&policy_name=${documentName}`}
                className="flex items-center gap-2 px-4 py-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
              >
                Policy Drift
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
              <a
                href={`/regulation-policy/document/policy-drift-risk-assessment/details?policy_id=${documentID}&policy_name=${documentName}`}
                className="flex items-center gap-2 px-4 py-1 dark:hover:bg-gray-700/70 duration-100 rounded-full"
              >
                Policy Drift Risk Assessment
                <FontAwesomeIcon icon={faArrowRightLong} />
              </a>
            </article>
          )}
          {!isPolicy && (
            <>
              <RegionsVerticals
                documentType={documentType}
                documentID={documentID}
              />
              <DocumentTags
                documentType={documentType}
                documentID={documentID}
              />
            </>
          )}
        </header>
      )}
    </>
  );
};

export default DocumentMetadata;
