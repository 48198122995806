import React from "react";
import FilterLayout from "src/layouts/FilterLayout";

const TableTabs = ({
  filter,
  setFilter,
}: {
  filter: string;
  setFilter: (filter: string) => void;
}) => {
  return (
    <FilterLayout label="Quick Filter">
      <ul className="flex flex-col flex-grow gap-1">
        {["All Tables", "Tables to Export"].map((option) => {
          return (
            <li key={option} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={filter === option}
                onClick={() => setFilter(option)}
              />
              <label htmlFor="">{option}</label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default TableTabs;
