import React, { useEffect, useState } from "react";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize, showVariants } from "src/constants/general";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import PageLayout from "src/layouts/PageLayout";
import NewAgreement from "./NewAgreement";
import AllTags from "src/components/GRC/AllTags";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Sort from "src/components/GRC/Sort";
import { KeyStringVal } from "src/types/general";
import { GetAgreementProposalReviews } from "src/services/agreement-proposal-review";
import { agreementContractReviewSortingTypes } from "src/constants/grc";
import { handleSort, sortRows } from "../../utils/general";
import TableLayout from "src/layouts/TableLayout";
import RowValue from "src/components/General/RowValue";

const AgreementProposalReviewList = () => {
  const navigate = useNavigate();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "desc",
    order_by: "agreement_date",
  });

  const { data: agreementList, status: agreementStatus } =
    GetAgreementProposalReviews(sort, pageNumber);

  const headers = agreementList && JSON.parse(agreementList.header.metadata);
  const filteredHeaders = headers?.required.filter((col: string) => {
    if (headers.properties[col]?.hidden === "True") return null;
    return col;
  });
  const sortedRows = agreementList && sortRows(agreementList?.data, sort);

  const totalCount = agreementList?.pager.total_results || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  useEffect(() => {
    sessionStorage.page = "Agreement & Proposal Review";
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-4 p-4 w-full h-full text-base overflow-auto scrollbar"
      >
        <header className="flex items-start justify-between gap-10">
          <h4 className="text-t1-semi">Agreements</h4>
          <NewAgreement />
        </header>
        <AllTags
          documentType="contractual_agreements"
          selectedTags={selectedTags}
          setSelectedTags={setSelectedTags}
        />
        {agreementStatus === "loading" ? (
          <Loader />
        ) : agreementList?.data.length > 0 ? (
          <section className="flex flex-col flex-grow gap-4 pb-24">
            <Sort
              sortingTypes={agreementContractReviewSortingTypes}
              sort={sort}
              setSort={setSort}
            />
            <TableLayout fullHeight>
              <thead className="sticky -top-1.5 dark:bg-gray-900">
                <tr>
                  {filteredHeaders?.map((col: string) => {
                    const column = headers.properties[col];
                    if (!column) return null;

                    return (
                      <th
                        scope="col"
                        key={col}
                        className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                      >
                        <article className="capitalize flex gap-10 justify-between">
                          <h4 className="break-words">{column.title}</h4>
                          <button aria-label="sort">
                            <FontAwesomeIcon
                              icon={faSort}
                              className="mt-0.5 dark:text-gray-700"
                              onClick={() => handleSort(col, setSort)}
                            />
                          </button>
                        </article>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className="divide-y dark:divide-gray-700">
                {sortedRows?.map((row: any, index: number) => {
                  return (
                    <tr
                      key={index}
                      data-test="table-row"
                      className="relative p-5 gap-3 cursor-pointer break-words dark:bg-gray-800 dark:hover:bg-gray-800/70 duration-100"
                      onClick={() => {
                        if (row.status === "READY")
                          navigate(
                            `/agreement-proposal-review/agreement/details?agreement_id=${row.id}`
                          );
                      }}
                    >
                      {filteredHeaders?.map((col: string, colIndex: number) => {
                        return (
                          <td
                            key={`${index}-${colIndex}`}
                            className="relative px-6 py-3 last:pr-16 text-left"
                          >
                            <RowValue row={row} col={col} />
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </TableLayout>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        ) : (
          <section className="flex items-center place-content-center gap-10 w-full h-full">
            <article className="grid gap-3">
              <h4 className="text-t1-bold">Agreement & Proposal Review</h4>
              <h4>No agreement & proposal review available</h4>
            </article>
          </section>
        )}
      </motion.main>
    </PageLayout>
  );
};

export default AgreementProposalReviewList;
