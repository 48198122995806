/* eslint-disable no-restricted-globals */
import { faXmark, faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment } from "react";
import { GetGlobalQuestionTags } from "../../../../../services/third-party-risk/questions/global-questions";
import { checkIsReadOnly } from "src/utils/general";
import { EditQuestionSetQuestion } from "src/services/third-party-risk/questions/custom-question-sets";
import { KeyStringVal } from "src/types/general";

const QuestionTags = ({
  questionSetID,
  questionID,
  qa,
}: {
  questionSetID: string;
  questionID: string;
  qa: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const { data: allTags } = GetGlobalQuestionTags();
  const editResponse = EditQuestionSetQuestion(questionSetID);

  const filteredTags = allTags?.filter((tag: KeyStringVal) =>
    qa.tags?.every((curTag: string) => curTag !== tag.tag_name)
  );

  return (
    <section className="flex flex-wrap items-center gap-4 text-b1-reg">
      {qa.tags?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {qa.tags.map((tag: string) => {
            return (
              <li
                key={tag}
                className="flex items-center gap-3 px-4 py-1 dark:bg-gray-700 rounded"
              >
                <p>{tag}</p>

                {/* remove tag */}
                <button
                  disabled={isReadOnly}
                  className="hover-icon"
                  onClick={() =>
                    editResponse.mutate({
                      inputs: {
                        request: {
                          [questionID]: {
                            ...qa,
                            tags: qa.tags.filter(
                              (curTag: string) => curTag !== tag
                            ),
                          },
                        },
                      },
                    })
                  }
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button disabled={isReadOnly} className="dark-blue-button">
          <FontAwesomeIcon icon={faPlus} />
          <h4>Assign Tag</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max -mr-5 mt-2 gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
            {filteredTags?.length > 0 ? (
              <nav className="grid content-start w-full max-h-60 overflow-auto scrollbar">
                {filteredTags?.map((tag: KeyStringVal) => {
                  return (
                    <button
                      key={tag.tag_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={() =>
                        editResponse.mutate({
                          inputs: {
                            request: {
                              [questionID]: {
                                ...qa,
                                tags: [...qa.tags, tag.tag_name],
                              },
                            },
                          },
                        })
                      }
                    >
                      <p>{tag.tag_name}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
                <h4>No new tags</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default QuestionTags;
