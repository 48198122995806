/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faTrashCan,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Switch } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import Loader from "src/components/Loader/Loader";
import { GetFrameworkToPolicyGroupMappings } from "src/services/regulation-policy/framework";
import {
  GetPoliciesFromGroup,
  GetPolicyGroups,
} from "src/services/regulation-policy/policy";
import { KeyStringVal } from "src/types/general";
import { GetGRCDocumentMetadata } from "src/services/grc";
import ViewInFile from "../../ViewInFile/ViewInFile";
import Subsection from "../Sections/Subsection/Subsection";
import MultiControlFilters from "../../../../../components/Filter/RegulationPolicy/MultiControlFilters";
import PolicyGroupFilter from "src/components/Filter/RegulationPolicy/PolicyGroupFilter";
import PoliciesFilter from "src/components/Filter/RegulationPolicy/PoliciesFilter";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

const PoliciesSelection = ({
  index,
  documentID,
  selectedPolicyGroups,
  setSelectedPolicyGroups,
  policyGroupSpace,
  setPolicyGroupSpace,
}: {
  index: number;
  documentID: string;
  selectedPolicyGroups: KeyStringVal[];
  setSelectedPolicyGroups: (selectedPolicyGroups: KeyStringVal[]) => void;
  policyGroupSpace: number;
  setPolicyGroupSpace: (policyGroupSpace: number) => void;
}) => {
  const documentType = "frameworks";

  const [selectedPolicyIDs, setSelectedPolicyIDs] = useState<string[]>([]);
  const [isGaps, setIsGaps] = useState<boolean>(false);
  const [controlFilters, setControlFilters] = useState<any>({
    context: [],
    level: [],
    domain: [],
    sub_domain: [],
  });

  const { data: info } = GetGRCDocumentMetadata("frameworks", documentID);
  const { data: policyGroups } = GetPolicyGroups();
  const { data: policies } = GetPoliciesFromGroup(
    selectedPolicyGroups[index]?.policy_group_id || ""
  );
  const { data: policyMappings, status: mappingStatus } =
    GetFrameworkToPolicyGroupMappings(
      documentID,
      selectedPolicyIDs,
      controlFilters.context,
      controlFilters.level,
      controlFilters.domain,
      controlFilters.sub_domain
    );

  const coverage = isGaps
    ? Math.round((100 - Number(policyMappings?.coverage)) * 100) / 100
    : Number(policyMappings?.coverage);
  const documentName = info?.framework_name || info?.policy_name;

  useEffect(() => {
    if (policies?.data.length > 0 && selectedPolicyIDs.length === 0)
      setSelectedPolicyIDs(
        policies?.data?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
          []
        )
      );
  }, [policies]);

  useEffect(() => {
    if (
      policyGroups?.length > 0 &&
      index === 0 &&
      !selectedPolicyGroups[index]?.policy_group_id
    ) {
      const newPolicyGroups = selectedPolicyGroups;
      const defaultGroup = policyGroups?.find(
        (policyGroup: KeyStringVal) =>
          policyGroup.title.toLowerCase() === "default"
      );
      newPolicyGroups.splice(index, 1, defaultGroup);
      setSelectedPolicyGroups([...newPolicyGroups]);
    }
  }, [policyGroups]);

  return (
    <section className="grid content-start gap-4 p-6 mb-4 w-full dark:bg-gray-800 rounded-xl">
      <header className="flex items-start gap-4 w-full">
        <article className="grid grid-cols-2 gap-4 p-6 w-full dark:bg-gray-900 rounded-lg">
          <PolicyGroupFilter
            selectedPolicyGroups={selectedPolicyGroups}
            setSelectedPolicyGroups={setSelectedPolicyGroups}
            setSelectedPolicyIDs={setSelectedPolicyIDs}
            policyGroups={policyGroups}
            index={index}
          />
          {selectedPolicyGroups[index]?.title && (
            <PoliciesFilter
              selectedPolicyIDs={selectedPolicyIDs}
              setSelectedPolicyIDs={setSelectedPolicyIDs}
              policies={policies}
            />
          )}
          <MultiControlFilters
            documentType={documentType}
            documentID={documentID}
            inputs={controlFilters}
            setInputs={setControlFilters}
          />
        </article>
        {index !== 0 && (
          <button
            className="red-trash-button"
            onClick={() => {
              setPolicyGroupSpace(policyGroupSpace - 1);
              if (selectedPolicyGroups[index]) {
                const newPolicyGroups = selectedPolicyGroups;
                newPolicyGroups.splice(index, 1);
                setSelectedPolicyGroups(newPolicyGroups);
              }
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </button>
        )}
      </header>

      {mappingStatus === "loading" ? (
        <Loader />
      ) : mappingStatus === "success" ? (
        <section className="flex flex-col flex-grow gap-4 w-full">
          <article className="flex flex-col flex-grow gap-4 p-8 text-center dark:bg-gray-800 rounded-lg">
            <h2 className="text-b1-semi">{isGaps ? "Gaps" : "Coverage"}</h2>
            <article className="mx-auto w-40 h-40">
              <CircularProgressbarWithChildren
                strokeWidth={10}
                value={coverage}
                maxValue={100}
                styles={buildStyles({
                  trailColor: "#111827",
                  pathColor: "#fcba03",
                })}
              >
                <span className="text-subt1-semi">{coverage}%</span>
              </CircularProgressbarWithChildren>
            </article>
            <article className="flex items-center gap-2 mx-auto">
              <Switch
                checked={isGaps}
                onChange={setIsGaps}
                className={`${
                  isGaps ? "bg-[#30CD9A]" : "bg-gray-500"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span
                  className={`${
                    isGaps ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <span className="text-b2-semi">Gaps</span>
            </article>
            <article className="flex items-center gap-4 mx-auto text-b2-semi">
              Controls Mapped: {policyMappings.num_mapped}/
              {policyMappings.total}
            </article>
          </article>
          {policyMappings ? (
            coverage ? (
              policyMappings.mappings.length > 0 ? (
                <ul className="grid gap-4">
                  {policyMappings.mappings.map(
                    (frameworkSection: any, frameworkSectionIndex: number) => {
                      return (
                        <li
                          key={frameworkSectionIndex}
                          className="grid content-start gap-3 p-4 dark:bg-gray-900 rounded-xl"
                        >
                          <header className="flex items-center gap-4">
                            <span className="px-2 py-1 w-max text-b2-reg dark:bg-gray-800 rounded">
                              FRAMEWORK
                            </span>
                            <h4 className="text-subt1-semi">
                              {!["-", "", null].includes(
                                frameworkSection.framework_sub_section_id
                              ) &&
                                frameworkSection.framework_sub_section_id}{" "}
                              {!["-", "", null].includes(
                                frameworkSection.framework_sub_section_title
                              ) && frameworkSection.framework_sub_section_title}
                            </h4>
                            {frameworkSection.framework_metadata?.length >
                              0 && (
                              <ViewInFile
                                generatedID={String(frameworkSectionIndex)}
                                section={frameworkSection}
                                bbox={frameworkSection.framework_metadata}
                              />
                            )}
                          </header>
                          {frameworkSection.metadata_ && (
                            <ul className="flex items-center gap-2">
                              {Object.entries(frameworkSection.metadata_).map(
                                (keyVal, index) => {
                                  if (
                                    !keyVal[1] ||
                                    (Array.isArray(keyVal[1]) &&
                                      keyVal[1]?.length === 0)
                                  )
                                    return null;
                                  return (
                                    <li
                                      key={keyVal[0]}
                                      className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded"
                                    >
                                      <h4 className="capitalize">
                                        {keyVal[0].replaceAll("_", " ")}
                                      </h4>
                                      {Array.isArray(keyVal[1]) ? (
                                        keyVal[1].map((value) => {
                                          return (
                                            <span
                                              key={value}
                                              className="text-b1-reg dark:text-blue-500"
                                            >
                                              {value}
                                            </span>
                                          );
                                        })
                                      ) : (
                                        <span className="flex items-center gap-1 text-b1-reg dark:text-blue-500">
                                          {keyVal[1]}
                                        </span>
                                      )}
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          )}
                          <p className="text-b1-reg">
                            {frameworkSection.framework_content}
                          </p>
                          <article className="flex flex-col flex-grow gap-4">
                            {frameworkSection.mapped_policies?.map(
                              (policy: any, policyIndex: number) => {
                                return (
                                  <article
                                    key={policyIndex}
                                    className="grid gap-3 p-4 dark:bg-gray-800 rounded-lg"
                                  >
                                    <h4 className="text-b1-semi">
                                      {policy.policy_name}
                                    </h4>
                                    <ul className="flex flex-col flex-grow gap-3">
                                      {policy.policy_sections?.map(
                                        (
                                          policySection: any,
                                          policySectionIndex: number
                                        ) => {
                                          return (
                                            <li
                                              key={policySectionIndex}
                                              className="grid gap-2 px-6 py-4 dark:bg-gray-700 rounded-lg"
                                            >
                                              <article className="flex items-center justify-between gap-4 py-1 border-b dark:border-gray-500">
                                                <h4 className="text-b1-semi">
                                                  {
                                                    policySection.policy_sub_section_id
                                                  }{" "}
                                                  {policySection.policy_sub_section_title !==
                                                    "-" &&
                                                    policySection.policy_sub_section_title}
                                                </h4>
                                                {policySection
                                                  .policy_page_metadata
                                                  ?.length > 0 && (
                                                  <ViewInFile
                                                    generatedID={String(
                                                      policySectionIndex
                                                    )}
                                                    section={policySection}
                                                    bbox={
                                                      policySection.policy_page_metadata
                                                    }
                                                    documentType="policies"
                                                  />
                                                )}
                                              </article>
                                              {policySection.policy_content && (
                                                <p className="text-b1-reg">
                                                  {policySection.policy_content}
                                                </p>
                                              )}
                                              {policySection.compliant ===
                                              "Yes" ? (
                                                <article className="flex items-center gap-1 text-b1-reg">
                                                  <FontAwesomeIcon
                                                    icon={faCheck}
                                                    className="text-green-500"
                                                  />
                                                  Fully compliant
                                                </article>
                                              ) : policySection.compliant ===
                                                "Maybe" ? (
                                                <article className="flex items-center gap-1 text-b1-reg">
                                                  <FontAwesomeIcon
                                                    icon={faWarning}
                                                    className="text-orange-500"
                                                  />
                                                  Partially compliant
                                                </article>
                                              ) : null}
                                              {policySection.reason && (
                                                <Disclosure>
                                                  {({ open }) => (
                                                    <>
                                                      <Disclosure.Button className="flex items-center gap-2 w-max">
                                                        <h4>Uno's Analysis</h4>
                                                        <FontAwesomeIcon
                                                          icon={
                                                            open
                                                              ? faChevronDown
                                                              : faChevronRight
                                                          }
                                                        />
                                                      </Disclosure.Button>
                                                      <Disclosure.Panel>
                                                        <article className="grid gap-2">
                                                          <ReactMarkdown
                                                            className="px-6 py-4 markdown"
                                                            remarkPlugins={[
                                                              remarkGfm,
                                                            ]}
                                                          >
                                                            {
                                                              policySection.reason
                                                            }
                                                          </ReactMarkdown>
                                                        </article>
                                                      </Disclosure.Panel>
                                                    </>
                                                  )}
                                                </Disclosure>
                                              )}
                                            </li>
                                          );
                                        }
                                      )}
                                    </ul>
                                  </article>
                                );
                              }
                            )}
                          </article>
                        </li>
                      );
                    }
                  )}
                </ul>
              ) : (
                <p className="mx-auto">No policy sections available</p>
              )
            ) : policyMappings.no_mappings.length > 0 ? (
              <ul className="grid gap-4">
                {policyMappings.no_mappings.map(
                  (section: any, sectionIndex: number) => {
                    return (
                      <li
                        key={sectionIndex}
                        className="grid content-start gap-3 p-4 dark:bg-gray-700 rounded-2xl"
                      >
                        <h4 className="flex items-start gap-2 text-xl border-b dark:border-gray-500">
                          {!["-", "", null].includes(section.section_id) &&
                            section.section_id}{" "}
                          {!["-", "", null].includes(section.section_title) &&
                            section.section_title}
                        </h4>
                        <article className="grid gap-4">
                          {section.sub_sections.map(
                            (subsection: any, subSectionIndex: number) => {
                              return (
                                <Subsection
                                  key={subSectionIndex}
                                  documentID={documentID}
                                  documentName={documentName}
                                  documentType="frameworks"
                                  docID={documentID}
                                  subsection={subsection}
                                  sectionIndex={sectionIndex}
                                  subSectionIndex={subSectionIndex}
                                  hideLink
                                />
                              );
                            }
                          )}
                        </article>
                      </li>
                    );
                  }
                )}
              </ul>
            ) : (
              <p className="mx-auto">No policy sections available</p>
            )
          ) : null}
        </section>
      ) : null}
    </section>
  );
};

export default PoliciesSelection;
