import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { AskQuestion } from "../../../../services/agreement-proposal-review";
import { checkIsReadOnly } from "src/utils/general";

const NewQuestion = ({ documentID }: { documentID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [question, setQuestion] = useState<string>("");

  const askQuestion = AskQuestion(documentID);

  const handleAsk = () => {
    askQuestion.mutate({
      question,
    });
    setQuestion("");
  };

  return (
    <section className="flex items-center gap-4">
      <input
        disabled={isReadOnly}
        spellCheck="false"
        autoComplete="off"
        value={question}
        placeholder="Ask new question"
        onChange={(e) => setQuestion(e.target.value)}
        onKeyUp={(e) => {
          if (e.key === "Enter") handleAsk();
        }}
        type="input"
        className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:question-gray-500 dark:disabled:question-checkbox dark:bg-gray-900 border dark:border-gray-700 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
      />
      <button disabled={isReadOnly} className="blue-button" onClick={handleAsk}>
        <FontAwesomeIcon icon={faPlus} />
      </button>
    </section>
  );
};

export default NewQuestion;
