import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ToastLayout from "../../../../layouts/ToastLayout";
import MultiUserInput from "src/components/Input/MultiUserInput";
import AssessmentQuestionnaireFilter from "src/components/Filter/ERC/AssessmentQuestionnaireFilter";
import CategoryInput from "src/components/Input/CategoryInput";
import { checkIsReadOnly } from "src/utils/general";
import { BulkRiskAction } from "src/services/erc/risks/risks";

const BulkAction = ({ selectedRisks }: { selectedRisks: string[] }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const bulkAction = BulkRiskAction();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => bulkAction.reset();

  return (
    <>
      <button
        disabled={isReadOnly || selectedRisks.length === 0}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            risk_ids: selectedRisks,
            status_to: "",
            state_to: "",
            owners: [],
            reviewers: [],
            approvers: [],
            assessment: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Bulk Action</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Bulk Action (applied to all the selected items)
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <CategoryInput
              label="Status"
              keyName="status_to"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <CategoryInput
              label="State"
              keyName="state_to"
              list={["Initiate Evaluation", "Close / Archive"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Owners"
              keyName="owners"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Reviewers"
              keyName="reviewers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiUserInput
              label="Approvers"
              keyName="approvers"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <AssessmentQuestionnaireFilter
              label="Assessment"
              keyName="assessment"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>

          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                bulkAction.mutate({
                  inputs: {
                    ...inputs,
                    state_to:
                      inputs.state_to === "Initiate Evaluation"
                        ? "INITIATE_EVALUATION"
                        : "CLOSE",
                  },
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={bulkAction.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Action has been applied to selected risks"
      />
    </>
  );
};

export default BulkAction;
