import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import ToastLayout from "../../../../layouts/ToastLayout";
import { AddControlIssue } from "src/services/erc/controls/control-issues";
import UserInput from "src/components/Input/UserInput";
import ControlFilter from "src/components/Filter/ERC/ControlFilter";
import { checkIsReadOnly } from "src/utils/general";

const NewControlIssue = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const addControlIssue = AddControlIssue();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addControlIssue.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
            notes: "",
            control_id: "",
            owner: null,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Control Issue</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Control Issue
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <ControlFilter
              label="Control"
              keyName="control_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addControlIssue.mutate({
                  inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addControlIssue.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New control issue has been created"
      />
    </>
  );
};

export default NewControlIssue;
