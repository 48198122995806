import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DropdownLayout from "src/layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const PoliciesFilter = ({
  selectedPolicyIDs,
  setSelectedPolicyIDs,
  policies,
}: {
  selectedPolicyIDs: string[];
  setSelectedPolicyIDs: (selectedPolicyIDs: string[]) => void;
  policies: any;
}) => {
  const policyIDs = policies?.data?.reduce(
    (pV: string[], cV: KeyStringVal) => [...pV, cV.policy_id],
    []
  );

  const handleSelect = (value: string) => {
    if (value === "All" && policies?.data?.length > 0) {
      if (selectedPolicyIDs.length < policies?.data?.length)
        setSelectedPolicyIDs(policyIDs);
      else setSelectedPolicyIDs([]);
    }
  };

  return (
    <DropdownLayout
      label="Policies"
      value={
        selectedPolicyIDs?.length > 0
          ? `${selectedPolicyIDs.length} selected`
          : ""
      }
      placeholder="Select"
    >
      <article className="flex items-start gap-2 px-4 pb-2">
        <input
          type="checkbox"
          className="transparent-checkbox"
          checked={selectedPolicyIDs.length === policies?.data?.length}
          onClick={() => handleSelect("All")}
        />
        <label htmlFor="">All</label>
      </article>
      <article className="flex flex-col flex-grow gap-1">
        {policies?.data?.map((policy: any, index: number) => {
          const selected = selectedPolicyIDs.includes(policy.policy_id);
          return (
            <button
              key={index}
              className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                selected ? "dark:bg-gray-800" : ""
              } rounded-lg`}
              onClick={() => {
                if (selected)
                  setSelectedPolicyIDs(
                    selectedPolicyIDs.filter(
                      (policyID) => policyID !== policy.policy_id
                    )
                  );
                else
                  setSelectedPolicyIDs([
                    ...selectedPolicyIDs,
                    policy.policy_id,
                  ]);
              }}
            >
              {selected && <FontAwesomeIcon icon={faCheck} />}
              <p>{policy.policy_name}</p>
            </button>
          );
        })}
      </article>
    </DropdownLayout>
  );
};

export default PoliciesFilter;
