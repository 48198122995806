/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import { Switch } from "@headlessui/react";
import ControlFilters from "src/components/Filter/ThirdPartyRisk/ControlFilters";
import {
  GetControlReportCoverage,
  GetControlReportStats,
  GetControls,
} from "src/services/third-party-risk/assessments/vendor-assessment";
import { pageSize } from "src/constants/general";
import ExportFile from "./ExportFile";
import {
  faWarning,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CoverageFilter from "./CoverageFilter";
import TablePagination from "src/components/General/TablePagination";
import Subsection from "./Sections/Subsection/Subsection";
import { GetGRCDocumentMetadata } from "src/services/grc";

const CoverageReport = ({
  documentType,
  documentID,
  reviewID,
  auditID,
  selectedTab,
  editSections,
  setEditSections,
}: {
  documentType: string;
  documentID: string;
  reviewID: string;
  auditID: string;
  selectedTab: string;
  editSections: any;
  setEditSections: any;
}) => {
  const [isGaps, setIsGaps] = useState<boolean>(false);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [filter, setFilter] = useState<string>("Overlaps with Audit");
  const [controlFilters, setControlFilters] = useState({
    context: [],
    domain: [],
    sub_domain: [],
    level: [],
  });

  const type = isGaps ? "gap" : "coverage";

  const { data: coverageGaps } = GetControlReportCoverage(
    reviewID,
    documentID,
    controlFilters,
    filter,
    type
  );
  const { data: controlStats } = GetControlReportStats(
    reviewID,
    documentID,
    controlFilters,
    filter,
    type
  );
  const { data: controls } = GetControls(
    reviewID,
    documentID,
    controlFilters,
    filter,
    pageNumber,
    type
  );
  const { data: metadata } = GetGRCDocumentMetadata(documentType, documentID);

  const filteredStats = isGaps ? coverageGaps?.gaps : coverageGaps?.coverage;
  const documentName = metadata?.framework_name;

  const sortedTable = controls?.data;
  const totalCount = controls?.pager?.total_results || 0;
  const totalPages = controls?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4 w-full h-full overflow-auto scrollbar">
      <ControlFilters
        reviewID={reviewID}
        controls={controls}
        inputs={controlFilters}
        setInputs={setControlFilters}
      />
      <CoverageFilter filter={filter} setFilter={setFilter} />
      <section className="flex flex-row flex-grow gap-4">
        <article className="flex flex-col flex-grow gap-4 p-8 text-center dark:bg-gray-800 rounded-lg">
          <h2 className="text-subt1-semi">{isGaps ? "Gaps" : "Coverage"}</h2>
          <article className="mx-auto w-56 h-56">
            <CircularProgressbarWithChildren
              strokeWidth={10}
              value={filteredStats}
              maxValue={100}
              styles={buildStyles({
                trailColor: "#111827",
                pathColor: "#fcba03",
              })}
            >
              <span className="text-h5">{filteredStats}%</span>
            </CircularProgressbarWithChildren>
          </article>
          <article className="flex items-center gap-2 mx-auto">
            <Switch
              checked={isGaps}
              onChange={setIsGaps}
              className={`${
                isGaps ? "bg-[#30CD9A]" : "bg-gray-500"
              } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
              <span
                className={`${
                  isGaps ? "translate-x-6" : "translate-x-1"
                } inline-block h-4 w-4 transform rounded-full bg-white transition`}
              />
            </Switch>
            <span className="text-b1-semi">Gaps</span>
          </article>
        </article>
        {controlStats && Object.keys(controlStats).length > 0 && (
          <section className="flex flex-col flex-grow gap-10 p-8 dark:bg-gray-800 rounded-lg">
            {Object.keys(controlStats).map((key) => {
              if (Object.keys(controlStats[key]).length === 0) return null;
              return (
                <article key={key} className="flex flex-col flex-grow gap-2">
                  <h4 className="text-center text-subt1-semi capitalize">
                    {key}
                  </h4>
                  <ul className="grid grid-cols-2 content-start gap-x-5 gap-y-2 w-full text-b1-reg">
                    {Object.entries(controlStats[key]).map((keyVal: any) => {
                      return (
                        <li
                          key={keyVal[0]}
                          className="flex flex-col flex-grow gap-1"
                        >
                          <h4 className="text-b1-semi">{keyVal[0]}</h4>
                          <article className="grid grid-cols-3 w-full rounded">
                            <span className="px-2 py-1 text-center bg-green-700">
                              {keyVal[1].covered}
                            </span>
                            <span className="px-2 py-1 text-center bg-yellow-600">
                              {keyVal[1].covered_with_warning}
                            </span>
                            <span className="px-2 py-1 text-center bg-red-700">
                              {keyVal[1].not_covered}
                            </span>
                          </article>
                        </li>
                      );
                    })}
                  </ul>
                </article>
              );
            })}
          </section>
        )}
      </section>

      <section className="grid gap-2">
        <ExportFile reviewID={reviewID} documentID={documentID} />
        {sortedTable ? (
          sortedTable.length > 0 ? (
            <ul className="grid gap-4">
              {sortedTable.map((section: any, sectionIndex: number) => {
                return (
                  <li
                    key={sectionIndex}
                    className="grid content-start gap-3 p-4 bg-gray-800 rounded-xl"
                  >
                    <h4 className="flex items-start gap-2 text-b1-semi">
                      {section.section_id}{" "}
                      {!["-", "", null].includes(section.section_title) &&
                        section.section_title}
                    </h4>
                    <article className="flex items-center gap-2">
                      {section.flag_sub_control === 1 && (
                        <span className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
                          <FontAwesomeIcon
                            icon={faWarning}
                            className="text-red-500"
                          />{" "}
                          No Citations
                        </span>
                      )}
                      {section.mapped_sub_controls /
                        section.total_sub_controls <
                        0.5 && (
                        <span className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-900 border dark:border-gray-700 rounded-lg">
                          <FontAwesomeIcon
                            icon={faExclamationTriangle}
                            className="text-orange-500"
                          />{" "}
                          Attention Needed
                        </span>
                      )}
                    </article>
                    <article className="grid gap-4">
                      {section.sub_sections?.map(
                        (subsection: any, subSectionIndex: number) => {
                          return (
                            <Subsection
                              key={subSectionIndex}
                              documentType="third-party"
                              documentID={documentID}
                              documentName={documentName}
                              reviewID={reviewID}
                              auditID={auditID}
                              subsection={subsection}
                              selectedTab={selectedTab}
                              editSections={editSections}
                              setEditSections={setEditSections}
                            />
                          );
                        }
                      )}
                    </article>
                  </li>
                );
              })}
            </ul>
          ) : (
            <p>No results found</p>
          )
        ) : null}
        <TablePagination
          totalPages={totalPages}
          beginning={beginning}
          end={end}
          totalCount={totalCount}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </section>
    </section>
  );
};

export default CoverageReport;
