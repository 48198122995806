import React from "react";
import { convertToUTCString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import MetadataField from "src/components/GRC/MetadataField";

const EntityMetadata = ({ entity }: { entity: any }) => {
  return (
    <>
      {entity && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <h4 className="text-t1-semi">Entity</h4>
            <header className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{entity.name}</h2>
              <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                <h4>Last Updated by {entity.last_updated_by}</h4>
                <span>| {convertToUTCString(entity.last_updated_at)}</span>
              </article>
              <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                <h4>Created by {entity.created_by}</h4>
                <span>| {convertToUTCString(entity.created_at)}</span>
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[entity.status?.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[entity.status?.toLowerCase()]
                  }`}
                >
                  {entity.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={entity}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField
                metadata={entity}
                label="Owner"
                keyName="owner"
                singleUser
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default EntityMetadata;
