import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ControlMetadata from "./ControlMetadata";
import AttestationLifecycleFlow from "./LifecycleFlow";
import TestPlan from "./TestPlan";
import ControlIssuesList from "./ControlIssueList";
import SimilarControls from "./SimilarControls";
import EvidenceList from "./Evidences/EvidenceList";
import AttachmentList from "./Attachments/AttachmentList";

const Control = () => {
  const parsed = parseURL();

  const controlID = String(parsed.control_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "controls";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <ControlMetadata controlID={controlID} />
        <AttachmentList controlID={controlID} />
        <ControlIssuesList controlID={controlID} />
        <AttestationLifecycleFlow controlID={controlID} />
        <EvidenceList controlID={controlID} />
        <TestPlan controlID={controlID} />
        <SimilarControls controlID={controlID} />
      </main>
    </PageLayout>
  );
};

export default Control;
