import React from "react";
import ControlByState from "./ControlByState";
import ControlByClassification from "./ControlByClassification";
import ControlByRisks from "./ControlByRisks";
import FrameworkControlCoverage from "./FrameworkControlCoverage";
import OverallStats from "./OverallStats";
import ControlIssuesByMonth from "./ControlIssuesByMonth";

const ControlDashboard = () => {
  return (
    <section className="grid grid-cols-2 gap-4 pb-24">
      <OverallStats />
      <ControlByState />
      <ControlIssuesByMonth />
      <ControlByRisks />
      <FrameworkControlCoverage />
      <ControlByClassification />
    </section>
  );
};

export default ControlDashboard;
