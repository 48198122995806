/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useState } from "react";
import Upload from "./Upload/Upload";
import Settings from "./Settings/Settings";

export const roleHeaders = [
  { display_name: "Customer Name", property_name: "customer_id" },
  { display_name: "Role Name", property_name: "role_name" },
  { display_name: "Role Type", property_name: "role_type" },
];

const PrivacyReview = () => {
  const [selectedTab, setSelectedTab] = useState<string>("Settings");

  return (
    <section className="grid content-start gap-6 pb-24 w-full h-full">
      <h4 className="text-subt1-semi">Privacy Review</h4>
      <nav className="flex flex-wrap items-center gap-6 text-b1-reg">
        {["Settings", "Upload"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center ${
                selectedTab === tab
                  ? "text-b1-semi border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          );
        })}
      </nav>
      {selectedTab === "Settings" ? <Settings /> : <Upload />}
    </section>
  );
};

export default PrivacyReview;
