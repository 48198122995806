import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import ToastLayout from "src/layouts/ToastLayout";
import { checkIsReadOnly } from "src/utils/general";
import { AddException } from "src/services/erc/exceptions/exceptions";
import CategoryInput from "src/components/Input/CategoryInput";
import RegularInput from "src/components/Input/RegularInput";
import { Disclosure } from "@headlessui/react";
import MultiUserInput from "src/components/Input/MultiUserInput";
import GeneralTemporalDatepicker from "src/components/Datepicker/GeneralTemporalDatepicker";
import { KeyStringVal } from "src/types/general";

const NewException = ({ controlIssue }: { controlIssue: KeyStringVal }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const add = AddException();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => add.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            serial_number: "",
            name: "",
            reason: "",
            justification: "",
            state: "",
            priority: "",
            grc_object_type: "CONTROL ISSUE",
            grc_object_id: controlIssue.control_issue_id,
            grc_object_name: controlIssue.name,
            valid_from: Date.now() * 1000,
            valid_to: Date.now() * 1000,
            risk_impact_level: "",
            risk_likelihood_level: "",
            risk_description: "",
            risk_impact_analysis: "",
            risk_mitigation_plan: "",
            notes: "",
            owners: [],
            reviewers: [],
            approvers: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Exception</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Exception
          </h3>
          <section className="grid grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Serial Number"
              keyName="serial_number"
              inputs={inputs}
              setInputs={setInputs}
            />
          </section>
          <GeneralTemporalDatepicker
            label="Valid"
            startKeyName="valid_from"
            endKeyName="valid_to"
            inputs={inputs}
            setInputs={setInputs}
          />
          <section className="grid grid-cols-2 gap-4">
            <RegularInput
              label="State"
              keyName="state"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Priority"
              keyName="priority"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Notes"
              keyName="notes"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
            <RegularInput
              label="Justification"
              keyName="justification"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
            <CategoryInput
              label="Reason"
              keyName="reason"
              list={["Fix Unavailable", "Awaiting Maintenance Window"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">Risk Info</h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CategoryInput
                      label="Risk Impact Level"
                      keyName="risk_impact_level"
                      list={[
                        "NEGLIGIBLE",
                        "MINOR",
                        "MODERATE",
                        "SIGNIFICANT",
                        "SEVERE",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <CategoryInput
                      label="Risk Likelihood Level"
                      keyName="risk_likelihood_level"
                      list={[
                        "VERY_UNLIKELY",
                        "UNLIKELY",
                        "POSSIBLE",
                        "LIKELY",
                        "VERY_LIKELY",
                      ]}
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <RegularInput
                      label="Description"
                      keyName="risk_description"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <RegularInput
                      label="Impact Analysis"
                      keyName="risk_impact_analysis"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <RegularInput
                      label="Mitigation Plan"
                      keyName="risk_mitigation_plan"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-b1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <MultiUserInput
                      label="Owners"
                      keyName="owners"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.name === "" ||
                inputs.grc_object_type === "" ||
                inputs.grc_object_id === ""
              }
              className="blue-button"
              onClick={() => {
                add.mutate({
                  inputs,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={add.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New exception has been added"
      />
    </>
  );
};

export default NewException;
