/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetRegulatoryAuthorities } from "src/services/regulation-policy/framework";
import { KeyStringVal } from "src/types/general";

const RegAuthFilter = ({
  inputs,
  setInputs,
  isFramework,
}: {
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
  isFramework?: boolean;
}) => {
  const { data: regAuth } = GetRegulatoryAuthorities(
    isFramework ? "framework" : "circular"
  );

  const handleSelect = (auth: string) => {
    setInputs({ ...inputs, regulatory_authority: auth });
  };

  return (
    <FilterLayout label="Regulatory Authority">
      <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
        <li className="flex items-center gap-2">
          <input
            type="radio"
            className="blue-radio"
            checked={inputs.regulatory_authority === "All"}
            onClick={() => handleSelect("All")}
          />
          <label htmlFor="" className="w-max">
            All
          </label>
        </li>
        {regAuth?.map((auth: string) => {
          return (
            <li key={auth} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={inputs.regulatory_authority.includes(auth)}
                onClick={() => handleSelect(auth)}
              />
              <label htmlFor="">{auth}</label>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default RegAuthFilter;
