const TableLayout: React.FC<{
  fullHeight?: boolean;
  height?: string;
  flexibleHeaders?: boolean;
}> = ({ fullHeight, height, flexibleHeaders, children }) => {
  return (
    <section
      className={`flex flex-col flex-grow w-full ${
        fullHeight
          ? ""
          : height
          ? height
          : "max-h-[50rem] overflow-y-auto scrollbar"
      } text-b1-reg border dark:border-gray-700 rounded-lg`}
    >
      <table
        className={`w-full h-full ${
          flexibleHeaders
            ? "table-auto overflow-x-auto scrollbar"
            : "lg:table-fixed"
        } rounded-lg`}
      >
        {children}
      </table>
    </section>
  );
};

export default TableLayout;
