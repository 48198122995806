import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import RiskBlueprintMetadata from "./RiskBlueprintMetadata";
import RiskScore from "./RiskScore";

const RiskBlueprint = () => {
  const parsed = parseURL();

  const riskBlueprintID = String(parsed.risk_blueprint_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "risks";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <RiskBlueprintMetadata riskBlueprintID={riskBlueprintID} />
        <RiskScore riskBlueprintID={riskBlueprintID} />
      </main>
    </PageLayout>
  );
};

export default RiskBlueprint;
