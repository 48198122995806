/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { Customer } from "../../../types/settings";

const CustomerFilter = ({
  allCustomers,
  value,
  setValue,
  list,
}: {
  allCustomers: Customer[];
  value: string;
  setValue: (value: string) => void;
  list: string[];
}) => {
  return (
    <DropdownLayout label="Customer" value={value} showAbove>
      {list?.map((item: string, index: number) => {
        const selected = value === item;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              setValue(
                allCustomers?.find(
                  (customer: Customer) => customer.customer_name === item
                )?.customer_id || ""
              );
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default CustomerFilter;
