import React from "react";
import { convertToUTCString } from "src/utils/general";
import ReturnPage from "src/components/Button/ReturnPage";
import { GetDocumentRequestMetadata } from "src/services/erc/attachments/document-requests";
import MetadataField from "src/components/GRC/MetadataField";
import UpdateRequest from "./UpdateRequest";
import { GetAuditList } from "src/services/audit-management";
import { KeyStringVal } from "src/types/general";

const DocumentRequestMetadata = ({
  documentRequestID,
}: {
  documentRequestID: string;
}) => {
  const { data: metadata } = GetDocumentRequestMetadata(documentRequestID);
  const { data: audits } = GetAuditList();

  const audit = audits?.data.find(
    (audit: KeyStringVal) => audit.audit_id === metadata?.assessment_id
  )?.name;

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Document Request</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-10">
              <article className="flex flex-col flex-grow gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by {metadata.last_updated_by?.user_name} (
                    {metadata.last_updated_by?.user_email})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by {metadata.created_by?.user_name} (
                    {metadata.created_by?.user_email})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateRequest
                  documentRequestID={documentRequestID}
                  request={metadata}
                />
              </article>
            </header>
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              {audit && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Audit / Assessment
                  </h4>
                  <span className="text-b1-semi">{audit}</span>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Framework Alias"
                keyName="framework_alias"
              />
              <MetadataField
                metadata={metadata}
                label="Task Completion Percentage"
                keyName="task_completion_percentage"
              />
              <MetadataField
                metadata={metadata}
                label="Due Date"
                keyName="due_date"
                isDate
              />
              <MetadataField
                metadata={metadata}
                label="Notes"
                keyName="notes"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default DocumentRequestMetadata;
