import { faDownload, faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ModalLayout from "src/layouts/ModalLayout";

const EvidenceDetail = ({ evidence }: { evidence: any }) => {
  const [show, setShow] = useState<boolean>(false);

  const handleOnClose = () => setShow(false);

  return (
    <li>
      <button
        className="flex items-center gap-2 px-2 py-1 text-b1-reg dark:bg-gray-700 dark:hover:bg-gray-700/70 duration-100 rounded"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faLink} className="dark:text-blue-500" />
        <h4>{evidence.name}</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <header className="grid gap-2">
            <h4 className="tracking-wide text-subt1-semi text-center">
              PREVIEW of {evidence.name}
            </h4>
            {evidence.file_uri && (
              <>
                <button
                  className="blue-button"
                  onClick={() => {
                    let a = document.createElement("a");
                    a.href = evidence.file_uri;
                    a.target = "_blank";
                    a.click();
                  }}
                >
                  <FontAwesomeIcon icon={faDownload} /> Download as File
                </button>
                <img
                  src={evidence.file_uri}
                  alt="preview"
                  className="mx-auto"
                />
              </>
            )}
          </header>
          {evidence.description && (
            <ReactMarkdown
              className="px-6 py-4 markdown"
              remarkPlugins={[remarkGfm]}
            >
              {evidence.description}
            </ReactMarkdown>
          )}
          {evidence.content && (
            <ReactMarkdown
              className="px-6 py-4 markdown"
              remarkPlugins={[remarkGfm]}
            >
              {evidence.content}
            </ReactMarkdown>
          )}
        </section>
      </ModalLayout>
    </li>
  );
};

export default EvidenceDetail;
