import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { EditQAPair } from "src/services/questionnaire-rfp";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly } from "src/utils/general";

const UpdateQAPair = ({
  questionBankID,
  questionID,
  question,
}: {
  questionBankID: string;
  questionID: string;
  question: KeyStringVal;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const editQAPair = EditQAPair(questionBankID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="gray-text-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs(question);
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Edit QA</h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Answer"
            keyName="response"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={Object.values(inputs).includes("")}
              className="blue-button"
              onClick={() => {
                editQAPair.mutate({
                  questionID: questionID,
                  newQuestion: inputs.question,
                  newResponse: inputs.response,
                });
                handleOnClose();
              }}
            >
              <h4>Add</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateQAPair;
