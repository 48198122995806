import React, { useState } from "react";
import ModalLayout from "../../../layouts/ModalLayout";
import { CreateGroup, GetAllGroups } from "../../../services/settings/groups";
import { Group } from "../../../types/settings";
import { getCustomerID } from "../../../utils/general";
import RegularInput from "src/components/Input/RegularInput";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AddGroup = () => {
  const customerID = getCustomerID();

  const [addGroup, setAddGroup] = useState<boolean>(false);
  const [valid, setValid] = useState(true);
  const [addGroupDetails, setAddGroupDetails] = useState({
    group_name: "",
    group_description: "",
  });

  const { data: allGroups } = GetAllGroups(customerID, false);
  const createGroup = CreateGroup(customerID);

  const handleOnClose = () => {
    setValid(true);
    setAddGroup(false);
  };

  return (
    <>
      <button
        className="blue-button"
        onClick={() => {
          setAddGroup(true);
          setAddGroupDetails({
            group_name: "",
            group_description: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Group
      </button>
      <ModalLayout showModal={addGroup} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Group</h3>
          <RegularInput
            label="Group Name"
            keyName="group_name"
            inputs={addGroupDetails}
            setInputs={setAddGroupDetails}
            valid={valid}
            setValid={setValid}
          />
          <RegularInput
            label="Description"
            keyName="group_description"
            inputs={addGroupDetails}
            setInputs={setAddGroupDetails}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={addGroupDetails.group_name === ""}
              className="blue-button"
              onClick={() => {
                if (
                  allGroups?.some(
                    (group: Group) =>
                      group.group_name.toLowerCase().trim() ===
                      addGroupDetails.group_name.toLowerCase().trim()
                  )
                )
                  setValid(false);
                else {
                  handleOnClose();
                  createGroup.mutate({ group: addGroupDetails });
                }
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default AddGroup;
