/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import ToastLayout from "src/layouts/ToastLayout";
import AssessmentQuestionSetFilter from "src/components/Filter/ThirdPartyRisk/AssessmentQuestionSetFilter";
import {
  GetInherentCheck,
  PreAssessmentCheck,
} from "src/services/third-party-risk/vendors/vendors";
import MultiUserInput from "src/components/Input/MultiUserInput";
import { checkIsReadOnly } from "src/utils/general";

const InherentCheck = ({ vendorID }: { vendorID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    vendor_mgr_email: [],
    third_party_id: null,
    question_set_id: null,
  });

  const { data: inherentCheck } = GetInherentCheck(vendorID);
  const check = PreAssessmentCheck(vendorID);

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => check.reset();

  useEffect(() => {
    if (inherentCheck) {
      setInputs({
        start_date: inherentCheck.start_date,
        end_date: inherentCheck.end_date,
        vendor_mgr_email: inherentCheck.vendor_mgr_email || [],
        third_party_id: inherentCheck.third_party_id,
        question_set_id: inherentCheck.question_set_id,
      });
    }
  }, [inherentCheck]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => setShow(true)}
      >
        <h4>Inherent Risk Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 mb-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Inherent Risk Assessment
          </h3>
          <article className="grid grid-cols-2 gap-4">
            <MultiUserInput
              label="Vendor Manager"
              keyName="vendor_mgr_email"
              inputs={inputs}
              setInputs={setInputs}
              required
              allowUnselect
            />
            <AssessmentQuestionSetFilter
              label="Question Set"
              keyName="question_set_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
          </article>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.vendor_mgr_email?.length === 0 || !inputs.question_set_id
              }
              className="blue-button"
              onClick={() => {
                check.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              Check
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={check.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="Risk Assessment Checked"
      />
    </>
  );
};

export default InherentCheck;
