import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import {
  AddVendorGroupsToQuestionSet,
  GetVendorGroupsFromQuestionSet,
  RemoveVendorGroupsFromQuestionSet,
} from "src/services/third-party-risk/questions/custom-question-sets";
import { GetVendorGroups } from "src/services/third-party-risk/vendors/vendor-groups";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly } from "src/utils/general";

const AddToVendorGroups = ({ questionSetID }: { questionSetID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const { data: curVendorGroups } =
    GetVendorGroupsFromQuestionSet(questionSetID);
  const { data: allVendorGroups } = GetVendorGroups();

  const addToVendorGroups = AddVendorGroupsToQuestionSet(questionSetID);
  const removeVendorGroups = RemoveVendorGroupsFromQuestionSet(questionSetID);

  return (
    <section className="flex flex-wrap items-center gap-4 text-b1-reg dark:bg-gray-800 rounded-2xl">
      {curVendorGroups?.length > 0 && (
        <ul className="flex flex-wrap items-center gap-3">
          {curVendorGroups.map((group: KeyStringVal) => {
            return (
              <li
                key={group.generated_id}
                className="flex items-center gap-3 px-4 py-1 border dark:border-blue-400 rounded"
              >
                <p>{group.name}</p>

                {/* remove group */}
                <button
                  disabled={isReadOnly}
                  className="hover-icon"
                  onClick={(e) => {
                    e.stopPropagation();
                    removeVendorGroups.mutate({
                      groupID: group.generated_id,
                    });
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </button>
              </li>
            );
          })}
        </ul>
      )}

      <Menu as="article" className="relative inline-block text-left">
        <Menu.Button
          disabled={isReadOnly}
          className="dark-blue-button"
          onClick={(e) => e.stopPropagation()}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Assign Vendor Group</h4>
        </Menu.Button>

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 bottom-10 grid w-max max-h-[10rem] gap-2 origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
            {allVendorGroups?.length > 0 ? (
              <nav className="grid content-start w-full h-full overflow-auto scrollbar">
                {allVendorGroups?.map((group: KeyStringVal) => {
                  return (
                    <button
                      key={group.generated_id}
                      className="flex items-center gap-2 px-4 py-2 w-full text-left dark:hover:bg-blue-500/70 duration-100 rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        addToVendorGroups.mutate({
                          groupID: group.generated_id,
                        });
                      }}
                    >
                      <p>{group.name}</p>
                    </button>
                  );
                })}
              </nav>
            ) : (
              <section className="grid gap-2 px-5 py-3 w-max origin-top-right focus:outline-none dark:text-white dark:bg-blue-600 rounded">
                <h4>No vendor groups</h4>
              </section>
            )}
          </Menu.Items>
        </Transition>
      </Menu>
    </section>
  );
};

export default AddToVendorGroups;
