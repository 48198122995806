import React, { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ToastLayout from "src/layouts/ToastLayout";
import { checkIsReadOnly } from "src/utils/general";
import FileInput from "src/components/Input/FileInput";
import { read, utils } from "xlsx";
import Sheet from "./Sheet";
import AuditFilter from "src/components/Filter/ERC/AuditFilter";
import { AddDocumentRequest } from "src/services/erc/attachments/document-requests";
import RegularInput from "src/components/Input/RegularInput";

const NewRequest = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [fileInfo, setFileInfo] = useState<any>({});

  const add = AddDocumentRequest();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => add.reset();

  useEffect(() => {
    if (inputs.file) {
      let fileReader = new FileReader();
      fileReader.readAsBinaryString(inputs.file);
      fileReader.onload = (event) => {
        let data = event.target?.result;
        let workbook = read(data, { type: "binary" });
        const sheetInfo = {} as any;
        workbook.SheetNames.forEach((sheet: string) => {
          const rowObject = utils.sheet_to_json(workbook.Sheets[sheet], {
            header: 1,
            defval: "",
          }) as any;
          let rows = [] as any;
          rowObject[0]?.forEach((col: string, idx: number) => {
            const preview1 = rowObject[1] ? rowObject[1][idx] : "";
            const preview2 = rowObject[2] ? rowObject[2][idx] : "";
            rows = [
              ...rows,
              {
                column: col || "-",
                preview: [preview1, preview2],
                property: "",
              },
            ];
          });
          sheetInfo[sheet] = rows;
        });
        setFileInfo(sheetInfo);
      };
    } else setFileInfo({});
  }, [inputs.file]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({});
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Request</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Request</h3>
          <FileInput
            label="File"
            keyName="file"
            types={["xlsx"]}
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          {Object.keys(fileInfo).length > 0 && (
            <section className="grid gap-4">
              {Object.keys(fileInfo).map((sheet, index) => {
                return (
                  <Sheet
                    key={index}
                    fileInfo={fileInfo}
                    setFileInfo={setFileInfo}
                    sheet={sheet}
                  />
                );
              })}
            </section>
          )}
          <AuditFilter
            label="Audit / Assessment"
            keyName="audit_id"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={!inputs.file || !inputs.audit_id}
              className="blue-button"
              onClick={() => {
                const formData = new FormData();

                formData.append("file", inputs.file);
                formData.append("properties", JSON.stringify(fileInfo));
                formData.append("audit_id", inputs.audit_id);
                formData.append("notes", inputs.notes);

                add.mutate({
                  formData,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={add.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New request has been added"
      />
    </>
  );
};

export default NewRequest;
