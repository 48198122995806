import React from "react";
import { GetFrameworkControlMetadata } from "../../../../../services/erc/controls/framework-controls";
import MetadataField from "src/components/GRC/MetadataField";
import ReturnPage from "src/components/Button/ReturnPage";

const FrameworkControlMetadata = ({
  frameworkControlID,
}: {
  frameworkControlID: string;
}) => {
  const { data: metadata } = GetFrameworkControlMetadata(frameworkControlID);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Framework Control</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <h2 className="text-subt1-semi">{metadata.name}</h2>
            <p>{metadata.control_description}</p>
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField
                metadata={metadata}
                label="Control Id"
                keyName="control_id"
              />
              <MetadataField
                metadata={metadata}
                label="Sub Control Id"
                keyName="sub_control_id"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              <MetadataField
                metadata={metadata}
                label="Framework Authority"
                keyName="framework_authority_short_name"
              />
              <MetadataField
                metadata={metadata}
                label="Framework Name"
                keyName="framework_short_name"
              />
              <MetadataField
                metadata={metadata}
                label="Framework Version"
                keyName="framework_version"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default FrameworkControlMetadata;
