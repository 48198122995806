/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { getCustomerID } from "../../../utils/general";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { GetCustomerEnvs } from "../../../services/settings/environments";
import { CustomerEnv } from "../../../types/settings";
import { useGeneralStore } from "src/stores/general";
import { useEffect } from "react";

const EnvFilter = () => {
  const customerID = getCustomerID();

  const { setEnv } = useGeneralStore();

  const { data: customerEnvs } = GetCustomerEnvs(customerID);

  const allEnvTypes = [
    ...new Set(
      customerEnvs?.reduce(
        (pV: string[], cV: CustomerEnv) => [...pV, cV.env_type],
        []
      )
    ),
  ] as string[];

  useEffect(() => {
    setEnv(sessionStorage.env);
  }, []);

  return (
    <DropdownLayout value={sessionStorage.envName}>
      {allEnvTypes?.map((item: string, index: number) => {
        const selected = sessionStorage.envName === item;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              sessionStorage.envName = item;
              const envID =
                customerEnvs?.find(
                  (customerEnv: CustomerEnv) => customerEnv.env_type === item
                )?.env_id || "";
              if (envID !== "") {
                sessionStorage.env = envID;
                setEnv(envID);
              }
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default EnvFilter;
