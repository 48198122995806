/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";
import { GetFrameworks } from "src/services/regulation-policy/framework";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CompareFrameworkFilter = ({
  selectedCompareFramework,
  setSelectedCompareFramework,
}: {
  selectedCompareFramework: KeyStringVal;
  setSelectedCompareFramework: (selectedCompareFramework: KeyStringVal) => void;
}) => {
  const { data: frameworks } = GetFrameworks();

  return (
    <DropdownLayout
      label="Selected Framework"
      value={selectedCompareFramework.name}
      width="w-[50rem]"
      placeholder="Select framework"
    >
      {frameworks?.data.map((framework: KeyStringVal, index: number) => {
        const selected = framework.id === selectedCompareFramework.id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => setSelectedCompareFramework(framework)}
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{framework.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default CompareFrameworkFilter;
