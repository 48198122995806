import React, { useState } from "react";
import { GetEvidenceAnalysis } from "../../../../services/audit-management";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import ModalLayout from "src/layouts/ModalLayout";

const Analysis = ({
  auditID,
  controlID,
}: {
  auditID: string;
  controlID: string;
}) => {
  const [show, setShow] = useState<boolean>(false);

  const { data: analysis } = GetEvidenceAnalysis(auditID, controlID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="flex items-center gap-2 px-4 py-2 w-max cursor-pointer text-b2-reg dark:text-blue-500 dark:hover:text-blue-500/70 duration-100"
        onClick={() => setShow(true)}
      >
        <h4>View Results</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Analysis Results
          </h3>
          <ReactMarkdown
            className="min-w-full px-6 py-4 prose dark:prose-invert dark:bg-gray-800 rounded-xl"
            remarkPlugins={[remarkGfm]}
          >
            {analysis?.response}
          </ReactMarkdown>
        </section>
      </ModalLayout>
    </>
  );
};

export default Analysis;
