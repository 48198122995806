/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  convertToUTCShortString,
  getCustomerID,
  getEmailFromUserID,
} from "src/utils/general";
import TablePagination from "src/components/General/TablePagination";
import { pageSize } from "src/constants/general";
import Loader from "src/components/Loader/Loader";
import { GetSOPList } from "src/services/business-continuity/sop";
import { GetAllUsers } from "src/services/settings/users";
import EditSOP from "./EditSOP";
import ExportAllTables from "./ExportAllTables/ExportAllTables";
import DepartmentFilter from "src/components/Filter/BusinessContinuity/DepartmentFilter";
import RowValue from "src/components/General/RowValue";

const SOPList = () => {
  const navigate = useNavigate();
  const customerID = getCustomerID();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [inputs, setInputs] = useState<any>({
    department: "",
  });

  const { data: sopList, status: sopStatus } = GetSOPList(
    inputs.department,
    pageNumber
  );
  const { data: allUsers } = GetAllUsers(customerID, false);

  const filteredSOPList = sopList?.data;
  const totalCount = filteredSOPList?.length || 0;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="flex flex-col flex-grow gap-4 w-full h-full overflow-auto scrollbar">
      <article className="flex items-center justify-between gap-4">
        <DepartmentFilter
          label="Department"
          keyName="department"
          inputs={inputs}
          setInputs={setInputs}
        />
        <ExportAllTables selectedDepartment={inputs.department} />
      </article>
      {sopStatus === "loading" ? (
        <Loader />
      ) : sopStatus === "success" ? (
        filteredSOPList?.length > 0 ? (
          <section className="flex flex-col flex-grow gap-4">
            <ul className="flex flex-col gap-4">
              {filteredSOPList?.map((sop: any, index: number) => {
                return (
                  <li
                    key={index}
                    className="flex flex-col content-start gap-2 px-6 py-4 cursor-pointer dark:bg-gray-800 dark:hover:bg-gray-700/70 duration-100 rounded-xl"
                    onClick={() => {
                      sessionStorage.GRCCategory =
                        "standard operating procedures";
                      navigate(
                        `/business-continuity/sop/details?sop_id=${sop.sop_id}&sop_version_id=${sop.latest_version_id}`
                      );
                    }}
                  >
                    <header className="flex flex-col flex-grow gap-1">
                      <article className="flex items-center justify-between gap-10">
                        <article className="flex items-center gap-2">
                          <h4 className="text-b1-reg">{sop.sop_name}</h4>
                          <RowValue row={sop} col="status" />
                        </article>
                        <article className="flex flex-wrap items-center gap-4">
                          <EditSOP
                            sopID={sop.sop_id}
                            sopName={sop.sop_name}
                            tag={sop.tag}
                          />
                        </article>
                      </article>
                      <p className="text-b2-reg dark:text-gray-400">
                        {sop.last_updated_at &&
                          convertToUTCShortString(sop.last_updated_at)}
                      </p>
                    </header>
                    <article className="flex items-center gap-4">
                      {sop.reviewers?.length > 0 && (
                        <article className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded">
                          <span className="text-b1-reg">Reviewers</span>
                          <ul className="flex flex-wrap items-center gap-2">
                            {sop.reviewers.map((reviewerID: string) => {
                              const user = getEmailFromUserID(
                                allUsers,
                                reviewerID
                              );
                              if (!user) return null;
                              return (
                                <li
                                  key={reviewerID}
                                  className="relative -ml-1 group"
                                >
                                  <span className="text-b1-semi dark:text-blue-500">
                                    {user}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </article>
                      )}
                      {sop.approvers?.length > 0 && (
                        <article className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded">
                          <span className="text-b1-reg">Approvers</span>
                          <ul className="flex flex-wrap items-center gap-2">
                            {sop.approvers.map((approverID: string) => {
                              const user = getEmailFromUserID(
                                allUsers,
                                approverID
                              );
                              if (!user) return null;
                              return (
                                <li
                                  key={approverID}
                                  className="relative -ml-1 group"
                                >
                                  <span className="text-b1-semi dark:text-blue-500">
                                    {user}
                                  </span>
                                </li>
                              );
                            })}
                          </ul>
                        </article>
                      )}
                      {sop.tag && (
                        <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-1-reg dark:bg-gray-700 rounded">
                          <span>Department</span>
                          <span
                            key={index}
                            className="text-b1-semi dark:text-blue-500"
                          >
                            {sop.tag}
                          </span>
                        </article>
                      )}
                    </article>
                  </li>
                );
              })}
            </ul>
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        ) : (
          <section className="flex items-center place-content-center gap-10 w-full h-full">
            <img
              src="/grc/policies-placeholder.svg"
              alt="policies placeholder"
              className="w-40 h-40"
            />
            <article className="grid gap-3">
              <h4 className="text-t1-bold">Standard Operating Procedures</h4>
              <h4>No procedures available</h4>
            </article>
          </section>
        )
      ) : null}
    </section>
  );
};

export default SOPList;
