import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { controlLifecycleStates } from "src/constants/erc";
import ModalLayout from "src/layouts/ModalLayout";
import {
  GetControlLifecycleEvents,
  GetControlLifecycleEvidenceList,
  GetControlLifecycleState,
  GetControlMetadata,
  UpdateControlState,
} from "src/services/erc/controls/controls";
import { GetAllUsers } from "src/services/settings/users";
import {
  checkIsReadOnly,
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  sortNumericData,
} from "src/utils/general";
import RadioInput from "src/components/Input/RadioInput";
import { KeyStringVal } from "src/types/general";
import MultipleFileInput from "src/components/Input/MultipleFileInput";

const LifecycleFlow = ({ controlID }: { controlID: string }) => {
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<string>("");
  const [inputs, setInputs] = useState<any>({
    is_control_implemented: "no",
    files: [],
    notes: "",
  });

  const { data: metadata } = GetControlMetadata(controlID);
  const { data: allUsers } = GetAllUsers(customerID, false);
  const { data: controlState } = GetControlLifecycleState(controlID);
  const updateState = UpdateControlState(controlID);
  const { data: lifecycleEvents } = GetControlLifecycleEvents(controlID);
  const { data: evidenceList } = GetControlLifecycleEvidenceList(controlID);

  const curStateIdx = controlLifecycleStates.findIndex(
    (state) => state === controlState?.state
  );
  const totalStates = controlLifecycleStates.length;
  const isControlImplemented = inputs.is_control_implemented === "yes";
  const sortedEvidences = sortNumericData(
    evidenceList,
    "last_updated_at",
    "desc"
  );
  const complianceStatus =
    sortedEvidences && sortedEvidences[0]?.compliance_status;

  const handleOnClose = () => {
    setShow("");
    setInputs({ is_control_implemented: "no", files: [], notes: "" });
  };

  const handleBacktoDraft = () => {
    const state = "DRAFT";

    const formData = new FormData();

    formData.append("state_to", state);
    formData.append("notes", inputs.notes);
    updateState.mutate({
      formData,
    });
    handleOnClose();
  };
  const handleGoBack = () => {
    const state = "AWAITING_ATTESTATION";

    const formData = new FormData();

    formData.append("state_to", state);
    formData.append("notes", inputs.notes);
    updateState.mutate({
      formData,
    });
    handleOnClose();
  };
  const handleGoForward = (stateIdx: number) => {
    const state =
      stateIdx === 0
        ? "AWAITING_ATTESTATION"
        : stateIdx === 1
        ? "IN_REVIEW"
        : stateIdx === 2
        ? "MONITORING"
        : "RETIRED";

    const formData = new FormData();

    if (inputs.files.length > 0)
      inputs.files.forEach((file: any) => formData.append("files", file));
    formData.append("is_control_implemented", isControlImplemented);
    formData.append("state_to", state);
    formData.append("notes", inputs.notes);
    updateState.mutate({
      formData,
    });
    handleOnClose();
  };

  return (
    <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      <h4 className="text-t1-semi">Attestation Lifecycle Flow</h4>
      <ol className="items-start sm:flex w-full">
        {controlLifecycleStates.map((state, stateIdx) => {
          const events = lifecycleEvents?.filter(
            (event: KeyStringVal) => event.state === state
          );
          const sortedEvents = sortNumericData(
            events,
            "state_change_date",
            "desc"
          );

          return (
            <li key={state} className="relative mb-6 sm:mb-0 w-full">
              <article className="flex items-center">
                <button
                  disabled={
                    isReadOnly ||
                    !metadata?.allowed_lifecycle_actions?.includes(state) ||
                    stateIdx < curStateIdx ||
                    stateIdx >= curStateIdx + 1 ||
                    curStateIdx === totalStates
                  }
                  className={`flex items-center justify-center w-32 h-full px-2 py-1 ${
                    curStateIdx !== totalStates && stateIdx === curStateIdx
                      ? "dark:text-[#4F71E3] dark:bg-gray-900 dark:ring-blue-600"
                      : stateIdx < curStateIdx || curStateIdx === totalStates
                      ? "dark:text-green-600 dark:bg-gray-900 dark:ring-green-600"
                      : "dark:text-gray-400 dark:bg-gray-900 dark:ring-gray-400"
                  } sm:ring-2 shrink-0 rounded z-10`}
                  onClick={() => {
                    setShow(state);
                    setInputs({
                      is_control_implemented: "no",
                      files: [],
                      notes: "",
                    });
                  }}
                >
                  <h3 className="text-b1-reg">{state?.replaceAll("_", " ")}</h3>
                </button>
                {stateIdx !== totalStates - 1 && (
                  <article className="hidden sm:flex w-full h-[1px] dark:bg-gray-500"></article>
                )}
                <ModalLayout showModal={show === state} onClose={handleOnClose}>
                  <section className="grid gap-4">
                    <h3 className="flex items-center gap-2 text-t1-semi">
                      Update State
                    </h3>
                    {state === "IN_REVIEW" && complianceStatus && (
                      <span>Compliance Status: {complianceStatus}</span>
                    )}
                    {state === "AWAITING_ATTESTATION" && (
                      <>
                        <RadioInput
                          label="Is Control Implemented?"
                          keyName="is_control_implemented"
                          list={["yes", "no"]}
                          inputs={inputs}
                          setInputs={setInputs}
                        />
                        {isControlImplemented && (
                          <MultipleFileInput
                            label="Evidence"
                            keyName="files"
                            types={[
                              "png",
                              "svg",
                              "pdf",
                              "docx",
                              "csv",
                              "txt",
                              "xlsx",
                              "jpg",
                              "jpeg",
                            ]}
                            inputs={inputs}
                            setInputs={setInputs}
                          />
                        )}
                      </>
                    )}
                    <RegularInput
                      label="Notes"
                      keyName="notes"
                      inputs={inputs}
                      setInputs={setInputs}
                      textarea
                    />
                    <article className="flex items-center place-content-center gap-4">
                      {[2, 3, 4].includes(stateIdx) && (
                        <button
                          className="red-button"
                          onClick={() => handleBacktoDraft()}
                        >
                          Back to Draft
                        </button>
                      )}
                      {[2].includes(stateIdx) && (
                        <button
                          className="red-button"
                          onClick={() => handleGoBack()}
                        >
                          Reject
                        </button>
                      )}
                      <button
                        disabled={
                          state === "AWAITING_ATTESTATION" &&
                          isControlImplemented &&
                          inputs.files.length === 0
                        }
                        className="blue-button"
                        onClick={() => handleGoForward(stateIdx)}
                      >
                        {stateIdx === 0
                          ? "Request Attestation"
                          : stateIdx === 1
                          ? "Request Review"
                          : stateIdx === 2
                          ? "Approve"
                          : "Retire"}
                      </button>
                    </article>
                  </section>
                </ModalLayout>
              </article>
              <article className="mt-3 sm:pe-8">
                <section className="flex flex-col flex-grow pr-5">
                  {sortedEvents?.map((event: any, index: number) => {
                    return (
                      <section
                        key={index}
                        className="grid gap-2 p-4 dark:bg-gray-900 rounded-md"
                      >
                        <header className="flex flex-col flex-grow">
                          <article className="text-b2-semi">
                            {getEmailFromUserID(
                              allUsers,
                              event.state_change_by
                            )}
                          </article>
                          <span className="text-b3-reg dark:text-gray-400">
                            {convertToUTCString(event.state_change_date)}
                          </span>
                        </header>
                        <p className="text-b3-reg">{event.notes}</p>
                      </section>
                    );
                  })}
                </section>
              </article>
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default LifecycleFlow;
