import React from "react";
import { GetAssessmentCycleMetadata } from "src/services/third-party-risk/assessments/assessment-cycles";
import {
  checkIsReadOnly,
  convertToUTCString,
} from "../../../../../utils/general";
import {
  attributeColors,
  attributeTextColors,
} from "../../../../../constants/general";
import { AssessVendors } from "../../../../../services/third-party-risk/assessments/assessment-cycles";
import MetadataField from "src/components/GRC/MetadataField";
import ReturnPage from "src/components/Button/ReturnPage";

const AssessmentMetadata = ({ assessmentID }: { assessmentID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const { data: metadata } = GetAssessmentCycleMetadata(assessmentID);
  const accessVendors = AssessVendors(assessmentID);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Third Party Risk</span>{" "}
          <span>/</span>
          <span>Assessment</span>
        </h4>
      </article>
      {metadata && (
        <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
          <header className="flex items-start justify-between gap-4">
            <article className="grid content-start gap-1">
              <h2 className="text-subt1-semi">{metadata.title}</h2>
              <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                <span>
                  {convertToUTCString(metadata.start_date)} -{" "}
                  {convertToUTCString(metadata.end_date)}
                </span>
              </article>
            </article>
          </header>
          <section className="flex flex-wrap items-center gap-4 text-b1-reg">
            <article
              className={`flex items-center gap-1 ${
                attributeColors[String(metadata.is_open)?.toLowerCase()]
              }`}
            >
              Status
              <span
                className={`capitalize ${
                  attributeTextColors[String(metadata.is_open)?.toLowerCase()]
                }`}
              >
                {metadata.is_open ? "OPEN" : "CLOSED"}
              </span>
            </article>
          </section>
          <MetadataField
            metadata={metadata}
            label="Purpose"
            keyName="purpose"
          />
        </section>
      )}
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => accessVendors.mutate({})}
      >
        Assess Vendors
      </button>
    </section>
  );
};

export default AssessmentMetadata;
