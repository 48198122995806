import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RegularInput from "src/components/Input/RegularInput";
import { AddVendorGroup } from "src/services/third-party-risk/vendors/vendor-groups";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ToastLayout from "../../../../layouts/ToastLayout";
import { checkIsReadOnly } from "src/utils/general";

const NewVendorGroup = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const addVendorGroup = AddVendorGroup();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addVendorGroup.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            name: "",
            description: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Vendor Group</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Vendor Group
          </h3>
          <RegularInput
            label="Vendor Group"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                addVendorGroup.mutate({
                  name: inputs.name,
                  description: inputs.description,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addVendorGroup.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New vendor group has been created"
      />
    </>
  );
};

export default NewVendorGroup;
