/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Subsection from "./Subsection/Subsection";
import TablePagination from "src/components/General/TablePagination";
import Loader from "src/components/Loader/Loader";
import { pageSize } from "src/constants/general";

const Sections = ({
  table,
  tableStatus,
  sopName,
  versionID,
  selectedTab,
  pageNumber,
  setPageNumber,
}: {
  table: any;
  tableStatus: string;
  sopName: string;
  versionID: string;
  selectedTab: string;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
}) => {
  const filteredTable = table?.data;

  const totalCount = table?.pager?.total_results || 0;
  const totalPages = table?.pager?.num_pages || 0;
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <>
      {tableStatus === "loading" ? (
        <Loader />
      ) : (
        tableStatus === "success" && (
          <section className="flex flex-col flex-grow content-start gap-3 h-full">
            {filteredTable ? (
              filteredTable.length > 0 ? (
                <ul className="grid gap-4">
                  {filteredTable.map((section: any, sectionIndex: number) => {
                    return (
                      <li
                        key={sectionIndex}
                        className="grid content-start gap-3 p-4 bg-gray-800 rounded-xl"
                      >
                        <header className="flex items-start gap-2 text-b1-semi">
                          {section.section_id && (
                            <span>{section.section_id}.</span>
                          )}
                          {section.title && section.title}
                          {!["-", "", null].includes(section.section_title) && (
                            <span>{section.section_title}</span>
                          )}
                        </header>
                        {section.sub_sections?.length > 0 && (
                          <article className="grid gap-4">
                            {section.sub_sections.map(
                              (subsection: any, subSectionIndex: number) => {
                                return (
                                  <Subsection
                                    key={subSectionIndex}
                                    subsection={subsection}
                                  />
                                );
                              }
                            )}
                          </article>
                        )}
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <p>No results found</p>
              )
            ) : null}
            <TablePagination
              totalPages={totalPages}
              beginning={beginning}
              end={end}
              totalCount={totalCount}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
            />
          </section>
        )
      )}
    </>
  );
};

export default Sections;
