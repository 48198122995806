/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useState } from "react";
import {
  riskScoreColors,
  riskScoreMappings,
  riskScores,
} from "src/constants/erc";
import ModalLayout from "src/layouts/ModalLayout";
import {
  EditException,
  GetExceptionMetadata,
} from "src/services/erc/exceptions/exceptions";
import { checkIsReadOnly } from "src/utils/general";

const RiskScore = ({ exceptionID }: { exceptionID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<string>("");

  const { data: metadata } = GetExceptionMetadata(exceptionID);
  const editException = EditException(exceptionID);

  const handleOnClose = () => setShow("");

  return (
    <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
      {metadata && (
        <section className="flex flex-col flex-grow gap-4">
          <h4 className="indent-12 text-center text-t1-semi">
            Risk Score{" "}
            {metadata.risk_score && (
              <span className="px-2 py-1 ml-1 text-h5 dark:bg-blue-500 rounded-full">
                {metadata.risk_score}
              </span>
            )}
          </h4>
          <section className="grid grid-cols-6 grid-rows-6">
            {[...Array(36).keys()].map((idx) => {
              const selected =
                riskScoreMappings[idx] &&
                metadata.risk_impact_level === riskScoreMappings[idx][0] &&
                metadata.risk_likelihood_level === riskScoreMappings[idx][1];
              return (
                <Fragment key={idx}>
                  <button
                    disabled={isReadOnly || idx % 6 === 0 || idx >= 30}
                    className={`place-content-center w-full h-20 text-center text-b2-reg ${
                      idx % 6 === 0 || idx >= 30
                        ? ""
                        : selected
                        ? "dark:bg-blue-600"
                        : riskScoreColors[idx]
                    } ${
                      idx % 6 === 0 || idx >= 30
                        ? ""
                        : "border-1 dark:border-gray-900"
                    } break-all`}
                    onClick={() => setShow(`inherent-${idx}`)}
                  >
                    {selected && (
                      <article className="grid gap-1 text-b4-reg">
                        <span>
                          {riskScoreMappings[idx][0]?.replaceAll("_", " ")} (
                          {metadata.risk_impact_level_score})
                        </span>
                        <span>
                          {riskScoreMappings[idx][1]?.replaceAll("_", " ")} (
                          {metadata.risk_likelihood_level_score})
                        </span>
                      </article>
                    )}
                    {idx !== 30 && riskScores[idx]?.replaceAll("_", " ")}
                  </button>
                  <ModalLayout
                    showModal={show === `inherent-${idx}`}
                    onClose={handleOnClose}
                  >
                    <section className="grid gap-10">
                      <h2 className="text-subt1-semi">Confirmation</h2>
                      <h4 className="text-b1-reg">
                        Are you sure you want to change this risk score?
                      </h4>
                      <article className="flex items-center place-content-end gap-4">
                        <button
                          className="black-button"
                          onClick={handleOnClose}
                        >
                          Cancel
                        </button>
                        <button
                          className="blue-button"
                          onClick={() => {
                            editException.mutate({
                              inputs: {
                                name: metadata?.name,
                                risk_impact_level: riskScoreMappings[idx][0],
                                risk_likelihood_level:
                                  riskScoreMappings[idx][1],
                              },
                            });
                            handleOnClose();
                          }}
                        >
                          Done
                        </button>
                      </article>
                    </section>
                  </ModalLayout>
                </Fragment>
              );
            })}
          </section>
        </section>
      )}
    </section>
  );
};

export default RiskScore;
