import React from "react";
import {
  convertToUTCString,
  getCustomerID,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetEntityTypeMetadata } from "../../../../../services/erc/entities/entity-types";
import { GetAllUsers } from "src/services/settings/users";
import MetadataField from "src/components/GRC/MetadataField";
import UpdateEntityType from "./UpdateEntityType";
import ArchiveRestoreEntityType from "./ArchiveRestoreEntityType";
import ReturnPage from "src/components/Button/ReturnPage";

const EntityTypeMetadata = ({ entityTypeID }: { entityTypeID: string }) => {
  const customerID = getCustomerID();

  const { data: metadata } = GetEntityTypeMetadata(entityTypeID);
  const { data: allUsers } = GetAllUsers(customerID, false);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Entity Type</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(allUsers, metadata.last_updated_by)} (
                    {getEmailFromUserID(allUsers, metadata.last_updated_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, metadata.created_by)} (
                    {getEmailFromUserID(allUsers, metadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateEntityType
                  entityTypeID={entityTypeID}
                  entityType={metadata}
                />
                <ArchiveRestoreEntityType
                  entityTypeID={entityTypeID}
                  entityType={metadata}
                />
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-2 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
          </section>
        </header>
      )}
    </>
  );
};

export default EntityTypeMetadata;
