import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React from "react";
import { GetGRCDocumentFAQ } from "src/services/grc";
import { KeyStringVal } from "src/types/general";

const FAQ = ({ documentID }: { documentID: string }) => {
  const { data: faq } = GetGRCDocumentFAQ(documentID);

  return (
    <Disclosure>
      {({ open }) => (
        <>
          <article className="grid gap-1 px-6 py-4 dark:bg-gray-800 black-shadow rounded-xl">
            <header className="flex items-center justify-between gap-10 w-full text-subt1-semi">
              <h4>FAQ</h4>
              <Disclosure.Button>
                <article className="flex items-center gap-2 text-b2-reg dark:text-blue-600">
                  <FontAwesomeIcon icon={open ? faChevronUp : faChevronDown} />
                  <span>Show {open ? "less" : "more"}</span>
                </article>
              </Disclosure.Button>
            </header>
            <span className="text-b2-reg dark:text-gray-400">
              {faq?.timestamp}
            </span>
          </article>
          <Disclosure.Panel className="grid gap-3 -mt-7">
            {faq ? (
              faq.faqs.length > 0 ? (
                <ul className="flex flex-col flex-grow gap-2 px-6 py-4 dark:bg-gray-800 rounded-b-xl">
                  {faq.faqs.map((faq: KeyStringVal, index: number) => {
                    return (
                      <Disclosure key={index}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex items-center justify-between gap-10 p-4 w-full dark:bg-gray-900 rounded-lg">
                              <p className="text-b1-semi">{faq.question}</p>
                              <FontAwesomeIcon
                                icon={open ? faChevronUp : faChevronDown}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className="grid gap-4 -mt-4 p-4 text-b1-reg dark:bg-gray-900 rounded-lg">
                              <p className="text-left break-words">
                                {faq.answer}
                              </p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    );
                  })}
                </ul>
              ) : (
                <p>No FAQs available</p>
              )
            ) : null}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

export default FAQ;
