import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringArrayVal } from "src/types/general";

export const controlPrefix = "grc/grc-controls/controls";

export const GetControls = (
  pageNumber?: number,
  tags?: string[] | undefined,
  columns?: string[],
  queryList?: any
) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringArrayVal | string[] | undefined)[]
  >(
    ["get-controls", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/getall`,
          {
            ...(pageNumber && {
              pager: {
                page_number: pageNumber,
                page_size: pageSize,
              },
            }),
            tags,
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddControl = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-controls"]),
    }
  );

export const EditControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${controlPrefix}/${controlID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-metadata"]);
      },
    }
  );

export const GetControlMetadata = (controlID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-metadata", controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlPrefix}/${controlID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-controls"]);
        queryClient.invalidateQueries(["get-control-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control"]);
      },
    }
  );

export const RemoveTagFromControl = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-controls"]);
        queryClient.invalidateQueries(["get-control-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control"]);
      },
    }
  );

export const GetControlLifecycleState = (controlID: string) =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-control-lifecycle-state", controlID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/lifecycle`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );

export const GetControlLifecycleEvidenceList = (controlID: string) =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-control-lifecycle-evidence-list", controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/evidence`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );

export const UpdateControlState = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/transition-state`,
          formData,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-lifecycle-state"]);
        queryClient.invalidateQueries(["get-control-lifecycle-events"]);
        queryClient.invalidateQueries(["get-control-lifecycle-evidence-list"]);
        queryClient.invalidateQueries(["get-control-metadata"]);
      },
    }
  );

export const GetControlLifecycleEvents = (controlID: string) =>
  useQuery<any, unknown, any, (string | boolean | string[])[]>(
    ["get-control-lifecycle-events", controlID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/events`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );

export const GenerateTestPlan = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/test-plan`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-metadata"]);
        queryClient.invalidateQueries(["get-test-plan-status"]);
      },
    }
  );

export const GetTestPlanStatus = (controlID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-test-plan-status", controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/test-plan/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const GetControlCoverage = (policyIDs: string[]) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-coverage", policyIDs],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/coverage`,
          { policy_ids: policyIDs },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: policyIDs?.length > 0,
      keepPreviousData: false,
    }
  );

export const GetControlIssues = (controlID: string, pageNumber: number) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringArrayVal | string[] | undefined)[]
  >(
    ["get-control-issues", controlID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/grc/grc-controls/control-issues/getall`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            control_id: controlID,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const FindSimilarControls = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/frameworktrigger`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-similar-controls-status"]),
    }
  );

export const GetSimilarControlsStatus = (controlID: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-similar-controls-status", controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/framework-mapping/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
      refetchInterval: 150000,
    }
  );

export const GetFrameworkmappingCount = (controlID: string, status: string) =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-framework-mapping-count", controlID, status],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/framework-mapping/counts`,
          {
            query_type: "ControlToFramework",
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: status === "READY",
      keepPreviousData: false,
    }
  );

export const AddControlAttachments = () =>
  useMutation<any, unknown, any, string>(
    async ({
      controlID,
      formData,
      signal,
    }: {
      controlID: string;
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/upload`,
          formData,
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-controls"]);
        queryClient.invalidateQueries(["get-control-attachments"]);
      },
    }
  );

export const RemoveControlAttachment = (
  controlID: string,
  attachmentID: string
) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/removedocument?attachment_id=${attachmentID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-control-attachments"]),
    }
  );

export const DownloadControlAttachment = (controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      attachmentID,
      signal,
    }: {
      attachmentID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/download?attachment_id=${attachmentID}`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(["get-control-attachments"]),
    }
  );

export const GetControlAttachments = (controlID: string, pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-control-attachments", controlID, pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlPrefix}/${controlID}/listdocuments`,
          {
            ...(pageNumber && {
              page_number: pageNumber,
              page_size: pageSize,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlID !== "",
      keepPreviousData: false,
    }
  );
