/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import SubsectionContent from "./SubsectionContent";
import ViewInFile from "src/pages/RegulationPolicy/Document/ViewInFile/ViewInFile";

const Subsection = ({
  documentType,
  subsection,
}: {
  documentType: string;
  subsection: any;
}) => {
  const generatedID = subsection.generated_id;

  return (
    <article className="grid content-start gap-3 p-4 dark:bg-gray-700 border-l-1 dark:border-black/60 rounded-2xl">
      <header className="flex items-start justify-between gap-10">
        <h4 className="flex items-start gap-2 text-lg break-words">
          {subsection.sub_section_id}{" "}
          {subsection.sub_section_title !== "-" && subsection.sub_section_title}
        </h4>
        <ViewInFile
          generatedID={generatedID}
          section={subsection}
          bbox={subsection.page_metadata}
          documentType={documentType}
        />
      </header>
      {subsection.metadata_ && (
        <ul className="flex flex-wrap items-center gap-2">
          {Object.entries(subsection.metadata_).map((keyVal, index) => {
            if (
              !keyVal[1] ||
              (Array.isArray(keyVal[1]) && keyVal[1]?.length === 0)
            )
              return null;
            return (
              <li
                key={keyVal[0]}
                className="flex items-center gap-2 px-2 py-1 dark:bg-gray-800 rounded"
              >
                <h4 className="capitalize">{keyVal[0].replaceAll("_", " ")}</h4>
                {Array.isArray(keyVal[1]) ? (
                  keyVal[1].map((value) => {
                    return (
                      <span
                        key={value}
                        className="text-b1-reg dark:text-blue-600 dark:bg-gray-800"
                      >
                        {value}
                      </span>
                    );
                  })
                ) : (
                  <span className="flex items-center gap-1 text-b1-reg dark:text-blue-600 dark:bg-gray-800">
                    {keyVal[1]}
                  </span>
                )}
              </li>
            );
          })}
        </ul>
      )}
      {subsection.content && <SubsectionContent subsection={subsection} />}
    </article>
  );
};

export default Subsection;
