import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import RadioInput from "../../../../../components/Input/RadioInput";
import ToastLayout from "../../../../../layouts/ToastLayout";
import { AddRiskQuestion } from "src/services/erc/risks/assessments/questions";

const NewQuestion = () => {
  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const addRiskQuestion = AddRiskQuestion();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addRiskQuestion.reset();

  return (
    <>
      <button
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            question: "",
            question_type: "",
            guideline_or_rubric: "",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Question</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">Add Question</h3>
          <section className="grid grid-cols-2 gap-4 w-full">
            <RadioInput
              label="Question Type"
              keyName="question_type"
              list={["Yes/No", "Open Ended"]}
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Question"
              keyName="question"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <RegularInput
              label="Guideline or Rubric"
              keyName="guideline_or_rubric"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
          </section>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={Object.values(inputs).includes("")}
              className="blue-button"
              onClick={() => {
                addRiskQuestion.mutate({
                  inputs: {
                    ...inputs,
                    question_type:
                      inputs.question_type === "Yes/No"
                        ? "YES_NO"
                        : "OPEN_ENDED",
                  },
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addRiskQuestion.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New question has been created"
      />
    </>
  );
};

export default NewQuestion;
