import React, { useState } from "react";
import HorizontalStackedBarChart from "src/components/Chart/HorizontalStackedBarChart";
import { GetCategoryCounts } from "src/services/erc/risks/risk-dashboard";

const RiskByCategory = () => {
  const [sectionProps, setSectionProps] = useState({});

  const { data } = GetCategoryCounts();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <HorizontalStackedBarChart
        title="Risk by Category"
        data={data}
        xKey="category"
        sectionProps={sectionProps}
        setSectionProps={setSectionProps}
      />
    </section>
  );
};

export default RiskByCategory;
