import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import CheckboxInput from "src/components/Input/CheckboxInput";
import { EditEntityType } from "src/services/erc/entities/entity-types";
import { checkIsReadOnly } from "src/utils/general";

const UpdateEntityType = ({
  entityTypeID,
  entityType,
}: {
  entityTypeID: string;
  entityType: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const editEntityType = EditEntityType(entityTypeID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="edit-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs(entityType);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Entity Type
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            valid={valid}
            setValid={setValid}
            required
          />
          {inputs.control_blueprint_ids?.length > 0 && (
            <CheckboxInput
              label="Create Controls Automatically"
              keyName="create_controls_automatically_enabled"
              inputs={inputs}
              setInputs={setInputs}
            />
          )}
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editEntityType.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateEntityType;
