import { useQuery, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";

const prefix = `grc/audits/internal`;

export const GetAuditList = (pageNumber?: number) =>
  useQuery<any, unknown, any, (string | number | undefined)[]>(
    ["get-audit-list", pageNumber],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}`,
          {
            ...(pageNumber && {
              pager: {
                page_number: pageNumber,
                page_size: pageSize,
              },
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const AddAudit = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/new`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-audit-list"]);
      },
    }
  );

export const RemoveAudit = () => {
  const navigate = useNavigate();

  return useMutation<any, unknown, any, string>(
    async ({ auditID, signal }: { auditID: string; signal: AbortSignal }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${auditID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-audit-list"]);
        navigate("/audit-management/summary");
      },
    }
  );
};

export const GetAuditStatus = (auditID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-audit-status", auditID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/grc/internal_audit/${auditID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "",
      keepPreviousData: false,
    }
  );

export const GetAuditMetadata = (auditID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-audit-metadata", auditID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "",
      keepPreviousData: false,
    }
  );

export const GetInternalAuditSections = (
  auditID: string,
  selectedTab: string,
  pageNumber: number,
  filters: any
) =>
  useQuery<any, unknown, any, (string | number | string[] | boolean)[]>(
    ["get-internal-audit-sections", auditID, selectedTab, pageNumber, filters],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/${selectedTab}?inline=true`,
          {
            page_number: pageNumber,
            page_size: pageSize,
            filter: filters.filter,
            hide_excluded: filters.hide_excluded,
            analysis_generated: filters.analysis_generated,
            has_evidence: filters.has_evidence,
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      enabled: auditID !== "",
    }
  );

export const GetInternalAuditMetrics = (auditID: string, isClosed: boolean) =>
  useQuery<any, unknown, any, (string | number | string[] | boolean)[]>(
    ["get-internal-audit-metrics", auditID],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/audit_stats`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
      enabled: auditID !== "" && isClosed,
    }
  );

export const GetControlEvidence = (auditID: string, controlID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-evidence", auditID, controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/evidence`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "" && controlID !== "",
      keepPreviousData: false,
    }
  );

export const AddControlEvidence = (auditID: string, controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      formData,
      signal,
    }: {
      formData: FormData;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/evidence`,
          formData,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-control-evidence",
          auditID,
          controlID,
        ]);
      },
    }
  );

export const DeleteControlEvidence = (auditID: string, controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({
      evidenceID,
      signal,
    }: {
      evidenceID: string;
      signal: AbortSignal;
    }) => {
      try {
        const res = await client.delete(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/evidence/${evidenceID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-control-evidence",
          auditID,
          controlID,
        ]);
      },
    }
  );

export const GetEvidenceAnalysis = (auditID: string, controlID: string) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-evidence-analysis", auditID, controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/evidence-response`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "" && controlID !== "",
      keepPreviousData: false,
    }
  );

export const StartEvidenceAnalysis = (auditID: string, controlID: string) =>
  useMutation<any, unknown, any, string[]>(
    async ({ signal }: { formData: FormData; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/trigger-evidence-analysis`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-analysis-status"]);
        setTimeout(
          () => queryClient.invalidateQueries(["get-evidence-analysis"]),
          5000
        );
      },
    }
  );

export const GetAnalysisStatus = (auditID: string, controlID: string) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-analysis-status", auditID, controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/controls/${controlID}/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "" && controlID !== "",
      keepPreviousData: false,
      refetchInterval: 10000,
    }
  );

export const CloseInternalAudit = (auditID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/close`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-audit-metadata", auditID]);
      },
    }
  );

export const ExportInternalAudit = (auditID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/export`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-internal-audit-status",
          auditID,
        ]);
      },
    }
  );

export const GetExportInternalAuditStatus = (auditID: string) =>
  useQuery<any, unknown, any, (string | number)[]>(
    ["get-export-internal-audit-status", auditID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/export/status`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "",
      keepPreviousData: false,
      refetchInterval: 30000,
    }
  );

export const DownloadInternalAudit = (auditID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/export`,
          { signal }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          "get-export-internal-audit-status",
          auditID,
        ]);
      },
    }
  );

export const UploadEvidenceReport = (auditID: string, controlID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ report, signal }: { report: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${prefix}/${auditID}/${controlID}/evidence_report`,
          report,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-internal-audit-sections"]);
        queryClient.invalidateQueries(["get-control-evidence"]);
        queryClient.invalidateQueries(["get-control-evidence-report"]);
      },
    }
  );

export const GetEvidenceReport = (auditID: string, controlID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-evidence-report", auditID, controlID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/${auditID}/${controlID}/evidence_report`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: auditID !== "" && controlID !== "",
      keepPreviousData: false,
    }
  );
