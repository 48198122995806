/* eslint-disable react-hooks/exhaustive-deps */
import {
  faDownload,
  faPlus,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, useState } from "react";
import { LineWave } from "react-loader-spinner";
import {
  DownloadResponses,
  ExportResponses,
  GetExportResponsesStatus,
} from "../../../../../../services/third-party-risk/vendors/vendors";
import { Popover, Transition } from "@headlessui/react";
import { checkIsReadOnly } from "src/utils/general";
import { KeyStringVal } from "src/types/general";

const ExportFile = ({
  entityID,
  assessmentID,
  selectedQuestions,
}: {
  entityID: string;
  assessmentID: string;
  selectedQuestions: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [includesCitations, setIncludesCitations] = useState<boolean>(false);

  const exportResponses = ExportResponses(entityID, assessmentID);
  const { data: exportStatus } = GetExportResponsesStatus(
    entityID,
    assessmentID
  );
  const downloadResponses = DownloadResponses(entityID, assessmentID);

  const hasResponse = selectedQuestions.some(
    (question: KeyStringVal) =>
      question.answer !== "Sorry, no related sections found."
  );

  const onDownload = () => {
    downloadResponses.mutate(
      {},
      {
        onSuccess: (data) => {
          const url = data.signed_url;
          let a = document.createElement("a");
          a.href = url;
          a.download = "responses.docx";
          a.target = "_blank";
          a.click();
        },
      }
    );
  };

  return (
    <article className="flex items-center gap-4 place-self-end">
      <Popover className="relative">
        <Popover.Button disabled={isReadOnly} className="blue-button">
          <FontAwesomeIcon icon={faPlus} className="w-3 h-3" />
          Export
        </Popover.Button>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="opacity-0 translate-y-1"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease-in duration-150"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 translate-y-1"
        >
          <Popover.Panel className="absolute left-28 top-0 z-10">
            {({ close }) => (
              <section className="grid gap-1 p-4 dark:bg-gray-700 black-shadow rounded-md">
                {!hasResponse && (
                  <span className="p-2 break-words text-left dark:bg-inner border dark:border-error rounded-sm">
                    Please only select questions with response
                  </span>
                )}
                <article className="flex items-center place-content-end gap-4 w-full">
                  <article className="flex items-center gap-1 w-max">
                    <input
                      type="checkbox"
                      checked={includesCitations}
                      onChange={() => setIncludesCitations(!includesCitations)}
                      className="white-checkbox"
                    />
                    <label htmlFor="">with citations</label>
                  </article>
                  <button
                    disabled={!hasResponse}
                    className="flex items-center place-self-end gap-1 px-4 py-1 blue-button"
                    onClick={() => {
                      exportResponses.mutate({
                        includesCitations: includesCitations,
                        questions: selectedQuestions,
                      });
                      close();
                    }}
                  >
                    Export
                  </button>
                </article>
              </section>
            )}
          </Popover.Panel>
        </Transition>
      </Popover>
      {exportStatus?.status === "parsing" ? (
        <span className="flex items-center gap-1">
          Exporting{" "}
          <LineWave
            visible={true}
            height="30"
            width="30"
            color="#4fa94d"
            ariaLabel="line-wave-loading"
            wrapperStyle={{}}
            wrapperClass=""
            firstLineColor=""
            middleLineColor=""
            lastLineColor=""
          />
        </span>
      ) : exportStatus?.status === "ready" ? (
        <button className="blue-button" onClick={onDownload}>
          <FontAwesomeIcon icon={faDownload} />
          Download Last Export
        </button>
      ) : exportStatus?.status === "failed" ? (
        <span>
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            className="text-red-500"
          />{" "}
          Export failed
        </span>
      ) : null}
    </article>
  );
};

export default ExportFile;
