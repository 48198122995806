/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import CVEList from "./CVEList";
import SummaryLayout from "../../../../layouts/SummaryLayout";
import { severityColors } from "src/constants/summaries";
import { convertToMicrosec, sortNumericData } from "src/utils/general";
import CVEDetail from "./CVEDetail";
import {
  GetVRSeverityDistribution,
  GetVRSummary,
} from "src/services/summaries/vulnerability-risks";
import { KeyStringVal } from "src/types/general";
import { useSummaryStore } from "src/stores/summaries";
import { useGeneralStore } from "src/stores/general";
import Overview from "./Overview/Overview";
import { AnimatePresence } from "framer-motion";
import DetailPanel from "src/components/Graph/DetailPanel/DetailPanel";
import { useGraphStore } from "src/stores/graph";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmileWink } from "@fortawesome/free-solid-svg-icons";
import SelectionOptions from "../../SelectionOptions";
import { severities } from "src/constants/general";

const VulnerabilityRisks = () => {
  const {
    period,
    selectedVRSeverity,
    setSelectedVRSeverity,
    selectedVRCVE,
    setSelectedVRCVE,
  } = useSummaryStore();
  const { spotlightSearchString } = useGeneralStore();
  const { graphInfo, setGraphInfo, snapshotTime } = useGraphStore();

  const [selectedSource, setSelectedSource] = useState<string>("");
  const [selectedVersion, setSelectedVersion] = useState<string>("");
  const [selectedIntegrationType, setSelectedIntegrationType] =
    useState<string>("");
  const [selectedSourceAccountID, setSelectedSourceAccountID] =
    useState<string>("");
  const [query, setQuery] = useState<string>("");

  const { data: vrSummary } = GetVRSummary(
    period,
    selectedSource,
    selectedVersion,
    selectedIntegrationType,
    selectedSourceAccountID
  );
  const { data: severityDistribution } = GetVRSeverityDistribution(
    period,
    selectedSource,
    selectedVersion,
    selectedIntegrationType,
    selectedSourceAccountID
  );

  const sortedLayers = sortNumericData(vrSummary?.data, "order", "desc");
  const cveList = vrSummary?.data.find(
    (d: { severity: string }) => d.severity === selectedVRSeverity
  )?.list_of_cves;

  const filteredCVEList =
    query !== ""
      ? cveList?.filter((cve: KeyStringVal) => {
          return cve.cve_id
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""));
        })
      : cveList;

  useEffect(() => {
    if (spotlightSearchString === "") setSelectedVRCVE("");
  }, [period]);

  useEffect(() => {
    setSelectedVRSeverity("CRITICAL");
    setSelectedVRCVE("");
  }, [
    selectedSource,
    selectedVersion,
    selectedIntegrationType,
    selectedSourceAccountID,
  ]);

  return (
    <SummaryLayout name="Vulnerability Risks">
      {vrSummary?.summary?.live_vulns_exposures === 0 ? (
        <section className="grid gap-2 w-full h-full place-content-center">
          <img
            src="/general/landing/summary-holding.svg"
            alt="summary"
            className="w-full h-70"
          />
          <h4 className="text-center">
            The summary is not calculated for one of the following reasons:
          </h4>
          <ul className="grid content-start gap-2 ml-36 list-decimal">
            {[
              "Your infrastructure has no images with vulnerabilities",
              "Your images which have vulnerabilities are not deployed in your infrastructure",
              "Your keys don't have the right permissions",
            ].map((reason, index) => {
              return <li key={index}>{reason}</li>;
            })}
          </ul>
          <article className="flex items-center gap-2 mx-auto">
            <FontAwesomeIcon icon={faSmileWink} className="text-yellow-500" />
            <p>Reach out and we'll help you get started!</p>
          </article>
        </section>
      ) : (
        <section className="grid content-start gap-4 w-full h-full">
          <SelectionOptions
            short="vr"
            long="vulnerability-risks"
            selectedSource={selectedSource}
            setSelectedSource={setSelectedSource}
            selectedVersion={selectedVersion}
            setSelectedVersion={setSelectedVersion}
            selectedIntegrationType={selectedIntegrationType}
            setSelectedIntegrationType={setSelectedIntegrationType}
            selectedSourceAccountID={selectedSourceAccountID}
            setSelectedSourceAccountID={setSelectedSourceAccountID}
          />
          <Overview
            vrSummary={vrSummary}
            severityDistribution={severityDistribution}
          />
          <section className="grid content-start gap-4">
            {vrSummary ? (
              <ul
                className={`grid sm:grid-cols-2 md:flex flex-row-reverse items-stretch gap-4 p-4 cursor-pointer ${
                  selectedVRSeverity !== "" &&
                  "dark:bg-tooltip border-l dark:border-blue-600"
                } rounded-2xl`}
              >
                {severities.map((severity: string) => {
                  const count =
                    sortedLayers?.find(
                      (layer: KeyStringVal) =>
                        layer.severity === severity.toUpperCase()
                    )?.count || 0;
                  return (
                    <li
                      key={severity}
                      className={`grid place-content-center p-2 w-full h-full break-all text-center ${
                        severityColors[severity]
                      } ${
                        selectedVRSeverity === severity.toUpperCase()
                          ? "outer-ring"
                          : ""
                      }`}
                      onClick={() => {
                        setSelectedVRSeverity(severity.toUpperCase());
                        setSelectedVRCVE("");
                      }}
                    >
                      <h4 className="break-all">{severity.toUpperCase()}</h4>
                      <p>{count}</p>
                    </li>
                  );
                })}
              </ul>
            ) : null}
            <section className="flex items-start gap-4">
              <CVEList
                headers={vrSummary?.metadata.headers}
                filteredCVEList={filteredCVEList}
                query={query}
                setQuery={setQuery}
              />
              {filteredCVEList?.length > 0 && selectedVRCVE !== "" && (
                <CVEDetail
                  selectedSource={selectedSource}
                  selectedVersion={selectedVersion}
                  selectedIntegrationType={selectedIntegrationType}
                  selectedSourceAccountID={selectedSourceAccountID}
                />
              )}
            </section>
          </section>
        </section>
      )}
      <AnimatePresence exitBeforeEnter>
        {graphInfo.showPanel && (
          <DetailPanel
            graphType="main"
            graphInfo={graphInfo}
            setGraphInfo={setGraphInfo}
            curSnapshotTime={convertToMicrosec(snapshotTime)}
            curSearchSnapshot={null}
          />
        )}
      </AnimatePresence>
    </SummaryLayout>
  );
};

export default VulnerabilityRisks;
