import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { GetGRCImages } from "src/services/grc";
import { KeyStringVal } from "src/types/general";
import ViewInFile from "../ViewInFile/ViewInFile";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { checkIsReadOnly } from "src/utils/general";

const Images = ({ documentID }: { documentID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const { data: images } = GetGRCImages(documentID);
  return (
    <>
      {images ? (
        images.length > 0 ? (
          <ul className="flex flex-col flex-grow gap-4 pb-24">
            {images?.map((image: KeyStringVal) => (
              <li
                key={image.image_id}
                className="flex items-start gap-10 p-6 dark:bg-gray-800 rounded-xl"
              >
                <article className="flex flex-col flex-grow gap-4 w-64 h-80">
                  <img
                    src={image.image_signed_uri}
                    alt="preview"
                    className="w-full h-full"
                  />
                  <article className="flex items-center gap-4 mx-auto">
                    <button
                      disabled={isReadOnly}
                      className="small-black-button"
                      onClick={() => {
                        let a = document.createElement("a");
                        a.href = image.image_signed_uri;
                        a.target = "_blank";
                        a.click();
                      }}
                    >
                      <FontAwesomeIcon icon={faDownload} /> Download as File
                    </button>
                    <ViewInFile
                      generatedID={image.image_id}
                      section={image}
                      bbox={image.page_metadata}
                    />
                  </article>
                </article>
                <article className="flex flex-col flex-grow gap-3 text-b1-reg">
                  <span className="px-2 py-1 w-max dark:text-blue-500 dark:bg-[#3B82F6]/20 border dark:border-blue-500 rounded">
                    Page {image.page_num}
                  </span>
                  {image.image_description && (
                    <article className="grid gap-2">
                      <h4>Uno's Analysis</h4>
                      <ReactMarkdown
                        className="markdown"
                        remarkPlugins={[remarkGfm]}
                      >
                        {image.image_description}
                      </ReactMarkdown>
                    </article>
                  )}
                </article>
              </li>
            ))}
          </ul>
        ) : (
          <p>No images found</p>
        )
      ) : null}
    </>
  );
};

export default Images;
