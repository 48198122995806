import { useQuery } from "react-query";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion } from "src/constants/general";

const prefix = "grc/grc-controls/controls/analytics";

export const GetKeyControlCounts = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-key-control-counts"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/key-controls-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const GetTotalControlCounts = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-total-control-counts"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/controls-count`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetFailedAttestation = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-failed-attestation"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/failed-attestation`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetControlsByState = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-controls-by-state"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/count-by-state`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetControlIssuesByMonth = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-issues-by-month"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/issue-count-by-month`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetControlByRisks = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-by-risks"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/control-count-by-risk`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetFrameworkControlCoverage = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-framework-control-coverage"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/framework-control-coverage`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );

export const GetControlByClassification = () =>
  useQuery<any, unknown, any, (string | number | string[] | undefined)[]>(
    ["get-control-by-classification"],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${prefix}/controls-by-classification`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: false,
    }
  );
