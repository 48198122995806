/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { GetAdminUsers, GetAllUsers } from "../../../services/settings/users";
import { sortRows } from "../../../utils/general";
import UserActionFilter from "../../../components/Filter/Settings/UserActionFilter";
import { activationColors } from "../../../constants/settings";
import { Customer, User } from "../../../types/settings";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import { checkIsSuperOrSiteAdmin, getCustomerID } from "../../../utils/general";
import { ListHeader } from "../../../types/general";
import TableLayout from "../../../layouts/TableLayout";
import SortColumn from "src/components/Button/SortColumn";
import { GetCustomers } from "src/services/settings/organization";
import { initialSort, pageSize } from "src/constants/general";
import RowValue from "src/components/General/RowValue";
import TablePagination from "src/components/General/TablePagination";

export const userHeaders = [
  { display_name: "Customer Name", property_name: "customer_id" },
  { display_name: "User Name", property_name: "user_name" },
  { display_name: "Email Address", property_name: "user_email" },
  { display_name: "Groups", property_name: "groups" },
  { display_name: "Role", property_name: "roles" },
  { display_name: "Status", property_name: "activated" },
];

const Users = () => {
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const [query, setQuery] = useState<string>("");
  const [multiSelect, setMultiSelect] = useState<string[]>([]);
  const [sort, setSort] = useState(initialSort);
  const [pageNumber, setPageNumber] = useState<number>(1);

  const { data: allCustomers } = GetCustomers(isSuperOrSiteAdmin);
  const { data: allUsers } = GetAllUsers(getCustomerID(), isSuperOrSiteAdmin);
  const { data: allAdminUsers } = GetAdminUsers(isSuperOrSiteAdmin);

  const filteredList = isSuperOrSiteAdmin ? allAdminUsers : allUsers;
  const filteredUsers =
    filteredList?.filter((user: User) =>
      user.user_name
        .toLowerCase()
        .replaceAll(/\s+/g, "")
        .includes(
          query.replaceAll('"', "").toLowerCase().replaceAll(/\s+/g, "")
        )
    ) || [];

  const sortedUsers = sortRows(filteredUsers, sort);
  const totalCount = sortedUsers.length;
  const totalPages = Math.ceil(totalCount / pageSize);
  const beginning = pageNumber === 1 ? 1 : pageSize * (pageNumber - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;

  return (
    <section className="grid content-start gap-6 pb-24 w-full h-full">
      <header className="flex items-center justify-between gap-10">
        <h4 className="text-subt1-semi">Users ({sortedUsers?.length || 0})</h4>
        <AddUser />
      </header>
      <header className="flex justify-between gap-10">
        <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="filter"
            autoComplete="off"
            spellCheck="false"
            placeholder="Filter users by name"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
        </article>
        {multiSelect.length > 0 && (
          <UserActionFilter
            list={["Activate", "Deactivate"]}
            multiSelect={multiSelect}
            setMultiSelect={setMultiSelect}
          />
        )}
      </header>

      {/* user table */}
      <TableLayout fullHeight>
        <thead className="sticky -top-1.5 dark:bg-gray-900">
          <tr>
            <th className="px-5 w-5 -mt-1">
              <input
                type="checkbox"
                checked={
                  sortedUsers?.length > 0 && multiSelect.length === totalCount
                }
                className="white-checkbox"
                onChange={(e) => {
                  e.stopPropagation();
                  if (multiSelect.length < totalCount) {
                    setMultiSelect(
                      sortedUsers?.map((user: User) => user.user_id)
                    );
                  } else setMultiSelect([]);
                }}
              />
            </th>
            {userHeaders.map((col: ListHeader) => {
              if (!isSuperOrSiteAdmin && col.property_name === "customer_id")
                return null;
              return (
                <th
                  scope="col"
                  key={col.display_name}
                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                >
                  <article className="capitalize flex gap-10 justify-between">
                    <h4>{col.display_name}</h4>
                    <SortColumn
                      propertyName={col.property_name}
                      setSort={setSort}
                    />
                  </article>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="divide-y dark:divide-gray-700">
          {sortedUsers?.map((user: User) => {
            return (
              <tr
                key={user.user_id}
                className="relative p-5 gap-3 break-words dark:bg-gray-800"
              >
                <td className="px-5 w-5">
                  <input
                    type="checkbox"
                    checked={multiSelect.includes(user.user_id)}
                    className="white-checkbox"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                    onChange={() => {
                      if (multiSelect.includes(user.user_id))
                        setMultiSelect(
                          multiSelect.filter(
                            (userID) => userID !== user.user_id
                          )
                        );
                      else setMultiSelect([...multiSelect, user.user_id]);
                    }}
                  />
                </td>
                {userHeaders.map((col: ListHeader, colIndex: number) => {
                  if (
                    !isSuperOrSiteAdmin &&
                    col.property_name === "customer_id"
                  )
                    return null;
                  return (
                    <td
                      key={`${user.user_id}-${colIndex}`}
                      className="relative px-6 py-3 last:pr-16 text-left break-words"
                    >
                      {col.property_name === "groups" ? (
                        <ul className="flex flex-wrap items-center gap-2 text-left">
                          {user[col.property_name].map(
                            (group: {
                              group_id: string;
                              group_name: string;
                            }) => {
                              return (
                                <li
                                  key={group.group_id}
                                  className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded"
                                >
                                  {group.group_name}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      ) : col.property_name === "roles" ? (
                        <ul className="flex flex-wrap items-center gap-2 text-left">
                          {user[col.property_name].map(
                            (role: {
                              role_id: string;
                              role_name: string;
                              role_type: string;
                            }) => {
                              return (
                                <li
                                  key={role.role_id}
                                  className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded"
                                >
                                  {role.role_name}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      ) : col.property_name === "customer_id" ? (
                        allCustomers?.find(
                          (customer: Customer) =>
                            customer.customer_id === user[col.property_name]
                        )?.customer_name
                      ) : col.property_name === "activated" ? (
                        <p
                          className={`w-max ${
                            activationColors[
                              user.activated ? "activated" : "deactivated"
                            ]
                          }`}
                        >
                          {user.activated ? "Activated" : "Deactivated"}
                        </p>
                      ) : (
                        <RowValue row={user} col={col.property_name} />
                      )}
                      {colIndex === userHeaders.length - 1 && (
                        <EditUser user={user} />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </TableLayout>
      <TablePagination
        totalPages={totalPages}
        beginning={beginning}
        end={end}
        totalCount={totalCount}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </section>
  );
};

export default Users;
