import React from "react";
import DonutChart from "src/components/Chart/DonutChart";
import { GetVendorRisksByState } from "src/services/third-party-risk/vendors/vendor-dashboard";

const VendorRisksByState = () => {
  const { data } = GetVendorRisksByState();

  return (
    <section className="flex flex-col flex-grow gap-2 p-4 dark:bg-gray-800 rounded-lg">
      <DonutChart data={data} xKey="name" title="Vendor Risks by State" />
    </section>
  );
};

export default VendorRisksByState;
