/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetCustomerEnvs,
  UpdateCustomerEnv,
} from "../../../services/settings/environments";
import { getCustomerID } from "../../../utils/general";
import ModalLayout from "../../../layouts/ModalLayout";
import { queryClient } from "src/App";
import { CustomerEnv } from "src/types/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import { KeyStringVal } from "src/types/general";

const RenameEnvironment = ({ envType }: { envType: string }) => {
  const customerID = getCustomerID();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<KeyStringVal>({ name: "" });

  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const updateCustomerEnv = UpdateCustomerEnv(customerID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        className="white-text-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs({ name: "" });
        }}
      >
        <FontAwesomeIcon icon={faEdit} className="w-4 h-4" />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <h4 className="text-subt1-semi">Rename Environment</h4>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                handleOnClose();
                updateCustomerEnv.mutate(
                  {
                    customerEnvID: customerEnvs?.find(
                      (customerEnv: CustomerEnv) =>
                        customerEnv.env_type === envType
                    )?.env_id,
                    envType: { env_type: inputs.name },
                  },
                  {
                    onSuccess: () =>
                      queryClient.invalidateQueries(["get-customer-envs"]),
                  }
                );
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default RenameEnvironment;
