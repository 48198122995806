import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import UserInput from "../../../../../components/Input/UserInput";
import CategoryInput from "src/components/Input/CategoryInput";
import { EditControlIssue } from "src/services/erc/controls/control-issues";
import { checkIsReadOnly } from "src/utils/general";

const UpdateControlIssue = ({
  controlIssueID,
  controlIssue,
}: {
  controlIssueID: string;
  controlIssue: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const editControlIssue = EditControlIssue(controlIssueID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="edit-button"
        onClick={(e) => {
          e.stopPropagation();
          setShow(true);
          setInputs(controlIssue);
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Control Issue
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={[
                "OPEN",
                "IN_PROGRESS",
                "RESOLVED",
                "IN_REVIEW",
                "REOPENED",
                "CLOSED",
                "ARCHIVED",
              ]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <UserInput
              label="Owner"
              keyName="owner"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
          </section>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <RegularInput
            label="Notes"
            keyName="notes"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editControlIssue.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateControlIssue;
