import React from "react";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import { KeyStringVal } from "src/types/general";
// import DiscussionNotes from "./DiscussionNotes/DiscussionNotes";
import NewQuestion from "./NewQuestion";
import { GetMyQuestions } from "src/services/regulation-policy/policy";
import Source from "src/pages/QuestionnaireRFP/Assessments/AssessmentDetail/ResponseList/Source";

const MyQuestions = ({ policyVersionID }: { policyVersionID: string }) => {
  const { data: myQuestions } = GetMyQuestions(policyVersionID);

  return (
    <aside className="flex flex-col flex-grow gap-2 p-3 w-full min-h-full dark:text-white overflow-x-hidden overflow-y-auto scrollbar rounded-md">
      <NewQuestion policyVersionID={policyVersionID} />
      {myQuestions?.data?.map((category: any, index: number) => (
        <article key={index} className="grid gap-4 p-3">
          <header className="flex items-center justify-between gap-4">
            <p>
              <span className="px-3 dark:bg-blue-600 rounded-md">
                {category.co_sections}
              </span>{" "}
              item{category.co_sections !== 1 && "s"} to review
            </p>
          </header>
          <section className="grid gap-3">
            {category.sub_categories.map(
              (question: any, questionIndex: number) => (
                <Disclosure key={questionIndex}>
                  {({ open }) => {
                    return (
                      <section className="grid gap-2 p-3 dark:bg-gray-800 rounded-md">
                        <header className="flex items-start gap-2 text-base">
                          <h4 className="text-left">Q: {question.question}</h4>
                          <Disclosure.Button>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                        </header>
                        {/* <article className="flex items-center gap-4">
                          {["Discussion"].map((tab) => {
                            return (
                              <button
                                key={tab}
                                className="flex items-center gap-1 text-b2-reg"
                                onClick={() => {
                                  setShowGRCPanel(!showGRCPanel);
                                  setSelectedGRCPanelTab(tab);
                                  setSelectedAnchorID(question.anchor_id);
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={
                                    tab === "Notes" ? faNoteSticky : faComment
                                  }
                                  className="p-1 w-3 h-3 dark:text-black dark:bg-white rounded-full"
                                />
                                {tab}
                              </button>
                            );
                          })}
                        </article> */}
                        <Transition
                          enter="transition duration-100 ease-out"
                          enterFrom="transform scale-95 opacity-0"
                          enterTo="transform scale-100 opacity-100"
                          leave="transition duration-75 ease-out"
                          leaveFrom="transform scale-100 opacity-100"
                          leaveTo="transform scale-95 opacity-0"
                        >
                          <Disclosure.Panel>
                            <section className="grid gap-4 p-4 bg-gradient-to-br dark:from-checkbox/30 dark:to-white/10 rounded-md">
                              <article className="flex gap-2 text-base p-3 break-words dark:bg-black/60 rounded-md">
                                {question.answer !== ""
                                  ? `A: ${question.answer}`
                                  : "Question is currently being processed"}
                              </article>
                              {question.sources?.length > 0 &&
                                question.sources.some(
                                  (source: KeyStringVal) => source.content
                                ) && (
                                  <section className="grid gap-2">
                                    <Disclosure defaultOpen>
                                      {({ open }) => {
                                        return (
                                          <section className="grid gap-2 text-b1-reg">
                                            <Disclosure.Button className="flex items-center gap-2 w-max">
                                              <h4>Sources</h4>
                                              <FontAwesomeIcon
                                                icon={
                                                  open
                                                    ? faChevronDown
                                                    : faChevronRight
                                                }
                                              />
                                            </Disclosure.Button>
                                            <Transition
                                              show={open}
                                              enter="transition duration-100 ease-out"
                                              enterFrom="transform scale-95 opacity-0"
                                              enterTo="transform scale-100 opacity-100"
                                              leave="transition duration-75 ease-out"
                                              leaveFrom="transform scale-100 opacity-100"
                                              leaveTo="transform scale-95 opacity-0"
                                            >
                                              <Disclosure.Panel>
                                                <section className="flex flex-wrap items-center gap-4">
                                                  {question.sources.map(
                                                    (
                                                      source: KeyStringVal,
                                                      sourceIndex: number
                                                    ) => {
                                                      return (
                                                        <Source
                                                          key={sourceIndex}
                                                          sourceIndex={
                                                            sourceIndex
                                                          }
                                                          source={source}
                                                        />
                                                      );
                                                    }
                                                  )}
                                                </section>
                                              </Disclosure.Panel>
                                            </Transition>
                                          </section>
                                        );
                                      }}
                                    </Disclosure>
                                  </section>
                                )}
                            </section>
                          </Disclosure.Panel>
                        </Transition>
                        {/* <AnimatePresence exitBeforeEnter>
                          {showGRCPanel &&
                            selectedAnchorID === question.anchor_id && (
                              <DiscussionNotes
                                documentID={documentID}
                                anchorID={question.anchor_id}
                                title={question.question}
                              />
                            )}
                        </AnimatePresence> */}
                      </section>
                    );
                  }}
                </Disclosure>
              )
            )}
          </section>
        </article>
      ))}
    </aside>
  );
};

export default MyQuestions;
