import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import EntityMetadata from "./EntityMetadata";
import ControlList from "./ControList";
import AssociateVendorWithEntityFilter from "src/components/Filter/ERC/AssociateVendorWithEntityFilter";
import AssessmentList from "./AssessmentList";
import AttachmentList from "./Attachments/AttachmentList";

const Entity = () => {
  const parsed = parseURL();

  const entityID = String(parsed.entity_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "entities";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <EntityMetadata entityID={entityID} />
        <AssociateVendorWithEntityFilter entityID={entityID} />
        <AttachmentList entityID={entityID} />
        <ControlList entityID={entityID} />
        <AssessmentList entityID={entityID} />
      </main>
    </PageLayout>
  );
};

export default Entity;
