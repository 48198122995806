/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import EvidenceDetail from "./EvidenceDetail";
import { GetControlLifecycleEvidenceList } from "src/services/erc/controls/controls";

const EvidenceList = ({ controlID }: { controlID: string }) => {
  const { data: evidenceList } = GetControlLifecycleEvidenceList(controlID);

  return (
    <>
      <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
        <h4 className="text-t1-semi">Evidences ({evidenceList?.length})</h4>
        {evidenceList?.length > 0 && (
          <ul className="flex flex-wrap gap-2">
            {evidenceList.map((evidence: any) => {
              return (
                <EvidenceDetail
                  key={evidence.evidence_id}
                  evidence={evidence}
                />
              );
            })}
          </ul>
        )}
      </section>
    </>
  );
};

export default EvidenceList;
