/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useERCStore } from "src/stores/erc";
import AttachmentList from "./AttachmentList/AttachmentList";
import DocumentRequestList from "./DocumentRequestList/DocumentRequestList";
import NewAttachment from "./AttachmentList/NewAttachment";
import NewRequest from "./DocumentRequestList/NewRequest/NewRequest";

const Attachments = () => {
  const { selectedERCTab, setSelectedERCTab } = useERCStore();

  useEffect(() => {
    if (sessionStorage.selectedERCTab)
      setSelectedERCTab(sessionStorage.selectedERCTab);
    if (selectedERCTab === "") setSelectedERCTab("attachments");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["attachments", "document requests"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedERCTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => {
                  setSelectedERCTab(tab);
                  sessionStorage.selectedERCTab = tab;
                }}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedERCTab === "attachments" ? <NewAttachment /> : <NewRequest />}
      </header>
      {selectedERCTab === "attachments" ? (
        <AttachmentList />
      ) : selectedERCTab === "document requests" ? (
        <DocumentRequestList />
      ) : null}
    </section>
  );
};

export default Attachments;
