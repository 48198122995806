/* eslint-disable react-hooks/exhaustive-deps */

import PropertyFilter from "src/components/Filter/Import/PropertyFilter";
import TableLayout from "src/layouts/TableLayout";
import { drlPrefix } from "src/services/erc/attachments/document-requests";
import { GetTableColumns } from "src/services/grc";

const Columns = ({
  fileInfo,
  setFileInfo,
  sheet,
}: {
  fileInfo: any;
  setFileInfo: any;
  sheet: any;
}) => {
  const info = fileInfo[sheet];

  const { data: fieldValues } = GetTableColumns(drlPrefix);

  return (
    <section className="flex flex-col flex-grow gap-3">
      <TableLayout fullHeight>
        <thead className="sticky -top-1.5 text-b2-reg dark:bg-gray-900">
          <tr>
            {[
              "Column Header From File",
              "Preview Information",
              "Uno Property",
            ]?.map((col: string) => {
              return (
                <th
                  scope="col"
                  key={col}
                  className="px-6 py-3 last:pr-10 w-full text-left font-semibold"
                >
                  <h4 className="break-words">{col}</h4>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="text-b2-reg divide-y dark:divide-gray-700">
          {info?.map((row: any, rowIdx: number) => {
            return (
              <tr
                key={rowIdx}
                data-test="table-row"
                className="relative p-5 gap-3 break-words dark:bg-gray-800"
              >
                <td className="relative px-6 py-3 last:pr-16 text-left break-words">
                  {row.column}
                </td>
                <td className="relative px-6 py-3 last:pr-16 text-left break-words">
                  <ul className="flex flex-col flex-grow gap-1 px-3 list-disc">
                    {row.preview.map((preview: string, idx: number) => (
                      <li key={idx}>{preview}</li>
                    ))}
                  </ul>
                </td>
                <td className="relative px-6 py-3 last:pr-16 text-left break-words">
                  <PropertyFilter
                    sheet={sheet}
                    rowIdx={rowIdx}
                    keyName="property"
                    list={fieldValues}
                    fileInfo={fileInfo}
                    setFileInfo={setFileInfo}
                    allowUnselect
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </TableLayout>
    </section>
  );
};

export default Columns;
