/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import {
  AddEntityToEntityType,
  RemoveEntityFromEntityType,
} from "../../../services/erc/entities/entity-types";
import { GetEntities } from "../../../services/erc/entities/entities";
import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import { checkIsReadOnly } from "src/utils/general";

const AddEntityFilter = ({
  label,
  members,
  entityTypeID,
}: {
  label?: string;
  members: KeyStringVal[];
  entityTypeID: string;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: entities } = GetEntities();
  const addEntityToEntityType = AddEntityToEntityType();
  const removeEntityToEntityType = RemoveEntityFromEntityType();

  const filteredItems = entities?.data.filter((item: KeyStringVal) =>
    item.name
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  return (
    <article className="flex items-center gap-3 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
      </header>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-[10rem] h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            disabled={isReadOnly}
            autoComplete="off"
            spellCheck="false"
            placeholder={`${members?.length || 0} selected`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {!isReadOnly && showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {filteredItems?.map((entity: KeyStringVal, index: number) => {
              const selected = members?.some(
                (member) => member.grc_entity_id === entity.grc_entity_id
              );
              if (
                entity.entity_type_id &&
                entity.entity_type_id !== entityTypeID
              )
                return null;

              return (
                <button
                  key={index}
                  className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    if (selected)
                      removeEntityToEntityType.mutate({
                        entityTypeID: entityTypeID,
                        entity: [entity.grc_entity_id],
                      });
                    else
                      addEntityToEntityType.mutate({
                        entityTypeID: entityTypeID,
                        entity: [entity.grc_entity_id],
                      });
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} />}
                  <p>{entity.name}</p>
                </button>
              );
            })}
          </article>
        )}
      </article>
    </article>
  );
};

export default AddEntityFilter;
