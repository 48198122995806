/* eslint-disable no-restricted-globals */
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { showVariants } from "../../../../constants/general";
import { KeyStringVal } from "../../../../types/general";
import { sortNumericData } from "../../../../utils/general";
import { GetAllReports } from "../../../../services/regulation-policy/overview";
import React from "react";
import Loader from "src/components/Loader/Loader";

const Summaries: React.FC = () => {
  const navigate = useNavigate();

  const { data: reports, status: reportStatus } = GetAllReports();

  return (
    <motion.main
      variants={showVariants}
      initial="hidden"
      animate="visible"
      className="flex flex-col flex-grow gap-4 pb-24 w-full h-full dark:text-white font-light"
    >
      {reportStatus === "loading" ? (
        <Loader />
      ) : reportStatus === "success" ? (
        <section className="flex flex-col flex-grow gap-4">
          {reports &&
            Object.keys(reports).map((companyName) => {
              return (
                <section key={companyName} className="grid gap-2">
                  <h4 className="text-h5">{companyName}</h4>
                  <section className="grid items-stretch gap-4 pb-4">
                    {Object.keys(reports[companyName])?.map(
                      (reportType: string) => {
                        const filteredReports = sortNumericData(
                          reports[companyName][reportType],
                          "coverage",
                          "desc"
                        );
                        return (
                          <section
                            key={reportType}
                            className="grid content-start gap-3 p-6 dark:bg-gray-800 rounded-xl"
                          >
                            <h4 className="text-b1-semi capitalize">
                              {reportType.replaceAll("_", " ")}
                            </h4>

                            {filteredReports?.length > 0 ? (
                              <ul className="grid md:grid-cols-3 gap-4 content-start">
                                {filteredReports?.map(
                                  (report: KeyStringVal) => {
                                    return (
                                      <button
                                        key={report.id}
                                        className="grid gap-8 px-4 py-2 text-left disabled:cursor-auto disabled:dark:text-gray-700 disabled:dark:bg-gray-900/40 cursor-pointer dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 border-b-1 dark:border-gray-700/70 rounded-lg"
                                        onClick={() =>
                                          navigate(
                                            `/regulation-policy/report/details?report_type=${reportType}&report_name=${report.name}&report_id=${report.id}&coverage=${report.coverage}`
                                          )
                                        }
                                      >
                                        <h4 className="text-b1-reg">
                                          {report.name}
                                        </h4>
                                        <span className="text-h4 dark:text-blue-500">
                                          {report.coverage}%
                                        </span>
                                      </button>
                                    );
                                  }
                                )}
                              </ul>
                            ) : (
                              <p className="dark:text-gray-500">No reports</p>
                            )}
                          </section>
                        );
                      }
                    )}
                  </section>
                </section>
              );
            })}
        </section>
      ) : null}
    </motion.main>
  );
};

export default Summaries;
