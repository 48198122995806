import React, { useState } from "react";
import AuditReportDetail from "./AuditReportDetail/AuditReportDetail";
import AuditReportMetadata from "./AuditReportMetadata";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";

const AuditReport = () => {
  const parsed = parseURL();

  const [editSections, setEditSections] = useState<any>({});

  const documentType = "frameworks";
  const documentID = String(parsed.framework_id) || "";
  const reviewID = String(parsed.review_id) || "";
  const auditID = String(parsed.audit_id) || "";

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 px-5 pt-5 pb-24 h-full w-full overflow-auto scrollbar">
        <AuditReportMetadata
          documentType={documentType}
          documentID={documentID}
          reviewID={reviewID}
        />
        <AuditReportDetail
          documentType={documentType}
          documentID={documentID}
          reviewID={reviewID}
          auditID={auditID}
          editSections={editSections}
          setEditSections={setEditSections}
        />
      </main>
    </PageLayout>
  );
};

export default AuditReport;
