/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import Coverage from "./Coverage/Coverage";
import ControlList from "./ControlList";

const ControlsLanding = () => {
  const [selectedTab, setSelectedTab] = useState("controls");

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {["controls", "coverage"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
      </header>
      {selectedTab === "controls" ? <ControlList /> : <Coverage />}
    </section>
  );
};

export default ControlsLanding;
