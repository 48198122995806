import { useState } from "react";
import Loader from "src/components/Loader/Loader";
import ModalLayout from "src/layouts/ModalLayout";
import { GetVideoWalkthroughList } from "src/services/general/general";
import { sortRows } from "src/utils/general";

const Videos = () => {
  const [show, setShow] = useState<string>("");

  const { data: videos, status: videoStatus } = GetVideoWalkthroughList();

  const sortedVideos =
    videos &&
    sortRows(videos, {
      order: "desc",
      orderBy: "last_update_at",
    });

  return (
    <section className="flex flex-col flex-grow gap-4 mx-6 my-4">
      {/* list of explanatory video */}
      {videoStatus === "loading" ? (
        <Loader />
      ) : videos?.length > 0 ? (
        <section className="grid grid-cols-4 items-center gap-4">
          {sortedVideos?.map((video, idx) => {
            return (
              <article key={idx} className="flex flex-col flex-grow gap-2">
                <h4 className="text-b1-semi">{video.name}</h4>
                <a
                  href={video.video_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src={video.thumbnail_url}
                    alt="thumbnail"
                    className="w-full h-60 rounded-lg"
                  />
                </a>
                <caption>{video.description}</caption>
                {/* <button onClick={() => setShow(video.video_url)}>
                  <img
                    src={video.thumbnail_url}
                    alt="thumbnail"
                    className="w-60 h-60 rounded-lg"
                  />
                </button>
                <ModalLayout
                  showModal={show === video.video_url}
                  onClose={() => setShow("")}
                >
                  <section className="relative flex flex-col flex-grow gap-4">
                    <h4 className="text-subt1-semi">Video</h4>
                    <article className="relative h-screen">
                      <iframe
                        src={video.video_url}
                        loading="lazy"
                        title="Regulation & Policy: #1 Navigating Frameworks"
                        allow="clipboard-write"
                        frameborder="0"
                        webkitallowfullscreen="true"
                        mozallowfullscreen="true"
                        allowfullscreen
                        className="absolute top-0 bottom-0 left-0 right-0 m-0 p-0 w-full h-full"
                      ></iframe>
                    </article>
                  </section>
                </ModalLayout> */}
              </article>
            );
          })}
        </section>
      ) : (
        <p className="text-b1-reg">No videos found</p>
      )}
    </section>
  );
};

export default Videos;
