import React, { useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";
import { UploadSOPVersion } from "src/services/business-continuity/sop";
import Tags from "./Tags";
import MultipleFileInput from "src/components/Input/MultipleFileInput";
import { checkIsReadOnly } from "src/utils/general";
import ToastLayout from "src/layouts/ToastLayout";
import RadioInput from "src/components/Input/RadioInput";

const NewSOP = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    sop_id: "",
    sop_name: "",
    sop_version: "",
    files: [],
    file_uri: "",
    selected_tag: "",
    entered_tag: "",
  });

  const addSOP = UploadSOPVersion();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addSOP.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({});
        }}
      >
        <FontAwesomeIcon icon={faUpload} />
        <h4>Upload Procedure</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 h-full overflow-auto scrollbar">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Upload Procedure
          </h3>
          <RadioInput
            label="Select"
            keyName="option"
            list={["Upload a new procedure", "Upload multiple procedures"]}
            inputs={inputs}
            setInputs={setInputs}
          />
          {inputs.option !== "" && (
            <>
              {inputs.option !== "Upload multiple procedures" && (
                <section className="grid md:grid-cols-2 gap-10">
                  <RegularInput
                    label="Procedure Name"
                    keyName="sop_name"
                    inputs={inputs}
                    setInputs={setInputs}
                    required
                  />
                  <RegularInput
                    label="Procedure Version"
                    keyName="sop_version"
                    inputs={inputs}
                    setInputs={setInputs}
                    required
                  />
                </section>
              )}
              <Tags inputs={inputs} setInputs={setInputs} />
              {inputs.option !== "Upload multiple procedures" && (
                <RegularInput
                  label="File URL (optional)"
                  keyName="file_uri"
                  inputs={inputs}
                  setInputs={setInputs}
                />
              )}
              <MultipleFileInput
                label="Procedures"
                keyName="files"
                types={["pdf"]}
                inputs={inputs}
                setInputs={setInputs}
              />
              <article className="flex items-center place-content-end gap-4">
                <button className="black-button" onClick={handleOnClose}>
                  Cancel
                </button>
                <button
                  disabled={
                    !inputs.sop_name ||
                    !inputs.sop_version ||
                    (!inputs.files?.length && !inputs.file_uri)
                  }
                  className="blue-button"
                  onClick={() => {
                    const formData = new FormData();

                    if (inputs.sop_name !== "")
                      formData.append("sop_name", inputs.sop_name);
                    if (inputs.sop_version !== "")
                      formData.append("bcm_sop_version", inputs.sop_version);
                    if (inputs.selected_tag || inputs.entered_tag)
                      formData.append(
                        "tag_name",
                        inputs.selected_tag !== ""
                          ? inputs.selected_tag
                          : inputs.entered_tag
                      );
                    if (inputs.files.length > 0)
                      inputs.files.forEach((file: any) =>
                        formData.append("files", file)
                      );
                    if (inputs.file_uri)
                      formData.append("file_uri", inputs.file_uri);

                    addSOP.mutate({
                      formData,
                    });
                    handleOnClose();
                  }}
                >
                  Done
                </button>
              </article>
            </>
          )}
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addSOP.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New SOP has been created"
      />
    </>
  );
};

export default NewSOP;
