/* eslint-disable react-hooks/exhaustive-deps */
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useEffect } from "react";
import { GetFrameworks } from "src/services/regulation-policy/framework";
import { KeyStringVal } from "src/types/general";

const Frameworks = ({
  selectedFrameworkIDs,
  setSelectedFrameworkIDs,
}: {
  selectedFrameworkIDs: string[];
  setSelectedFrameworkIDs: (selectedFrameworkIDs: string[]) => void;
}) => {
  const { data: selectedFrameworks } = GetFrameworks("All");

  const regulatoryAuthorities = [
    ...new Set(
      selectedFrameworks?.data?.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.regulatory_authority],
        []
      )
    ),
  ] as string[];

  useEffect(() => {
    if (selectedFrameworks?.data?.length > 0) {
      const frameworkIDs = selectedFrameworks?.data?.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.id],
        []
      );
      setSelectedFrameworkIDs([...selectedFrameworkIDs, ...frameworkIDs]);
    }
  }, [selectedFrameworks]);

  return (
    <section className="flex flex-col flex-grow">
      <header className="flex items-start gap-4 p-6 text-subt1-semi dark:bg-gray-700 rounded-t-xl">
        <input
          type="checkbox"
          checked={
            selectedFrameworkIDs?.length === selectedFrameworks?.data?.length
          }
          className="white-checkbox"
          onChange={() => {
            if (
              selectedFrameworkIDs?.length === selectedFrameworks?.data?.length
            )
              setSelectedFrameworkIDs([]);
            else {
              const frameworkIDs = selectedFrameworks?.data?.reduce(
                (pV: string[], cV: KeyStringVal) => [...pV, cV.id],
                []
              );
              setSelectedFrameworkIDs(frameworkIDs);
            }
          }}
        />
        <h4>Frameworks</h4>
      </header>
      <ul className="flex flex-col flex-grow gap-4 p-6 mb-20 dark:bg-gray-800 divide-y dark:divide-gray-700 rounded-b-xl">
        {regulatoryAuthorities?.map((auth) => {
          const filteredFrameworks = selectedFrameworks?.data?.filter(
            (framework: KeyStringVal) => framework.regulatory_authority === auth
          );
          const frameworkIDs = filteredFrameworks?.reduce(
            (pV: string[], cV: KeyStringVal) => [...pV, cV.id],
            []
          );

          const allSelected = frameworkIDs.every((frameworkID: string) =>
            selectedFrameworkIDs.includes(frameworkID)
          );

          return (
            <article key={auth} className="grid gap-3 first:pt-0 pt-5">
              <Disclosure defaultOpen>
                {({ open }) => (
                  <>
                    <header className="flex items-center justify-between gap-4">
                      <article className="flex items-start gap-4">
                        <input
                          type="checkbox"
                          checked={allSelected}
                          className="white-checkbox"
                          onChange={() => {
                            if (allSelected)
                              setSelectedFrameworkIDs(
                                selectedFrameworkIDs.filter(
                                  (frameworkID) =>
                                    !frameworkIDs.includes(frameworkID)
                                )
                              );
                            else
                              setSelectedFrameworkIDs([
                                ...selectedFrameworkIDs,
                                ...frameworkIDs,
                              ]);
                          }}
                        />
                        <h3 className="text-lg">{auth}</h3>
                      </article>
                      <Disclosure.Button className="flex items-center justify-between gap-4 text-b1-reg">
                        <FontAwesomeIcon
                          icon={open ? faChevronUp : faChevronDown}
                          className="dark:text-blue-600"
                        />
                      </Disclosure.Button>
                    </header>
                    <Disclosure.Panel className="grid gap-4">
                      <ul className="flex flex-col flex-grow gap-3 pl-8 pb-2 text-b1-reg overflow-auto scrollbar">
                        {filteredFrameworks?.map(
                          (framework: KeyStringVal, index: number) => {
                            const selected = selectedFrameworkIDs.includes(
                              framework.id
                            );
                            return (
                              <li
                                key={index}
                                className="flex flex-row flex-grow items-start gap-4 break-words"
                              >
                                <input
                                  type="checkbox"
                                  checked={selected}
                                  className="white-checkbox"
                                  onChange={() => {
                                    if (selected)
                                      setSelectedFrameworkIDs(
                                        selectedFrameworkIDs.filter(
                                          (frameworkID) =>
                                            framework.id !== frameworkID
                                        )
                                      );
                                    else
                                      setSelectedFrameworkIDs([
                                        ...selectedFrameworkIDs,
                                        framework.id,
                                      ]);
                                  }}
                                />
                                <article className="flex items-start gap-2 px-2 py-1 w-full dark:bg-gray-700 rounded">
                                  <img
                                    src={framework.thumbnail_uri}
                                    alt={framework.regulatory_authority}
                                    className="w-6 h-6 rounded-full"
                                  />
                                  <h4>{framework.name}</h4>
                                </article>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            </article>
          );
        })}
      </ul>
    </section>
  );
};

export default Frameworks;
