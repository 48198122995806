/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import IntegrationDetails from "./IntegrationDetails";
import { getCustomerID, parseURL } from "../../../utils/general";
import { GetAvailableIntegrations } from "../../../services/settings/integrations";
import { AvailableIntegration } from "../../../types/settings";
import { attributeColors } from "src/constants/general";

const Integrations = () => {
  const navigate = useNavigate();
  const parsed = parseURL();
  const customerID = getCustomerID();

  const { data: availableIntegrations } = GetAvailableIntegrations(customerID);

  const categories = [
    ...new Set(
      availableIntegrations?.reduce(
        (pV: string[], cV: AvailableIntegration) => [...pV, cV.category],
        []
      )
    ),
  ] as string[];

  return (
    <>
      {!parsed.integration ? (
        <section className="flex flex-col flex-grow content-start gap-6 pb-24 w-full h-full overflow-auto scrollbar">
          <ul className="grid justify-items-start gap-6 w-full overflow-auto scrollbar">
            {categories?.map((category: string) => {
              const filteredIntegrations = availableIntegrations?.filter(
                (integration: AvailableIntegration) =>
                  integration.category === category
              );
              return (
                <li key={category} className="grid gap-6 w-full">
                  <h4 className="text-subt1-semi">{category}</h4>
                  <section className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 content-start gap-4">
                    {filteredIntegrations?.map(
                      (integration: AvailableIntegration) => {
                        return (
                          <button
                            key={integration.integration_type}
                            disabled={integration.current_state === 0}
                            className="grid gap-4 p-4 dark:disabled:hover:bg-gray-600 dark:bg-gray-800 dark:hover:bg-gray-700/70 duration-100 black-shadow rounded-xl"
                            onClick={() =>
                              navigate(
                                `/settings/details?${queryString.stringify(
                                  parsed
                                )}&integration=${integration.integration_type.toUpperCase()}`
                              )
                            }
                          >
                            <img
                              src={`/general/integrations/${integration.integration_type.toLowerCase()}.svg`}
                              alt={integration.integration_type}
                              className="w-10 h-10 mx-auto"
                            />
                            <p className="text-b1-semi">
                              {integration.description}
                            </p>
                            {integration.current_state === 0 && (
                              <span
                                className={`mx-auto ${attributeColors["disabled"]}`}
                              >
                                DEACTIVATED
                              </span>
                            )}
                          </button>
                        );
                      }
                    )}
                  </section>
                </li>
              );
            })}
          </ul>
        </section>
      ) : (
        <IntegrationDetails />
      )}
    </>
  );
};

export default Integrations;
