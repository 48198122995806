/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { useEffect, useState } from "react";
import { sortRows } from "../../../../utils/general";
import ListLayout from "../../../../layouts/ListLayout";
import { ListHeader, SortRows } from "../../../../types/general";
import { CVEInfo } from "../../../../types/summaries";
import { KeyStringVal } from "src/types/general";
import { useSummaryStore } from "src/stores/summaries";
import { useGeneralStore } from "src/stores/general";

const CVEList = ({
  headers,
  filteredCVEList,
  query,
  setQuery,
}: {
  headers: ListHeader[];
  filteredCVEList: KeyStringVal[];
  query: string;
  setQuery: (query: string) => void;
}) => {
  const { selectedVRSeverity, selectedVRCVE, setSelectedVRCVE } =
    useSummaryStore();
  const { spotlightSearchString } = useGeneralStore();

  const [sort, setSort] = useState<SortRows>({
    order: "asc",
    orderBy: "cve_id",
  });

  const sortedCVEList = filteredCVEList && sortRows(filteredCVEList, sort);

  useEffect(() => {
    if (
      selectedVRSeverity !== "" &&
      selectedVRCVE === "" &&
      spotlightSearchString === "" &&
      sortedCVEList?.length > 0
    )
      setSelectedVRCVE(sortedCVEList[0].cve_id);
  }, [sortedCVEList, selectedVRCVE]);

  useEffect(() => {
    if (sortedCVEList && selectedVRCVE !== "" && spotlightSearchString !== "") {
      document.getElementById(`${selectedVRCVE}`)?.scrollIntoView();
    }
  }, [sortedCVEList]);

  return (
    <section className="grid content-start gap-4 w-max h-full text-sm">
      <article className="grid gap-4">
        <article className="flex items-center gap-2 px-4 dark:bg-account border-l dark:border-blue-600">
          <img
            src="/general/search.svg"
            alt="search"
            className="w-5 h-5 mr-2"
          />

          {/* search cves */}
          <input
            type="input"
            value={query}
            spellCheck="false"
            autoComplete="off"
            placeholder="Search CVE"
            onChange={(e) => setQuery(e.target.value)}
            className="w-full h-10 placeholder:text-sm text-sm dark:placeholder:text-gray-400 dark:bg-account focus:outline-none border-none "
          />
        </article>

        {/* list of cves */}
        {sortedCVEList?.length > 0 ? (
          <ListLayout
            listHeader={headers}
            setSort={setSort}
            height="max-h-[60rem]"
            hideSticky
          >
            {sortedCVEList.map((cve: CVEInfo) => {
              return (
                <tr
                  id={cve.cve_id}
                  key={cve.cve_id}
                  className={`px-4 py-2 cursor-pointer dark:even:bg-card ${
                    selectedVRCVE === cve.cve_id
                      ? "border dark:border-blue-600"
                      : "dark:hover:bg-blue-600/30"
                  }`}
                  onClick={() => setSelectedVRCVE(cve.cve_id)}
                >
                  {headers?.map((col: ListHeader) => {
                    return (
                      <td key={col.property_name} className="py-2 px-5">
                        {cve[col.property_name]}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </ListLayout>
        ) : (
          <p>No CVE found</p>
        )}
      </article>
    </section>
  );
};

export default CVEList;
