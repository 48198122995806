import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ThreatMetadata from "./ThreatMetadata";
import AssociateRiskWithThreatFilter from "src/components/Filter/ERC/AssociateRiskWithThreatFilter";

const Threat = () => {
  const parsed = parseURL();

  const threatID = String(parsed.threat_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "risks";
    sessionStorage.threat_vuln = "threats";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <ThreatMetadata threatID={threatID} />
        <AssociateRiskWithThreatFilter threatID={threatID} />
      </main>
    </PageLayout>
  );
};

export default Threat;
