/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import DocumentNameFilter from "src/components/Filter/RegulationPolicy/DocumentNameFilter";
import DocumentSectionFilter from "src/components/Filter/RegulationPolicy/DocumentSectionFilter";
import ModalLayout from "src/layouts/ModalLayout";
import { AddMapping } from "src/services/grc";
import { GetFrameworks } from "src/services/regulation-policy/framework";
import { GetPolicies } from "src/services/regulation-policy/policy";
import { GetGRCDocumentSectionsControls } from "src/services/regulation-policy/regulation-policy";
import { checkIsReadOnly } from "src/utils/general";

const NewMapping = ({ selectedType }: { selectedType: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    source_document_id: "",
    source_version_id: "",
    target_document_id: sessionStorage.document_id,
    target_document_name: "",
    source_section_generated_id: "",
    target_section_generated_id: sessionStorage.generated_id,
    target_section_title: "",
    content: "",
    section_id: "",
    source_section_id: "",
    source_section_title: "",
    selected_tab: "sections",
  });

  const documentType = sessionStorage.document_type || "";
  const isPolicy = documentType === "policies";
  const type =
    isPolicy || (!isPolicy && selectedType === "RFS")
      ? "frameworks"
      : "policies";
  const policyVersionID = isPolicy ? inputs.source_version_id : "";

  const addMapping = AddMapping();
  const { data: policies } = GetPolicies();
  const { data: frameworks } = GetFrameworks();
  const { data: sections } = GetGRCDocumentSectionsControls(
    "ready",
    type,
    inputs.source_document_id,
    policyVersionID,
    inputs.selected_tab,
    "All"
  );

  const documentList =
    isPolicy || (!isPolicy && selectedType === "RFS")
      ? frameworks?.data
      : policies?.data;

  const handleOnClose = () => {
    setShow(false);
    sessionStorage.removeItem("open_new_mapping");
  };

  useEffect(() => {
    if (sessionStorage.open_new_mapping) setShow(true);
  }, [localStorage]);

  return (
    <section>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            source_document_id: "",
            source_version_id: "",
            target_document_id: sessionStorage.document_id,
            target_document_name: "",
            source_section_generated_id: "",
            target_section_generated_id: sessionStorage.generated_id,
            target_section_title: "",
            content: "",
            section_id: "",
            source_section_id: "",
            source_section_title: "",
            selected_tab: "sections",
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} /> Add New Mapping
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="flex flex-col flex-grow gap-4 h-[30rem]">
          <h4 className="text-base">
            <FontAwesomeIcon icon={faPlus} /> Add New Mapping
          </h4>
          <DocumentNameFilter
            inputs={inputs}
            setInputs={setInputs}
            list={documentList}
          />
          {inputs.source_document_id !== "" && (
            <article className="flex flex-col flex-grow gap-4">
              {isPolicy && (
                <nav className="flex flex-wrap items-center gap-1 p-1 w-max text-b2-reg dark:bg-gray-900 rounded-lg">
                  {["sections", "controls"].map((tab) => {
                    return (
                      <button
                        key={tab}
                        className={`px-4 py-1 text-center capitalize ${
                          inputs.selected_tab === tab
                            ? "dark:text-white dark:bg-gray-800"
                            : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                        } rounded-lg`}
                        onClick={() =>
                          setInputs({ ...inputs, selected_tab: tab })
                        }
                      >
                        {tab}
                      </button>
                    );
                  })}
                </nav>
              )}
              <DocumentSectionFilter
                inputs={inputs}
                setInputs={setInputs}
                list={sections?.data
                  .reduce(
                    (pV: any, cV: any) => [
                      ...pV,
                      cV.sub_sections.map((subSection) => {
                        return { ...subSection, section_id: cV.section_id };
                      }),
                    ],
                    []
                  )
                  .flat()}
              />
            </article>
          )}
          {inputs.content !== "" && (
            <article className="grid gap-2">
              <h4 className="text-subt1-semi">Preview</h4>
              <p className="grid gap-1 px-4 py-2 mr-10 dark:bg-blue-500/30 border dark:border-blue-500 rounded-md">
                <span className="text-b1-reg">{inputs.section_id}</span>
                {inputs.content?.slice(0, 300)}
                {inputs.content?.length > 300 && "......"}
              </p>
            </article>
          )}

          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.source_document_id === "" ||
                inputs.target_document_id === "" ||
                inputs.source_section_generated_id === "" ||
                inputs.target_section_generated_id === ""
              }
              className="blue-button"
              onClick={() => {
                addMapping.mutate({
                  sourceDocumentID: isPolicy
                    ? inputs.source_document_id
                    : inputs.target_document_id,
                  targetDocumentID: isPolicy
                    ? inputs.target_document_id
                    : inputs.source_version_id,
                  sourceSectionID: isPolicy
                    ? inputs.source_section_generated_id
                    : inputs.target_section_generated_id,
                  targetSectionID: isPolicy
                    ? inputs.target_section_generated_id
                    : inputs.source_section_generated_id,
                });
                setShow(false);
              }}
            >
              Add
            </button>
          </article>
        </section>
      </ModalLayout>
    </section>
  );
};

export default NewMapping;
