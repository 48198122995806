import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { GetAuditMetadata } from "src/services/audit-management";

const Filters = ({
  auditID,
  filters,
  setFilters,
}: {
  auditID: string;
  filters: any;
  setFilters: any;
}) => {
  const { data: auditMetadata } = GetAuditMetadata(auditID);

  return (
    <FilterLayout label="Quick Filters">
      <ul className="flex flex-col flex-grow gap-1">
        {["All", "Relevant Sections", "Assigned to Me"].map((filter) => {
          if (
            auditMetadata?.use_organization_controls &&
            ["Relevant Sections"].includes(filter)
          )
            return null;

          return (
            <li key={filter} className="flex items-start gap-2">
              <input
                type="radio"
                className="blue-radio"
                checked={filters.filter === filter}
                onChange={() => setFilters({ ...filters, filter })}
              />
              <label htmlFor="">{filter}</label>
            </li>
          );
        })}
      </ul>
      <ul className="flex flex-col flex-grow gap-1">
        {["hide_excluded", "analysis_generated", "has_evidence"].map(
          (filter) => {
            return (
              <li key={filter} className="flex items-start gap-2">
                <input
                  type="checkbox"
                  className="transparent-checkbox"
                  checked={filters[filter]}
                  onChange={() =>
                    setFilters({
                      ...filters,
                      [filter]: !filters[filter],
                    })
                  }
                />
                <label htmlFor="" className="capitalize">
                  {filter.replaceAll("_", " ")}
                </label>
              </li>
            );
          }
        )}
      </ul>
    </FilterLayout>
  );
};

export default Filters;
