/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { Role, UserDetails } from "../../../types/settings";

const RoleFilter = ({
  allRoles,
  details,
  value,
  setValue,
  list,
  showAbove,
}: {
  allRoles: Role[];
  details: UserDetails;
  value: string;
  setValue: (value: UserDetails) => void;
  list: string[];
  showAbove?: boolean;
}) => {
  return (
    <DropdownLayout label="Role" value={value} showAbove={showAbove} labelAbove>
      {list?.map((item: string, index: number) => {
        const selected = value === item;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              setValue({
                ...details,
                role_ids: [
                  ...new Set([
                    ...details.role_ids,
                    allRoles?.find((role: Role) => role.role_name === item)
                      ?.role_id || "",
                  ]),
                ],
              });
            }}
          >
            <p>{item}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default RoleFilter;
