import React from "react";

const DocumentTabs = ({
  selectedTab,
  setSelectedTab,
}: {
  selectedTab: string;
  setSelectedTab: (selectedTab: string) => void;
}) => {
  return (
    <nav className="flex flex-wrap items-center gap-6 text-b1-reg">
      {["Sections", "Tables", "Images", "Audit Trail", "Verification"].map(
        (tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize ${
                selectedTab === tab
                  ? "text-b1-semi border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          );
        }
      )}
    </nav>
  );
};

export default DocumentTabs;
