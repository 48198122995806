import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ControlBlueprintMetadata from "./ControlBlueprintMetadata";
import AssociateEntityTypeFilter from "../../../../../components/Filter/ERC/AssociateEntityTypeFilter";

const ControlBlueprint = () => {
  const parsed = parseURL();

  const blueprintID = String(parsed.control_blueprint_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "controls";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 min-h-full w-full overflow-auto scrollbar">
        <ControlBlueprintMetadata blueprintID={blueprintID} />
        <AssociateEntityTypeFilter blueprintID={blueprintID} />
      </main>
    </PageLayout>
  );
};

export default ControlBlueprint;
