/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import GeneralSnapshotDatepicker from "src/components/Datepicker/GeneralSnapshotDatepicker";
import RegularInput from "src/components/Input/RegularInput";
import ModalLayout from "src/layouts/ModalLayout";
import { UpdateAssessment } from "src/services/questionnaire-rfp";
import Tags from "./Tags";
import { KeyStringVal } from "src/types/general";
import { checkIsReadOnly, getCustomerID } from "src/utils/general";
import { GetAllUsers } from "src/services/settings/users";
import CheckboxInput from "src/components/Input/CheckboxInput";

const EditAssessment = ({
  selectedAssessment,
  selectedSourceType,
}: {
  selectedAssessment: any;
  selectedSourceType: string;
}) => {
  const customerID = getCustomerID();
  const isReadOnly = checkIsReadOnly();

  const [editAssessmentID, setEditAssessmentID] = useState<string>("");
  const [inputs, setInputs] = useState<any>({});

  const editAssessment = UpdateAssessment();
  const { data: allUsers } = GetAllUsers(customerID, false);

  const handleOnClose = () => setEditAssessmentID("");

  return (
    <>
      <button
        disabled={isReadOnly}
        className="white-text-button"
        onClick={() => {
          setEditAssessmentID(selectedAssessment.assessment_id);
          setInputs({
            ...selectedAssessment,
            owner:
              allUsers?.find(
                (user: KeyStringVal) =>
                  user.user_email === selectedAssessment.owner
              )?.user_id || "",
            reviewer:
              allUsers?.find(
                (user: KeyStringVal) =>
                  user.user_email === selectedAssessment.reviewer
              )?.user_id || "",
            due_date: selectedAssessment.due_date,
            questionnaires: selectedAssessment.questionnaires || [],
            source_type: selectedSourceType,
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
        Edit
      </button>
      <ModalLayout
        showModal={editAssessmentID === selectedAssessment.assessment_id}
        onClose={handleOnClose}
      >
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Update Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="name"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RegularInput
            label="Requesting Party"
            keyName="requesting_party"
            inputs={inputs}
            setInputs={setInputs}
          />
          <GeneralSnapshotDatepicker
            label="Due Date"
            keyName="due_date"
            inputs={inputs}
            setInputs={setInputs}
            onlyFutureDate
          />

          <article className="flex flex-col flex-grow gap-1 text-b2-reg">
            <h4>Must select at least one </h4>
            <CheckboxInput
              label="Search Documents (e.g. Policies, Procedures, Manuals, and SOPs)"
              keyName="enable_sources"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Search Question Banks"
              keyName="enable_questionbank"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <Tags inputs={inputs} setInputs={setInputs} />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editAssessment.mutate({
                  assessmentID: selectedAssessment.assessment_id,
                  name: inputs.name,
                  requestingParty: inputs.requesting_party,
                  dueDate: inputs.due_date,
                  owner: inputs.owner,
                  reviewer: null,
                  questionnaires: inputs.questionnaires,
                  tags: inputs.tags.reduce(
                    (pV: string[], cV: KeyStringVal) => [...pV, cV.tag_id],
                    []
                  ),
                  sourceType: inputs.source_type,
                });
                handleOnClose();
              }}
            >
              <h4>Save</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default EditAssessment;
