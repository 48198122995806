import { faLink, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { FileUploader } from "react-drag-drop-files";

const MultipleFileInput = ({
  label,
  keyName,
  types,
  inputs,
  setInputs,
}: {
  label: string;
  keyName: string;
  types: string[];
  inputs: any;
  setInputs: any;
}) => {
  return (
    <article className="grid content-start text-b2-reg">
      <h4>Upload {label}</h4>
      <FileUploader
        multiple
        handleChange={(files: any) => {
          setInputs({
            ...inputs,
            [keyName]: [...(inputs[keyName] || []), ...[...files]],
          });
        }}
        name="file"
        types={types}
        children={
          <button className="flex flex-col flex-grow gap-4 items-center p-4 w-full h-full mx-auto dark:bg-gray-900 dark:hover:bg-gray-900/70 duration-100 rounded-lg">
            <svg
              width="48"
              height="48"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M41.4929 20.9109L41.4836 20.8734L36.2242 7.50937C35.9898 6.75469 35.2914 6.23438 34.4992 6.23438H13.1804C12.3836 6.23438 11.6757 6.76406 11.4507 7.52813L6.53355 20.7562L6.51949 20.7891L6.51012 20.8266C6.44918 21.0562 6.43043 21.2906 6.46324 21.5203C6.45855 21.5953 6.45387 21.6703 6.45387 21.7453V38.8969C6.45511 39.6524 6.75577 40.3765 7.28998 40.9108C7.82419 41.445 8.54838 41.7456 9.30387 41.7469H38.7039C40.2742 41.7469 41.5539 40.4672 41.5586 38.8969V21.7453C41.5586 21.6844 41.5586 21.6234 41.5539 21.5719C41.5726 21.3422 41.5539 21.1219 41.4929 20.9109ZM27.6273 18.8953L27.6132 19.6312C27.5757 21.7359 26.1226 23.1516 23.9992 23.1516C22.9632 23.1516 22.0726 22.8187 21.4304 22.1859C20.7882 21.5531 20.4367 20.6719 20.4179 19.6312L20.4039 18.8953H10.757L14.4836 9.83437H33.1961L37.0257 18.8953H27.6273ZM10.0492 22.4953H17.4226C18.5617 25.1719 20.9851 26.7516 24.0039 26.7516C25.5836 26.7516 27.0507 26.3109 28.2367 25.4766C29.2773 24.7453 30.0882 23.7234 30.6132 22.4953H37.9492V38.1469H10.0492V22.4953Z"
                fill={inputs[keyName] ? "#6085FF" : "#2563EB"}
              />
            </svg>
            <article className="grid gap-3">
              <p>Click or drag file to this area to upload</p>
              <article className="grid gap-2 text-b3-reg dark:text-gray-500">
                <p>Support for a single or bulk upload.</p>
                <p>
                  Strictly prohibit from uploading company data or other band
                  files
                </p>
              </article>
            </article>
          </button>
        }
      />
      {inputs[keyName]?.length > 0 && (
        <ul className="grid gap-1 list-disc">
          {inputs[keyName]?.map((file: any, curIndex: number) => {
            return (
              <li key={curIndex} className="flex items-center gap-2">
                <FontAwesomeIcon icon={faLink} />
                {curIndex + 1}. {file.name}
                <FontAwesomeIcon
                  icon={faXmark}
                  className="hidden group-hover:block cursor-pointer red-button"
                  onClick={(e) =>
                    setInputs({
                      ...inputs,
                      files: [
                        ...inputs[keyName].filter(
                          (_: any, fileIndex: number) => fileIndex !== curIndex
                        ),
                      ],
                    })
                  }
                />
              </li>
            );
          })}
        </ul>
      )}
    </article>
  );
};

export default MultipleFileInput;
