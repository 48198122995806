/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import NewControlBlueprint from "./ControlBlueprints/NewControlBlueprint";
import ControlBlueprints from "./ControlBlueprints/ControlBlueprints";
import NewControl from "./Controls/NewControl";
import FrameworkControls from "./FrameworkControls/FrameworkControls";
import ControlsLanding from "./Controls/ControlsLanding";
import { useERCStore } from "src/stores/erc";
import ControlIssueList from "./ControlIssues/ControlIssueList";
import NewControlIssue from "./ControlIssues/NewControlIssue";
import ControlDashboard from "./ControlDashboard/ControlDashboard";

const Controls = () => {
  const { selectedERCTab, setSelectedERCTab } = useERCStore();

  useEffect(() => {
    if (sessionStorage.selectedERCTab)
      setSelectedERCTab(sessionStorage.selectedERCTab);
    if (selectedERCTab === "") setSelectedERCTab("framework controls");
  }, []);

  return (
    <section className="flex flex-col flex-grow gap-4">
      <header className="flex items-center justify-between gap-10 text-b1-reg">
        <nav className="flex flex-wrap items-center">
          {[
            "controls",
            "control dashboard",
            "control issues",
            "framework controls",
            "control blueprints",
          ].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-8 py-2 text-center capitalize ${
                  selectedERCTab === tab
                    ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                    : "dark:text-gray-500 dark:hover:text-white"
                }`}
                onClick={() => {
                  setSelectedERCTab(tab);
                  sessionStorage.selectedERCTab = tab;
                }}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedERCTab === "controls" ? (
          <NewControl />
        ) : selectedERCTab === "control issues" ? (
          <NewControlIssue />
        ) : selectedERCTab === "control blueprints" ? (
          <NewControlBlueprint />
        ) : null}
      </header>
      {selectedERCTab === "controls" ? (
        <ControlsLanding />
      ) : selectedERCTab === "control dashboard" ? (
        <ControlDashboard />
      ) : selectedERCTab === "control issues" ? (
        <ControlIssueList />
      ) : selectedERCTab === "framework controls" ? (
        <FrameworkControls />
      ) : selectedERCTab === "control blueprints" ? (
        <ControlBlueprints />
      ) : null}
    </section>
  );
};

export default Controls;
