/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import {
  convertToUTCString,
  getEmailFromUserID,
  getNamefromUserID,
} from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetRiskMetadata } from "../../../../../services/erc/risks/risks";
import { GetAllUsers } from "../../../../../services/settings/users";
import { getCustomerID } from "../../../../../utils/general";
import RiskTags from "./RiskTags";
import { Disclosure } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import MetadataField from "src/components/GRC/MetadataField";
import { useNavigate } from "react-router-dom";
import UpdateRisk from "./UpdateRisk";
import ArchiveRestoreRisk from "./ArchiveRestoreRisk";
import { GetRiskBlueprints } from "src/services/erc/risks/risk-blueprints";
import { GetEntities } from "src/services/erc/entities/entities";
import ReturnPage from "src/components/Button/ReturnPage";
import { KeyStringVal } from "src/types/general";

const RiskMetadata = ({ riskID }: { riskID: string }) => {
  const navigate = useNavigate();
  const customerID = getCustomerID();

  const { data: metadata } = GetRiskMetadata(riskID);
  const { data: entities } = GetEntities();
  const { data: riskBlueprints } = GetRiskBlueprints();
  const { data: allUsers } = GetAllUsers(customerID, false);

  const entity =
    entities?.data?.find(
      (entity: KeyStringVal) => entity.grc_entity_id === metadata?.grc_entity_id
    )?.name || "-";
  const riskBlueprint =
    riskBlueprints?.data.find(
      (blueprint: KeyStringVal) =>
        blueprint.risk_blueprint_id === metadata?.risk_blueprint_id
    )?.name || "-";

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Risk</span>
        </h4>
      </article>
      {metadata && (
        <section className="grid gap-4">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>
                    Last Updated by{" "}
                    {getNamefromUserID(allUsers, metadata.last_updated_by)} (
                    {getEmailFromUserID(allUsers, metadata.last_updated_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>
                    Created by{" "}
                    {getNamefromUserID(allUsers, metadata.created_by)} (
                    {getEmailFromUserID(allUsers, metadata.created_by)})
                  </h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateRisk riskID={riskID} risk={metadata} />
                {/* <ArchiveRestoreRisk riskID={riskID} risk={metadata} /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-start gap-4 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
              <article className="flex flex-col flex-grow gap-1">
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[metadata.state?.toLowerCase()]
                  }`}
                >
                  State
                  <span
                    className={`capitalize ${
                      attributeTextColors[metadata.state?.toLowerCase()]
                    }`}
                  >
                    {metadata.state?.replaceAll("_", " ")}
                  </span>
                </article>
                <span className="text-b2-reg dark:text-gray-300">
                  Updated {convertToUTCString(metadata.state_change_date)}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              {entity && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Entity</h4>
                  <span className="text-b1-semi">{entity}</span>
                </article>
              )}
              {metadata.controls?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Controls</h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.controls.map((control: KeyStringVal) => {
                      return (
                        <li
                          key={control.control_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/controls/details?control_id=${control.control_id}`
                            )
                          }
                        >
                          {control.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {riskBlueprint && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Risk Blueprint
                  </h4>
                  <article
                    className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                    onClick={() =>
                      navigate(
                        `/erc/risk-blueprints/details?risk_blueprint_id=${metadata.risk_blueprint_id}`
                      )
                    }
                  >
                    {riskBlueprint}
                  </article>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Serial Number"
                keyName="serial_number"
              />
              <MetadataField metadata={metadata} label="Type" keyName="type" />
              <MetadataField
                metadata={metadata}
                label="Classification"
                keyName="classification"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              {metadata.threats?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Threats</h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.threats.map((threat: KeyStringVal) => {
                      return (
                        <li
                          key={threat.threat_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/threats/details?threat_id=${threat.threat_id}`
                            )
                          }
                        >
                          {threat.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {metadata.vulnerabilities?.length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">
                    Vulnerabilities
                  </h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {metadata.vulnerabilities.map((vuln: KeyStringVal) => {
                      return (
                        <li
                          key={vuln.vulnerability_id}
                          className="px-2 py-1 break-all cursor-pointer text-b1-semi dark:bg-gray-900 dark:hover:bg-gray-700/70 duration-100 rounded-md"
                          onClick={() =>
                            navigate(
                              `/erc/vulns/details?vuln_id=${vuln.vulnerability_id}`
                            )
                          }
                        >
                          {vuln.name}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              {metadata.ref_ids && Object.keys(metadata.ref_ids).length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Ref-Ids</h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {Object.entries(metadata.ref_ids).map((keyVal) => {
                      return (
                        <li key={keyVal[0]} className="text-b1-semi">
                          {keyVal[0]}: {keyVal[1]}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Risk Identification Source"
                keyName="risk_identification_source"
              />
              <MetadataField
                metadata={metadata}
                label="Compliance Contact"
                keyName="compliance_contact"
              />
            </section>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Risk Treatment</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Treatment"
                        keyName="treatment"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Treatment Type"
                        keyName="treatment_type"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Treatment Status"
                        keyName="treatment_status"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Deadline"
                        keyName="deadline"
                        isDate
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Control Type"
                        keyName="control_type"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Risk Acceptance via Email"
                        keyName="risk_acceptance_via_email"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Cost / Benefit Analysis"
                        keyName="cost_benefit_analysis"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Treatment Plan"
                        keyName="treatment_plan"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Treatment Notes"
                        keyName="treatment_notes"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="URL"
                        keyName="url"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Compensating Controls"
                        keyName="compensating_controls"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">
                      Owners, Reviewers, & Approvers
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Owners"
                        keyName="owners"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Reviewers"
                        keyName="reviewers"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Approvers"
                        keyName="approvers"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Create Risks Automatically"
                        keyName="create_risks_automatically"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <RiskTags riskID={riskID} tags={metadata.tags} />
        </section>
      )}
    </>
  );
};

export default RiskMetadata;
