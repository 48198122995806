/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkIsReadOnly } from "src/utils/general";
import MultipleFileInput from "src/components/Input/MultipleFileInput";
import ToastLayout from "src/layouts/ToastLayout";
import { AddEntityAttachments } from "src/services/erc/entities/entities";

const NewAttachment = ({ entityID }: { entityID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const addAttachments = AddEntityAttachments();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAttachments.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            files: [],
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>Add Attachment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add Attachment
          </h3>
          <MultipleFileInput
            label="Attachment"
            keyName="files"
            types={[
              "pdf",
              "docx",
              "xlsx",
              "jpeg",
              "jpg",
              "png",
              "mp4",
              "json",
              "eml",
              "zip",
              "csv",
            ]}
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.files?.length === 0}
              className="blue-button"
              onClick={() => {
                const formData = new FormData();
                if (inputs.files?.length > 0)
                  inputs.files.forEach((file: any) =>
                    formData.append("files", file)
                  );

                addAttachments.mutate({
                  entityID,
                  formData,
                });

                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAttachments.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New attachment has been created"
      />
    </>
  );
};

export default NewAttachment;
