import React from "react";
import { GetGRCDocumentMetadata } from "src/services/grc";

const RegionsVerticals = ({
  documentType,
  documentID,
}: {
  documentType: string;
  documentID: string;
}) => {
  const { data: metadata } = GetGRCDocumentMetadata(documentType, documentID);

  return (
    <>
      {metadata && (
        <section className="grid gap-2">
          {metadata.regions?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
              <span>Regions</span>
              {metadata.regions.map((tag: string, index: number) => {
                return (
                  <span key={index} className="dark:text-blue-500">
                    {tag}
                  </span>
                );
              })}
            </article>
          )}
          {metadata.verticals?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
              <span>Verticals</span>
              {metadata.verticals.map((tag: string, index: number) => {
                return (
                  <span key={index} className="dark:text-blue-500">
                    {tag}
                  </span>
                );
              })}
            </article>
          )}
          {metadata.scanners?.length > 0 && (
            <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
              <span>Scanners</span>
              {metadata.scanners.map((scanner: string, index: number) => {
                return (
                  <span key={index} className="dark:text-blue-500">
                    {scanner}
                  </span>
                );
              })}
            </article>
          )}
        </section>
      )}
    </>
  );
};

export default RegionsVerticals;
