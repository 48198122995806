import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ExceptionMetadata from "./ExceptionMetadata";
import RiskScore from "./RiskScore";

const Exception = () => {
  const parsed = parseURL();

  const exceptionID = String(parsed.exception_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "exceptions";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 min-h-full w-full overflow-auto scrollbar">
        <ExceptionMetadata exceptionID={exceptionID} />
        <RiskScore exceptionID={exceptionID} />
      </main>
    </PageLayout>
  );
};

export default Exception;
