import React, { useState } from "react";
import { KeyStringVal } from "src/types/general";
import { validateEmail } from "src/utils/general";

const ContactInput = ({
  label,
  keyName,
  index,
  inputs,
  setInputs,
  disabled,
  required,
  placeholderText,
  singleEmail,
}: {
  label: string;
  keyName: string;
  index: number;
  inputs: any;
  setInputs: (inputs: any) => void;
  disabled?: boolean;
  required?: boolean;
  placeholderText?: string;
  singleEmail?: boolean;
}) => {
  const value = inputs.contacts[index][keyName]?.replaceAll(" ", "");

  const [emailValid, setEmailValid] = useState<boolean>(true);

  return (
    <article className="grid content-start text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="-mt-2 text-h5 text-red-500">*</span>}
      </header>
      <input
        spellCheck="false"
        autoComplete="off"
        value={value}
        disabled={disabled}
        placeholder={placeholderText || "Input text"}
        type="input"
        className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
        onChange={(e) =>
          setInputs({
            ...inputs,
            contacts: inputs.contacts.map(
              (contact: KeyStringVal, curContactIndex: number) => {
                if (index === curContactIndex) {
                  return { ...contact, [keyName]: e.target.value };
                } else return contact;
              }
            ),
          })
        }
        onBlur={() => {
          if (singleEmail && value) {
            if (!validateEmail(value)) setEmailValid(false);
            else setEmailValid(true);
          } else setEmailValid(true);
        }}
      />
      {!emailValid && (
        <span className="p-2 mt-2 break-words text-b2-reg text-left dark:bg-inner border dark:border-error rounded-sm">
          Email is not in correct format
        </span>
      )}
    </article>
  );
};

export default ContactInput;
