import React from "react";
import { convertToUTCString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetVulnerabilityMetadata } from "src/services/erc/risks/threats-vulns/vulns";
import UpdateVuln from "./UpdateVuln";
import MetadataField from "src/components/GRC/MetadataField";
import ArchiveRestoreVuln from "./ArchiveRestoreVuln";
import ReturnPage from "src/components/Button/ReturnPage";

const VulnMetadata = ({ vulnID }: { vulnID: string }) => {
  const { data: metadata } = GetVulnerabilityMetadata(vulnID);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Threats, & Controls
          </span>{" "}
          <span>/</span>
          <span>Vulnerability</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-4">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>Last Updated by {metadata.last_updated_by}</h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>Created by {metadata.created_by}</h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateVuln vulnID={vulnID} vuln={metadata} />
                {/* <ArchiveRestoreVuln vulnID={vulnID} vuln={metadata} /> */}
              </article>
            </header>
            <section className="flex flex-wrap items-center gap-4 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.status.toLowerCase()]
                }`}
              >
                Status
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.status.toLowerCase()]
                  }`}
                >
                  {metadata.status}
                </span>
              </article>
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField
                metadata={metadata}
                label="Serial Number"
                keyName="serial_number"
              />
              <MetadataField
                metadata={metadata}
                label="Category"
                keyName="category"
              />
              <MetadataField
                metadata={metadata}
                label="Subcategory"
                keyName="sub_category"
              />
              {metadata.ref_ids && Object.keys(metadata.ref_ids).length > 0 && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Ref-Ids</h4>
                  <ul className="flex flex-col flex-grow gap-1">
                    {Object.entries(metadata.ref_ids).map((keyVal) => {
                      return (
                        <li key={keyVal[0]} className="text-b1-semi">
                          {keyVal[0]}: {keyVal[1]}
                        </li>
                      );
                    })}
                  </ul>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Standard or Framework"
                keyName="framework_or_standard"
              />
            </section>
          </section>
        </header>
      )}
    </>
  );
};

export default VulnMetadata;
