/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import {
  CreateContact,
  GetCustomer,
  UpdateContact,
} from "../../../services/settings/organization";
import { Contact, ContactInput } from "../../../types/settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEnvelope,
  faPhone,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { getCustomerID } from "../../../utils/general";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";

const Admin = () => {
  const customerID = getCustomerID();

  const [primaryContact, setPrimaryContact] = useState<ContactInput>({
    contact_type: "Primary",
    name: "",
    email: "",
    phone_number: "",
  });
  const [editPrimary, setEditPrimary] = useState<boolean>(false);
  const [billingContact, setBillingContact] = useState<ContactInput>({
    contact_type: "Billing",
    name: "",
    email: "",
    phone_number: "",
  });
  const [editBilling, setEditBilling] = useState<boolean>(false);

  const { data: getCustomer } = GetCustomer(customerID);
  const createContact = CreateContact(customerID);
  const updateContact = UpdateContact(customerID);

  const primary = getCustomer?.contacts.find(
    (contact: Contact) => contact.contact_type === "Primary"
  );
  const billing = getCustomer?.contacts.find(
    (contact: Contact) => contact.contact_type === "Billing"
  );

  const handleOnClosePrimary = () => setEditPrimary(false);
  const handleOnCloseBilling = () => setEditBilling(false);

  // populate the primary and billing contact on initial render
  useEffect(() => {
    if (getCustomer) {
      if (primary)
        setPrimaryContact({
          ...primaryContact,
          name: primary.name,
          email: primary.email,
          phone_number: primary.phone_number,
        });

      if (billing)
        setBillingContact({
          ...billingContact,
          name: billing.name,
          email: billing.email,
          phone_number: billing.phone_number,
        });
    }
  }, [getCustomer]);

  return (
    <section className="grid content-start gap-6 w-full h-full">
      {/* customer information */}
      <section className="grid content-start gap-4 px-6 py-4 w-full dark:bg-gray-800 black-shadow rounded-xl">
        <h1 className="text-subt1-semi">{getCustomer.customer_name}</h1>
        <article className="flex flex-wrap items-center gap-2">
          <p className="px-2 py-1 dark:bg-gray-700 rounded">
            {getCustomer.customer_alias}
          </p>
          <p className="px-2 py-1 dark:bg-gray-700 rounded">
            {getCustomer.address || (
              <span className="italic dark:text-gray-300 font-light">
                address not specified
              </span>
            )}
          </p>
        </article>
      </section>

      <section className="grid grid-cols-1 sm:grid-cols-2 gap-6">
        {/* primary contact */}
        <article className="grid content-start gap-4 px-6 py-4 w-full dark:bg-gray-800 black-shadow rounded-xl">
          <header className="flex items-center justify-between gap-4">
            <h3 className="text-subt1-semi">Primary Contact</h3>
            <button
              className="white-text-button"
              onClick={() => setEditPrimary(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </button>
            <ModalLayout showModal={editPrimary} onClose={handleOnClosePrimary}>
              <section className="grid content-start gap-4 w-full h-full">
                <h3 className="flex items-center gap-2 text-t1-semi">
                  Edit Primary Contact
                </h3>
                <RegularInput
                  label="Name"
                  keyName="name"
                  inputs={primaryContact}
                  setInputs={setPrimaryContact}
                />
                <RegularInput
                  label="Email"
                  keyName="email"
                  inputs={primaryContact}
                  setInputs={setPrimaryContact}
                />
                <RegularInput
                  label="Phone Number"
                  keyName="phone_number"
                  inputs={primaryContact}
                  setInputs={setPrimaryContact}
                />
                <article className="flex items-center place-content-end gap-4">
                  <button
                    className="black-button"
                    onClick={handleOnClosePrimary}
                  >
                    Cancel
                  </button>
                  <button
                    className="blue-button"
                    onClick={() => {
                      if (primary)
                        updateContact.mutate({
                          contactID: primary.contact_id,
                          contact: primaryContact,
                        });
                      else
                        createContact.mutate({
                          contact: primaryContact,
                        });

                      handleOnClosePrimary();
                    }}
                  >
                    <h4>Done</h4>
                  </button>
                </article>
              </section>
            </ModalLayout>
          </header>
          <article className="grid gap-2 text-b2-reg break-all">
            {primary?.name && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon icon={faUser} className="dark:text-blue-500" />
                {primary.name}
              </p>
            )}
            {primary?.email && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="dark:text-blue-500"
                />
                {primary.email}
              </p>
            )}
            {primary?.phone_number && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="dark:text-blue-500"
                />
                {primary.phone_number}
              </p>
            )}
          </article>
        </article>

        {/* billing contact */}
        <article className="grid content-start gap-4 px-6 py-4 w-full dark:bg-gray-800 black-shadow rounded-xl">
          <header className="flex items-center justify-between gap-4">
            <h3 className="text-subt1-semi">Billing Contact</h3>
            <button
              className="white-text-button"
              onClick={() => setEditBilling(true)}
            >
              <FontAwesomeIcon icon={faEdit} />
              Edit
            </button>
            <ModalLayout showModal={editBilling} onClose={handleOnCloseBilling}>
              <section className="grid content-start gap-4 w-full h-full">
                <h3 className="flex items-center gap-2 text-t1-semi">
                  Edit Billing Contact
                </h3>
                <RegularInput
                  label="Name"
                  keyName="name"
                  inputs={billingContact}
                  setInputs={setBillingContact}
                />
                <RegularInput
                  label="Email"
                  keyName="email"
                  inputs={billingContact}
                  setInputs={setBillingContact}
                />
                <RegularInput
                  label="Phone Number"
                  keyName="phone_number"
                  inputs={billingContact}
                  setInputs={setBillingContact}
                />
                <article className="flex items-center place-content-end gap-4">
                  <button
                    className="black-button"
                    onClick={handleOnCloseBilling}
                  >
                    Cancel
                  </button>
                  <button
                    className="blue-button"
                    onClick={() => {
                      if (billing)
                        updateContact.mutate({
                          contactID: billing.contact_id,
                          contact: billingContact,
                        });
                      else
                        createContact.mutate({
                          contact: billingContact,
                        });

                      handleOnCloseBilling();
                    }}
                  >
                    <h4>Done</h4>
                  </button>
                </article>
              </section>
            </ModalLayout>
          </header>
          <article className="grid gap-2 text-b2-reg break-all">
            {billing?.name && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon icon={faUser} className="dark:text-blue-500" />
                {billing.name}
              </p>
            )}
            {billing?.email && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="dark:text-blue-500"
                />
                {billing.email}
              </p>
            )}
            {billing?.phone_number && (
              <p className="flex items-center gap-2 px-2 py-1 w-max dark:bg-gray-700 rounded">
                <FontAwesomeIcon
                  icon={faPhone}
                  className="dark:text-blue-500"
                />
                {billing.phone_number}
              </p>
            )}
          </article>
        </article>
      </section>
    </section>
  );
};

export default Admin;
