import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import {
  GetQuestionSetMetadata,
  PromoteQuestionSetToPublish,
} from "src/services/third-party-risk/questions/custom-question-sets";
import { checkIsReadOnly } from "src/utils/general";

const State = ({ questionSetID }: { questionSetID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);

  const { data: metadata } = GetQuestionSetMetadata(questionSetID);
  const promoteToPublished = PromoteQuestionSetToPublish(questionSetID);

  const state = metadata?.state;

  const handleOnClose = () => setShow(false);

  return (
    <>
      <nav className="flex items-center gap-1 p-1 w-max dark:bg-gray-900 rounded-lg">
        <button
          disabled
          className={`${
            state === "DRAFT" ? "active-state-button" : "status-button"
          }`}
        >
          DRAFT
        </button>
        <button
          disabled={isReadOnly || state === "PUBLISHED"}
          className={`${
            state === "PUBLISHED" ? "active-state-button" : "status-button"
          }`}
          onClick={(e) => {
            e.stopPropagation();
            setShow(true);
          }}
        >
          PUBLISHED
        </button>
      </nav>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-10">
          <h2 className="text-subt1-semi">Publish Question Set</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to publish this question set?
          </h4>
          <section className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="blue-button"
              onClick={() => {
                promoteToPublished.mutate({});
                handleOnClose();
              }}
            >
              Publish
            </button>
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default State;
