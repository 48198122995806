import { motion } from "framer-motion";
import React, { useState } from "react";
import { showVariants } from "src/constants/general";
import Coverage from "./Coverage/Coverage";
import Activities from "./Activities/Activities";
import Reports from "./Reports/Reports";
// import Scorecard from "./Scorecard";

const Overview = () => {
  const [selectedTab, setSelectedTab] = useState<string>("reports");

  return (
    <motion.main
      variants={showVariants}
      initial="hidden"
      animate="visible"
      className="flex flex-col flex-grow gap-4 py-4 w-full h-full"
    >
      <nav className="flex flex-wrap items-center">
        {["reports", "coverage", "activities"].map((tab) => {
          return (
            <button
              key={tab}
              className={`px-8 py-2 text-center capitalize ${
                selectedTab === tab
                  ? "border-b-2 dark:text-blue-400 dark:border-blue-400"
                  : "dark:text-gray-500 dark:hover:text-white"
              }`}
              onClick={() => setSelectedTab(tab)}
            >
              {tab}
            </button>
          );
        })}
      </nav>

      {selectedTab === "coverage" ? (
        <Coverage />
      ) : selectedTab === "activities" ? (
        <Activities />
      ) : (
        <Reports />
      )}
    </motion.main>
  );
};

export default Overview;
