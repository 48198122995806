/* eslint-disable no-restricted-globals */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import {
  AddVendorToGroup,
  GetVendorGroups,
  RemoveVendorFromGroup,
} from "src/services/third-party-risk/vendors/vendor-groups";
import { ChevronDownIcon } from "@heroicons/react/solid";
import React, { useState } from "react";
import { checkIsReadOnly } from "src/utils/general";

const VendorGroupFilter = ({
  label,
  vendor,
}: {
  label?: string;
  vendor: KeyStringVal;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const { data: vendorGroups } = GetVendorGroups(1);
  const addVendorToGroup = AddVendorToGroup();
  const removeVendorGroup = RemoveVendorFromGroup();

  const selectedGroup = vendorGroups?.data.find(
    (group: KeyStringVal) => group.generated_id === vendor?.group_id
  );
  const selectedGroupName = selectedGroup?.name;

  return (
    <article
      onMouseLeave={() => setShowDropdown(false)}
      onClick={() => setShowDropdown(true)}
      className="relative flex items-center gap-2 px-4 py-1 text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-[#3B82F6]/10 border dark:border-blue-500 focus:outline-none rounded"
    >
      <h4 className="justify-self-start">{label}</h4>
      <article className="relative grid w-[15rem] text-left cursor-pointer">
        <article className="flex items-center justify-between gap-2 cursor-pointer">
          <input
            type="input"
            disabled
            autoComplete="off"
            spellCheck="false"
            placeholder="Select"
            value={selectedGroupName}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
            }}
            className="w-full placeholder:normal-case capitalize dark:text-blue-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon className="w-4 h-4" aria-hidden="true" />
          </span>
        </article>
        {!isReadOnly && showDropdown && (
          <article className="absolute top-8 left-0 grid p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-10">
            {vendorGroups?.data?.map(
              (vendorGroup: KeyStringVal, index: number) => {
                const selected = vendorGroup.name === selectedGroupName;
                return (
                  <button
                    key={index}
                    className={`relative flex items-center gap-2 capitalize px-4 py-1 break-words dark:hover:bg-gray-800 duration-100 ${
                      selected ? "dark:bg-gray-800" : ""
                    } rounded-lg`}
                    onClick={() => {
                      removeVendorGroup.mutate(
                        {
                          groupID: vendorGroup.generated_id,
                          vendor: [vendor.third_party_id],
                        },
                        {
                          onSuccess: () => {
                            if (
                              selectedGroup?.generated_id ===
                              vendorGroup.generated_id
                            ) {
                              sessionStorage.removeItem("vendor_group_id");
                              sessionStorage.removeItem("vendor_group_name");
                            } else {
                              sessionStorage.vendor_group_id =
                                vendorGroup.generated_id;
                              sessionStorage.vendor_group_name =
                                vendorGroup.name;
                              addVendorToGroup.mutate({
                                groupID: vendorGroup.generated_id,
                                vendor: [vendor.third_party_id],
                              });
                            }
                          },
                        }
                      );
                    }}
                  >
                    {selected && <FontAwesomeIcon icon={faCheck} />}
                    <p>{vendorGroup.name}</p>
                  </button>
                );
              }
            )}
          </article>
        )}
      </article>
    </article>
  );
};

export default VendorGroupFilter;
