import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { vrInfoDescription } from "src/constants/summaries";

const SummaryInfo = ({ vrSummary }: { vrSummary: any }) => {
  return (
    <ul className="lg:col-span-2 flex flex-wrap items-center gap-10">
      {vrSummary &&
        Object.entries(vrSummary.summary).map((keyVal) => {
          return (
            <li
              key={keyVal[0]}
              className="flex flex-col flex-grow gap-3 text-center"
            >
              <span className="text-4xl">{keyVal[1]}</span>
              <article className="group relative flex flex-wrap items-center gap-2 mx-auto">
                <FontAwesomeIcon
                  icon={faCircleInfo}
                  className="w-3 h-3 dark:text-gray-400 z-0 focus:outline-none"
                />
                <p className="hidden group-hover:block absolute top-6 left-0 w-60 text-left text-sm dark:text-white dark:bg-tooltip p-3 overflow-hidden rounded-sm">
                  {vrInfoDescription[keyVal[0]]}
                </p>
                <h4 className="capitalize">{keyVal[0].replaceAll("_", " ")}</h4>
              </article>
            </li>
          );
        })}
    </ul>
  );
};

export default SummaryInfo;
