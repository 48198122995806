/* eslint-disable react-hooks/exhaustive-deps */
import {
  faMagnifyingGlass,
  faSquarePollHorizontal,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { LineWave } from "react-loader-spinner";

const DocumentSearch = ({
  search,
  query,
  setQuery,
  setSearch,
}: {
  search: boolean;
  query: string;
  setQuery: (query: string) => void;
  setSearch: (search: boolean) => void;
}) => {
  useEffect(() => {
    if (query === "") setSearch(false);
  }, [query]);

  const handleSearch = () => {
    setSearch(true);
  };

  return (
    <section className="grid gap-2 place-self-end">
      <article className="flex items-center px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
        <FontAwesomeIcon
          icon={faMagnifyingGlass}
          className="dark:text-gray-500"
        />
        <input
          type="input"
          autoComplete="off"
          spellCheck="false"
          placeholder="Search content"
          value={query}
          onKeyUpCapture={(e) => {
            if (e.key === "Enter" && query !== "") handleSearch();
          }}
          onChange={(e) => {
            setSearch(false);
            setQuery(e.target.value);
          }}
          className="p-4 pr-12 w-[20rem] h-8 dark:bg-gray-800 focus:outline-none"
        />
        <article className="flex items-stretch gap-2 divide-x-1 dark:divide-checkbox/60">
          {query !== "" && (
            <button
              onClick={() => {
                setQuery("");
                setSearch(false);
              }}
            >
              <FontAwesomeIcon
                icon={faXmark}
                className="text-red-500 hover:text-red-500/60 duration-100"
              />
            </button>
          )}
          {search ? (
            <LineWave
              visible={true}
              height="30"
              width="50"
              color="#4fa94d"
              ariaLabel="line-wave-loading"
              wrapperStyle={{}}
              wrapperClass=""
              firstLineColor=""
              middleLineColor=""
              lastLineColor=""
            />
          ) : (
            <button
              disabled={query === ""}
              className="px-2 dark:disabled:text-blue-500/30 dark:text-blue-500 dark:hover:text-blue-500/60 duration-100"
              onClick={handleSearch}
            >
              <FontAwesomeIcon icon={faMagnifyingGlass} />
            </button>
          )}
        </article>
      </article>
      {search && (
        <span className="flex items-center gap-1 text-sm text-investigation">
          <FontAwesomeIcon icon={faSquarePollHorizontal} />
          Showing content matching the search
        </span>
      )}
    </section>
  );
};

export default DocumentSearch;
