import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import GeneralTemporalDatepicker from "../../../../components/Datepicker/GeneralTemporalDatepicker";
import RegularInput from "src/components/Input/RegularInput";
import ToastLayout from "../../../../layouts/ToastLayout";
import AssessmentQuestionSetFilter from "src/components/Filter/ThirdPartyRisk/AssessmentQuestionSetFilter";
import MultiUserInput from "src/components/Input/MultiUserInput";
import EntityFilter from "src/components/Filter/ERC/EntityFilter";
import { AddAssessment } from "src/services/erc/assessments/assessments";
import { checkIsReadOnly } from "src/utils/general";

const NewAssessment = () => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});

  const addAssessment = AddAssessment();

  const handleOnClose = () => setShow(false);
  const handleOnCloseConfirmation = () => addAssessment.reset();

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end blue-button"
        onClick={() => {
          setShow(true);
          setInputs({
            title: "",
            purpose: "",
            module: "ERC",
            object_type: "ENTITY",
            object_id: "",
            respondent_emails: [],
            question_set_id: "",
            start_date: Date.now() * 1000,
            end_date: Date.now() * 1000,
          });
        }}
      >
        <FontAwesomeIcon icon={faPlus} />
        <h4>New Assessment</h4>
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 mb-10">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add New Assessment
          </h3>
          <RegularInput
            label="Name"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <GeneralTemporalDatepicker
            startKeyName="start_date"
            endKeyName="end_date"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <article className="grid grid-cols-2 gap-4">
            <RegularInput
              label="Purpose"
              keyName="purpose"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <EntityFilter
              label="Entity"
              keyName="object_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
            <MultiUserInput
              label="Respondent Emails"
              keyName="respondent_emails"
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <AssessmentQuestionSetFilter
              label="Question Set"
              keyName="question_set_id"
              inputs={inputs}
              setInputs={setInputs}
              required
            />
          </article>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.title === "" ||
                Object.keys(inputs).length < 4 ||
                inputs.start_date === inputs.end_date ||
                inputs.purpose === "" ||
                inputs.object_id === ""
              }
              className="blue-button"
              onClick={() => {
                addAssessment.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              Done
            </button>
          </article>
        </section>
      </ModalLayout>
      <ToastLayout
        showModal={addAssessment.data !== undefined}
        onClose={handleOnCloseConfirmation}
        type="success"
        message="New assessment has been created"
      />
    </>
  );
};

export default NewAssessment;
