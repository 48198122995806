/* eslint-disable react-hooks/exhaustive-deps */
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { SearchGRCVerticals } from "src/services/regulation-policy/overview";
import { KeyStringVal } from "src/types/general";

const VerticalFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: (inputs: KeyStringVal) => void;
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const search = SearchGRCVerticals();

  const handleSelect = (vertical: any) => {
    if (vertical === "All" && search?.data?.length > 0) {
      if (inputs[keyName].length < search.data?.length) {
        search.mutate(
          {
            vertical: "",
          },
          {
            onSuccess: (data) => {
              const verticals = data.reduce(
                (pV: string[], cV: KeyStringVal) => [
                  ...pV,
                  `${cV.industry}-${cV.sub_category}`,
                ],
                []
              );
              setInputs({
                ...inputs,
                verticals,
              });
            },
          }
        );
      } else setInputs({ ...inputs, [keyName]: [] });
    } else {
      const value = `${vertical.industry}-${vertical.sub_category}`;

      if (inputs[keyName].includes(value))
        setInputs({
          ...inputs,
          [keyName]: inputs[keyName].filter(
            (curVal: string) => curVal !== value
          ),
        });
      else setInputs({ ...inputs, [keyName]: [...inputs[keyName], value] });
    }
  };

  useEffect(() => {
    search.mutate({
      vertical: "",
    });
  }, []);

  return (
    <FilterLayout label={label}>
      <article className="flex flex-col flex-grow gap-2">
        <article className="flex items-center justify-between gap-2 px-4 py-1 border dark:border-gray-700 rounded">
          <FontAwesomeIcon
            icon={faMagnifyingGlass}
            className="dark:text-gray-500"
          />
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder="Search"
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
              search.mutate({
                vertical: e.target.value,
              });
            }}
            className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
        </article>
        <ul className="flex flex-col flex-grow gap-1 max-w-[20rem] max-h-[15rem] overflow-auto scrollbar">
          <li className="flex items-start gap-2">
            <input
              type="checkbox"
              className="transparent-checkbox"
              checked={inputs[keyName].length === search?.data?.length}
              onClick={() => handleSelect("All")}
            />
            <label htmlFor="">All</label>
          </li>
          {search.data?.map((item: KeyStringVal, idx: number) => (
            <li key={idx} className="flex items-start gap-2 px-4">
              <input
                type="checkbox"
                className="transparent-checkbox"
                checked={inputs[keyName].includes(
                  `${item.industry}-${item.sub_category}`
                )}
                onClick={() => handleSelect(item)}
              />
              <label htmlFor="">
                {item.industry} - {item.sub_category}
              </label>
            </li>
          ))}
        </ul>
      </article>
    </FilterLayout>
  );
};

export default VerticalFilter;
