import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faTriangleExclamation,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { KeyStringVal } from "src/types/general";
import Source from "./Source";
import { EditAnswer } from "src/services/questionnaire-rfp";
import DeleteResponse from "./DeleterResponse";
import { checkIsReadOnly } from "src/utils/general";

const Response = ({
  assessmentID,
  questionIndex,
  questionRef,
  question,
  selectedQuestions,
  setSelectedQuestions,
}: {
  assessmentID: string;
  questionIndex: number;
  questionRef: any;
  question: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const questionID = question.question_id;
  const selected = selectedQuestions.some(
    (question: KeyStringVal) => question.question_id === questionID
  );

  const [show, setShow] = useState<boolean>(false);
  const [editedAnswer, setEditedAnswer] = useState<string>(question.answer);
  const [selectedExpand, setSelectedExpand] = useState<string>("");

  const filteredHeaders = ["question", "answer"];

  const editAnswer = EditAnswer(assessmentID);

  const handleDiscardEditedAnswer = () => setEditedAnswer(question.answer);

  return (
    <Fragment>
      <tr className="relative p-5 gap-3 w-full break-words dark:bg-gray-800">
        {filteredHeaders?.map((col: string, colIndex: number) => {
          return (
            <td
              key={`${questionID}-${colIndex}`}
              className="relative px-6 py-3 last:pr-16 text-left"
            >
              <article className="flex items-start gap-2">
                {colIndex === 0 && (
                  <article className="flex items-center gap-2 pl-4 pr-3 mt-1">
                    <input
                      type="checkbox"
                      disabled={isReadOnly}
                      checked={selected}
                      onChange={() => {
                        if (selected)
                          setSelectedQuestions(
                            selectedQuestions.filter(
                              (curQuestion: KeyStringVal) =>
                                curQuestion.question_id !== questionID
                            )
                          );
                        else
                          setSelectedQuestions([
                            ...selectedQuestions,
                            question,
                          ]);
                      }}
                      className="white-checkbox"
                    />
                  </article>
                )}
                {col === "answer" ? (
                  <article className="grid content-start gap-3 pr-10 w-full">
                    <article className="flex items-center gap-2">
                      <FontAwesomeIcon
                        className={`${
                          question.compliant === "Yes"
                            ? "text-green-500"
                            : question.compliant === "Maybe"
                            ? "text-yellow-500"
                            : "text-red-500"
                        }`}
                        icon={
                          question.compliant === "Yes"
                            ? faCheck
                            : question.compliant === "Maybe"
                            ? faTriangleExclamation
                            : faXmark
                        }
                      />
                      <span>{question.compliant}</span>
                    </article>
                    <textarea
                      disabled={isReadOnly}
                      placeholder="Input text"
                      value={editedAnswer}
                      onChange={(e) => setEditedAnswer(e.target.value)}
                      className={`px-4 py-2 w-full ${
                        show
                          ? "h-40 overflow-auto scrollbar"
                          : "h-20 overflow-hidden"
                      } border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded`}
                    />
                    {question.answer !== editedAnswer && (
                      <article className="flex items-center place-self-end gap-2">
                        <button
                          disabled={isReadOnly}
                          className="red-button"
                          onClick={handleDiscardEditedAnswer}
                        >
                          Discard
                        </button>
                        <button
                          disabled={isReadOnly}
                          className="blue-button"
                          onClick={() => {
                            editAnswer.mutate({
                              questionID: questionID,
                              answer: editedAnswer,
                            });
                            handleDiscardEditedAnswer();
                          }}
                        >
                          Save
                        </button>
                      </article>
                    )}
                    {question.answer?.length >= 100 && (
                      <button
                        className="w-max underline dark:text-blue-400 dark:hover:text-blue-400/70 duration-100"
                        onClick={() => setShow(!show)}
                      >
                        {show ? "Hide" : "Show"}
                      </button>
                    )}
                  </article>
                ) : (
                  <p>{question.question}</p>
                )}
                {colIndex === filteredHeaders.length - 1 && (
                  <article className="flex items-center gap-2 absolute right-5 top-1/3">
                    <DeleteResponse
                      assessmentID={assessmentID}
                      questionID={questionID}
                    />
                    <button
                      className="w-6 h-6 dark:hover:text-gray-400 duration-100 place-self-end cursor-pointer"
                      onMouseUp={(e) => {
                        if (selectedExpand === questionID)
                          setSelectedExpand("");
                        else setSelectedExpand(questionID);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={
                          selectedExpand === questionID
                            ? faChevronDown
                            : faChevronRight
                        }
                      />
                    </button>
                  </article>
                )}
              </article>
            </td>
          );
        })}
      </tr>
      {selectedExpand === questionID && (
        <tr className="relative py-5 px-10 gap-10">
          <td colSpan={filteredHeaders.length + 1} className="p-5 w-5">
            <section className="grid content-start gap-4 text-b2-reg">
              {question.context?.length > 0 && (
                <section className="grid gap-2">
                  <Disclosure>
                    {({ open }) => {
                      return (
                        <section className="grid gap-2">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4>Sources</h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel>
                              <section className="flex flex-wrap items-center gap-4">
                                {question.context.map(
                                  (
                                    source: KeyStringVal,
                                    sourceIndex: number
                                  ) => {
                                    return (
                                      <Source
                                        key={sourceIndex}
                                        sourceIndex={sourceIndex}
                                        source={source}
                                      />
                                    );
                                  }
                                )}
                              </section>
                            </Disclosure.Panel>
                          </Transition>
                        </section>
                      );
                    }}
                  </Disclosure>
                </section>
              )}
              {question.mapped_questions?.from_assessments?.length > 0 && (
                <section className="grid gap-2">
                  <Disclosure>
                    {({ open }) => {
                      return (
                        <section className="grid gap-2">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4>From Assessments</h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel>
                              <section className="flex flex-wrap items-center gap-4">
                                {question.mapped_questions?.from_assessments?.map(
                                  (
                                    source: KeyStringVal,
                                    sourceIndex: number
                                  ) => {
                                    return (
                                      <Source
                                        key={sourceIndex}
                                        sourceIndex={sourceIndex}
                                        source={source}
                                        questionID={questionID}
                                        selectedQuestions={selectedQuestions}
                                        setSelectedQuestions={
                                          setSelectedQuestions
                                        }
                                      />
                                    );
                                  }
                                )}
                              </section>
                            </Disclosure.Panel>
                          </Transition>
                        </section>
                      );
                    }}
                  </Disclosure>
                </section>
              )}
              {question.mapped_questions?.from_question_banks?.length > 0 && (
                <section className="grid gap-2">
                  <Disclosure>
                    {({ open }) => {
                      return (
                        <section className="grid gap-2">
                          <Disclosure.Button className="flex items-center gap-2 w-max">
                            <h4>From Question Banks</h4>
                            <FontAwesomeIcon
                              icon={open ? faChevronDown : faChevronRight}
                            />
                          </Disclosure.Button>
                          <Transition
                            show={open}
                            enter="transition duration-100 ease-out"
                            enterFrom="transform scale-95 opacity-0"
                            enterTo="transform scale-100 opacity-100"
                            leave="transition duration-75 ease-out"
                            leaveFrom="transform scale-100 opacity-100"
                            leaveTo="transform scale-95 opacity-0"
                          >
                            <Disclosure.Panel>
                              <section className="flex flex-wrap items-center gap-4">
                                {question.mapped_questions?.from_question_banks?.map(
                                  (
                                    source: KeyStringVal,
                                    sourceIndex: number
                                  ) => {
                                    return (
                                      <Source
                                        key={sourceIndex}
                                        sourceIndex={sourceIndex}
                                        source={source}
                                        questionID={questionID}
                                        selectedQuestions={selectedQuestions}
                                        setSelectedQuestions={
                                          setSelectedQuestions
                                        }
                                      />
                                    );
                                  }
                                )}
                              </section>
                            </Disclosure.Panel>
                          </Transition>
                        </section>
                      );
                    }}
                  </Disclosure>
                </section>
              )}
            </section>
          </td>
        </tr>
      )}
    </Fragment>
  );
};

export default Response;
