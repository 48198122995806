import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import ControlMetadata from "./ControlIssueMetadata";

const ControlIssue = () => {
  const parsed = parseURL();

  const controlIssueID = String(parsed.control_issue_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "controls";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <ControlMetadata controlIssueID={controlIssueID} />
      </main>
    </PageLayout>
  );
};

export default ControlIssue;
