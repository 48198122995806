import React, { useEffect, useState } from "react";
import AskAQuestion from "./AskAQuestion";
import ResponseList from "./ResponseList";
import { KeyStringVal } from "../../../../../../types/general";
import DraftList from "./DraftList/DraftList";
import UploadQuestionnaire from "./UploadQuestionnaire";
import SelectQuestionSetFilter from "../../../../../../components/Filter/ThirdPartyRisk/SelectQuestionSetFilter";

const QnA = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string;
}) => {
  const [nav, setNav] = useState<number>(1);
  const [inputs, setInputs] = useState<any>({ drafts: [] });
  const [selectedQuestionSet, setSelectedQuestionSet] = useState<KeyStringVal>({
    generated_id: "",
    document_name: "",
  });

  useEffect(() => {
    if (nav === 2) setTimeout(() => setNav(1), 5000);
  }, [nav]);

  return (
    <section className="flex flex-col flex-grow gap-4">
      {nav === 1 ? (
        <section className="grid gap-4">
          <section className="flex items-center place-content-end gap-4">
            <SelectQuestionSetFilter
              selectedQuestionSet={selectedQuestionSet}
              setSelectedQuestionSet={setSelectedQuestionSet}
            />
            <AskAQuestion inputs={inputs} setInputs={setInputs} />
            <UploadQuestionnaire
              vendorID={vendorID}
              assessmentID={assessmentID}
            />
          </section>
          <DraftList
            vendorID={vendorID}
            assessmentID={assessmentID}
            inputs={inputs}
            setInputs={setInputs}
            setNav={setNav}
            selectedQuestionSet={selectedQuestionSet}
            setSelectedQuestionSet={setSelectedQuestionSet}
          />
        </section>
      ) : (
        <section className="grid content-start gap-4">
          <article className="grid gap-3 text-center">
            <h4 className="text-xl">
              Uno is processing your questions. We will notify you when ready!
            </h4>
            <img
              src="/grc/draft-questions-processing.png"
              alt="questions processing"
              className="h-[20rem] mx-auto"
            />
          </article>
          <button
            className="px-4 py-2 mx-auto h-max dark:hover:bg-blue-600/30 duration-100 dark:bg-blue-600 rounded-md"
            onClick={() => setNav(1)}
          >
            Ask another
          </button>
        </section>
      )}
      <ResponseList vendorID={vendorID} assessmentID={assessmentID} />
    </section>
  );
};

export default QnA;
