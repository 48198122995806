/* eslint-disable no-restricted-globals */
import DropdownLayout from "../../../layouts/DropdownLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { KeyStringVal } from "src/types/general";
import { GetFrameworksOrPrivacyStandards } from "src/services/vendor-webform";

const SelectDocFrameworkFilter = ({
  label,
  fileIndex,
  inputs,
  setInputs,
  type,
  selectedTextSize,
  width,
}: {
  label?: string;
  fileIndex: number;
  inputs: any;
  type: string;
  setInputs: (inputs: any) => void;
  selectedTextSize?: string;
  width?: string;
}) => {
  const { data: frameworks } = GetFrameworksOrPrivacyStandards(type);

  const value = inputs.frameworks[fileIndex];

  const list = frameworks;

  const frameworkName =
    list?.find((framework) => framework.id === value)?.name || "";

  return (
    <DropdownLayout
      label={label}
      value={frameworkName}
      width="w-[20rem]"
      placeholder="Select"
      selectedTextSize={selectedTextSize}
      showAbove
    >
      {list?.map((framework: KeyStringVal, index: number) => {
        const selected = value === framework.id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() => {
              const updatedFrameworks = inputs.frameworks;
              updatedFrameworks[fileIndex] = framework.id;
              setInputs({
                ...inputs,
                frameworks: updatedFrameworks,
              });
            }}
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>{framework.name}</p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default SelectDocFrameworkFilter;
