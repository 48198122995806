import React from "react";
import { convertToUTCString } from "src/utils/general";
import ReturnPage from "src/components/Button/ReturnPage";
import {
  GetExceptionMetadata,
  GetObjectTypes,
} from "src/services/erc/exceptions/exceptions";
import UpdateException from "./UpdateException";
import {
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { KeyStringVal } from "src/types/general";
import MetadataField from "src/components/GRC/MetadataField";

const ExceptionMetadata = ({ exceptionID }: { exceptionID: string }) => {
  const { data: metadata } = GetExceptionMetadata(exceptionID);
  const { data: objectTypes } = GetObjectTypes(metadata?.grc_object_type);

  const id =
    metadata?.grc_object_type === "POLICY"
      ? "policy_id"
      : metadata?.grc_object_type === "CONTROL"
      ? "control_id"
      : "control_issue_id";
  const objectType = objectTypes?.data.find(
    (item: KeyStringVal) => metadata?.grc_object_id === item[id]
  );
  const objectTypeName = objectType?.policy_name || objectType?.name;

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">
            Entities, Risks, & Controls
          </span>{" "}
          <span>/</span>
          <span>Exception</span>
        </h4>
      </article>
      {metadata && (
        <section className="grid gap-3">
          <section className="flex flex-col flex-gmetadata gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            <header className="flex items-start justify-between gap-10">
              <article className="grid content-start gap-1">
                <h2 className="text-subt1-semi">{metadata.name}</h2>
                <article className="flex items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-blue-700 rounded">
                  <h4>Last Updated by {metadata.last_updated_by}</h4>
                  <span>| {convertToUTCString(metadata.last_updated_at)}</span>
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>Created by {metadata.created_by}</h4>
                  <span>| {convertToUTCString(metadata.created_at)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                <UpdateException
                  exceptionID={exceptionID}
                  exception={metadata}
                />
              </article>
            </header>
            <section className="flex flex-wrap items-start gap-4 text-b1-reg">
              <article
                className={`flex items-center gap-1 ${
                  attributeColors[metadata.state?.toLowerCase()]
                }`}
              >
                State
                <span
                  className={`capitalize ${
                    attributeTextColors[metadata.state?.toLowerCase()]
                  }`}
                >
                  {metadata.state?.replaceAll("_", " ")}
                </span>
              </article>
            </section>
            <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
              <MetadataField
                metadata={metadata}
                label="Serial Number"
                keyName="serial_number"
              />
              <MetadataField
                metadata={metadata}
                label="Object Type"
                keyName="grc_object_type"
              />
              {objectTypeName && (
                <article className="grid content-start gap-1">
                  <h4 className="dark:text-gray-300 text-b2-reg">Object</h4>
                  <span className="text-b1-semi">{objectTypeName}</span>
                </article>
              )}
              <MetadataField
                metadata={metadata}
                label="Valid From"
                keyName="valid_from"
                isDate
              />
              <MetadataField
                metadata={metadata}
                label="Valid To"
                keyName="valid_to"
                isDate
              />
              <MetadataField
                metadata={metadata}
                label="Priority"
                keyName="priority"
              />
              <MetadataField
                metadata={metadata}
                label="Notes"
                keyName="notes"
              />
              <MetadataField
                metadata={metadata}
                label="Justification"
                keyName="justification"
              />
              <MetadataField
                metadata={metadata}
                label="Reason"
                keyName="reason"
              />
            </section>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Risk Info</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Description"
                        keyName="risk_description"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Impact Analysis"
                        keyName="risk_impact_analysis"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Mitigation Plan"
                        keyName="risk_mitigation_plan"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">
                      Owners, Reviewers, & Approvers
                    </h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Owners"
                        keyName="owners"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Reviewers"
                        keyName="reviewers"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Approvers"
                        keyName="approvers"
                        multipleUsers
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
        </section>
      )}
    </>
  );
};

export default ExceptionMetadata;
