/* eslint-disable react-hooks/exhaustive-deps */
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import RegularInput from "src/components/Input/RegularInput";
import RadioInput from "src/components/Input/RadioInput";
import { KeyStringVal } from "src/types/general";
import CheckboxInput from "src/components/Input/CheckboxInput";
import { checkIsReadOnly } from "src/utils/general";
import { GetQuestionTypes } from "src/services/third-party-risk/questions/global-questions";

const NewQuestions = ({
  newQuestions,
  setNewQuestions,
  selectedQuestions,
  setSelectedQuestions,
}: {
  newQuestions: any;
  setNewQuestions: any;
  selectedQuestions: any;
  setSelectedQuestions: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [newChoice, setNewChoice] = useState("");

  const { data: questionTypes } = GetQuestionTypes();

  useEffect(() => {
    setInputs({
      ...inputs,
      multi_answers: [],
    });
  }, [inputs.question_type]);

  const handleOnClose = () => setShow(false);

  return (
    <>
      {!show ? (
        <button
          disabled={isReadOnly}
          className="black-button"
          onClick={() => {
            setShow(true);
            setInputs({
              question: "",
              question_type: "Text",
              weight: 1,
              description: "",
              question_mandatory: false,
              attachment_mandatory: false,
              multi_choices: [],
              multi_answers: [],
            });
            setNewChoice("");
          }}
        >
          <FontAwesomeIcon icon={faPlus} />
          <h4>Add a Question</h4>
        </button>
      ) : (
        <section className="grid content-start gap-4 h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Add a Question
          </h3>
          <RegularInput
            label="Question"
            keyName="question"
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          <RadioInput
            label="Question Type"
            keyName="question_type"
            list={questionTypes?.fields}
            inputs={inputs}
            setInputs={setInputs}
            required
          />
          {["Multiple Choice", "Multiple Select"].includes(
            inputs.question_type
          ) && (
            <section className="flex flex-col flex-grow gap-2">
              <section className="grid gap-1 px-1 w-full max-h-[30rem] overflow-auto scrollbar">
                {inputs.multi_choices?.map((choice: KeyStringVal) => {
                  const selected = inputs.multi_answers.some(
                    (selectedChoice: KeyStringVal) =>
                      selectedChoice.answer === choice.choice
                  );
                  return (
                    <article
                      key={choice.choice}
                      className="flex items-start gap-1"
                    >
                      {inputs.question_type === "Multiple Select" ? (
                        <input
                          type="checkbox"
                          className="white-checkbox"
                          checked={selected}
                          onChange={() => {
                            if (selected)
                              setInputs({
                                ...inputs,
                                multi_answers: inputs.multi_answers.filter(
                                  (curChoice: KeyStringVal) =>
                                    curChoice.answer !== choice.choice
                                ),
                              });
                            else
                              setInputs({
                                ...inputs,
                                multi_answers: [
                                  ...inputs.multi_answers,
                                  { answer: choice.choice },
                                ],
                              });
                          }}
                        />
                      ) : (
                        <input
                          type="radio"
                          className="blue-radio"
                          checked={selected}
                          onChange={() =>
                            setInputs({
                              ...inputs,
                              multi_answers: [{ answer: choice.choice }],
                            })
                          }
                        />
                      )}
                      <label htmlFor="">{choice.choice}</label>
                      <button
                        className="red-trash-button"
                        onClick={() =>
                          setInputs({
                            ...inputs,
                            multi_choices: inputs.multi_choices.filter(
                              (curChoice: KeyStringVal) =>
                                curChoice.choice !== choice.choice
                            ),
                            multi_answers: inputs.multi_answers.filter(
                              (curChoice: KeyStringVal) =>
                                curChoice.answer !== choice.choice
                            ),
                          })
                        }
                      >
                        <FontAwesomeIcon icon={faXmark} className="mt-1" />
                      </button>
                    </article>
                  );
                })}
              </section>
              <article className="flex items-center gap-4">
                <textarea
                  value={newChoice}
                  placeholder="Input text"
                  onChange={(e) => setNewChoice(e.target.value)}
                  className="px-4 py-2 w-40 h-12 overflow-hidden border-0 bg-transparent dark:hover:bg-gray-700 duration-100 dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
                />
                {newChoice && (
                  <button
                    className="place-self-end small-blue-button"
                    onClick={() => {
                      setInputs({
                        ...inputs,
                        multi_choices: [
                          ...inputs.multi_choices,
                          { choice: newChoice },
                        ],
                      });
                      setNewChoice("");
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    Add
                  </button>
                )}
              </article>
            </section>
          )}
          <article className="flex flex-wrap items-center gap-4">
            <RegularInput
              label="Weight"
              keyName="weight"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Question Mandatory"
              keyName="question_mandatory"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CheckboxInput
              label="Attachment Mandatory"
              keyName="attachment_mandatory"
              inputs={inputs}
              setInputs={setInputs}
            />
          </article>
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
            textarea
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={
                inputs.question === "" ||
                inputs.question_type === "" ||
                (["Multiple Choice", "Multiple Select"].includes(
                  inputs.question_type
                ) &&
                  inputs.multi_answers?.length === 0)
              }
              className="blue-button"
              onClick={() => {
                setNewQuestions([inputs, ...newQuestions]);
                setSelectedQuestions([inputs, ...selectedQuestions]);
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      )}
    </>
  );
};

export default NewQuestions;
