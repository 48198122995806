/* eslint-disable react-hooks/exhaustive-deps */
import {
  faCheck,
  faChevronDown,
  faChevronRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import React, { useEffect, useState } from "react";
import {
  EditPrivacyReviewQuestion,
  GetPrivacyAgreements,
  UpdatePrivacyAgreements,
} from "src/services/settings/privacy-review/settings";
import { KeyStringVal } from "src/types/general";
import { getCustomerID } from "src/utils/general";
import DeleteQuestion from "./DeleteQuestion";

const Settings = () => {
  const customerID = getCustomerID();

  const [initial, setInitial] = useState<boolean>(true);
  const [selectedQuestions, setSelectedQuestions] = useState<string[]>([]);
  const [editedQuestion, setEditedQuestion] = useState<KeyStringVal>({
    id: "",
    question: "",
  });

  const { data: privacyAgreements } = GetPrivacyAgreements(customerID);
  const updateAgreements = UpdatePrivacyAgreements(customerID);
  const editQuestion = EditPrivacyReviewQuestion(customerID);

  const frameworkTypes = [
    ...new Set(
      privacyAgreements?.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.framework_type],
        []
      ) as string[]
    ),
  ].sort();

  useEffect(() => {
    if (privacyAgreements?.length > 0 && initial) {
      const filteredQuestions = privacyAgreements.reduce(
        (pV: string[], cV: KeyStringVal) => {
          if (cV.status) return [...pV, cV.id];
          else return [...pV];
        },
        []
      );
      setSelectedQuestions(filteredQuestions);
      setInitial(false);
    }
  }, [privacyAgreements]);

  return (
    <section className="grid gap-6 w-full h-full">
      <p className="text-b1-reg">
        A data processing agreement, data protection agreement, or data
        processing addendum is a contractual agreement between a data controller
        (a company) and a data processor (a third-party service provider.) It
        defines each party's rights and obligations regarding data protection.
        Uno could help assess a DPA. As Uno assesses a DPA from a standpoint of
        coverage, clarity, comprehensiveness, and enforceability, please choose
        which topics out of the following would you like Uno to include as a
        part of the analysis:
      </p>
      <article className="flex items-center place-content-end gap-4">
        <button
          className="black-button"
          onClick={() => {
            setSelectedQuestions([]);
            setInitial(true);
          }}
        >
          Reset
        </button>
        <button
          className="blue-button"
          onClick={() => {
            const filteredQuestions = [
              ...new Set(
                privacyAgreements
                  ?.filter(
                    (question: KeyStringVal) =>
                      (question.status &&
                        !selectedQuestions.includes(question.id)) ||
                      (!question.status &&
                        selectedQuestions.includes(question.id))
                  )
                  ?.reduce(
                    (pV: string[], cV: KeyStringVal) => [...pV, cV.id],
                    []
                  )
              ),
            ];
            updateAgreements.mutate({
              questions: filteredQuestions,
            });
            setInitial(true);
          }}
        >
          Save
        </button>
      </article>
      {frameworkTypes?.map((frameworkType) => {
        const questions = privacyAgreements?.filter(
          (agreement: KeyStringVal) =>
            frameworkType === agreement.framework_type
        );
        const questionIDs = questions?.reduce(
          (pV: string[], cV: KeyStringVal) => [...pV, cV.id],
          []
        );
        const selectedAll = questionIDs.every((questionID: string) =>
          selectedQuestions.includes(questionID)
        );
        return (
          <section
            key={frameworkType}
            className="flex flex-col flex-grow gap-4 p-4 w-full dark:bg-gray-800 rounded-xl"
          >
            <Disclosure defaultOpen>
              {({ open }) => (
                <>
                  <article className="flex items-center justify-between gap-4 w-full">
                    <article className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        checked={selectedAll}
                        className="white-checkbox"
                        onChange={(e) => {
                          e.stopPropagation();

                          if (selectedAll)
                            setSelectedQuestions(
                              selectedQuestions.filter(
                                (curQuestionID) =>
                                  !questionIDs.includes(curQuestionID)
                              )
                            );
                          else
                            setSelectedQuestions([
                              ...selectedQuestions,
                              ...questionIDs,
                            ]);
                        }}
                      />
                      <h4 className="text-b1-semi">{frameworkType}</h4>
                    </article>
                    <Disclosure.Button className="flex items-center gap-2 dark:text-blue-500">
                      <FontAwesomeIcon
                        icon={open ? faChevronDown : faChevronRight}
                      />
                      Show {open ? "less" : "more"}
                    </Disclosure.Button>
                  </article>
                  <Disclosure.Panel className="grid gap-4">
                    {questions?.map((question: any, index: number) => {
                      const checked = selectedQuestions.includes(question.id);
                      const isCustomer = question.is_customer;
                      return (
                        <article
                          key={index}
                          className="flex items-center justify-between gap-10 pl-4"
                        >
                          <article className="flex items-center gap-4">
                            <input
                              type="checkbox"
                              checked={checked}
                              className="white-checkbox"
                              onChange={() => {
                                if (checked)
                                  setSelectedQuestions(
                                    selectedQuestions.filter(
                                      (curQuestionID) =>
                                        curQuestionID !== question.id
                                    )
                                  );
                                else
                                  setSelectedQuestions([
                                    ...selectedQuestions,
                                    question.id,
                                  ]);
                              }}
                            />
                            {isCustomer ? (
                              <article className="flex items-center justify-between gap-2 px-4 py-1 w-[50rem] border dark:border-gray-700 rounded">
                                <input
                                  type="input"
                                  value={
                                    question.id === editedQuestion.id
                                      ? editedQuestion.question
                                      : question.sub_category_question
                                  }
                                  className="w-full dark:place-holder:text-gray-500 border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
                                  onClick={() =>
                                    setEditedQuestion({
                                      id: question.id,
                                      ...(editedQuestion.id === "" && {
                                        question:
                                          question.sub_category_question,
                                      }),
                                    })
                                  }
                                  onChange={(e) =>
                                    setEditedQuestion({
                                      ...editedQuestion,
                                      question: e.target.value,
                                    })
                                  }
                                />
                                {editedQuestion.id === question.id && (
                                  <article className="flex items-center gap-4">
                                    <button
                                      className="hover-icon"
                                      onClick={() =>
                                        setEditedQuestion({
                                          id: "",
                                          question: "",
                                        })
                                      }
                                    >
                                      <FontAwesomeIcon icon={faXmark} />
                                    </button>
                                    <button
                                      className="hover-icon"
                                      onClick={() => {
                                        editQuestion.mutate({
                                          questionID: question.id,
                                          newQuestion: editedQuestion.question,
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon icon={faCheck} />
                                    </button>
                                  </article>
                                )}
                              </article>
                            ) : (
                              <p className="flex items-center gap-2 px-2 py-1 dark:bg-gray-700 rounded">
                                {question.sub_category_question}
                              </p>
                            )}
                          </article>
                          {isCustomer && (
                            <DeleteQuestion questionID={question.id} />
                          )}
                        </article>
                      );
                    })}
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </section>
        );
      })}
    </section>
  );
};

export default Settings;
