import { useQuery, useMutation } from "react-query";
import { queryClient } from "src/App";
import { client } from "src/components/General/AxiosInterceptor";
import { apiVersion, pageSize } from "src/constants/general";
import { KeyStringArrayVal } from "src/types/general";

export const controlIssuePrefix = "grc/grc-controls/control-issues";

export const GetControlIssues = (
  pageNumber: number,
  tags?: string[] | undefined,
  columns?: string[],
  queryList?: any
) =>
  useQuery<
    any,
    unknown,
    any,
    (string | number | KeyStringArrayVal | string[] | undefined)[]
  >(
    ["get-control-issues", pageNumber, tags],
    async ({ signal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}/getall`,
          {
            pager: {
              page_number: pageNumber,
              page_size: pageSize,
            },
            tags,
            ...(columns?.length >= 5 && {
              selected_columns: columns,
            }),
            ...(queryList?.length > 0 && {
              query_params: queryList,
            }),
          },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      keepPreviousData: true,
    }
  );

export const AddControlIssue = () =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries(["get-control-issues"]),
    }
  );

export const EditControlIssue = (controlIssueID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ inputs, signal }: { inputs: any; signal: AbortSignal }) => {
      try {
        const res = await client.patch(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}`,
          inputs,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-issue-metadata"]);
      },
    }
  );

export const GetControlIssueMetadata = (controlIssueID: string) =>
  useQuery<any, unknown, any, (string | undefined)[]>(
    ["get-control-issue-metadata", controlIssueID],
    async ({ signal }) => {
      try {
        const res = await client.get(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      enabled: controlIssueID !== "",
      keepPreviousData: false,
    }
  );

export const AssignTagToControlIssue = (controlIssueID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}/tag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-issues"]);
        queryClient.invalidateQueries(["get-control-issue-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control-issue"]);
      },
    }
  );

export const RemoveTagFromControlIssue = (controlIssueID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ tagName, signal }: { tagName: string; signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}/untag`,
          { tag_name: tagName },
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-issues"]);
        queryClient.invalidateQueries(["get-control-issue-metadata"]);
        queryClient.invalidateQueries(["get-tags-for-control-issue"]);
      },
    }
  );

export const RestoreControlIssue = (controlIssueID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}/restore`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-issues"]);
        queryClient.invalidateQueries(["get-control-issue-metadata"]);
      },
    }
  );

export const ArchiveControlIssue = (controlIssueID: string) =>
  useMutation<any, unknown, any, string>(
    async ({ signal }: { signal: AbortSignal }) => {
      try {
        const res = await client.post(
          `/api/${apiVersion}/${controlIssuePrefix}/${controlIssueID}/archive`,
          {
            signal,
          }
        );
        return res?.data;
      } catch (error) {
        if (error instanceof Error) throw new Error(error.message);
      }
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["get-control-issues"]);
        queryClient.invalidateQueries(["get-control-issue-metadata"]);
      },
    }
  );
