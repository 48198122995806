import React from "react";
import { convertToUTCShortString } from "src/utils/general";
import { attributeColors, attributeTextColors } from "src/constants/general";
import { GetVendorMetadata } from "src/services/third-party-risk/vendors/vendors";
import VendorTags from "../VendorTags";
import VendorGroupFilter from "../../../../../components/Filter/ThirdPartyRisk/VendorGroupFilter";
import Status from "./Status";
import SendEmail from "./SendEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { Disclosure } from "@headlessui/react";
import ExportResponses from "./DocumentList/VendorResponseList/ExportResponses";
import MetadataField from "src/components/GRC/MetadataField";
import NewAssessment from "./NewAssessment";
import InherentCheck from "./InherentCheck";
import UpdateVendor from "./UpdateVendor";
import ReturnPage from "src/components/Button/ReturnPage";
import { KeyStringVal } from "src/types/general";

const VendorMetadata = ({
  vendorID,
  assessmentID,
}: {
  vendorID: string;
  assessmentID: string | undefined;
}) => {
  const hasAssessment = sessionStorage.assessmentCycle && assessmentID;

  const { data: metadata } = GetVendorMetadata(vendorID, assessmentID);

  return (
    <>
      <article className="flex items-center gap-2">
        <ReturnPage />
        <h4 className="flex items-center gap-2 text-b3-reg">
          <span className="dark:text-gray-500">Third Party Risk</span>{" "}
          <span>/</span>
          <span>Vendor</span>
        </h4>
      </article>
      {metadata && (
        <header className="grid gap-3">
          <section className="flex flex-col flex-grow gap-4 p-6 dark:bg-gray-800 rounded-2xl">
            {hasAssessment && (
              <h4 className="text-subt1-semi">
                Assessment: {sessionStorage.assessmentCycle}
              </h4>
            )}
            <header className="flex items-start justify-between gap-4">
              <article className="grid content-start gap-1">
                <article className="flex items-center gap-4">
                  {metadata.logo_uri && (
                    <img
                      src={metadata.logo_uri}
                      alt="logo"
                      className="w-10 h-10 rounded-full"
                    />
                  )}
                  <h2 className="text-subt1-semi">{metadata.name}</h2>
                  <VendorGroupFilter label="Vendor Group" vendor={metadata} />
                </article>
                <article className="flex items-center gap-1 text-b2-reg dark:text-gray-300">
                  <h4>last update at</h4>
                  <span>{convertToUTCShortString(metadata.last_updated)}</span>
                </article>
              </article>
              <article className="flex items-center gap-4">
                {!hasAssessment && (
                  <>
                    <InherentCheck vendorID={vendorID} />
                    <NewAssessment vendorID={vendorID} vendor={metadata} />
                  </>
                )}
                {hasAssessment &&
                  metadata.expiry_time >= 0 &&
                  metadata?.status !== "CLOSED" && (
                    <SendEmail
                      vendorID={vendorID}
                      assessmentID={assessmentID}
                      vendor={metadata}
                    />
                  )}
                <Status vendorID={vendorID} status={metadata.status} />
                {hasAssessment && (
                  <ExportResponses
                    vendorID={vendorID}
                    assessmentID={assessmentID}
                  />
                )}
                <UpdateVendor vendorID={vendorID} vendor={metadata} />
              </article>
            </header>
            <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
              <article className="flex flex-wrap items-center gap-2">
                <article
                  className={`flex items-center gap-1 ${
                    attributeColors[metadata.status.toLowerCase()]
                  }`}
                >
                  Status
                  <span
                    className={`capitalize ${
                      attributeTextColors[metadata.status.toLowerCase()]
                    }`}
                  >
                    {metadata.status.toLowerCase()}
                  </span>
                </article>
              </article>
              {metadata.email_sent && metadata.expiry_time >= 0 && (
                <span>
                  <FontAwesomeIcon
                    icon={faWarning}
                    className="text-yellow-500"
                  />{" "}
                  {metadata.expiry_time <= 24
                    ? `${metadata.expiry_time} hours`
                    : `${Math.round(metadata.expiry_time / 24)} days`}{" "}
                  left for response
                </span>
              )}
            </section>
            <MetadataField
              metadata={metadata}
              label="Description"
              keyName="description"
            />
            <Disclosure defaultOpen>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Primary Vendor Information</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Vendor Type"
                        keyName="vendor_type"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Assessed for AI Functionality"
                        keyName="assessed_for_ai_functionality"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Website"
                        keyName="website"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Address"
                        keyName="address"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Number of Employees"
                        keyName="number_of_employees"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Ref-ID"
                        keyName="ref_id"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="DUNS Number"
                        keyName="duns_number"
                      />
                      {metadata.rank_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Rank Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[metadata.rank_tier?.toLowerCase()]
                            } ${attributeTextColors[metadata.rank_tier]}`}
                          >
                            {metadata.rank_tier}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={metadata}
                        label="Is Publicly Traded"
                        keyName="is_publicly_traded"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Risk Management</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Application Owner Email"
                        keyName="primary_app_service"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Vendor Manager"
                        keyName="vendor_manager"
                        multipleUsers
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Help Ticket"
                        keyName="help_ticket"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Sponsor"
                        keyName="sponsor"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Criticality"
                        keyName="criticality"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Data Classification"
                        keyName="data_classification"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Last Review Date"
                        keyName="last_review_date"
                        isDate
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Next Review Date"
                        keyName="next_review_date"
                        isDate
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Reassessment Frequency"
                        keyName="reassessment_frequency"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Notes"
                        keyName="notes"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Additional Notes"
                        keyName="additional_notes"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Analyst Comments"
                        keyName="analyst_comments"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Vendor Review Checklist"
                        keyName="review_checklist"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Additional Information"
                        keyName="additional_information"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Impacted Systems"
                        keyName="impacted_systems"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="SSO Enabled"
                        keyName="sso_enabled"
                      />
                      {metadata.vendor_tier && (
                        <article className="grid content-start gap-1">
                          <h4 className="dark:text-gray-300 text-b2-reg">
                            Vendor Tier
                          </h4>
                          <span
                            className={`capitalize ${
                              attributeColors[
                                metadata.vendor_tier?.toLowerCase()
                              ]
                            } ${attributeTextColors[metadata.vendor_tier]}`}
                          >
                            {metadata.vendor_tier}
                          </span>
                        </article>
                      )}
                      <MetadataField
                        metadata={metadata}
                        label="Business Associate Agreement Executed"
                        keyName="baa_executed"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Vendor Contact Information</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="flex flex-col flex-grow gap-6">
                      {metadata.contacts?.map(
                        (contact: KeyStringVal, index: number) => {
                          const keys = Object.keys(contact);

                          return (
                            <section
                              key={index}
                              className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl"
                            >
                              {keys.map((key) => {
                                if (key.includes("id")) return null;

                                return (
                                  <article
                                    key={key}
                                    className="grid content-start"
                                  >
                                    <h4 className="capitalize dark:text-gray-300 text-b2-semi">
                                      {key.replaceAll("_", " ")}
                                    </h4>
                                    <span className="text-b1-semi">
                                      {contact[key]}
                                    </span>
                                  </article>
                                );
                              })}
                            </section>
                          );
                        }
                      )}
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Scoping Details</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Target Primary Application Service"
                        keyName="target_primary_application_service"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Authentication Protocol"
                        keyName="authentication_protocol"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="PHI"
                        keyName="is_PHI"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="PII"
                        keyName="is_PII"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Send Risk Assessment"
                        keyName="send_risk_assessment"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
            <Disclosure>
              {({ open }) => (
                <section className="grid content-start gap-3">
                  <Disclosure.Button className="flex items-center gap-2 w-max">
                    <h4 className="text-b1-semi">Others</h4>
                    <FontAwesomeIcon
                      icon={open ? faChevronDown : faChevronRight}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel>
                    <section className="grid md:grid-cols-5 content-start gap-x-4 gap-y-10 p-4 w-full dark:bg-gray-900 rounded-xl">
                      <MetadataField
                        metadata={metadata}
                        label="Workflow"
                        keyName="workflow"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Dow Jones Nth Party Risks"
                        keyName="dj_nth_party_risks"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Internal Email CC List"
                        keyName="internal_email_list"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Name of Business User"
                        keyName="business_user_name"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Head of Department"
                        keyName="department_head"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Department"
                        keyName="department"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Stage"
                        keyName="stage"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="Historical Data"
                        keyName="historical_data"
                      />
                      <MetadataField
                        metadata={metadata}
                        label="External Document URL"
                        keyName="external_document_url"
                      />
                    </section>
                  </Disclosure.Panel>
                </section>
              )}
            </Disclosure>
          </section>
          <section className="p-6 dark:bg-gray-800 rounded-2xl">
            <VendorTags vendorID={vendorID} tags={metadata.tags} />
          </section>
        </header>
      )}
    </>
  );
};

export default VendorMetadata;
