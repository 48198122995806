import React, { useEffect } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import FrameworkControlMetadata from "./FrameworkControlMetadata";

const FrameworkControl = () => {
  const parsed = parseURL();

  const frameworkControlID = String(parsed.framework_control_id) || "";

  useEffect(() => {
    sessionStorage.ERCCategory = "controls";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-10 p-4 pb-24 min-h-full w-full overflow-auto scrollbar">
        <FrameworkControlMetadata frameworkControlID={frameworkControlID} />
      </main>
    </PageLayout>
  );
};

export default FrameworkControl;
