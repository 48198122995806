import React, { useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronRight,
  faEdit,
} from "@fortawesome/free-solid-svg-icons";
import RegularInput from "src/components/Input/RegularInput";
import { EditRiskBlueprint } from "src/services/erc/risks/risk-blueprints";
import AssessmentQuestionnaireFilter from "src/components/Filter/ERC/AssessmentQuestionnaireFilter";
import CheckboxInput from "src/components/Input/CheckboxInput";
import MultiUserInput from "src/components/Input/MultiUserInput";
import CategoryInput from "src/components/Input/CategoryInput";
import MultiControlBlueprintFilter from "src/components/Filter/ERC/MultiControlBlueprintFilter";
import MultiEntityTypeFilter from "src/components/Filter/ERC/MultiEntityTypeFilter";
import SourceControlFilter from "src/components/Filter/ERC/SourceControlFilter";
import SourceNameFilter from "src/components/Filter/ERC/SourceNameFilter";
import ThreatsFilter from "src/components/Filter/ERC/ThreatsFilter";
import VulnerabilitiesFilter from "src/components/Filter/ERC/VulnerabilitiesFilter";
import { Disclosure } from "@headlessui/react";
import { checkIsReadOnly } from "src/utils/general";

const UpdateRiskBlueprint = ({
  riskBlueprintID,
  blueprint,
}: {
  riskBlueprintID: string;
  blueprint: any;
}) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({});
  const [valid, setValid] = useState<boolean>(true);

  const editRiskBlueprint = EditRiskBlueprint(riskBlueprintID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="place-self-end edit-button"
        onClick={() => {
          setShow(true);
          setInputs({
            ...blueprint,
            threats:
              blueprint.threats?.reduce(
                (pV, cV) => [...pV, cV.threat_id],
                []
              ) || [],
            vulnerabilities:
              blueprint.vulnerabilities?.reduce(
                (pV, cV) => [...pV, cV.vulnerability_id],
                []
              ) || [],
            owners:
              blueprint.owners?.reduce((pV, cV) => [...pV, cV.user_id], []) ||
              [],
            reviewers:
              blueprint.reviewers?.reduce(
                (pV, cV) => [...pV, cV.user_id],
                []
              ) || [],
            approvers:
              blueprint.approvers?.reduce(
                (pV, cV) => [...pV, cV.user_id],
                []
              ) || [],
            deadline: blueprint.deadline || Date.now() * 1000,
            entity_types:
              blueprint.entity_types?.reduce(
                (pV, cV) => [...pV, cV.entity_type_id],
                []
              ) || [],
            control_blueprints:
              blueprint.control_blueprints?.reduce(
                (pV, cV) => [...pV, cV.control_blueprint_id],
                []
              ) || [],
          });
        }}
      >
        <FontAwesomeIcon icon={faEdit} />
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid content-start gap-4 w-full h-full">
          <h3 className="flex items-center gap-2 text-t1-semi">
            Edit Risk Blueprint
          </h3>
          <section className="grid md:grid-cols-2 gap-4">
            <RegularInput
              label="Name"
              keyName="name"
              inputs={inputs}
              setInputs={setInputs}
              valid={valid}
              setValid={setValid}
              required
            />
            <RegularInput
              label="Classification"
              keyName="classification"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Type"
              keyName="type"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Category"
              keyName="category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Subcategory"
              keyName="sub_category"
              inputs={inputs}
              setInputs={setInputs}
            />
            <ThreatsFilter
              label="Threats"
              inputs={inputs}
              setInputs={setInputs}
            />
            <VulnerabilitiesFilter
              label="Vulnerabilities"
              inputs={inputs}
              setInputs={setInputs}
            />
            <SourceNameFilter
              label="Source Name"
              keyName="source_name"
              inputs={inputs}
              setInputs={setInputs}
            />
            {inputs.source_name && (
              <SourceControlFilter
                label="Source Id"
                keyName="source_id"
                inputs={inputs}
                setInputs={setInputs}
              />
            )}
            <RegularInput
              label="State"
              keyName="state"
              inputs={inputs}
              setInputs={setInputs}
            />
            <CategoryInput
              label="Status"
              keyName="status"
              list={["DRAFT", "INACTIVE", "ACTIVE", "ARCHIVED"]}
              inputs={inputs}
              setInputs={setInputs}
              allowUnselect
            />
            <MultiEntityTypeFilter
              label="Entity Types"
              keyName="entity_types"
              inputs={inputs}
              setInputs={setInputs}
            />
            <MultiControlBlueprintFilter
              label="Control Blueprints"
              keyName="control_blueprints"
              inputs={inputs}
              setInputs={setInputs}
            />
            <AssessmentQuestionnaireFilter
              label="Risk Assessment"
              keyName="risk_assessment_questionnaire_id"
              inputs={inputs}
              setInputs={setInputs}
            />
            <RegularInput
              label="Description"
              keyName="description"
              inputs={inputs}
              setInputs={setInputs}
              textarea
            />
          </section>
          <Disclosure>
            {({ open }) => (
              <section className="grid content-start gap-3">
                <Disclosure.Button className="flex items-center gap-2 w-max">
                  <h4 className="text-subt1-semi">
                    Owners, Reviewers, & Approvers
                  </h4>
                  <FontAwesomeIcon
                    icon={open ? faChevronDown : faChevronRight}
                  />
                </Disclosure.Button>
                <Disclosure.Panel>
                  <section className="grid md:grid-cols-2 gap-4">
                    <CheckboxInput
                      label="Owner Same as Entity Owner"
                      keyName="owner_same_as_entity_owner"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                    {!inputs.owner_same_as_entity_owner && (
                      <MultiUserInput
                        label="Owners"
                        keyName="owners"
                        inputs={inputs}
                        setInputs={setInputs}
                        allowUnselect
                        allowNewValue
                      />
                    )}
                    <MultiUserInput
                      label="Reviewers"
                      keyName="reviewers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <MultiUserInput
                      label="Approvers"
                      keyName="approvers"
                      inputs={inputs}
                      setInputs={setInputs}
                      allowUnselect
                      allowNewValue
                    />
                    <CheckboxInput
                      label="Create Risks Automatically"
                      keyName="create_risks_automatically"
                      inputs={inputs}
                      setInputs={setInputs}
                    />
                  </section>
                </Disclosure.Panel>
              </section>
            )}
          </Disclosure>
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.name === ""}
              className="blue-button"
              onClick={() => {
                editRiskBlueprint.mutate({
                  inputs,
                });
                handleOnClose();
              }}
            >
              <h4>Done</h4>
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default UpdateRiskBlueprint;
