import React, { useState } from "react";
import CheckboxInput from "src/components/Input/CheckboxInput";
import ModalLayout from "src/layouts/ModalLayout";
import { CloseInternalAudit } from "src/services/audit-management";
import { ExportInternalAudit } from "src/services/audit-management";
import { checkIsReadOnly } from "src/utils/general";

const CloseAudit = ({ auditID }: { auditID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState({
    trigger_export: false,
  });

  const closeAudit = CloseInternalAudit(auditID);
  const exportInternalAudit = ExportInternalAudit(auditID);

  const handleOnClose = () => setShow(false);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="blue-button"
        onClick={() => setShow(true)}
      >
        Close Audit
      </button>
      <ModalLayout showModal={show} onClose={() => setShow(false)}>
        <section className="grid gap-4">
          <h2 className="text-subt1-semi">Close Internal Audit</h2>
          <h4 className="text-b1-reg">
            Are you sure you want to close this internal audit?
          </h4>
          <CheckboxInput
            label="Do you want to export?"
            keyName="trigger_export"
            inputs={inputs}
            setInputs={setInputs}
          />
          <section className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              className="red-button"
              onClick={() => {
                if (inputs.trigger_export) exportInternalAudit.mutate({});
                closeAudit.mutate({
                  auditID: auditID,
                });
                handleOnClose();
              }}
            >
              Close
            </button>
          </section>
        </section>
      </ModalLayout>
    </>
  );
};

export default CloseAudit;
