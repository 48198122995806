/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { showVariants } from "src/constants/general";
import PageLayout from "src/layouts/PageLayout";
import PolicyGroups from "./Policies/PolicyGroups/PolicyGroups";
import Documents from "./FrameworksAndCirculars/Documents";
import { faXmark, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useGRCStore } from "src/stores/grc";
import SearchResults from "./SearchResults";
import { riskComplianceTabs } from "src/constants/grc";
import Overview from "./Overview/Overview";
import { SearchGRC } from "src/services/grc";

const RegulationPolicy = () => {
  const { GRCCategory, setGRCCategory, GRCQuery, setGRCQuery, GRCQueryOption } =
    useGRCStore();

  const search = SearchGRC();

  useEffect(() => {
    sessionStorage.page = "Regulation & Policy";
    if (riskComplianceTabs.includes(sessionStorage.GRCCategory))
      setGRCCategory(sessionStorage.GRCCategory);
    else setGRCCategory("overview");
  }, []);

  const handleSearch = () => {
    search.mutate(
      {
        query: GRCQuery,
        searchType: GRCQueryOption,
      },
      {
        onSuccess: () => {
          setGRCCategory("policies");
          sessionStorage.GRCCategory = "policies";
        },
      }
    );
  };

  useEffect(() => {
    if (GRCQuery === "") search.reset();
  }, [GRCQuery]);

  useEffect(() => {
    setGRCQuery("");
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow gap-4 p-4 w-full min-h-full text-base overflow-auto no-scrollbar"
      >
        <section className="flex flex-col flex-grow gap-4 h-full">
          <header className="flex flex-wrap items-center justify-between gap-10">
            <nav className="flex flex-wrap items-center gap-1 p-1 dark:bg-gray-900 rounded-lg">
              {riskComplianceTabs.map((tab) => {
                if (search.data && tab === "overview") return null;

                return (
                  <button
                    key={tab}
                    className={`px-4 py-1 text-center capitalize ${
                      GRCCategory === tab
                        ? "dark:text-white dark:bg-gray-800"
                        : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                    } rounded-lg`}
                    onClick={() => setGRCCategory(tab)}
                  >
                    {tab}
                  </button>
                );
              })}
            </nav>
            <article className="flex items-center px-4 dark:bg-gray-800 border dark:border-gray-700 rounded-sm">
              <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className="dark:text-gray-500"
              />
              <input
                type="input"
                autoComplete="off"
                spellCheck="false"
                placeholder="Search by metadata and content"
                value={GRCQuery}
                onKeyUpCapture={(e) => {
                  if (e.key === "Enter" && GRCQuery !== "") handleSearch();
                }}
                onChange={(e) => setGRCQuery(e.target.value)}
                className="p-4 pr-12 w-[30rem] h-8 dark:bg-gray-800 focus:outline-none"
              />
              <article className="flex items-center gap-2 divide-x dark:divide-checkbox">
                {GRCQuery !== "" && (
                  <button
                    data-test="clear-query"
                    className="trash-button"
                    onClick={() => setGRCQuery("")}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </button>
                )}
                <button
                  disabled={GRCQuery === ""}
                  className="px-2 dark:disabled:text-blue-500/30 dark:text-blue-500 dark:hover:text-blue-500/60 duration-100"
                  onClick={handleSearch}
                >
                  <FontAwesomeIcon icon={faMagnifyingGlass} />
                </button>
              </article>
            </article>
          </header>
          {search.data ? (
            <SearchResults searchData={search.data} />
          ) : (
            <>
              {GRCCategory === "overview" && <Overview />}
              {["frameworks", "circulars"].includes(GRCCategory) && (
                <Documents documentType={GRCCategory} />
              )}
              {GRCCategory === "policies" && <PolicyGroups />}
            </>
          )}
        </section>
      </motion.main>
    </PageLayout>
  );
};

export default RegulationPolicy;
