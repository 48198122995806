import React from "react";
import { KeyStringVal } from "src/types/general";

const ConditionValue = ({
  keyName,
  queryList,
  setQueryList,
  id,
  disabled,
}: {
  keyName: string;
  queryList: any;
  setQueryList: (queryList: any) => void;
  id: string;
  disabled?: boolean;
}) => {
  return (
    <article className="grid content-start text-b2-reg">
      <input
        spellCheck="false"
        autoComplete="off"
        value={queryList[keyName]}
        disabled={disabled}
        placeholder="Input value"
        onChange={(e) =>
          setQueryList(
            queryList.map((curCondition: KeyStringVal) => {
              if (curCondition.id === id)
                return {
                  ...curCondition,
                  [keyName]: e.target.value,
                };
              else return curCondition;
            })
          )
        }
        type="input"
        className="px-4 py-2 w-full h-9 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
      />
    </article>
  );
};

export default ConditionValue;
