import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import FilterLayout from "src/layouts/FilterLayout";
import { KeyStringVal } from "src/types/general";

const Sort = ({
  sortingTypes,
  sort,
  setSort,
  showOnLeft,
}: {
  sortingTypes: string[];
  sort: KeyStringVal;
  setSort: (sort: KeyStringVal) => void;
  showOnLeft?: boolean;
}) => {
  return (
    <FilterLayout label="Sort by" showOnLeft={showOnLeft}>
      <ul className="flex flex-col flex-grow gap-1">
        {sortingTypes?.map((sortingType: string) => {
          return (
            <li
              key={sortingType}
              className="relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 rounded-lg"
              onClick={() =>
                setSort({
                  direction: sort.direction === "asc" ? "desc" : "asc",
                  order_by: sortingType,
                })
              }
            >
              <FontAwesomeIcon icon={faSort} />
              <p className="w-max capitalize">
                {sortingType.replace("_", " ").replace("framework", "")}
              </p>
            </li>
          );
        })}
      </ul>
    </FilterLayout>
  );
};

export default Sort;
