/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { GetCustomerEnvs } from "src/services/settings/environments";
import {
  GetAccountInfo,
  UpdateAccount,
} from "../../../services/settings/integrations";
import { CustomerEnv } from "../../../types/settings";
import { getCustomerID } from "../../../utils/general";
import { KeyStringVal } from "src/types/general";
import { ChevronDownIcon } from "@heroicons/react/solid";

const AccountEnvFilter = ({
  label,
  keyName,
  inputs,
  setInputs,
  selectedAccountID,
  required,
}: {
  label: string;
  keyName: string;
  inputs: any;
  setInputs: any;
  selectedAccountID: string;
  required?: boolean;
}) => {
  const customerID = getCustomerID();

  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const { data: customerEnvs } = GetCustomerEnvs(customerID);
  const { data: accountInfo } = GetAccountInfo(customerID, selectedAccountID);
  const updateAccount = UpdateAccount(customerID);

  const filteredItems = customerEnvs?.filter((item: KeyStringVal) =>
    item.env_type
      .toLowerCase()
      .replace(/\s+/g, "")
      .includes(query.toLowerCase().replace(/\s+/g, ""))
  );

  useEffect(() => {
    if (accountInfo) {
      const accountEnvIDs = accountInfo.customer_env_associations.reduce(
        (pV: string[], cV: CustomerEnv) => [...pV, cV.env_id],
        []
      );
      setInputs({ ...inputs, envs: accountEnvIDs });
    }
  }, [accountInfo]);

  return (
    <section className="grid content-start gap-1 text-b2-reg">
      <header className="flex items-center gap-1 justify-self-start">
        <h4>{label}</h4>
        {required && <span className="-mt-2 text-h5 text-red-500">*</span>}
      </header>
      <article
        onMouseLeave={() => setShowDropdown(false)}
        onClick={() => setShowDropdown(true)}
        className="relative px-4 py-2 w-full h-9 dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 focus:outline-none rounded"
      >
        <article className="flex items-center justify-between gap-2">
          <input
            type="input"
            autoComplete="off"
            spellCheck="false"
            placeholder={`${inputs[keyName].length} selected`}
            value={query}
            onChange={(e) => {
              if (!showDropdown) setShowDropdown(true);
              setQuery(e.target.value);
            }}
            className="w-full placeholder:normal-case capitalize border-transparent focus:ring-0 focus:border-transparent bg-transparent focus:outline-none"
          />
          <span className="flex items-center pointer-events-none">
            <ChevronDownIcon
              className="w-5 h-5 dark:text-gray-500"
              aria-hidden="true"
            />
          </span>
        </article>
        {showDropdown && (
          <article className="absolute top-9 left-0 grid gap-1 p-1 content-start w-full max-h-36 dark:bg-gray-900 focus:outline-none shadow dark:shadow-black overflow-auto scrollbar rounded-b-sm z-50">
            {filteredItems?.map((item: KeyStringVal) => {
              const selected = inputs[keyName].includes(item.env_id);
              return (
                <button
                  key={item.control_blueprint_id}
                  className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
                    selected ? "dark:bg-gray-800" : ""
                  } rounded-lg`}
                  onClick={() => {
                    setQuery("");
                    if (selected) {
                      const filteredEnvs = inputs[keyName].filter(
                        (envID: string) => item.env_id !== envID
                      );
                      setInputs({
                        ...inputs,
                        [keyName]: filteredEnvs,
                      });
                      if (selectedAccountID !== "")
                        updateAccount.mutate({
                          accountID: selectedAccountID,
                          account: {
                            customer_envs: [...inputs[keyName], item.env_id],
                          },
                        });
                    } else {
                      const filteredEnvs = [...inputs[keyName], item.env_id];
                      setInputs({
                        ...inputs,
                        [keyName]: filteredEnvs,
                      });
                      if (selectedAccountID !== "")
                        updateAccount.mutate({
                          accountID: selectedAccountID,
                          account: {
                            customer_envs: filteredEnvs,
                          },
                        });
                    }
                  }}
                >
                  {selected && <FontAwesomeIcon icon={faCheck} />}
                  {item.env_type}
                </button>
              );
            })}
          </article>
        )}
      </article>
      <ul className="flex flex-wrap items-center gap-2 mt-1">
        {inputs[keyName].map((envID: string) => {
          const value =
            customerEnvs?.find((env: KeyStringVal) => env.env_id === envID)
              ?.env_type || "";

          return (
            <li
              key={envID}
              className="grid gap-1 px-2 py-1 dark:bg-gray-600 rounded-md"
            >
              <h4 className="text-b2-reg">{value}</h4>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default AccountEnvFilter;
