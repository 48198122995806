import React, { useEffect, useState } from "react";
import PageLayout from "src/layouts/PageLayout";
import { parseURL } from "src/utils/general";
import VendorMetadata from "./VendorMetadata";
import DocumentList from "./DocumentList/DocumentList";
import QnA from "./QnA/QnA";
import AssessmentList from "./AssessmentList";
import RiskList from "./RiskList";
import EntityList from "./EntityList";
import AssessmentRiskList from "./AssessmentRiskList";
import RiskScore from "./RiskScore";

const Vendor = () => {
  const parsed = parseURL();

  const [selectedTab, setSelectedTab] = useState<string>("Documents");

  const vendorID = String(parsed.vendor_id) || "";
  const assessmentID = parsed.assessment_id;

  useEffect(() => {
    sessionStorage.GRCCategory = "vendors";
  }, []);

  return (
    <PageLayout>
      <main className="relative flex flex-col flex-grow gap-4 p-4 pb-24 h-full w-full overflow-auto scrollbar">
        <VendorMetadata vendorID={vendorID} assessmentID={assessmentID} />
        {!assessmentID ? (
          <>
            <AssessmentList vendorID={vendorID} assessmentID={assessmentID} />
            <EntityList vendorID={vendorID} />
            <RiskList vendorID={vendorID} assessmentID={assessmentID} />
          </>
        ) : (
          <AssessmentRiskList vendorID={vendorID} assessmentID={assessmentID} />
        )}
        <RiskScore vendorID={vendorID} assessmentID={assessmentID} />
        <nav className="flex flex-wrap items-center gap-1 p-1 w-max text-b1-reg dark:bg-gray-900 rounded-lg">
          {["Documents", "QnA"].map((tab) => {
            return (
              <button
                key={tab}
                className={`px-4 py-1 text-center capitalize ${
                  selectedTab === tab
                    ? "dark:text-white dark:bg-gray-800"
                    : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                } rounded-lg`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </button>
            );
          })}
        </nav>
        {selectedTab === "Documents" ? (
          <DocumentList vendorID={vendorID} assessmentID={assessmentID} />
        ) : (
          <QnA vendorID={vendorID} assessmentID={assessmentID} />
        )}
      </main>
    </PageLayout>
  );
};

export default Vendor;
