import {
  faCheck,
  faChevronDown,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure, Transition } from "@headlessui/react";
import React from "react";
import { KeyStringVal } from "src/types/general";
import AIGeneratedAnswer from "./AIGeneratedAnswer";
import { GetControlToAuditMapping } from "src/services/third-party-risk/assessments/vendor-assessment";
import { checkIsReadOnly, sortNumericData } from "src/utils/general";
import { UpdateAuditSections } from "src/services/third-party-risk/assessments/vendor-assessment";
import Source from "../../../../../../../../../../QuestionnaireRFP/Assessments/AssessmentDetail/ResponseList/Source";
import Markdown from "react-markdown";

const SubsectionContent = ({
  documentID,
  reviewID,
  auditID,
  subsection,
  selectedTab,
  editSections,
  setEditSections,
  opened,
  setOpened,
}: {
  documentID: string;
  reviewID: string;
  auditID: string;
  subsection: any;
  selectedTab: string;
  editSections: any;
  setEditSections: any;
  opened?: boolean;
  setOpened?: (opened: boolean) => void;
}) => {
  const isReadOnly = checkIsReadOnly();

  const isControls = selectedTab === "Controls Coverage";
  const generatedID = subsection.generated_id;

  const controlToAuditMapping = GetControlToAuditMapping(
    documentID,
    auditID,
    generatedID
  );
  const updateAuditSections = UpdateAuditSections(
    auditID,
    documentID,
    reviewID
  );

  const sortedControlToAuditMapping = sortNumericData(
    controlToAuditMapping?.data?.data,
    "ip_score",
    "desc"
  );

  return (
    <section className="text-b1-reg">
      {isControls ? (
        <section className="grid gap-4">
          <Markdown>{subsection.content}</Markdown>
          {subsection.mapped_sections > 0 && (
            <section className="grid gap-4">
              <AIGeneratedAnswer
                reviewID={reviewID}
                generatedID={generatedID}
              />
              <Disclosure>
                {({ open }) => {
                  return (
                    <section className="grid gap-2 text-sm">
                      <Disclosure.Button
                        className="flex items-center gap-2 w-max"
                        onClick={() => controlToAuditMapping.mutate({})}
                      >
                        <h4 className="flex items-center gap-1">
                          <FontAwesomeIcon icon={faCheck} className="text-no" />
                          {subsection.mapped_sections} Citation
                          {subsection.mapped_sections !== 1 && "s"} in Audit
                          Report
                        </h4>
                        <FontAwesomeIcon
                          icon={open ? faChevronDown : faChevronRight}
                          className="dark:text-gray-400"
                        />
                      </Disclosure.Button>
                      <Transition
                        show={open}
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      >
                        <Disclosure.Panel>
                          <section className="flex flex-wrap items-center gap-4">
                            {sortedControlToAuditMapping?.map(
                              (source: KeyStringVal, sourceIndex: number) => {
                                return (
                                  <Source
                                    key={sourceIndex}
                                    sourceIndex={sourceIndex}
                                    source={source}
                                  />
                                );
                              }
                            )}
                          </section>
                        </Disclosure.Panel>
                      </Transition>
                    </section>
                  );
                }}
              </Disclosure>
            </section>
          )}
        </section>
      ) : (
        <Disclosure>
          {({ open }) => {
            const show = opened;

            return (
              <section className="text-sm">
                <Disclosure.Button
                  className="flex items-center gap-2"
                  onClick={() => {
                    if (opened != null && setOpened) setOpened(!opened);
                    if (isControls) controlToAuditMapping.mutate({});
                  }}
                >
                  <FontAwesomeIcon
                    icon={show ? faChevronDown : faChevronRight}
                  />
                  <h4>{show ? "Hide" : "Show"} content</h4>
                </Disclosure.Button>
                <Transition
                  show={show}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                >
                  <Disclosure.Panel className="grid content-start gap-2 w-full max-h-[36rem] break-words overflow-auto scrollbar">
                    {editSections &&
                    Object.keys(editSections).includes(
                      subsection.generated_id
                    ) ? (
                      <>
                        <article className="flex items-center place-content-end gap-4 text-b2-reg">
                          <button
                            disabled={isReadOnly}
                            className="black-button"
                            onClick={() =>
                              setEditSections(
                                Object.fromEntries(
                                  Object.entries(editSections).filter(
                                    ([key]) => key !== subsection.generated_id
                                  )
                                )
                              )
                            }
                          >
                            Discard
                          </button>
                          {subsection.content !==
                            editSections[subsection.generated_id].content && (
                            <button
                              disabled={isReadOnly}
                              className="blue-button"
                              onClick={() => {
                                updateAuditSections.mutate({
                                  editSections: editSections,
                                });
                                setEditSections({});
                              }}
                            >
                              Save
                            </button>
                          )}
                        </article>

                        <textarea
                          disabled={isReadOnly}
                          spellCheck="false"
                          autoComplete="off"
                          value={editSections[subsection.generated_id]?.content}
                          onChange={(e) =>
                            setEditSections({
                              ...editSections,
                              [subsection.generated_id]: {
                                content: e.target.value,
                              },
                            })
                          }
                          className="px-4 py-2 w-full h-40 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 rounded"
                        />
                      </>
                    ) : (
                      <>
                        <Markdown>{subsection.content}</Markdown>
                        {editSections &&
                          !editSections[subsection.generated_id]?.content && (
                            <button
                              disabled={isReadOnly}
                              className="blue-button"
                              onClick={() =>
                                setEditSections({
                                  ...editSections,
                                  [subsection.generated_id]: {
                                    content: subsection.content,
                                  },
                                })
                              }
                            >
                              Edit
                            </button>
                          )}
                      </>
                    )}
                  </Disclosure.Panel>
                </Transition>
              </section>
            );
          }}
        </Disclosure>
      )}
    </section>
  );
};

export default SubsectionContent;
