/* eslint-disable no-restricted-globals */
import Integrations from "./Integrations/Integrations";
import Organization from "./Organization/Organization";
import { useNavigate } from "react-router-dom";
import Groups from "./Groups/Groups";
import { motion } from "framer-motion";
import { showVariants } from "../../constants/general";
import Users from "./Users/Users";
import Roles from "./Roles/Roles";
import Environments from "./Environments/Environments";
import { checkIsSuperOrSiteAdmin, parseURL } from "../../utils/general";
import PageLayout from "../../layouts/PageLayout";
import { GetEnabledModules } from "src/services/general/general";
import PrivacyReview from "./PrivacyReview/PrivacyReview";
import BCM from "./BCM";
import { useEffect } from "react";

const Settings = () => {
  const navigate = useNavigate();
  const parsed = parseURL();
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();

  const navItems = isSuperOrSiteAdmin
    ? ["organization", "users", "roles"]
    : [
        "organization",
        "integrations",
        "environments",
        "groups",
        "users",
        "roles",
        "privacy review",
      ];

  const { data: enabledModules } = GetEnabledModules();

  useEffect(() => {
    sessionStorage.page = "Settings";
  }, []);

  return (
    <PageLayout>
      <motion.main
        variants={showVariants}
        initial="hidden"
        animate="visible"
        className="flex flex-col flex-grow content-start gap-6 p-6 w-full min-h-full z-10 overflow-auto scrollbar"
      >
        <section className="flex flex-wrap items-center justify-between gap-10 text-b1-reg">
          <nav className="flex flex-wrap items-center gap-1 p-1 dark:bg-gray-900 rounded-lg">
            {navItems.map((tab) => {
              if (
                enabledModules &&
                !enabledModules.EXTRA &&
                ["environments"].includes(tab)
              )
                return null;

              return (
                <button
                  key={tab}
                  className={`px-4 py-1 text-center capitalize ${
                    parsed.section === tab
                      ? "dark:text-white dark:bg-gray-800"
                      : "dark:text-gray-500 dark:hover:bg-gray-500/30 duration-100"
                  } rounded-lg`}
                  onClick={() =>
                    navigate(
                      `/settings/details?section=${tab.replace("&", "%26")}`
                    )
                  }
                >
                  {tab}
                </button>
              );
            })}
          </nav>
        </section>

        {/* organization page */}
        {parsed.section === "organization" && <Organization />}

        {/* integrations page */}
        {parsed.section === "integrations" && (
          <section className="grid w-full overflow-auto scrollbar">
            <Integrations />
          </section>
        )}

        {parsed.section === "environments" && <Environments />}

        {/* groups page */}
        {parsed.section === "groups" && (
          <section className="grid w-full">
            <Groups />
          </section>
        )}

        {/* users page */}
        {parsed.section === "users" && <Users />}

        {/* roles page */}
        {parsed.section === "roles" && <Roles />}

        {parsed.section === "privacy review" && <PrivacyReview />}

        {parsed.section === "bcm" && <BCM />}
      </motion.main>
    </PageLayout>
  );
};

export default Settings;
