/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useState } from "react";
import { KeyNumVal, KeyStringVal } from "src/types/general";
import {
  convertToUTCShortString,
  checkIsSuperOrSiteAdmin,
  checkIsSuperOrGRCAdmin,
  checkIsReadOnly,
} from "src/utils/general";
import { useNavigate } from "react-router-dom";
import { pageSize } from "src/constants/general";
import TablePagination from "src/components/General/TablePagination";
import { GetFrameworksOrCirculars } from "src/services/regulation-policy/framework";
import Loader from "src/components/Loader/Loader";
import AllTags from "../../../components/GRC/AllTags";
import SelectFrameworks from "./SelectFrameworks";
import RegionFilter from "src/components/Filter/RegulationPolicy/RegionFilter";
import VerticalFilter from "src/components/Filter/RegulationPolicy/VerticalFilter";
import NewDocument from "./NewDocument/NewDocument";
import Sort from "../../../components/GRC/Sort";
import { frameworkSortingTypes } from "src/constants/grc";
import EditDocument from "./EditDocument";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import RegAuthFilter from "src/components/Filter/RegulationPolicy/RegAuthFilter";
import { checkIsAdmin } from "../../../utils/general";
import { GetAvailableFrameworks } from "../../../services/regulation-policy/framework";
import Filters from "./Filters";
import RowValue from "src/components/General/RowValue";

const Documents = ({ documentType }: { documentType: string }) => {
  const navigate = useNavigate();
  const isReadOnly = checkIsReadOnly();
  const isSuperOrSiteAdmin = checkIsSuperOrSiteAdmin();
  const isSuperOrGRCAdmin = checkIsSuperOrGRCAdmin();
  const isAdmin = checkIsAdmin();
  const isFramework = documentType === "frameworks";

  const [filters, setFilters] = useState<any>({
    regulatory_authority: "All",
    regions: [],
    verticals: [],
    mapped_to_policy: "",
    in_progress: false,
  });
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [selectedTags, setSelectedTags] = useState<string[]>([]);
  const [selectFramework, setSelectFramework] = useState<boolean>(false);
  const [sort, setSort] = useState<KeyStringVal>({
    direction: "",
    order_by: "",
  });

  const { data: documents, status: documentsStatus } = GetFrameworksOrCirculars(
    documentType,
    filters.regulatory_authority,
    filters.regions,
    filters.verticals,
    filters.mapped_to_policy,
    pageNumber,
    selectedTags,
    sort
  );

  const { data: availableFrameworks } = GetAvailableFrameworks(pageNumber);

  const filteredDocuments = filters.in_progress
    ? documents?.data?.filter((doc: KeyNumVal) => doc.estimated_time_left > 0)
    : documents?.data;
  const totalCount = documents?.pager.total_results || 0;
  const totalPages = documents?.pager.num_pages || 0;
  const beginning =
    pageNumber === 1 ? 1 : pageSize * ((pageNumber || 1) - 1) + 1;
  const end = pageNumber === totalPages ? totalCount : beginning + pageSize - 1;
  const regulatoryAuthorities = [
    ...new Set(
      documents?.data?.reduce(
        (pV: string[], cV: KeyStringVal) => [...pV, cV.regulatory_authority],
        []
      )
    ),
  ] as string[];

  const goToDocument = (document: KeyStringVal) => {
    sessionStorage.GRCCategory = documentType;
    navigate(
      `/regulation-policy/document/details?document_type=${documentType}&document_id=${document.id}`
    );
  };

  useEffect(() => {
    setFilters({
      regulatory_authority: "All",
      regions: [],
      verticals: [],
      mapped_to_policy: "",
      in_progress: false,
    });
  }, [documentType]);

  const Document = ({ docList }: { docList: any }) => {
    return (
      <ul className="flex flex-col flex-grow gap-4 overflow-auto scrollbar">
        {docList?.map((document: any) => {
          return (
            <li
              key={document.id}
              className="grid gap-3 px-6 py-4 dark:bg-gray-800 hover:p-6 cursor-pointer dark:hover:bg-gray-700/70 duration-100 rounded-xl"
              onClick={() => goToDocument(document)}
            >
              <header className="flex flex-col flex-grow gap-1">
                <article className="flex items-center justify-between gap-10">
                  <article className="flex items-center gap-2">
                    <h4 className="text-b1-reg">{document.name}</h4>
                    <RowValue row={document} col="status" />
                  </article>
                  <article className="flex flex-wrap items-center gap-4">
                    {(!isFramework ||
                      (isFramework &&
                        (isSuperOrSiteAdmin || isSuperOrGRCAdmin))) && (
                      <EditDocument
                        documentType={documentType}
                        documentID={document.id}
                      />
                    )}
                  </article>
                </article>
                <p className="text-b2-reg dark:text-gray-400">
                  {document.regulatory_date &&
                    convertToUTCShortString(Number(document.regulatory_date))}
                </p>
              </header>
              {document.additional_regulatory_authorities?.length > 0 && (
                <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
                  <span>Additional Regulatory Authorities</span>
                  {document.additional_regulatory_authorities.map(
                    (auth: string, index: number) => {
                      return (
                        <span key={index} className="dark:text-blue-500">
                          {auth}
                        </span>
                      );
                    }
                  )}
                </article>
              )}
              {document.tags?.length > 0 && (
                <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max text-b2-reg dark:bg-gray-700 rounded">
                  <span>Tags</span>
                  {document.tags.map((tag: string, index: number) => {
                    return (
                      <span key={index} className="dark:text-blue-500">
                        {tag}
                      </span>
                    );
                  })}
                </article>
              )}
              {document.regions?.length > 0 && (
                <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max break-all text-b2-reg dark:bg-gray-700 rounded">
                  <span>Regions</span>
                  {document.regions.map((region: string, index: number) => {
                    return (
                      <span key={index} className="dark:text-blue-500">
                        {region}
                      </span>
                    );
                  })}
                </article>
              )}
              {document.verticals?.length > 0 && (
                <article className="flex flex-wrap items-center gap-2 px-2 py-1 w-max break-all text-b2-reg dark:bg-gray-700 rounded">
                  <span>Verticals</span>
                  {document.verticals.map((vertical: string, index: number) => {
                    return (
                      <span key={index} className="dark:text-blue-500">
                        {vertical}
                      </span>
                    );
                  })}
                </article>
              )}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <section className="flex flex-col flex-grow gap-4 py-4 w-full h-full">
      {sessionStorage.select_framework ? (
        <SelectFrameworks
          regAuth={filters.regulatory_authority}
          setSelectFramework={setSelectFramework}
        />
      ) : (
        <>
          <section className="grid gap-3">
            <header className="flex items-start justify-between gap-10">
              <article className="flex flex-wrap items-center gap-4">
                <RegAuthFilter
                  inputs={filters}
                  setInputs={setFilters}
                  isFramework={isFramework}
                />
                <RegionFilter
                  label="Regions"
                  keyName="regions"
                  inputs={filters}
                  setInputs={setFilters}
                />
                <VerticalFilter
                  label="Verticals"
                  keyName="verticals"
                  inputs={filters}
                  setInputs={setFilters}
                />
              </article>
              <article className="flex items-center gap-4">
                <Sort
                  sortingTypes={frameworkSortingTypes}
                  sort={sort}
                  setSort={setSort}
                />

                <Filters
                  filters={filters}
                  setFilters={setFilters}
                  isFramework={isFramework}
                />
              </article>
            </header>
          </section>
          <article className="flex items-center gap-4 place-content-end">
            {isFramework ? (
              <>
                {isSuperOrGRCAdmin && (
                  <NewDocument documentType={documentType} />
                )}
                {isAdmin && availableFrameworks?.data?.length > 0 && (
                  <button
                    disabled={isReadOnly}
                    className="blue-button"
                    onClick={() => {
                      setSelectFramework(true);
                      sessionStorage.select_framework = "true";
                    }}
                  >
                    <FontAwesomeIcon icon={faPlus} />
                    <h4>Select Frameworks</h4>
                  </button>
                )}
              </>
            ) : (
              <NewDocument documentType={documentType} />
            )}
          </article>
          <AllTags
            documentType="frameworks"
            selectedTags={selectedTags}
            setSelectedTags={setSelectedTags}
          />
          {documentsStatus === "loading" ? (
            <Loader />
          ) : documents?.data ? (
            <section className="flex flex-col flex-grow gap-4 overflow-auto scrollbar">
              {filteredDocuments?.length > 0 ? (
                <section className="flex flex-col flex-grow gap-3 pb-4 w-full h-full overflow-auto scrollbar">
                  {filters.regulatory_authority === "All" ? (
                    <section className="flex flex-col flex-grow gap-4 pb-24">
                      {regulatoryAuthorities?.map((auth) => {
                        const filtered = filteredDocuments?.filter(
                          (document: KeyStringVal) =>
                            document.regulatory_authority === auth ||
                            document.additional_regulatory_authorities?.includes(
                              auth
                            )
                        );
                        return (
                          <article
                            key={auth}
                            className="grid gap-3 px-6 py-4 dark:bg-gray-800 rounded-xl"
                          >
                            <h3 className="text-subt1-semi">{auth}</h3>
                            <Document docList={filtered} />
                          </article>
                        );
                      })}
                      {filters.regulatory_authority !== "All" && (
                        <TablePagination
                          totalPages={totalPages}
                          beginning={beginning}
                          end={end}
                          totalCount={totalCount}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                        />
                      )}
                    </section>
                  ) : (
                    <Document docList={filteredDocuments} />
                  )}
                </section>
              ) : (
                <section className="flex items-center place-content-center gap-10 w-full h-full">
                  <img
                    src={`/grc/${documentType}-placeholder.svg`}
                    alt={`${documentType} placeholder`}
                    className="w-40 h-40"
                  />
                  <article className="grid gap-3">
                    <h4 className="text-t1-bold">
                      {isFramework
                        ? "Regulatory Frameworks & Standards"
                        : "Circulars"}
                    </h4>
                    <h4>No {documentType} available</h4>
                    {isSuperOrGRCAdmin && (
                      <NewDocument documentType={documentType} />
                    )}
                  </article>
                </section>
              )}
            </section>
          ) : null}
        </>
      )}
    </section>
  );
};

export default Documents;
