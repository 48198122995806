import React, { Fragment, useState } from "react";
import UserInput from "../../../../../../../components/Input/UserInput";
import { Popover, Transition } from "@headlessui/react";
import { faNoteSticky } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PostVendorResponseNote } from "../../../../../../../services/third-party-risk/vendors/vendors";

const Notes = ({
  vendorID,
  assessmentID,
  questionID,
  question,
}: {
  vendorID: string;
  assessmentID: string;
  questionID: string;
  question: any;
}) => {
  const selected = question.generated_id === questionID;

  const [inputs, setInputs] = useState({
    user_id: selected ? question.assigned_to_user || "" : "",
    notes: selected ? question.notes || "" : "",
  });

  const postNote = PostVendorResponseNote(vendorID, assessmentID, questionID);

  return (
    <Popover className="relative w-4/5 text-b2-reg">
      <Popover.Button className="flex items-center gap-1 dark:hover:bg-filter/30 duration-100">
        <h4>
          <FontAwesomeIcon
            icon={faNoteSticky}
            className={`${question.notes ? "text-blue-500" : ""}`}
          />{" "}
          {question.notes ? "Notes" : "Add Notes"}
        </h4>
      </Popover.Button>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="opacity-0 translate-y-1"
        enterTo="opacity-100 translate-y-0"
        leave="transition ease-in duration-150"
        leaveFrom="opacity-100 translate-y-0"
        leaveTo="opacity-0 translate-y-1"
        beforeEnter={() =>
          setInputs({
            user_id: selected ? question.assigned_to_user || "" : "",
            notes: selected ? question.notes || "" : "",
          })
        }
      >
        <Popover.Panel className="pointer-events-auto absolute top-8 grid gap-2 p-4 dark:bg-gray-800 rounded z-10">
          <UserInput
            label="Assigned to"
            keyName="user_id"
            inputs={inputs}
            setInputs={setInputs}
            allowUnselect
          />
          <article className="grid gap-2">
            <h4>Notes</h4>
            <textarea
              value={inputs.notes}
              onChange={(e) => setInputs({ ...inputs, notes: e.target.value })}
              className="px-4 py-2 w-full h-32 focus:outline-none text-b2-reg placeholder:text-b2-reg dark:placeholder:text-gray-500 dark:disabled:text-checkbox dark:bg-gray-900 dark:border-transparent dark:focus:ring dark:focus:ring-offset-blue-500/50 dark:focus:ring-offset dark:focus:border dark:focus:border-blue-500 resize-none rounded"
            />
          </article>
          <button
            disabled={
              postNote.status === "loading" ||
              Object.values(inputs).includes("")
            }
            className="place-self-end small-blue-button"
            onClick={() =>
              postNote.mutate({
                user: inputs.user_id,
                notes: inputs.notes,
              })
            }
          >
            Save
          </button>
        </Popover.Panel>
      </Transition>
    </Popover>
  );
};

export default Notes;
