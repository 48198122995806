/* eslint-disable no-restricted-globals */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetPolicyGroup,
  UpdatePolicyGroup,
} from "src/services/regulation-policy/policy";
import { useEffect, useState } from "react";
import ModalLayout from "src/layouts/ModalLayout";
import RegularInput from "src/components/Input/RegularInput";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { checkIsReadOnly } from "src/utils/general";

const EditPolicyGroup = ({ policyGroupID }: { policyGroupID: string }) => {
  const isReadOnly = checkIsReadOnly();

  const [show, setShow] = useState<boolean>(false);
  const [inputs, setInputs] = useState<any>({
    title: "",
    description: "",
  });

  const { data: policyGroup } = GetPolicyGroup(policyGroupID);
  const editPolicyGroup = UpdatePolicyGroup();

  const handleOnClose = () => setShow(false);

  useEffect(() => {
    if (policyGroup && inputs.title === "" && inputs.description === "")
      setInputs({
        title: policyGroup.title,
        description: policyGroup.description,
      });
  }, [policyGroup]);

  return (
    <>
      <button
        disabled={isReadOnly}
        className="white-text-button"
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={faEdit} />
        Edit
      </button>
      <ModalLayout showModal={show} onClose={handleOnClose}>
        <section className="grid gap-4">
          <h4 className="text-lg">Edit Policy Group</h4>
          <RegularInput
            label="Title"
            keyName="title"
            inputs={inputs}
            setInputs={setInputs}
          />
          <RegularInput
            label="Description"
            keyName="description"
            inputs={inputs}
            setInputs={setInputs}
          />
          <article className="flex items-center place-content-end gap-4">
            <button className="black-button" onClick={handleOnClose}>
              Cancel
            </button>
            <button
              disabled={inputs.title === ""}
              className="blue-button"
              onClick={() => {
                handleOnClose();
                editPolicyGroup.mutate({
                  policyGroupID: policyGroupID,
                  title: inputs.title,
                  description: inputs.description,
                });
              }}
            >
              Save
            </button>
          </article>
        </section>
      </ModalLayout>
    </>
  );
};

export default EditPolicyGroup;
