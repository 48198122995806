/* eslint-disable no-restricted-globals */
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DropdownLayout from "../../../layouts/DropdownLayout";
import { KeyStringVal } from "src/types/general";

const DocumentSectionFilter = ({
  inputs,
  setInputs,
  list,
}: {
  inputs: KeyStringVal;
  setInputs: (inputs: KeyStringVal) => void;
  list: KeyStringVal[];
}) => {
  const value = inputs.source_section_title
    ? `${
        !["", null, "-"].includes(inputs.source_section_id)
          ? inputs.source_section_id
          : ""
      } ${inputs.source_section_title}`
    : "";
  return (
    <DropdownLayout
      label={`Document ${inputs.selected_tab}`}
      value={value}
      width="w-[30rem]"
      placeholder="Select"
    >
      {list?.map((section: KeyStringVal, index: number) => {
        const selected = inputs.source_generated_id === section.generated_id;

        return (
          <button
            key={index}
            className={`relative flex items-center gap-2 px-4 py-1 text-left break-words dark:disabled:hover:bg-transparent dark:hover:bg-gray-800 duration-100 ${
              selected ? "dark:bg-gray-800" : ""
            } rounded-lg`}
            onClick={() =>
              setInputs({
                ...inputs,
                section_id: section.section_id,
                source_section_id: section.sub_section_id,
                source_section_title: section.sub_section_title,
                source_section_generated_id: section.generated_id,
                content: section.content,
              })
            }
          >
            {selected && <FontAwesomeIcon icon={faCheck} />}
            <p>
              {!["", null, "-"].includes(section.sub_section_id) ? (
                <span>
                  {section.sub_section_id}
                  {section.sub_section_title}
                </span>
              ) : (
                section.mapping_text
              )}
            </p>
          </button>
        );
      })}
    </DropdownLayout>
  );
};

export default DocumentSectionFilter;
